import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';


  export default function CreateCampaign(props){
    let { showBatchRange,handleBatchRangeDropdown,selectBatchRange,selectBatchSize,formBatchRange, AllEmpTotal, handleSendTypeCheck,campaignFormData,handleTestUserCheck, handleCampaignInput, handleMessageInput, handleCampaignSubmit,employees,
        allEmployeesList, handleCampaignEmployees, EmpIndexA, EmpIndexB, prevEmpPage, nextEmpPage, SelectEmpPage, 
        currentEmpPage, SelectAllEmployees, selectAllEmp, showEmployeeFilter, handleEmpDropdown, EmployeFilterList, 
        handleEmpFilterSelect, empSelectFilter, LanguageObj, CurrentLanguage,
      
        currFilterLevel,showLevelFilter,handleEmpLevelDropdown,handleLevelSelect,
        currFilterValue, showValueFilter, handleEmpValueDropdown, handleValueSelect, allCustomerList,

        employeeValueSearch, handleEmployeeValueSearch, getIndexByFilterDMGData,handleGetEmployees
      
      
      } = props;


      let [ showSizeDrop, handleSizeDrop ] = React.useState(false)
      let [showCampaignTypeDropdown, handleshowCampaignTypeDropdown]= React.useState(false);


    let CustomerList = allCustomerList;


    //  let NeglectedList = ["name","list","isTest", "isDisable", "Age","Alternatenumber", "emp_id","actionPlanStage", "ManagerPID","id","mngr_id","createdAt", "companyId", "currentStage"]
     let NeglectedList = ["name","list"]

     let EmployeesList = [];

     let EmpLevelList = [];
     let EmpValueList = [];
     let EmpValueObj = {};

     if(allEmployeesList && allEmployeesList.length>0){
       
       let tempLevelList = Object.keys(getIndexByFilterDMGData);
       if(tempLevelList && tempLevelList.length>0){
        tempLevelList.forEach((temp)=>{
          if(NeglectedList.findIndex(prev=>prev===temp)===-1){
            EmpLevelList.push(temp)
          }
        });
       }

       if(EmpLevelList){
         EmpLevelList.forEach((filter)=>{
           EmpValueObj[filter] = [];
 
           allEmployeesList.forEach((emp)=>{
             let getIndex = EmpValueObj[filter].findIndex(prev=>prev===emp[filter]);
             if(getIndex===-1 && emp && emp[filter]){
               EmpValueObj[filter].push(emp[filter]);
             }
           });
         });
       }


      if(currFilterLevel!=="Select Filter" && currFilterValue!=="Select Value"){
        allEmployeesList.forEach((emp)=>{
          if(emp && (emp[currFilterLevel] === currFilterValue)){
            EmployeesList.push(emp);
          }
         });
      }
      else{
        EmployeesList = allEmployeesList;
      }


       
      }
      if(currFilterLevel && getIndexByFilterDMGData && getIndexByFilterDMGData[currFilterLevel]){
        EmpValueList = getIndexByFilterDMGData[currFilterLevel];
      }


      console.log('EmployeesList: ',EmployeesList);

      console.log('EmpLevelList: ',EmpLevelList);
      console.log('EmpValueList: ',EmpValueList);




      
  //--------------------------------------------------------------------------------

    let IndexList = []
    let pageCount = 7
    let IndexListIndex = []
    let indexA=0
    let indexB=0

    if(EmployeesList && EmployeesList.length>0){
    

     if(EmployeesList && EmployeesList.length>0){
      EmployeesList.forEach((item,index)=>{
        if(index % pageCount === 0){
          IndexList.push(index)
        }
        if(index===(EmployeesList.length-1)){
          IndexList.push(index)
        }
      })
     }
     
     console.log('IndexList: ',IndexList)

     if(IndexList && IndexList.length>0){
      IndexList.forEach((item,index)=>{
        if(index!==(IndexList.length-1)){
          IndexListIndex.push(index+1)
        }
      })
     }
    //  console.log('IndexListIndex: ',IndexListIndex)





     if(IndexListIndex && IndexListIndex.length>0){
      IndexListIndex.forEach((item,index)=>{
        if(item === currentEmpPage){

          if(index<(IndexList.length-2)){
            indexA=IndexList[index];
            indexB=IndexList[index]+(pageCount-1)
          }
          else{
            indexA=IndexList[index];
            indexB=IndexList[IndexList.length-1]
          }
        }
      })
     }

  }
     //--------------------------------------------------------------------------------

    let initSize = formBatchRange && formBatchRange.size?formBatchRange.size:200;

     function getBatchRange(total){
      let rangeList=[]
      if(total && total>0){
        [...Array(total).keys()].forEach((item,index)=>{
          if(index % initSize === 0){
            rangeList.push(index)
          }
          if(index===([...Array(total).keys()].length-1)){
            rangeList.push(index)
          }
        })
       }
      let RangeNameList = []
      if(rangeList && rangeList.length>0){
        rangeList.forEach((item,index)=>{
          if((index+1)!=rangeList.length){
            let front = (item+1).toString();
            let end = (item+initSize).toString();
            if((index+1)===(rangeList.length-1)){
              end = total.toString()
            }
            let temp={
              "label":front + " - " + end,
              "rangeNo":index+1
            }
            RangeNameList.push(temp);
          }
        });
      }
      return RangeNameList
     }


     let RangeLabelList = getBatchRange(AllEmpTotal);
 
     console.log('Range: ',RangeLabelList)
     console.log('AllEmpTotal: ',AllEmpTotal)

     let sizeList = [200,500,1000,2000,3000,5000]




     let { campaignTypeList, handleCampaignType } = props;



     console.log("employeeValueSearch: ",employeeValueSearch)

    return (
      <>
    <div className="xl:flex lg:flex mx-4">

      <div className="bg-white  rounded-md  xl:mb-0 lg:mb-0 mb-2 w-8/12" >
        <h2 className="px-4 bg-blue-500 py-2 text-white rounded-t-md">{LanguageObj && LanguageObj["create_campaign"+"_"+CurrentLanguage]? LanguageObj["create_campaign"+"_"+CurrentLanguage]:"Create Campaign"}</h2>
        <div className="px-4 py-4 ">
          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["campaign_title"+"_"+CurrentLanguage]? LanguageObj["campaign_title"+"_"+CurrentLanguage]:"Campaign Title"}</label><br />
          <input type="text" name="title" value={campaignFormData.title} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["campaign_title"+"_"+CurrentLanguage]? LanguageObj["campaign_title"+"_"+CurrentLanguage]:"Campaign Title"} className="text-sm text-gray-900 outline-none border-b py-1.5 w-full" /><br /><br />
          
          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["email_subject"+"_"+CurrentLanguage]? LanguageObj["email_subject"+"_"+CurrentLanguage]:"Subject"}</label><br />
          <input type="text" name="subject" value={campaignFormData.subject} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["subject"+"_"+CurrentLanguage]? LanguageObj["subject"+"_"+CurrentLanguage]:"Subject"} className="text-sm text-gray-900 outline-none border-b py-1.5 w-full" /><br /><br />
          
          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["emailName"+"_"+CurrentLanguage]? LanguageObj["emailName"+"_"+CurrentLanguage]:"Email Name"}</label><br />
          <input type="text" name="emailName" value={campaignFormData.emailName} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["emailName"+"_"+CurrentLanguage]? LanguageObj["emailName"+"_"+CurrentLanguage]:"Email Name"} className="text-sm text-gray-900 outline-none border-b py-1.5 w-full" /><br /><br />
          

          <label className="text-xs text-gray-700 font-medium">Campaign Type</label><br />

          <div className="cursor-pointer my-4 bg-white w-full border text-black flex justify-between px-2 py-1.5 items-center relative">
              <span className=" text-black text-xs font-semibold pr-4 w-full" onClick={()=>handleshowCampaignTypeDropdown(!showCampaignTypeDropdown)}>{
              campaignFormData && campaignFormData.campaign_type?campaignFormData.campaign_type:"Select Campaign Type"
              }</span> 
              <span className="material-icons text-gray-500" onClick={()=>handleshowCampaignTypeDropdown(!showCampaignTypeDropdown)}>expand_more</span>

              {showCampaignTypeDropdown?
                    <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500 overflow-hidden z-10">
                      {campaignTypeList && campaignTypeList.length>0?
                        campaignTypeList.map((item,index)=>
                          (item!==campaignFormData.campaign_type)?
                          <span onClick={()=>{
                            handleCampaignType(item);
                            handleshowCampaignTypeDropdown(!showCampaignTypeDropdown);
                          }}
                          className={(campaignFormData.campaign_type===item)?"inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100":
                          "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}> 
                          {item}</span>
                          :null
                        )
                      :null}
                    </div>
              :null}


          </div>



          <div className=" py-2"  >
            <label htmlFor="mandatory-check" className="text-xs font-semibold pb-8">{"Sending Option:-"}</label>
            <div className="flex py-2" >
              <div onClick={()=>handleSendTypeCheck("EMAIL")} className={campaignFormData.type==="EMAIL"?"mx-1 bg-blue-500 px-4 p-1 text-white  cursor-pointer":"mx-1 bg-gray-300 px-3 p-1 text-gray-500  cursor-pointer"}>EMAIL</div>
              <div onClick={()=>handleSendTypeCheck("MOBILE")} className={campaignFormData.type==="MOBILE"?"mx-1 bg-blue-500 px-4 p-1 text-white  cursor-pointer":"mx-1 bg-gray-300 px-3 p-1 text-gray-500  cursor-pointer"}>MOBILE</div>
            </div>
          </div>
          
          {campaignFormData.type==="EMAIL"?
          <>
          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["email_message"+"_"+CurrentLanguage]? LanguageObj["email_message"+"_"+CurrentLanguage]:"Email Message"}</label><br />
          <div className="overflow-y-auto" style={{height:'150px'}}>
            <ReactQuill className="w-12/12" theme="snow" value={campaignFormData.message} onChange={handleMessageInput} placeholder={"Message"}/>
          </div>
          </>
          :
          <div className="text-blue-400 text-xs">SMS Message will be system generated*</div>
          }
          <br />

          
          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["team_name"+"_"+CurrentLanguage]? LanguageObj["team_name"+"_"+CurrentLanguage]:"Team Name"}</label><br />
          <input type="text" name="teamname" value={campaignFormData.teamname} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["team_name"+"_"+CurrentLanguage]? LanguageObj["team_name"+"_"+CurrentLanguage]:"Team Name"} className="text-sm text-gray-900 outline-none border-b py-1.5 w-full" /><br /><br />
          <p className="text-xs font-semibold">{LanguageObj && LanguageObj["no_of_user_selected"+"_"+CurrentLanguage]? LanguageObj["no_of_user_selected"+"_"+CurrentLanguage]+": ":"No. of user selected: "} <span className="class pl-2 text-sm">{employees.length}</span></p>
          <br/>


          <div className="mb-4">
          <input
          id="mandatory-check"
          value={campaignFormData.isTest}
          checked={campaignFormData.isTest}
          type="checkbox"
          onChange={()=>{}}
          className="cursor-pointer"
          onClick={()=>handleTestUserCheck(!campaignFormData.isTest)}
          />
          <label htmlFor="mandatory-check" className="text-xs font-semibold pb-8 pl-2">{LanguageObj && LanguageObj["test_user"+"_"+CurrentLanguage]? LanguageObj["test_user"+"_"+CurrentLanguage]+": ":"Test User"}</label>
        </div>





          <div className="flex justify-center py-6">
            <button onClick={()=>handleCampaignSubmit()} className="bg-blue-500 text-white rounded py-2.5 px-6 text-sm hover:shadow-lg">{LanguageObj && LanguageObj["send_survey"+"_"+CurrentLanguage]? LanguageObj["send_survey"+"_"+CurrentLanguage]:"SEND SURVEY"}<span className="fa fa-paper-plane px-2" /></button>
          </div>
        </div>
      </div> 




      {campaignFormData.campaign_type==="NORMAL" || campaignFormData.campaign_type===""?

      <div className="bg-white w-full  xl:ml-4 lg:ml-4 rounded-md">
        <h2 className="px-4 bg-blue-500 py-2 text-white rounded-t-md">{LanguageObj && LanguageObj["employees"+"_"+CurrentLanguage]? LanguageObj["employees"+"_"+CurrentLanguage]:"Employees"}</h2>
        <div className="px-4 py-4 ">
          {/* serch and button */}
          
          
          <div className="border rounded-md pb-2 border-gray-300">
          
          
          <div className="flex justify-left my-2  z-20" style={{zIndex:'200'}}>



            <div className="ml-4 mt-1.5 font-bold text-blue-500">Filter</div>
              <div className="cursor-pointer bg-white mx-1.5 w-40 border text-black flex justify-between px-4 items-center relative py-1.5">
                <span className=" text-black text-xs font-semibold w-full pr-4 overflow-hidden" onClick={()=>handleEmpLevelDropdown(showLevelFilter)}>{currFilterLevel}</span> 
                <span className="material-icons text-gray-500" onClick={()=>handleEmpLevelDropdown(showLevelFilter)}>expand_more</span>

                {showLevelFilter?
                      <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500 overflow-y-auto" style={{height:'400px',zIndex:'200'}}>
                            <span onClick={()=>handleLevelSelect("All")} className={(false)?"inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100":"inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}> 
                            {"All"}</span>
                        {EmpLevelList && EmpLevelList.length>0?
                          EmpLevelList.map((item,index)=>
                            <span onClick={()=>handleLevelSelect(item)} className={(currFilterLevel===item)?"inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100":"inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}> 
                            {item}</span>
                          )
                        :null}
                      </div>
                :null}


              </div>

            {currFilterLevel?
            <>
              <div className="ml-4 mt-1.5 font-bold text-blue-500">Filter Value</div>
              <div className="cursor-pointer bg-white mx-1.5 w-96 border text-black flex justify-between px-4 items-center relative py-1.5">
                <span style={{height:"20px"}} className=" text-black text-xs font-semibold w-full pr-4 overflow-hidden" 
                onClick={()=>handleEmpValueDropdown(showValueFilter)}>{currFilterValue}</span> 
                <span className="material-icons text-gray-500" onClick={()=>handleEmpValueDropdown(showValueFilter)}>expand_more</span>
  
                {showValueFilter?
                      <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500 overflow-y-auto" style={{height:'400px',zIndex:'200'}}>
                        {EmpValueList && EmpValueList.length>0?
                          EmpValueList.map((item,index)=>
                            <span onClick={()=>handleValueSelect(item)} className={(currFilterValue===item)?"inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100":"inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}> 
                            {item}</span>
                          )
                        :null}
                      </div>
                :null}
  
  
              </div>
                </>
            :null}







</div>

{/* ------------------------------------------------- */}
          
          
          
          
          
          
          
          
          
          <div className="flex justify-left " >

            
          {/* <div className="ml-4 mt-1.5 font-bold text-blue-500">Status</div>
            <div className="cursor-pointer bg-white mx-1.5 w-40 border text-black flex justify-between px-4 items-center relative py-1.5">
              <span className=" text-black text-xs font-semibold w-full pr-4" onClick={()=>handleEmpDropdown(showEmployeeFilter)}>{empSelectFilter.template===1?LanguageObj && LanguageObj["show_all"+"_"+CurrentLanguage]? LanguageObj["show_all"+"_"+CurrentLanguage]:empSelectFilter.name:empSelectFilter.name}</span> 
              <span className="material-icons text-gray-500" onClick={()=>handleEmpDropdown(showEmployeeFilter)}>expand_more</span>

              {showEmployeeFilter?
                    <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500 overflow-hidden" style={{zIndex:"200"}}>
                      {EmployeFilterList && EmployeFilterList.length>0?
                        EmployeFilterList.map((item,index)=>
                          <span onClick={()=>handleEmpFilterSelect(item)} className={(empSelectFilter.template===item.template)?"inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100":"inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}> 
                          {item.name}</span>
                        )
                      :null}
                    </div>
              :null}


            </div> */}



                      {/* //SIZE */}
          <div className="ml-4 mt-1.5 font-bold text-blue-500">Batch Size</div>
            <div className="cursor-pointer bg-white mx-1.5 w-40 border text-black flex justify-between px-4 items-center relative py-1.5">
              <span className=" text-black text-xs font-semibold w-full pr-4" onClick={()=>handleSizeDrop(!showSizeDrop)}>
                {formBatchRange&&formBatchRange.size?formBatchRange.size:sizeList && sizeList.length?sizeList[0]:"200"}</span> 
              <span className="material-icons text-gray-500" onClick={()=>handleSizeDrop(!showSizeDrop)}>expand_more</span>

              {showSizeDrop?
                    <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500 overflow-hidden" >
                      {sizeList && sizeList.length>0?
                        sizeList.map((item,index)=>
                          <span onClick={()=>{
                            selectBatchSize(item);
                            handleSizeDrop(false);
                          }} 
                          className={(formBatchRange.size===item)?
                          "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100":
                          "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}> 
                          {item}</span>
                        )
                      :null}
                    </div>
              :null}


            </div>


            

            {/* Batch Range */}
            <div className="ml-4 mt-1.5 font-bold text-blue-500">Batch Range</div>
            <div className="cursor-pointer bg-white mx-1.5 w-40 border text-black flex justify-between px-4 items-center relative py-1.5">
              <span className=" text-black text-xs font-semibold w-full pr-4" onClick={()=>handleBatchRangeDropdown(!showBatchRange)}>
                {formBatchRange&&formBatchRange.label?formBatchRange.label:RangeLabelList && RangeLabelList.length?RangeLabelList[0].label:"1-200"}</span> 
              <span className="material-icons text-gray-500" onClick={()=>handleBatchRangeDropdown(!showBatchRange)}>expand_more</span>

              {showBatchRange?
                    <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500 overflow-hidden" >
                      {RangeLabelList && RangeLabelList.length>0?
                        RangeLabelList.map((item,index)=>
                          <span onClick={()=>selectBatchRange(item)} 
                          className={(formBatchRange.rangeNo===item.rangeNo)?
                          "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100":
                          "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}> 
                          {item.label}</span>
                        )
                      :null}
                    </div>
              :null}


            </div>




            <button onClick={()=>handleGetEmployees()} className="ml-2 rounded-md bg-blue-400 hover:bg-green-500 p-2 text-white  text-center" >Get Employees</button>



                  

          </div>

          </div>





          <div className="flex my-2 pt-2 ">
            <div className="ml-4 font-bold text-blue-500 mt-1.5">Search: </div>
            <input className="mx-2 px-2 py-1.5 border  rounded-sm w-5/12" 
            placeholder="Search employee by name, email.. " type="text" 
            value={employeeValueSearch} onChange={handleEmployeeValueSearch}  />
          </div>




          {/* <div className="ml-4 mt-1.5 font-bold ">
            <span className="text-blue-500">Total: </span>
            <span className="text-gray-500">{EmployeesList && EmployeesList.length>0?" "+(EmployeesList.length).toString():" 0"}</span>
          </div> */}



            


            {/* <button className="bg-blue-500 text-white px-4 py-2 text-xs rounded">CREATE CAMPAIGN</button> */}
     
          <div className="flex flex-col mt-8 ">
            <div className="-my-2 py-2 overflow-x-auto whitespace-nowrap sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block min-w-full overflow-hidden  sm:rounded-lg border border-gray-200">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                        <input type="checkbox" checked={selectAllEmp} onClick={()=>SelectAllEmployees(selectAllEmp,EmployeesList)} name="list-check" className="mr-4" />
                        {LanguageObj && LanguageObj["name_label"+"_"+CurrentLanguage]? LanguageObj["name_label"+"_"+CurrentLanguage]:"Name"}</th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                      {LanguageObj && LanguageObj["email_address_label"+"_"+CurrentLanguage]? LanguageObj["email_address_label"+"_"+CurrentLanguage]:"Email Address"}</th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                      {LanguageObj && LanguageObj["mobile_no_label"+"_"+CurrentLanguage]? LanguageObj["mobile_no_label"+"_"+CurrentLanguage]:"Mobile No	"}</th>
                      {/* <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                      {LanguageObj && LanguageObj["created_date"+"_"+CurrentLanguage]? LanguageObj["created_date"+"_"+CurrentLanguage]:"Created Date"}</th> */}
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 tracking-wider">
                      {LanguageObj && LanguageObj["status_label"+"_"+CurrentLanguage]? LanguageObj["status_label"+"_"+CurrentLanguage]:"Status"}</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">

                      {EmployeesList && EmployeesList.length>0?
                        EmployeesList.map((item,index)=>
                        (index >= indexA && index <=indexB)?
                        <tr>
                          <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 ">
                                <input type="checkbox" value={item.id} checked={employees.some(prev => prev.id === item.id)?true:false} onClick={() => handleCampaignEmployees(item)} onChange={()=>{}} />
                              </div>
                              <div className="ml-4">
                                <div className="text-xs leading-5 text-gray-500">{(item && item.name?item.name:'Not Available').slice(0,15)}</div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="leading-5 text-gray-500 text-xs">{item.email}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs font-medium text-gray-500">
                          {item && item.mobile?item.mobile:'Not Available'}
                          </td>
                          {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs leading-5 text-gray-500">
                          { moment.unix(item.createdAt / 1000).format(" DD MMM, YYYY ")}
                          </td> */}
                          <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center" >
                            {item.isDisable?
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">InActive</span>
                            :
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span>
                            }
                            {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span> */}
                          </td>
                          </tr>
                           :null
                          )
                      :null}

                  </tbody>
                </table>
              </div>
            </div>
          </div>


        </div>
        {/* end */}
        <div className="flex justify-center items-center text-center py-8">
          <span onClick={()=>prevEmpPage()} className="select-none material-icons w-8 h-8 rounded-full bg-gray-400 inline-flex border border-transparent items-center justify-center mx-1 text-sm text-white" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_left</span>
          {IndexListIndex && IndexListIndex.length>0?
              IndexListIndex.map((item,index)=>
                (index >= EmpIndexA && index <= EmpIndexB)?
                  (item===currentEmpPage)?
                  <span onClick={()=>SelectEmpPage(item)} className="cursor-pointer w-8 h-8 rounded-full bg-blue-500 text-white inline-flex border border-transparent items-center justify-center mx-1 text-sm">{item}</span>
                  :
                  <span onClick={()=>SelectEmpPage(item)} className="cursor-pointer w-8 h-8 rounded-full bg-white border  inline-flex  items-center justify-center mx-1 text-sm">{item}</span>
                  :null
              )

          :null}
          {/* <span className="w-8 h-8 rounded-full bg-blue-500 text-white inline-flex border border-transparent items-center justify-center mx-1 text-sm">1</span>
          <span className="w-8 h-8 rounded-full bg-white border  inline-flex  items-center justify-center mx-1 text-sm">2</span>
          <span className="w-8 h-8 rounded-full bg-white border inline-flex  items-center justify-center mx-1 text-sm">3</span> */}
          <span onClick={()=>nextEmpPage(IndexListIndex)} className="select-none material-icons w-8 h-8 rounded-full bg-white text-blue-500 border inline-flex  items-center justify-center mx-1 text-sm" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_right</span>
        </div>
      </div>

      :null}



        {campaignFormData.campaign_type==="CUSTOMER"?

          <div className="bg-white w-full  xl:ml-4 lg:ml-4 rounded-md">
              <h2 className="px-4 bg-blue-500 py-2 text-white rounded-t-md">{LanguageObj && LanguageObj["employees"+"_"+CurrentLanguage]? LanguageObj["employees"+"_"+CurrentLanguage]:"Employees"}</h2>
              <div className="px-4 py-4 ">
                {/* serch and button */}


                <div className="flex flex-col mt-8 ">
                  <div className="-my-2 py-2 overflow-x-auto whitespace-nowrap sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="align-middle inline-block min-w-full overflow-hidden  sm:rounded-lg border border-gray-200">
                      <table className="min-w-full">
                        <thead>
                          <tr>
                            <th className="px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                              <input type="checkbox" checked={selectAllEmp} onClick={()=>SelectAllEmployees(selectAllEmp,CustomerList)} name="list-check" className="mr-4" />
                              {LanguageObj && LanguageObj["name_label"+"_"+CurrentLanguage]? LanguageObj["name_label"+"_"+CurrentLanguage]:"Name"}</th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                            {LanguageObj && LanguageObj["email_address_label"+"_"+CurrentLanguage]? LanguageObj["email_address_label"+"_"+CurrentLanguage]:"Email Address"}</th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                            {LanguageObj && LanguageObj["mobile_no_label"+"_"+CurrentLanguage]? LanguageObj["mobile_no_label"+"_"+CurrentLanguage]:"Mobile No	"}</th>
                            {/* <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                            {LanguageObj && LanguageObj["created_date"+"_"+CurrentLanguage]? LanguageObj["created_date"+"_"+CurrentLanguage]:"Created Date"}</th> */}
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 tracking-wider">
                            {LanguageObj && LanguageObj["status_label"+"_"+CurrentLanguage]? LanguageObj["status_label"+"_"+CurrentLanguage]:"Status"}</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">

                            {CustomerList && CustomerList.length>0?
                              CustomerList.map((item,index)=>
                              (index >= indexA && index <=indexB)?
                              <tr>
                                <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                                  <div className="flex items-center">
                                    <div className="flex-shrink-0 ">
                                      <input type="checkbox" value={item.id} checked={employees.some(prev => prev.id === item.id)?true:false} onClick={() => handleCampaignEmployees(item)} onChange={()=>{}} />
                                    </div>
                                    <div className="ml-4">
                                      <div className="text-xs leading-5 text-gray-500">{(item && item.name?item.name:'Not Available').slice(0,15)}</div>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                  <div className="leading-5 text-gray-500 text-xs">{item.email}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs font-medium text-gray-500">
                                {item && item.mobile?item.mobile:'Not Available'}
                                </td>
                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs leading-5 text-gray-500">
                                { moment.unix(item.createdAt / 1000).format(" DD MMM, YYYY ")}
                                </td> */}
                                <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center" >
                                  {item.isDisable?
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">InActive</span>
                                  :
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span>
                                  }
                                  {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span> */}
                                </td>
                                </tr>
                                :null
                                )
                            :null}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>


              </div>

            </div>
        
        :null}
  
  </div> 

      </>
    );
  }
