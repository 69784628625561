import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class RadarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList:[],
      EmpIndexA:0,
      EmpIndexB:4
    }
  }
  componentDidMount() {

  }

  handleDimensionList=(name)=>{
    let { dimensionValueList } = this.state;
    if(dimensionValueList.findIndex(prev=>prev === name)===-1){
      dimensionValueList.push(name)
      this.setState({ dimensionValueList });
    }
    else{
      let getIndex = dimensionValueList.findIndex(prev=>prev === name);
      dimensionValueList.splice(getIndex, 1);
      this.setState({ dimensionValueList });
      
    }

  }

  // handlePage=(setPage)=>{
  //   this.setState({currentPage:setPage})
  // }


  prevPage=()=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(EmpIndexA>0){
        this.setState({EmpIndexA:EmpIndexA-1, EmpIndexB:EmpIndexB-1});
    }
  }
  nextPage=(IndexListIndex)=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(IndexListIndex && IndexListIndex.length && (EmpIndexB<IndexListIndex.length)){
      this.setState({EmpIndexA:EmpIndexA+1, EmpIndexB:EmpIndexB+1});
    }
  }



  handlePage=(setPage)=>{
    let { handlePageChange } = this.props;
    handlePageChange(setPage)
  }


  render() {

    let { GetParameterLabel,currFilterValue, currFilterName, getDistributionIndexData,getIndexDriverData, currentPage }= this.props;
    let { dimensionValueList, EmpIndexA, EmpIndexB }= this.state;

    // console.log('dimensionValueList:=============> ',dimensionValueList)

    // console.log('getIndexDriverData: ',getIndexDriverData)

    let YAxis= []
    let XAxis= ['Pride','Presence','Performance']
    let XAxisName= ['PrideAvg','PresenceAvg','PerformanceAvg']
    let DataValues = []
    let list = []
    let overallData = {}

    
    if(getDistributionIndexData   && getDistributionIndexData.length>0 && getIndexDriverData){
       list = getDistributionIndexData

        overallData = {
          PrideAvg:  getIndexDriverData.PrideCount,
          PresenceAvg:  getIndexDriverData.PresenceCount,
          PerformanceAvg:  getIndexDriverData.PerformanceCount,
          name: "OVERALL"
        }

        if(list.findIndex(prev=>prev.name === "OVERALL")===-1){
          list.push(overallData)
        }
     
          




      list.forEach((item,index)=>{
        XAxisName.forEach((axis,axisIndex)=>{
          
          if(index!==(list.length-1)){
            DataValues.push({
              value:[axisIndex,index,parseFloat(item[axis]).toFixed(2)],
              itemStyle:{color:
                (parseFloat(overallData[axis]) > parseFloat(item[axis]))?
                  (parseFloat(item[axis])===0)?'#ffb3b3':'rgba(230, 0, 0,'+parseFloat(item[axis])/100+')'
                  :
                  (parseFloat(item[axis])===0)?'#c2f0c2':'rgba(46, 184, 46,'+parseFloat(item[axis])/100+')'
              }
            })
          }
          else{
            DataValues.push({
              value:[axisIndex,index,parseFloat(item[axis]).toFixed(2)],
              itemStyle:{color:(parseFloat(item[axis])===0)?'#b3ecff':'rgba(0, 172, 230,'+parseFloat(item[axis])/100+')'}
            })  
          }

        })
      })



    }

      if(list && list.length>0){
        list.forEach((driver)=>{
          YAxis.push(driver.name)
        })
      }




      // console.log('===================================',list)


      let RadarValueList = []
      let ParaNameList = []

      if(XAxis && XAxis.length>0){
        XAxis.forEach((axisData)=>{
          ParaNameList.push({
            text: GetParameterLabel(axisData), 
            max: 100
          })
        })
      }


      if(list && list.length>0){
        // console.log('list: ',list)

        list.forEach((item,index)=>{



          if(dimensionValueList.some(prev => prev === item.name)){
            console.log('2 list 2: ',list[index].name)
            console.log('dimensionValueList==>',dimensionValueList.some(prev => prev === item.name))


            // console.log('axisData: ',item.name)
                   let insertData = {
                      value: [
                        item.PrideAvg.toFixed(2),
                        item.PresenceAvg.toFixed(2),
                        item.PerformanceAvg.toFixed(2)
                      ],
                      name: item.name
                    }

                    RadarValueList.push(insertData)

                    // data["value"].push(item[driver[0]+"Avg"].toFixed(2))
          }
        })
      }






        // if(FieldList && FieldList.length>0){
        //   FieldList.forEach((item, itemIndex)=>{
        //       let data = {
        //         value: [],
        //         name: item.level
        //       }
        //       if(NewParaList && NewParaList.length>0){
        //         NewParaList.forEach((driver, driverIndex)=>{
        //           if(item[driver[0]+"Avg"]){
        //             data["value"].push(item[driver[0]+"Avg"].toFixed(2))
        //             console.log(': ',item.level+"Avg"+" > "+driver[0]+"Avg"+" > "+item[driver[0]+"Avg"])
        //           }
        //           else{
        //             data["value"].push("NaN")
        //             console.log(': ',item.level+"Avg"+" > "+driver[0]+"Avg"+" > "+item[driver[0]+"Avg"])
        //           }
        //         })
        //       }
        //       console.log('===================================')

        //       RadarValueList.push(data)
        //   })
        // }





      function reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
      }

    //  let list2 =reverseArr(list);
    let list2 = list;





     
     //--------------------------------------------------------------------------------

    let IndexList = []
    let pageCount = 10

     if(list2 && list2.length>0){
      list2.forEach((item,index)=>{
        if(index%pageCount===0){
          IndexList.push(index)
        }
        if(index===(list2.length-1)){
          IndexList.push(index)
        }
      })
     }
     
     console.log('IndexList: ',IndexList)
     let IndexListIndex = []

     if(IndexList && IndexList.length>0){
      IndexList.forEach((item,index)=>{
        if(index!==(IndexList.length-1)){
          IndexListIndex.push(index+1)
        }
      })
     }
     console.log('IndexListIndex: ',IndexListIndex)


    let indexA=0
    let indexB=0


     if(IndexListIndex && IndexListIndex.length>0){
      IndexListIndex.forEach((item,index)=>{
        if(item === currentPage){

          if(index<(IndexList.length-2)){
            indexA=IndexList[index];
            indexB=IndexList[index]+(10-1)
          }
          else{
            indexA=IndexList[index];
            indexB=IndexList[IndexList.length-1]
          }
        }
      })
     }

     //--------------------------------------------------------------------------------



     console.log('=================E==================',list2)


                    
    return (
      <>
      <div className="index-driver-radar">
       <div style={{width:'35%',float:'left'}}>

       <div style={{height:'300px'}}>
            {list2 && list2.length>0?
                list2.map((item,index)=>
                      (index >= indexA && index <=indexB)?
                                <div 
                                onClick={()=>this.handleDimensionList(item.name)}
                                key={index}
                                className="pl-4 text-sm cursor-pointer py-1" 
                                style={{
                                  fontWeight:(dimensionValueList.some(prev => prev === item.name))?'bold':null,
                                  color:(dimensionValueList.some(prev => prev === item.name))?'#0077e0':'black',

                                  }} 
                                >
                                  {item.name}</div>
                      :null
                )
            :null}
          </div>     




          <div className="flex justify-center items-center text-center py-8">
            <span onClick={()=>this.prevPage()} className="select-none material-icons w-8 h-8 rounded-full bg-gray-400 inline-flex border border-transparent items-center justify-center mx-1 text-sm text-white" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_left</span>
              {IndexListIndex && IndexListIndex.length>0 && IndexListIndex.length!==1?
              IndexListIndex.map((item,elemindex)=>
              <>
              {(elemindex >= EmpIndexA && elemindex <= EmpIndexB)?
              (elemindex < (IndexListIndex.length-1))?
              <span onClick={()=>this.handlePage(item)} className={(currentPage===item)?"w-8 h-8 rounded-full bg-blue-500 text-white inline-flex border border-transparent items-center justify-center mx-1 text-sm":"w-8 h-8 rounded-full bg-white border  inline-flex  items-center justify-center mx-1 text-sm"}>{item}</span>
              :
              <span onClick={()=>this.handlePage(item)} className={(currentPage===item)?"w-8 h-8 rounded-full bg-blue-500 text-white inline-flex border border-transparent items-center justify-center mx-1 text-sm":"w-8 h-8 rounded-full bg-white border  inline-flex  items-center justify-center mx-1 text-sm"}>{item}</span>
              :null}</>
              )
              :null}
            <span onClick={()=>this.nextPage(IndexListIndex)} className="select-none material-icons w-8 h-8 rounded-full bg-white text-blue-500 border inline-flex  items-center justify-center mx-1 text-sm" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_right</span>
          </div>       
      </div>

      <div style={{width:'65%',float:'left'}}>
                                    
      {list && list.length>0?
      <div  className=" pb-8 ">
          <ReactEcharts
            style={{height:"450px"}}
            option={{
              aria: {
                show: true
              },
              tooltip: {
                  show: true,
                  trigger: 'item'
                  // formatter: function(a) {
                  //   console.log('a:',a)
                  //       return a.name + ;
                    
                  // }
              },
              radar: [
                  {
                      indicator: (function (a){
                          var res = ParaNameList;
                          return res;
                      })(),
                      // radius: 140
                  }
              ],
              series: [
                  {
                      type: 'radar',
                      tooltip: {
                          trigger: 'item'
                      },
                      label: {
                        show: false,
                        position: 'insideRight',
                        formatter: function(d) {
                          console.log('d:',d)

                              return ParaNameList[d.dimensionIndex].text;
                          
                        }
                      },
                      // areaStyle: {normal: {}},
                      data: RadarValueList
                  }
              ]
          }}
                            
          />
     </div>
      :null}
                           



    </div>    
   </div>    

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(RadarChart);
