import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../../_helpers';

import { dashboardActions, surveyActions } from '../../../_actions';
import Header from '../Header/Header';

import SurveyList from './Components/SurveyList';
import AddSurvey from './Modal/AddSurvey/AddSurvey';
import UpdateSurvey from './Modal/AddSurvey/UpdateSurvey';
import { userActions } from '../../../_actions';
import DeleteSurveyConfirm from './Modal/DeleteSurveyConfirm';
import LoadingOverlay from 'react-loading-overlay';
import ChooseSurvey from './Modal/AddSurvey/ChooseSurvey';
import SurveyLibrary from './Components/SurveyLibrary';
import QuestionLibrary  from './Components/QuestionLibrary';
import AddSurveyLibModal from './Modal/AddSurvey/AddSurveyLibModal';
import Sidebar from './Components/Sidebar/Sidebar';

class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysize:10000,

      showSurveyList:true,
      showAddNewSurvey:false,
      showEditSurvey:false,
      confirmDeleteSurvey:false,
      CurrentSurveyId:'',
      CurrentSurveyName:'',
      showChooseSurveyModal:false,
      showSurveyLibrary:false,
      isPackageSelected:false,
      CurrentSurveyData:'',
      showQuestionLibrary:'',
      savePackage:'',
      
      EmpIndexA:0,
      EmpIndexB:6,

      currentSurveyPage:1,

      packageList:[],
      surveyValidation:{
        "name":true,
        "desc":true
      },


      surveyFormData:{
        "name":'',
        "desc":'',
        "type":"custom",
        "roleTemp":"ADMIN"
      },

      showCreateSurveyPopup:false,


      showSidebar:false,

      CrrRoute:"survey",

      crrSurvey:"",
      updateformSurveyData:{
        id:"",
        name:"",
        roleTemp:"",
        desc:"",
        templateId:"",
        companyId:"",
      },



      CurrentLanguage:'en',
      CurrentLanguageName:'English',
      

      showLanguageModal:false


    }
  }


  componentDidMount() {
    
    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
  }
    this.props.dispatch(dashboardActions.getAllParameterList(dataX));

    
    let data = {
      "keyWord": "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    this.props.dispatch(surveyActions.getSurveyList(data));
    this.props.dispatch(dashboardActions.getClientProfile());


    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));
    
    

  }



  prevEmpPage=()=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(EmpIndexA>0){
        this.setState({EmpIndexA:EmpIndexA-1, EmpIndexB:EmpIndexB-1});
    }
  }
  nextEmpPage=(IndexListIndex)=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(IndexListIndex && IndexListIndex.length && (EmpIndexB<IndexListIndex.length)){
      this.setState({EmpIndexA:EmpIndexA+1, EmpIndexB:EmpIndexB+1});
    }
  }

  SelectEmpPage=(val)=>{
    this.setState({currentSurveyPage:val})
  }


  handleSearchSurvey = (e) => {
    let data = {
      "keyWord": e.target.value,
      "pageNo": 1,
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "size": this.state.size
    }
    this.props.dispatch(surveyActions.getSurveyList(data));
  }


  onAddNewSurvey=(check)=>{
    if(check)
      this.setState({
        showSurveyList:true,
        showAddNewSurvey:false
      })
    else{
      this.setState({
        showSurveyList:false,
        showAddNewSurvey:true
      })
    }
  }

  handleAddSurveyInput=(e)=>{
    let { surveyFormData } = this.state;
    surveyFormData[e.target.name] = e.target.value;
    this.setState({surveyFormData})
  }

  handleUpdateSurveyInput=(e)=>{
    let { updateformSurveyData } = this.state;
    updateformSurveyData[e.target.name] = e.target.value;
    this.setState({updateformSurveyData})
  }

  handleAddSurveySubmit=()=>{

    let reqData = {
      "name": this.state.surveyFormData.name,
      "desc": this.state.surveyFormData.desc,
      "type":this.state.surveyFormData.type,
      "roleTemp":this.state.surveyFormData.roleTemp,
      "templateId": 2,
    }

    // let datatempsurvey = {
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": this.state.surveysize
    // }


    if(reqData && reqData.name ){
      this.setState({SurveySubmitData:reqData},()=>{
        if(this.state.surveyFormData && this.state.surveyFormData.type==="custom"){
          this.handleCustomSurvey();
        }
        else if(this.state.surveyFormData && this.state.surveyFormData.type==="package"){
          this.handleCreateSurveyFromLibrary();
          this.setState({showCreateSurveyPopup:false});
        }
      });

    }
    else{
      let { surveyValidation } = this.state;

      if(reqData && reqData.name){
        surveyValidation["name"] = true
      }
      else{
        surveyValidation["name"] = false
      }
  
      if(reqData && reqData.desc){
        surveyValidation["desc"] = true
      }
      else{
        surveyValidation["desc"] = false
      }
      this.setState({surveyValidation})
    }





    

  }

  handleCustomSurvey=()=>{
    let clientData = this.props.dashboard.ClientProfileData;


      let { SurveySubmitData } = this.state;

      let resData = {
        "name":SurveySubmitData.name,
        "desc":SurveySubmitData.desc,
        "templateId":3,
        "roleTemp":clientData && clientData.role && clientData.role === "CROLE"?"CROLE":SurveySubmitData.roleTemp

      }
      let datatempsurvey = {
        "keyWord": "",
        "pageNo": 1,
        "size": this.state.surveysize
      }
  
      this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey, true,[],this.props));

      let validate = {
         "name":true,
         "desc":true
      }
      let tempData={
         "name":'',
         "desc":'',
         "type":"custom",
         "roleTemp":"ADMIN"

      }
      this.setState({SurveySubmitData:"",showCreateSurveyPopup:false,showChooseSurveyModal:false, surveyValidation:validate, surveyFormData:tempData})

  }

  onDeleteSurvey=(survey)=>{
    this.setState({confirmDeleteSurvey:true, CurrentSurveyId:survey.id,CurrentSurveyName:survey.name})
  }

  handleConfirmDeleteSurveyClose=()=>{
    this.setState({confirmDeleteSurvey:false, CurrentSurveyId:'',CurrentSurveyName:''})
  }


  handleViewPackage=(survey)=>{
    let { packageList } = this.state;
    this.setState({showQuestionLibrary:true,savePackage:survey});

    if(packageList.findIndex(prev=>prev === survey.id)!==-1){
      this.setState({isPackageSelected:true})
    }else{
      this.setState({isPackageSelected:false})
    }

  }

  handlePackageSelection=(check,Id)=>{
    this.setState({showQuestionLibrary:false})
    let { packageList } = this.state;

    if(!check){
      packageList.push(Id);
    }
    else{
      let getIndex = packageList.findIndex(prev=>prev === Id)
      packageList.splice(getIndex,1);
    }
    this.setState({ packageList });


    // if(packageList.findIndex(prev=>prev === survey.id)===-1){
    //   packageList.push(survey.id);
    //   this.setState({isPackageSelected:false})
    // }
    // else{
    //   let getIndex = packageList.findIndex(prev=>prev === survey.id);
    //   packageList.splice(getIndex,1);
    //   this.setState({isPackageSelected:true})
    // }


  }





  handleADDpackage=(survey)=>{
    this.setState({showQuestionLibrary:true})

    let { packageList } = this.state;
    if(packageList.findIndex(prev=>prev === survey.id)===-1){
      packageList.push(survey.id);
      this.setState({isPackageSelected:false})
    }
    else{
      let getIndex = packageList.findIndex(prev=>prev === survey.id);
      packageList.splice(getIndex,1);
      this.setState({isPackageSelected:true})
    }
    this.setState({ packageList });
  }


  onSurveyDelete=()=>{
    let data = {
      id:this.state.CurrentSurveyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }
    this.props.dispatch(surveyActions.deleteSurvey(data,datatempsurvey));
    this.setState({confirmDeleteSurvey:false, CurrentSurveyId:'',CurrentSurveyName:''})
  }

  onEditSurvey=(data)=>{
    this.props.history.push(`/app/question/${data.id}`)
  }
  
  onReportSurvey=(data)=>{
    this.props.history.push(`/app/surveyreport/${data.id}`)
  }
  
  onPublishSurvey=(data)=>{
    this.props.history.push(`/app/publish/${data.id}`)
  }

  handleChooseSurveyPopup=()=>{
    this.setState({showCreateSurveyPopup:false,showChooseSurveyModal:true})
  }

  handleCreateSurveyPopup=()=>{
    this.handleChooseSurveyPopupClose();
    this.setState({showCreateSurveyPopup:true})
  }

  handleCreateSurveyFromLibrary=()=>{
    this.handleChooseSurveyPopupClose();
    this.setState({showSurveyLibrary:true});


    this.props.dispatch(surveyActions.getSurveyFromLib());
  }

  handleSurveyLibraryClose=(check)=>{
    this.setState({showCreateSurveyPopup:true, showSurveyLibrary:false,packageList:[]});
  }


  QuestionLibraryClose=()=>{
      this.setState({CurrentSurveyData:false, savePackage:'',showQuestionLibrary:false});
   }



  handleCreateSurveyPopupClose=()=>{
    this.handleChooseSurveyPopupClose();

    let validate = {
      "name":true,
      "desc":true
    }
    let tempData={
      "name":'',
      "desc":'',
      "type":"custom"

    }
    this.setState({showCreateSurveyPopup:false, surveyValidation:validate, surveyFormData:tempData});
  }

  handleChooseSurveyPopupClose=()=>{
    this.setState({showChooseSurveyModal:false})
  }

  onLogout = () =>{
    console.log('logout')
    this.props.dispatch(userActions.logout());
  }


  goToDashboard = () =>{
    
    this.props.history.push('/app/dashboard');

  }

  goToGeneralDashboard = () =>{
    let SurveyId = '5fa04d316ede0f0336066cce';

    this.props.history.push('/app/generaldashboard/' + SurveyId);

  }


  gotoEmployeeScreen=()=>{
    this.props.history.push('/app/employees/');
  }




  onDashboard=(survey) =>{
    console.log('survey: ',survey);
    // let SurveyId = '5fa04d316ede0f0336066cce';
    if(survey && survey.templateId===1){
      this.props.history.push('/app/dashboard/' + survey.id);
    }
    else if(survey && survey.templateId===2){
      this.props.history.push('/app/dashboard/' + survey.id);
    }
    else if(survey && survey.templateId===3){
      this.props.history.push('/app/generaldashboard/' + survey.id);
    }
    else if(survey && survey.templateId===4){
      this.props.history.push('/app/generaldashboard/' + survey.id);
    }

  }



  handleSurveyLibQuestions=(survey)=>{
    this.setState({CurrentSurveyData:survey});
  }

  handleUseSurveyPage=()=>{
    let { CurrentSurveyData } = this.state;
    console.log('CurrentSurveyData: ',CurrentSurveyData)

    let data = {
      "surveyId": "605993ff4e91a52e9f001f80",
      "surveyLib": ["60598a67ade90e2a7fbf2830","6058a75c6ba424628f903463"]
    }
  }

  handleSuveyPackageSubmit=()=>{
    let { SurveySubmitData, packageList } = this.state;
 

    let resData = {
      "name":SurveySubmitData.name,
      "desc":SurveySubmitData.desc,
      "roleTemp":SurveySubmitData.roleTemp,
      "templateId":2
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }
    // this.props.history.push(`/app/question/${data.id}`)

    this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey,false, packageList,this.props));

    let validate = {
       "name":true,
       "desc":true
    }
    let tempData={
       "name":'',
       "desc":'',
       "type":"custom",
       "roleTemp":"ADMIN"

    }
    this.setState({showSurveyLibrary:false,showCreateSurveyPopup:false,showChooseSurveyModal:false, surveyValidation:validate, surveyFormData:tempData, packageList:[]})

}



  sidebarToggle = () =>{
    this.setState({showSidebar:!this.state.showSidebar});
  }
  routeLink=(link)=>{
    this.props.history.push('/app/'+link);
    this.setState({CrrRoute:link});
  }
  onOptionDot=(data)=>{
    if(this.state.crrSurvey === data.id){
      this.setState({crrSurvey:""});
    }
    else{
      this.setState({crrSurvey:data.id});
    }

    setTimeout(()=>{
      this.setState({crrSurvey:""});
    },7000);
  }


  handleLanguage=(item)=>{
    this.setState({CurrentLanguage:item.shortName, CurrentLanguageName:item.name, showLanguageModal:false});
  }


  openLanguageModal=(check)=>{
    this.setState({showLanguageModal:!check});
    setTimeout(()=>{
      this.setState({showLanguageModal:false});
    },7000);
  }


  onPreviewSurvey=(data)=>{
    this.props.history.push(`/app/preview/${data.id}`)
  }


  handleSurveyType=(type)=>{
    let { surveyFormData } = this.state;
    surveyFormData["type"] = type;
    this.setState({surveyFormData});
  }

  handleSurveyRole=(role)=>{
    let { surveyFormData } = this.state;
    surveyFormData["roleTemp"] = role;
    this.setState({surveyFormData});
  }

  updatehandleSurveyRole=(role)=>{
    let { updateformSurveyData } = this.state;
    updateformSurveyData["roleTemp"] = role.trim();
    this.setState({updateformSurveyData});
  }

  onUpdateSurvey=(survey)=>{
    let SurveyRole = "ADMIN"
    if(survey && survey.roles && survey.roles && survey.roles.length>0){
      if(survey.roles.length===1){
        if(survey.roles[0]==="ADMIN"){
          SurveyRole = "ADMIN";
        }
        else if(survey.roles[0]==="CROLE"){
          SurveyRole = "CROLE";
        }
      }
      else if((survey.roles[0]==="ADMIN" && survey.roles[1]==="CROLE") || (survey.roles[0]==="CROLE" && survey.roles[1]==="ADMIN")){
        SurveyRole = "CROLE";
      }
      
    }
    console.log('SurveyRole',SurveyRole)
    // survey.roles
    // roles
    let data={
      id:survey.id,
      name:survey.name,
      roleTemp:SurveyRole,
      desc:survey.desc,
      templateId:survey.templateId,
      companyId:survey.companyId,
    }
    this.setState({showUpdateSurveyModal:true,updateformSurveyData:data});
  }
  closeUpdateSurvey=()=>{
    this.setState({showUpdateSurveyModal:false,updateformSurveyData:""});
  }

  handleUpdateSurveySubmit=()=>{
    let {updateformSurveyData}= this.state;
    let resData = {
      "id":updateformSurveyData.id,
      "name":updateformSurveyData.name,
      "desc":updateformSurveyData.desc,
      "roleTemp":updateformSurveyData.roleTemp,
      "templateId":updateformSurveyData.templateId,
      "companyId":updateformSurveyData.companyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }
    this.props.dispatch(surveyActions.updateSurvey(resData, datatempsurvey));
    let tempClear={
      id:"",
      name:"",
      roleTemp:"",
      desc:"",
      templateId:"",
      companyId:"",
    }
    this.setState({showUpdateSurveyModal:false, updateformSurveyData:tempClear});
  }


  render() {
    let { survey } = this.props;
    let { getLanguageLabelData,getLanguageData, createSurveyData, getSurveyFromLibData, getSurveyListData, getSurveyListTotal, loading, error } = survey;
    
    let { showUpdateSurveyModal,updateformSurveyData, showLanguageModal,CurrentLanguageName, CurrentLanguage, crrSurvey, CrrRoute,showSidebar, savePackage, isPackageSelected, showQuestionLibrary, packageList,CurrentSurveyData, showSurveyLibrary, showChooseSurveyModal, showSurveyList,CurrentSurveyName, confirmDeleteSurvey, surveyFormData, showCreateSurveyPopup, surveyValidation } = this.state;


    let moduleAccess = {
      "isAdmin":false,
      "access":{}
    };
    if(accessModule()){
      moduleAccess["access"] = accessModule();
    }
    console.log('updateformSurveyData: ',updateformSurveyData)

  //ROLE MANAGEMENT ----------------------------------------------

    let SHOW_ELEMENT = false;
    let CLIENT_ID = "";
    let CLIENT_ROLE = "";

    let clientData = this.props.dashboard.ClientProfileData;

      if(clientData && clientData.id){
        CLIENT_ID = clientData.id;
      }


    if(clientData && clientData.role){
        CLIENT_ROLE=clientData.role;

        if(CLIENT_ROLE === "ADMIN"){
          SHOW_ELEMENT = true;
          moduleAccess["isAdmin"] = true;
        }
        else if(CLIENT_ROLE === "MNGR"){
          SHOW_ELEMENT = true;
          moduleAccess["isAdmin"] = false;
        }
        else if(CLIENT_ROLE === "CROLE"){
          SHOW_ELEMENT = true;
          moduleAccess["isAdmin"] = false;
        }
        else if(CLIENT_ROLE === "EMP"){
          SHOW_ELEMENT = false;
          moduleAccess["isAdmin"] = false;
        }
    }

    let isCrole =false;
    if(clientData && clientData.role && clientData.role === "CROLE"){
      isCrole = true;
    }

    function accessAllow (item){
      return (moduleAccess.isAdmin?true:(moduleAccess && moduleAccess.access && moduleAccess.access[item]?true:false))
    }

  //END -------------------------------------------------------
    let { ClientProfileData } = this.props.dashboard;
    if(ClientProfileData && ClientProfileData.role && ClientProfileData.role==="MNGR"){
      this.props.history.push(`/app/manageractionplan`)
    }
      //--------------------------------------------------------------------------------

      let IndexList = []
      let pageCount = 22
      let IndexListIndex = []
      let indexA=0
      let indexB=0
  
      if(getSurveyListData && getSurveyListData.length>0){
      
  
       if(getSurveyListData && getSurveyListData.length>0){
        getSurveyListData.forEach((item,index)=>{
          if(index % pageCount === 0){
            IndexList.push(index)
          }
          if(index===(getSurveyListData.length-1)){
            IndexList.push(index)
          }
        })
       }
       
       console.log('IndexList: ',IndexList)
  
       if(IndexList && IndexList.length>0){
        IndexList.forEach((item,index)=>{
          if(index!==(IndexList.length-1)){
            IndexListIndex.push(index+1)
          }
        })
       }
       console.log('IndexListIndex: ',IndexListIndex)
  
  
  
  
  
       if(IndexListIndex && IndexListIndex.length>0){
        IndexListIndex.forEach((item,index)=>{
          if(item === this.state.currentSurveyPage){
  
            if(index<(IndexList.length-2)){
              indexA=IndexList[index];
              indexB=IndexList[index]+(pageCount-1)
            }
            else{
              indexA=IndexList[index];
              indexB=IndexList[IndexList.length-1]
            }
          }
        })
       }
  
    }
       //--------------------------------------------------------------------------------

        console.log('CLIENT_ROLE: ',CLIENT_ROLE)
        // console.log('getLanguageLabelData: ',getLanguageLabelData,getLanguageData,);


    
        let LanguageObj = {};
        if(getLanguageLabelData && getLanguageLabelData[CurrentLanguage]){
          LanguageObj =  getLanguageLabelData[CurrentLanguage];
        }
    

        // createSurvey: false
        // dashboard: true
        // responseAnalytics: true
        // uploadUser: false
        // viewSurvey: true



  

        
        console.log("accessModule",accessModule());



        // accessAllow("createSurvey");
        // accessAllow("viewSurvey");
        // accessAllow("publishSurvey");
        let { getAllParameterListData } = this.props.dashboard;
        console.log('getAllParameterListData V : ',getAllParameterListData);
    
        function GetParameterLabel(name){
          let AllParameterObj = {};
          if(getAllParameterListData && getAllParameterListData.length>0){
            getAllParameterListData.forEach((item)=>{
              AllParameterObj[item.name] = item&&item.label?item.label:item.name;
            });
          }
          return (AllParameterObj&&AllParameterObj[name]?AllParameterObj[name]:name);
        }
  

    return (
      <>
            <div>
  {/*this is side bar  */}
      <Sidebar sidebarToggle={this.sidebarToggle} accessAllow={accessAllow} showSidebar={showSidebar} routeLink={this.routeLink} CrrRoute={CrrRoute}
       LanguageLabel={this.LanguageLabel}

       LanguageObj={LanguageObj}
       CurrentLanguage={CurrentLanguage}
      />
  {/* side bar end */} 



  <main className="lg:ml-16 h-screen bg-gray-100">
    {/* component */}



    <Header  onLogout={this.onLogout} 
    CurrentLanguageName={CurrentLanguageName} 
    getLanguageData={getLanguageData} 
    handleLanguage={this.handleLanguage}
    openLanguageModal={this.openLanguageModal}
    showLanguageModal={showLanguageModal} 

    />

{showSurveyLibrary?
    showQuestionLibrary?
    <QuestionLibrary
    handleSurveyLibraryClose={this.handleSurveyLibraryClose}
    getSurveyFromLibData={getSurveyFromLibData}
    handleSurveyLibQuestions={this.handleSurveyLibQuestions}
    CurrentSurveyData={CurrentSurveyData}
    handleADDpackage={this.handleADDpackage}
    packageList={packageList}
    handleSuveyPackageSubmit={this.handleSuveyPackageSubmit}


    isPackageSelected={isPackageSelected}
    handlePackageSelection={this.handlePackageSelection}
    savePackage={savePackage}
    QuestionLibraryClose={this.QuestionLibraryClose}

    GetParameterLabel={GetParameterLabel}

    />
    :
    <SurveyLibrary
    handleSurveyLibraryClose={this.handleSurveyLibraryClose}
    getSurveyFromLibData={getSurveyFromLibData}
    handleSurveyLibQuestions={this.handleSurveyLibQuestions}
    CurrentSurveyData={CurrentSurveyData}
    handleADDpackage={this.handleADDpackage}
    packageList={packageList}
    handleSuveyPackageSubmit={this.handleSuveyPackageSubmit}

    handleViewPackage={this.handleViewPackage}
    />
  :


<>
    {(accessAllow("viewSurvey"))?
      getSurveyListData && getSurveyListData.length>0?
        <div className=" py-8 mx-auto px-4 h-full  overflow-hidden overflow-y-auto bg-gray-50" style={{height: 'calc(100% - 4rem)'}}>
      <div className="md:flex justify-between">
        {/* left side */}


        <div className="flex items-center">
          <h1 className="text-xl font-medium text-gray-800 ">{LanguageObj && LanguageObj["my_surveys_label"+"_"+CurrentLanguage]? LanguageObj["my_surveys_label"+"_"+CurrentLanguage]:"My Surveys"}</h1>
          <div className="flex text-blue-500 text-xs items-center ml-2 border-l border-blue-500  pl-2 ">
            <p>Home</p> <span className="material-icons" style={{fontSize: '12px'}}>navigate_next</span>
            <p>My Survey</p>
          </div>
        </div>




        {/* right side */}
        <div className="flex items-center md:mt-0 mt-4">
         
         
         
          {/* search box */}
          {/* <div className="w-full ml-2 flex  justify-between px-4 py-1 bg-white rounded-sm text-xs shadow items-center">
          <span className="material-icons text-gray-500 mr-4">search</span> 
          <input type="search" name="search" onChange={this.handleSearchSurvey} className="md:w-44 w-full focus:outline-none" placeholder={LanguageObj && LanguageObj["seach_by_survey_name_label"+"_"+CurrentLanguage]? LanguageObj["seach_by_survey_name_label"+"_"+CurrentLanguage]:"Search by survey name..."} /> </div> */}
        

          <div className="flex items-center md:mt-0 mt-4">
            
            <div className="flex w-32 justify-between px-4 py-1 bg-white rounded-sm text-xs shadow items-center hidden">
              <p>Status</p><span className="material-icons text-gray-500">arrow_drop_down</span> </div>

            <div className="w-full flex justify-between bg-white rounded-full text-sm items-center border overflow-hidden">
              <input type="search" name="search" onChange={this.handleSearchSurvey}
              className="md:w-56 w-full focus:outline-none px-4 py-2" 
              placeholder="Search by survey name..." /> 
              <span className="material-icons text-blue-500 p-2  rounded-l-full" style={{fontSize: '1.4rem'}}>search</span> 
            </div>
          
          </div>



        
        </div>
      </div>
      {/* end */}
      {/* boxes work start */}
      <div className="grid   xl:grid-cols-5  lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4 my-6">
        {/* box 1 */}
        
        

          {accessAllow("createSurvey")?
          <>
            {/* <div style={{ height: "320px" }} onClick={() => this.handleCreateSurveyPopup()} className="shadow-md hover:shadow-2xl cursor-pointer bg-white p-4 rounded-md text-center border-blue-200 border-dotted border-2 flex items-center justify-center">
            <div>
              <div className="flex justify-center mb-4"><span className="material-icons text-blue-500 bg-blue-100 rounded-full p-8">add</span></div>
              <h1 className="text-blue-500 font-medium">{LanguageObj && LanguageObj["create_survey_label" + "_" + CurrentLanguage] ? LanguageObj["create_survey_label" + "_" + CurrentLanguage] : "Create Survey"}</h1>
              <p className="text-xs text-gray-500">{LanguageObj && LanguageObj["create_new_survey_text" + "_" + CurrentLanguage] ? LanguageObj["create_new_survey_text" + "_" + CurrentLanguage] : "Click here to create a new survey	"}</p>
            </div>
          </div> */}

              <div onClick={() => this.handleCreateSurveyPopup()} className="bg-white p-4 rounded-md text-center border-blue-300  border-dotted border-2 flex items-center justify-center cursor-pointer transform scale-100 hover:scale-105 transition duration-500 ease-in-out" style={{height: '21rem'}}>
              <div>
                <div className="flex justify-center mb-4 "><span className="material-icons text-white bg-blue-500 rounded-full p-8">add</span></div>
                <h1 className="text-blue-500 font-medium">{LanguageObj && LanguageObj["create_survey_label" + "_" + CurrentLanguage] ? LanguageObj["create_survey_label" + "_" + CurrentLanguage] : "Create Survey"}</h1>
                <p className="text-xs text-gray-500">{LanguageObj && LanguageObj["create_new_survey_text" + "_" + CurrentLanguage] ? LanguageObj["create_new_survey_text" + "_" + CurrentLanguage] : "Click here to create a new survey	"}</p>
              </div>
              </div>


              </>
          :null}

          {showSurveyList?
              <SurveyList
                getSurveyListData={getSurveyListData}
                getSurveyListTotal={getSurveyListTotal}

                onDashboard={this.onDashboard}
                onEditSurvey={this.onEditSurvey}
                onPreviewSurvey={this.onPreviewSurvey}
                onReportSurvey={this.onReportSurvey}
                onPublishSurvey={this.onPublishSurvey}
                onDeleteSurvey={this.onDeleteSurvey}
                SHOW_ELEMENT={SHOW_ELEMENT}

                indexA={indexA}
                indexB={indexB}

                onOptionDot={this.onOptionDot}

                crrSurvey={crrSurvey}

                LanguageObj={LanguageObj}
                CurrentLanguage={CurrentLanguage}

                onUpdateSurvey={this.onUpdateSurvey}

                isCrole={isCrole}

                CLIENT_ID={CLIENT_ID}
                CLIENT_ROLE={CLIENT_ROLE}
                accessAllow={accessAllow}
              />
              :
              null}
        
        
        </div>
        
        {IndexListIndex && IndexListIndex.length>1?
        
        <div className="flex justify-center items-center text-center py-8">
          <span onClick={()=>this.prevEmpPage()} className="select-none material-icons w-8 h-8 rounded-full bg-gray-400 inline-flex border border-transparent items-center justify-center mx-1 text-sm text-white" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_left</span>
          {IndexListIndex && IndexListIndex.length>0?
              IndexListIndex.map((item,index)=>
                (index >= this.state.EmpIndexA && index <= this.state.EmpIndexB)?
                  (item===this.state.currentSurveyPage)?
                  <span onClick={()=>this.SelectEmpPage(item)} className="cursor-pointer w-8 h-8 rounded-full bg-blue-500 text-white inline-flex border border-transparent items-center justify-center mx-1 text-sm">{item}</span>
                  :
                  <span onClick={()=>this.SelectEmpPage(item)} className="cursor-pointer w-8 h-8 rounded-full bg-white border  inline-flex  items-center justify-center mx-1 text-sm">{item}</span>
                  :null
              )

          :null}
          <span onClick={()=>this.nextEmpPage(IndexListIndex)} className="select-none material-icons w-8 h-8 rounded-full bg-white text-blue-500 border inline-flex  items-center justify-center mx-1 text-sm" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_right</span>
        </div>

        :null}


    </div>
            
    :
    <>
    {!loading && !showCreateSurveyPopup?
        <div className="container py-12 mx-auto px-4 h-full overflow-hidden overflow-y-auto" style={{height: 'calc(100% - 4rem)'}}>
        <div className="w-full h-full flex justify-center items-center">
          {/*  text start*/}
          <div className="text-center">
            <img src="img/empty_survey.png" className="mb-4" />
            <h1 className="text-gray-800 text-lg  font-semibold cursor-default">No survey has been created yet.</h1>
            <p className="text-xs text-gray-500 cursor-default">Let's get started &amp; create your first survey</p>   
            <div  className="flex justify-center"><button onClick={()=>this.handleCreateSurveyPopup()} className="my-6 bg-blue-500 px-6 py-3 text-white flex items-center focus:outline-none  rounded-md text-sm"><span className="material-icons mr-2">add</span> CREATE SURVEY</button>    </div>
          </div>
        </div>
      </div>
      :null}
      </>
  :
  <>
    {!loading?
    <div className="container py-12 mx-auto px-4 h-full overflow-hidden overflow-y-auto" style={{height: 'calc(100% - 4rem)'}}>
    <div className="w-full h-full flex justify-center items-center">
      {/*  text start*/}
      <div className="text-center">
        <img src="img/empty_survey.png" className="mb-4" />
        <h1 className="text-gray-500 text-xl  font-semibold cursor-default">You have no access <br/> to this module!</h1>
      </div>
    </div>
  </div>
  :null}
  </>

    }



</>}

  </main>





  {showCreateSurveyPopup?
  (accessAllow("createSurvey"))?
      <AddSurvey 
        handleCreateSurveyPopupClose = {this.handleCreateSurveyPopupClose}
        handleAddSurveyInput={this.handleAddSurveyInput}
        handleAddSurveySubmit = {this.handleAddSurveySubmit}
        surveyFormData = {surveyFormData}
        surveyValidation={surveyValidation}
        handleSurveyType={this.handleSurveyType}
        handleSurveyRole={this.handleSurveyRole}
        isCrole={isCrole}
      />
      :null
    :null}


{showUpdateSurveyModal?
  (accessAllow("uploadUser"))?
      <UpdateSurvey 
        handleCreateSurveyPopupClose = {this.closeUpdateSurvey}
        updateformSurveyData = {updateformSurveyData}
        handleUpdateSurveyInput={this.handleUpdateSurveyInput}
        updatehandleSurveyRole={this.updatehandleSurveyRole}
        handleUpdateSurveySubmit={this.handleUpdateSurveySubmit}
        isCrole={isCrole}
      />
      :null
    :null}  


    {showChooseSurveyModal?
      <ChooseSurvey
      handleCreateSurveyPopupClose = {this.handleChooseSurveyPopupClose}
      handleCreateEditableSurvey = {this.handleCustomSurvey}
      handleCreateSurveyFromLibrary = {this.handleCreateSurveyFromLibrary}

      />
    :null}

    {CurrentSurveyData?
      <AddSurveyLibModal
      CurrentSurveyData={CurrentSurveyData}
      handleUseSurveyPage={this.handleUseSurveyPage}
      
      />
    :null}


    {confirmDeleteSurvey?
      <DeleteSurveyConfirm
        CurrentSurveyName={CurrentSurveyName}
        handleSendSingleMail={this.onSurveyDelete}
        handleConfirmMailClose={this.handleConfirmDeleteSurveyClose}
      />
    :null}



</div>

      </>
    );
  }
}
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(Survey);
