import React, { Component } from 'react';
import { connect } from 'react-redux';

import WordCloud from './Components/WordCloud/WordCloud';
import AdvanceWordCloud from './Components/AdvanceWordCloud/AdvanceWordCloud';
import Network from './Components/Network/Network';


class AdvanceComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType:1
    }
  }
  componentDidMount() {

  }

  onOptionType=(val)=>{
    this.setState({optionType:val})
    let { onNetworkGraph, onAdvanceWorldCloud, onWorldCloud } = this.props;
    if(val===1){
      onWorldCloud();
    }
    else if(val===2){
      onNetworkGraph(1);
    }
    else if(val===3){
      onAdvanceWorldCloud(3);
    }
  }

  onSwitchOptionScreen=()=>{
    let {CountRestrict,AdvanceWordCloudData, indexType,getCommentData,loading,callQuestionNetworkCloud, 
      handleNeighborGraph,  neighborGraphData, handleNetworkDemographicFilter, cooccurmatrixData, 
      callWordCloudText,callQuestionWordCloud, wordCloudTextData,wordTextKeyWord, getTextQuestionData, 
      handleChangeQuestion, wordCloudData,EmpFilterData,getIndexFilterData, handleWordCloudDemographicFilter,
      wordCloudMultiTextData,wordMultiTextKeyWord } = this.props;

      console.log("wordCloudMultiTextData--->",wordCloudMultiTextData)


    let {optionType} = this.state;

    switch(optionType){
      
        case 1: return <WordCloud  loading={loading} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion} 
        wordCloudData={wordCloudData}  handleWordCloudDemographicFilter={handleWordCloudDemographicFilter}
        callWordCloudText={callWordCloudText} callQuestionWordCloud={callQuestionWordCloud} wordCloudTextData={wordCloudTextData} wordTextKeyWord={wordTextKeyWord}
        />

        case 2: return <Network loading={loading} callQuestionNetworkCloud={callQuestionNetworkCloud} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        cooccurmatrixData={cooccurmatrixData}
        getTextQuestionData={getTextQuestionData}
        handleNetworkDemographicFilter={handleNetworkDemographicFilter}
        neighborGraphData={neighborGraphData}
        callWordCloudText={callWordCloudText}
        wordCloudTextData={wordCloudMultiTextData} wordTextKeyWord={wordMultiTextKeyWord}
        handleNeighborGraph={handleNeighborGraph}
        />

        case 3: return <AdvanceWordCloud  loading={loading} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion} 
        wordCloudData={AdvanceWordCloudData}  handleWordCloudDemographicFilter={handleWordCloudDemographicFilter}
        callWordCloudText={callWordCloudText} callQuestionWordCloud={callQuestionWordCloud} wordCloudTextData={wordCloudTextData} wordTextKeyWord={wordTextKeyWord}
        />

        // case 2: return <BestFitData />

        default: return <div>Default Option</div> 
    }
  }

  render() {
    let { optionType } = this.state;

    return (
      <> 

      <main className="flex-1 w-full bg-gray-100  overflow-hidden overflow-y-auto ">
      <div className="px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b h-10">
        <div className="cursor-pointer text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden">
          <span onClick={()=>this.onOptionType(1)} className={(optionType===1)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Word Cloud</span>
          <span onClick={()=>this.onOptionType(2)} className={(optionType===2)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Network Graph</span>
          {/* <span onClick={()=>this.onOptionType(3)} className={(optionType===3)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Advance Word Cloud</span> */}
        </div>
      </div>
          {this.onSwitchOptionScreen()}
      </main> 


      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AdvanceComparision);
