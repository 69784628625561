import React, { Component } from 'react';
import { connect } from 'react-redux';

class IndexKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { getRespondantDetailsData } = this.props;


    let ParticipationObj={
      total:0,
      completed:0,
      mailSent:0,
      clicked:0,
      participated:0,
      performance:0,
      perfPlusSign:true
    }

    if(getRespondantDetailsData && getRespondantDetailsData[0]){
        let apiData = getRespondantDetailsData[0]
        ParticipationObj['total'] = apiData['total']?apiData['total']:0;
        ParticipationObj['completed'] = apiData['completed']?apiData['completed']:0;
        ParticipationObj['mailSent'] = apiData['mailSent']?apiData['mailSent']:0;
        ParticipationObj['clicked'] = apiData['clicked']?apiData['clicked']:0;
        ParticipationObj['participated'] = apiData['completed']&&apiData['total']?(apiData['completed']*100/apiData['total']).toFixed(2):0;



    }



    // indexType={indexType}
    // getIndexData={getIndexData}
    // getIndexScore={getIndexScore}
    // getAllBenchMarkData={getAllBenchMarkData}
    // getRespondantDetailsData={getRespondantDetailsData}

    return (
      <>
    <div className="bg-white p-6 rounded-lg flex border"> <span className="material-icons text-white bg-green-400 p-4 rounded-full" style={{fontSize: '2rem'}}>mark_email_read</span>
      <div className="w-full flex items-center justify-center">
        <div>
          <h1 className="text-2xl font-medium justify-center w-full flex items-center">{ParticipationObj['total']}</h1>
          <p className="block text-sm text-gray-500 w-full text-center">Sent</p>
        </div>
      </div>
    </div>


    <div className="bg-white p-6 rounded-lg flex  border"> <span className="material-icons text-white bg-yellow-300 p-4 rounded-full" style={{fontSize: '2rem'}}>ads_click</span>
      <div className="w-full flex items-center justify-center">
        <div>
          <h1 className="text-2xl font-medium justify-center w-full flex items-center">{ParticipationObj['completed']}</h1>
          <p className="block text-sm text-gray-500 w-full text-center">Responded</p>
        </div>
      </div>
    </div>

    <div className="bg-white p-6 rounded-lg flex  border"> <span className="material-icons text-white bg-blue-400 p-4 rounded-full" style={{fontSize: '2rem'}}>trending_up</span>
      <div className="w-full flex items-center justify-center">
        <div>
          <h1 className="text-2xl font-medium justify-center w-full flex items-center">{ParticipationObj['participated']+'%'}</h1>
          <p className="block text-sm text-gray-500 w-full text-center">Response Rate</p>
        </div>
      </div>
    </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexKPI);
