import React from "react";
import moment from 'moment';

export default function SurveyList(props) {
  let { CLIENT_ID, CLIENT_ROLE, isCrole, indexA, indexB, accessAllow,
    SHOW_ELEMENT, onOptionDot, crrSurvey, getSurveyListData, getSurveyListTotal, onDashboard,
    onEditSurvey, onPreviewSurvey, onReportSurvey, onPublishSurvey, onDeleteSurvey, LanguageObj, CurrentLanguage, onUpdateSurvey
  } = props;

  console.log('getSurveyListData CLIENT_ROLE:', CLIENT_ROLE);



  function AllowChange(survey) {
    if (CLIENT_ROLE === "ADMIN") {
      return true
    }
    if ((survey && survey.roles && survey.roles.length > 0 && ((survey.roles[0] && survey.roles[0] === "ADMIN") || (survey.roles[1] && survey.roles[1] === "ADMIN")))) {
      return false
    }
    else {
      return true
    }
  }

  function ShowUserSurvey(survey) {
    if (CLIENT_ROLE === "ADMIN") {
      if (survey && survey.roles && survey.roles.length === 1 && ((survey.roles[0] && survey.roles[0] === "CROLE"))) {
        return false
      }
      else if (survey && survey.roles && survey.roles.length > 0 && ((survey.roles[0] && survey.roles[0] === "ADMIN") || (survey.roles[1] && survey.roles[1] === "ADMIN"))) {
        return true
      }
      else { //can comment
        return true
      }
    }
    else if (CLIENT_ROLE === "CROLE" && CLIENT_ID === survey.createdBy) {
      return true
    }
    else if (survey && survey.roles && survey.roles.length > 0 && ((survey.roles[0] && survey.roles[0] === "ADMIN") || (survey.roles[1] && survey.roles[1] === "ADMIN"))) {
      return true
    }
    else {
      return false
    }

  }


  return (
    <>
      {getSurveyListData && getSurveyListData.length > 0 ?
        getSurveyListData.map((survey, index) =>
          (index >= indexA && index <= indexB && ShowUserSurvey(survey)) ?
          <>


            <div 
            className="bg-white p-4 rounded-md  border capitalize cursor-pointer transform scale-100 hover:scale-105 transition duration-500 ease-in-out " style={{height: '21rem'}}
            // className={
            //   survey.isExpire === true ?
            //     "bg-white  p-4 rounded-md shadow-md hover:shadow-2xl"
            //     :
            //     ((moment(moment.unix(survey.expireTime / 1000).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) === true) ?
            //       "bg-white  p-4 rounded-md shadow-md hover:shadow-2xl"
            //       :
            //       "bg-white  p-4 rounded-md shadow-md hover:shadow-2xl")
            // } 
            >

              <div className="relative flex justify-between items-start">
                {/* <img src="https://logos-download.com/wp-content/uploads/2016/10/icici_bank_logo_symbol.png" className="mr-4 w-2/3" alt="company_logo" />  */}

                <div className="" style={{ height: '50px' }} >
                  {survey && survey.logo ? <img src={survey.logo} style={{ height: '50px' }} className="mr-4 w-auto" alt="company_logo" /> : <img src={"/img/logo/happypluslogo.png"}
                   style={{ height: '50px' }} className="mr-4 w-auto" alt="default_survey_logo" />}
                </div>
                <div className="">
                  <div className="dropdown inline-block relative">
                    <span onClick={() => onOptionDot(survey)} className="cursor-pointer p-1 material-icons text-gray-600 hover:text-blue-500 hover:bg-blue-100 rounded-full" 
                    style={{ fontSize: '18px' }}>more_vert
                      {/* drop down */}
                    </span>

                    {/* {(crrSurvey === survey.id) || true?
                      <ul className="dropdown-menu hidden bg-white shadow absolute right-2 top-6 text-gray-500 z-30 pt-1 text-xs">
                        <span onClick={() => onPreviewSurvey(survey)} className="cursor-pointer block px-4 py-2 hover:bg-gray-100 ">Preview</span>
                        {accessAllow("uploadUser") && AllowChange(survey) ?
                          <span onClick={() => onUpdateSurvey(survey)} className="cursor-pointer block px-4 py-2 hover:bg-gray-100">Access</span>
                          : null}
                        <span onClick={() => onDeleteSurvey(survey)} className="cursor-pointer block px-4 py-2 hover:bg-gray-100">Delete</span>
                      </ul>
                      : null} */}
                      
                      
                      {(crrSurvey === survey.id) || true?
                      <div className="dropdown-menu hidden bg-white border shadow-lg absolute right-2  top-6 text-gray-500 z-30 text-xs w-24 overflow-hidden"> 
                        <span onClick={() => onPreviewSurvey(survey)}  className=" block px-4 py-2 hover:bg-blue-50 hover:text-blue-500  ">Preview</span> 
                        {accessAllow("uploadUser") && AllowChange(survey) ?
                        <span onClick={() => onUpdateSurvey(survey)} className=" block px-4 py-2 hover:bg-blue-50  hover:text-blue-500 ">Access</span> 
                        : null}
                        <span onClick={() => onDeleteSurvey(survey)} className=" block px-4 py-2 hover:bg-blue-50 hover:text-blue-500 ">Delete</span> 
                      </div>
                      : null}


                  </div>

                  
                </div>
              </div>

              <div className=" py-6" style={{ height: "70px" }}>
                <h1 className="text-gray-800 font-semibold  text-sm ">{survey.name}</h1>
                {/* <p className="bg-gray-100 rounded-full px-3 font-medium py-1.5 text-xs inline-flex">Draft</p> */}
              </div>

              <div className="space-y-1.5 h-12">

                  <p className={(survey.isExpire ? false : (moment(moment.unix(survey.expireTime / 1000).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? false : true)) ? 
                  "text-xs text-gray-600 font-medium" : "text-xs text-gray-600 font-medium"}>
                    {"Created Date: " + moment(new Date(parseInt(survey.createdAt))).utcOffset("+05:30").format("DD MMM, YYYY")}</p>


                  {survey.isExpire ?
                  <>
                  <p className="text-xs text-red-500 font-medium flex items-center "><span className="material-icons mr-2" style={{fontSize: "1.5rem"}}>domain_verification</span> Survey Closed </p>
                  </>
                  :
                  <>
                        {moment(moment.unix(survey.expireTime / 1000).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ?
                        <p className="text-xs text-red-500 font-medium flex items-center "><span className="material-icons mr-2" style={{fontSize: "1.5rem"}}>domain_verification</span> Survey Closed </p>
                          :
                        <p className="text-xs text-gray-400 font-medium flex items-center">{"Expiry Date: " + moment.unix(survey.expireTime / 1000).format("DD MMM, YYYY")}</p>
                        }
                  </>
                  }

              </div>

              
              
              <div className="my-8">
                <div className="flex justify-between py-3">
                  
                  {/* <p className="text-xs text-gray-500">{LanguageObj && LanguageObj["response_label"+"_"+CurrentLanguage]? LanguageObj["response_label"+"_"+CurrentLanguage]:"Response"}</p> */}
                  {/* <p className="text-base text-blue-500">96 <span className="text-xs text-gray-500">/200</span></p> */}
                </div>
                {/* <div className="h-1.5 w-full bg-gray-100 rounded-full overflow-hidden"><div className="h-1.5 bg-blue-500 w-3/6" /></div> */}
              </div>



              <div className="flex justify-between text-gray-400 items-center">
              
                {accessAllow("createSurvey") ?
                  <>
                <div onClick={() => onEditSurvey(survey)} className="relative bg-gray-100 rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-100 hover:text-blue-500 tooltipshow cursor-pointer">
                  <span className="material-icons" style={{fontSize: '18px'}}>edit</span>
                  <span className="tooltipcard bg-gray-800 p-2 text-xs absolute -top-10 text-white rounded-md whitespace-nowrap ">{LanguageObj && LanguageObj["edit_survey_label" + "_" + CurrentLanguage] ? LanguageObj["edit_survey_label" + "_" + CurrentLanguage] : "Edit Survey"}</span>
                </div>
                </>
                :null}


                {accessAllow("responseAnalytics")?
                <>
                <div onClick={() => onReportSurvey(survey)} className="relative bg-gray-100 rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-100 hover:text-blue-500 tooltipshow cursor-pointer">
                  <span className="material-icons" style={{fontSize: '18px'}}>signal_cellular_alt</span>
                  <span className="tooltipcard bg-gray-800 p-2 text-xs absolute -top-10 text-white rounded-md whitespace-nowrap">{LanguageObj && LanguageObj["response_analytics_label" + "_" + CurrentLanguage] ? LanguageObj["response_analytics_label" + "_" + CurrentLanguage] : "Response Analytics"}</span>
                </div>
                </>
                :null}


                {accessAllow("dashboard")?
                <>
                <div onClick={() => onDashboard(survey)}  className="relative bg-gray-100 rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-100 hover:text-blue-500 tooltipshow cursor-pointer">
                  <span className="material-icons" style={{fontSize: '18px'}}>dashboard</span>
                  <span className="tooltipcard bg-gray-800 p-2 text-xs absolute -top-10 text-white rounded-md whitespace-nowrap">{LanguageObj && LanguageObj["dashboard_label" + "_" + CurrentLanguage] ? LanguageObj["dashboard_label" + "_" + CurrentLanguage] : "Dashboard"}</span>
                </div>
                </>
                :null}
                

                {accessAllow("publishSurvey") ?
                  <>
                <div onClick={() => onPublishSurvey(survey)} className="relative bg-gray-100 rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-100 hover:text-blue-500 tooltipshow cursor-pointer">
                  <span className="material-icons" style={{fontSize: '18px'}}>send</span>
                  <span className="tooltipcard bg-gray-800 p-2 text-xs absolute -top-10 text-white rounded-md whitespace-nowrap">{LanguageObj && LanguageObj["campaign_label" + "_" + CurrentLanguage] ? LanguageObj["campaign_label" + "_" + CurrentLanguage] : "Campaign"} </span>
                </div>
                </>
                : null}


              </div>




            </div>







          </>
            : null
        )
        : null
      }
    </>
  );
}
