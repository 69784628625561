
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const userService = {
    userlogin,
    validateOtp,
    logout,

//Super Admin Login
    login,

    uploadImage,
    uploadImageLogo,
    uploadImageLogo2,
    
};
function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
    localStorage.removeItem('backendUrl');
    localStorage.removeItem('accessModule');
}
function userlogin(data) {

    let header = new Headers({
            'Content-Type': 'application/json'
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/userlogin`, requestOptions)
            .then(handleResponse)
            .then(user => {
                let userObj = {
                    userinfoToken: user.data
                }
                
                return userObj;
            });
}
function validateOtp(data) {

    let header = new Headers({
            'Content-Type': 'application/json'
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/validateOtp`, requestOptions)
            .then(handleResponse)
            .then(user => {
                let userObj = {
                    userinfo: user.data
                }
                if (user.data) {
                    localStorage.setItem('adminuser', JSON.stringify(user.data));
                }
                
                return userObj;
            });
}
function handleResponse(response) {
   // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            // console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            // console.log("datadatadatadatadata   ", data);
            if (data.code===3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}




//Super Admin Login

function login(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
            .then(handleResponse)
            .then(user => {
                let userObj = {
                    userinfo: user.data
                }
                if (user.data) {
                    localStorage.setItem('adminuser', JSON.stringify(user.data));
                }
                
                return userObj;
            });
}



function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            return userObj;
        });
}
function uploadImageLogo(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetailsLogo: res.data
            }
            return userObj;
        });
}

function uploadImageLogo2(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetailsLogo2: res.data
            }
            return userObj;
        });
}