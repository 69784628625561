import React, { Component } from 'react';
import { connect } from 'react-redux';
import DemographicFilter from './Filters/DemographicFilter';
import QuestionFilter from './Filters/QuestionFilter';
import CooccurGraph from './Content/CooccurGraph';
import NeighbourGraph from './Content/NeighbourGraph';
import Commentary from '../../../Index/Components/Commentary/Commentary';

class Network extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuesId:'',
      DemographicFilterName:'',
      DemographicFilterValue:'',
      graphType:1,
      FilterList:[],
      showFilter:false,
      showValue:'',
      showViewBy:false

    }
  }



  componentDidMount() {
  }

  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }
  closeFilterQues=(check1)=>{
    this.setState({showViewBy:check1});
  }
  handleChangeQuesIdSave=(Id)=>{
    this.setState({currentQuesId:Id})
  }

  FilterSave=(name, value)=>{
    this.setState({DemographicFilterName:name, DemographicFilterValue:value})
  }


  handleKeywordCall=(keyWord, isMultiKeyword)=>{
    
    let { currentQuesId, DemographicFilterName, DemographicFilterValue } = this.state;
    let { getTextQuestionData, callWordCloudText } = this.props;

    let initQuesId = '';
    if(currentQuesId){
      initQuesId = currentQuesId;
    }
    else{
      initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
    }


    callWordCloudText(initQuesId, keyWord, DemographicFilterName, DemographicFilterValue,  isMultiKeyword)


    
  }


    ongraphType=(type)=>{
      let { handleNeighborGraph, callQuestionNetworkCloud , getTextQuestionData} = this.props;
      let { FilterList, currentQuesId} = this.state;
      this.setState({graphType:type})

      let initQuesId = '';
      if(currentQuesId){
        initQuesId = currentQuesId;
      }
      else{
        initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
      }

      callQuestionNetworkCloud(initQuesId, type, FilterList);


      // if(type===2){
      //   handleNeighborGraph(FilterList);
      // }
      // else{
      //   callQuestionNetworkCloud(initQuesId,type, FilterList);
      // }
    }

    handleNetworkDemographicFilter =(currentQuesId, selectedFilterName, val)=>{
      let { handleNetworkDemographicFilter } = this.props;
      handleNetworkDemographicFilter (currentQuesId, selectedFilterName, val, this.state.graphType)

    }



    

  
      handleMultifilterIndexScore2=(finalFilter)=>{
        let { getTextQuestionData } = this.props;
        let { currentQuesId , graphType } = this.state;
        let initQuesId = '';
        if(currentQuesId){
          initQuesId = currentQuesId;
        }
        else{
          initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
        }

        let { handleNetworkDemographicFilter }= this.props;
        this.setState({FilterList:finalFilter});
        handleNetworkDemographicFilter (initQuesId,finalFilter, graphType)

      }




    render(){

        let { indexType,optionType,getCommentData,loading,wordTextKeyWordData,neighborGraphData, callQuestionNetworkCloud, cooccurmatrixData , callQuestionWordCloud, wordTextKeyWord, wordCloudTextData, getTextQuestionData, handleChangeQuestion, wordCloudData,EmpFilterData,getIndexFilterData, handleNetworkDemographicFilter } = this.props;

        let { currentQuesId , graphType, FilterList } = this.state;


        let initQuesId = '';


        if(currentQuesId){
          initQuesId = currentQuesId;
        }
        else{
          initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
        }


        let { CountRestrict } = this.props;

        let count1 = cooccurmatrixData && cooccurmatrixData.count?cooccurmatrixData.count:"NaN";
        let cooccurmatrixDataFinal = CountRestrict(count1)?{}:cooccurmatrixData;

        let count2 = neighborGraphData && neighborGraphData.count?neighborGraphData.count:"NaN";
        let neighborGraphDataFinal = CountRestrict(count2)?{}:neighborGraphData;

        console.log('-----:wordCloudData ',wordCloudData)

        return(
            <>
                            <div className="px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b h-10">
                  <div className="cursor-pointer text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden">
                    <span onClick={()=>this.ongraphType(1)} className={(graphType===1)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Co-Occurrence Graph</span>
                    <span onClick={()=>this.ongraphType(2)} className={(graphType===2)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Neighbour Graph</span>
                  </div>
                </div>


            <div  onClick={()=>this.closeFilter(false)}  className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
              <QuestionFilter
                                        showViewBy={this.state.showViewBy}
                                        closeFilterQues={this.closeFilterQues}
                                        graphType={graphType}
                                        FilterList={FilterList}

                callQuestionNetworkCloud={callQuestionNetworkCloud} getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion} handleChangeQuesIdSave={this.handleChangeQuesIdSave}
              />
              <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
            </div>


            <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
            <DemographicFilter 
                                                  showFilter={this.state.showFilter}
                                                  showValue={this.state.showValue}
                                                  closeFilter={this.closeFilter}
              EmpFilterData2={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
              />
            </div>

              {/* {FilterList && FilterList.length>0?
              <div className=" px-4 xl:flex lg:flex md:flex  justify-left items-center bg-white border-b py-3 h-10 ">
                {FilterList && FilterList.length>0?FilterList.map((element)=>
                <p className="text-md mx-2 my-4 font-semibold flex"><p className="text-blue-500 mr-1">{element.level+": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ):null}</div>
            :null} */}
        {FilterList && FilterList.length > 0 ?
          <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
            {FilterList && FilterList.length > 0 ? FilterList.map((element) =>
              <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null}

            {getTextQuestionData && getTextQuestionData.length>0?
            
          

                <div onClick={()=>{
                  this.closeFilter(false);
                  this.closeFilterQues(false);
                }}  className=" " >
                {/* <nav className="scroll flex justify-left  overflow-x-auto  px-6 overscroll-x-contain">
                  <div className="cursor-pointer flex justify-left">
                    <a onClick={()=>this.ongraphType(1)} className={(graphType===1)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-1 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-1 mr-8"} href>
                    Co-Occurrence Graph
                    </a>
                    <a onClick={()=>this.ongraphType(2)} className={(graphType===2)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-1 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-1 mr-8"} href>
                    Neighbour Graph 
                    </a>
                  </div>
                </nav> */}


                {graphType===1?
              <div className="p-4">
              <CooccurGraph cooccurmatrixData={cooccurmatrixDataFinal} handleKeywordCall={this.handleKeywordCall}
              wordCloudTextData={wordCloudTextData} wordTextKeyWord={wordTextKeyWord}
              />
                  </div>
                :null}

                {graphType===2?
              <div className="p-4">
              <NeighbourGraph neighborGraphData={neighborGraphDataFinal} handleKeywordCall={this.handleKeywordCall}/>
                  </div>
                :null}






                </div>


:

<>
{!loading?
<div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
:null}
</>
}
            </>
        );
    }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Network);
