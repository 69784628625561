import React from "react";
export default function AddSurvey(props) {
  let { CurrentSurveyData,handleUseSurveyPage, handleCreateSurveyPopupClose, handleAddSurveySubmit, surveyFormData, handleAddSurveyInput, surveyValidation } = props;
  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
          <div onClick={()=>handleCreateSurveyPopupClose()} className="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
          <div className="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8">
            <h2 className="font-bold text-xl  mb-2 text-gray-800  text-center pb-4">Use this Survey</h2>
            {/* <p className=" text-base mb-6 text-gray-700 border-b pb-2 text-center"></p> */}

            <div className="my-2">
              <input type="text" name="name" value={CurrentSurveyData && CurrentSurveyData.name?CurrentSurveyData.name:""} onChange={()=>{}} className="py-2 px-2 w-full border border-gray-300 outline-none" />
            </div>
            {/* <div className="my-2">
              <textarea name="desc" value={surveyFormData.desc} onChange={handleAddSurveyInput} rows={4} placeholder="Description here. ." className="text-sm text-gray-900 outline-none border-b border-gray-200 py-1.5 border px-2 w-full resize-none" defaultValue={""} />
            </div> */}
            <div className="mt-8 text-center ">
              <p className="text-purple-400">You have choosen this survey package!</p>
            </div>
            <div className="mt-8 text-center">
              <button onClick={()=>handleUseSurveyPage()} type="button" className="bg-blue-500 hover:bg-blue-500 text-white font-semibold py-2 px-6  rounded-full shadow-sm" >
                Continue
              </button> 
            </div>
          </div>
        </div>
        </div>
   </>
  );
}
