export const campaignConstants = {

    ADD_CAMPAIGN_REQUEST: 'ADD_CAMPAIGN_REQUEST',
    ADD_CAMPAIGN_SUCCESS: 'ADD_CAMPAIGN_SUCCESS',
    ADD_CAMPAIGN_FAILURE: 'ADD_CAMPAIGN_FAILURE',

    EXPIRE_CAMPAIGN_REQUEST: 'EXPIRE_CAMPAIGN_REQUEST',
    EXPIRE_CAMPAIGN_SUCCESS: 'EXPIRE_CAMPAIGN_SUCCESS',
    EXPIRE_CAMPAIGN_FAILURE: 'EXPIRE_CAMPAIGN_FAILURE',

    RESEND_CAMPAIGN_REQUEST: 'RESEND_CAMPAIGN_REQUEST',
    RESEND_CAMPAIGN_SUCCESS: 'RESEND_CAMPAIGN_SUCCESS',
    RESEND_CAMPAIGN_FAILURE: 'RESEND_CAMPAIGN_FAILURE',

    CAMPAIGN_GETALL_REQUEST: 'CAMPAIGN_GETALL_REQUEST',
    CAMPAIGN_GETALL_SUCCESS: 'CAMPAIGN_GETALL_SUCCESS',
    CAMPAIGN_GETALL_FAILURE: 'CAMPAIGN_GETALL_FAILURE',

    CAMPAIGN_EMPLOYEES_GETALL_REQUEST: 'CAMPAIGN_EMPLOYEES_GETALL_REQUEST',
    CAMPAIGN_EMPLOYEES_GETALL_SUCCESS: 'CAMPAIGN_EMPLOYEES_GETALL_SUCCESS',
    CAMPAIGN_EMPLOYEES_GETALL_FAILURE: 'CAMPAIGN_EMPLOYEES_GETALL_FAILURE',

    CAMPAIGN_CUSTOMER_GETALL_REQUEST: 'CAMPAIGN_CUSTOMER_GETALL_REQUEST',
    CAMPAIGN_CUSTOMER_GETALL_SUCCESS: 'CAMPAIGN_CUSTOMER_GETALL_SUCCESS',
    CAMPAIGN_CUSTOMER_GETALL_FAILURE: 'CAMPAIGN_CUSTOMER_GETALL_FAILURE',

    GET_RESPONSE_ANALYTICS_REQUEST: 'GET_RESPONSE_ANALYTICS_REQUEST',
    GET_RESPONSE_ANALYTICS_SUCCESS: 'GET_RESPONSE_ANALYTICS_SUCCESS',
    GET_RESPONSE_ANALYTICS_FAILURE: 'GET_RESPONSE_ANALYTICS_FAILURE',

    GET_CUSTOMER_RESPONSE_ANALYTICS_REQUEST: 'GET_CUSTOMER_RESPONSE_ANALYTICS_REQUEST',
    GET_CUSTOMER_RESPONSE_ANALYTICS_SUCCESS: 'GET_CUSTOMER_RESPONSE_ANALYTICS_SUCCESS',
    GET_CUSTOMER_RESPONSE_ANALYTICS_FAILURE: 'GET_CUSTOMER_RESPONSE_ANALYTICS_FAILURE',


    RESEND_MAIL_IN_BULK_REQUEST:'RESEND_MAIL_IN_BULK_REQUEST',
    RESEND_MAIL_IN_BULK_SUCCESS: 'RESEND_MAIL_IN_BULK_SUCCESS',
    RESEND_MAIL_IN_BULK_FAILURE: 'RESEND_MAIL_IN_BULK_FAILURE',

    GET_REMINDER_HISTORY_REQUEST:'GET_REMINDER_HISTORY_REQUEST',
    GET_REMINDER_HISTORY_SUCCESS: 'GET_REMINDER_HISTORY_SUCCESS',
    GET_REMINDER_HISTORY_FAILURE: 'GET_REMINDER_HISTORY_FAILURE',




    ALLEMPLOYEE_GETALL_REQUEST: 'ALLEMPLOYEE_GETALL_REQUEST',
    ALLEMPLOYEE_GETALL_SUCCESS: 'ALLEMPLOYEE_GETALL_SUCCESS',
    ALLEMPLOYEE_GETALL_FAILURE: 'ALLEMPLOYEE_GETALL_FAILURE',
};
