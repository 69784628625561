
import { authHeader } from '../_helpers';
import { CONST } from '../_config';
let backendUrl = JSON.parse(localStorage.getItem('backendUrl'));

export const campaignService = {
    disableCampaign,
    getCampaignById,
    deleteCampaign,
    logout,
    createNewCampaign,
    updateNewCampaign,
    getAllCampaign,
    getEmployeeList,
    saveEmployeeList,
    getAllCampaignType,
    getCampaignListBySurveyID,
    getCampaignEmployeesByID,
    getCampaignCustomerByID,
    setExpireCampaign,
    resendCampain,
    responseAnalysisByCampaignId,
    customerResponseAnalysisByCampaignId,
    resendMailInBulk,
    getReminderHistoryByCamapaignAndCompanyId,



    getAllEmployees
};
function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
    //window.location.reload();

}
async function getAllCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                listOfCampaigns: data.data
            }
            console.log();
            return campaignObj;
        });
}
async function createNewCampaign(data) {
    console.log("------createNewCampaign------------ ",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }
            console.log("After API: ",data.data);

            return campaignObj;
        });
}
async function setExpireCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/setExpireCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }

            return campaignObj;
        });
}
async function resendCampain(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/resendCampain`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }

            return campaignObj;
        });
}
async function updateNewCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                updateNewCampaign: data.data
            }
            console.log(campaignObj);

            return campaignObj;
        });
}
async function deleteCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                deleteCampaign: data.data
            }
            console.log();

            return campaignObj;
        });
}
async function disableCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateCampaignStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                deleteCampaign: data.data
            }
            console.log();

            return campaignObj;
        });
}
async function getCampaignById(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                campaigndetails: data.data
            }
            console.log();

            return userObj;
        });
}
async function saveEmployeeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/saveEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }
            console.log();

            return campaignObj;
        });
}
async function getEmployeeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                employeeList: data.data
            }
            console.log();

            return campaignObj;
        });
}


async function getAllCampaignType(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllCampaignType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                campaignTypeList: data.data
            }
            console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}

async function getCampaignListBySurveyID(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignListBySurveyID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                campaignList: data.data
            }
            console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}

async function getCampaignEmployeesByID(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignEmployeesByID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                getCampaignEmployeesByIDData: data.data
            }

            return campaignObj;
        });
}


async function getCampaignCustomerByID(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignCustomerByID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                getCampaignCustomerByIDData: data.data
            }

            return campaignObj;
        });
}



async function responseAnalysisByCampaignId(data) {
    console.log("response data 2222 :",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/responseAnalysisByCampaignId`, requestOptions)
        .then(handleResponse)
        .then(data => {

                // const items = data.data
                // const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
                // const header = Object.keys(items[0])
                // let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
                // csv.unshift(header.join(','))
                // csv = csv.join('\r\n')
                
                // console.log(csv)



            let campaignObj = {
                responseAnalysisByCampaignIdData: data.data
            }
            console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}



async function customerResponseAnalysisByCampaignId(data) {
    console.log("response data 2222 :",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/customerResponseAnalysisByCampaignId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                customerResponseAnalysisByCampaignIdData: data.data
            }
            console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}



async function resendMailInBulk(data) {
    console.log("response resendMailInBulk 2222 :",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/resendMailInBulk`, requestOptions)
        .then(handleResponse)
        .then(data => {

                // const items = data.data
                // const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
                // const header = Object.keys(items[0])
                // let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
                // csv.unshift(header.join(','))
                // csv = csv.join('\r\n')
                
                // console.log(csv)



            let campaignObj = {
                responseAnalyticsData: data.data
            }
            console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}

async function getReminderHistoryByCamapaignAndCompanyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getReminderHistoryByCamapaignAndCompanyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                getReminderHistoryData: data.data
            }
            console.log();
            return campaignObj;
        });
}

async function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}





async function getAllEmployees(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                allEmployeesList: data.data
            }

            return companyObj;
        });
}