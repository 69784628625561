import React from "react";
export default function UserAccessModal(props) {
  let { handleOpenEmployeeDrop, addUserModal, handleOpenLevel, handleOpenValue, selectDMGLevel, selectDMGValue, selectDMGValueAll ,handleScreenAcess, giveAccessSubmit, 
    showEmployeeDrop,selectedEmployeeData, showUserDmgLevel, showUserDmgValue, DMGLevelList, finalDemoList, AccessObj, AccessList,DemographicLevels,checkDMGValueAll,
    DemographicValues, searchName, handleSearch , type ,removeAccessSubmit} = props;


    console.log('DMGLevelList: ',DMGLevelList);
    console.log('DemographicValues: ',DemographicValues);

    console.log('finalDemoList: ',finalDemoList);
    console.log('type: ',type);

  return (
   <>
   <div className="fixed h-screen top-0 w-full flex justify-center xl:items-center z-40 xl:py-0 py-2" style={{background: '#0000005c'}}>
  <div className="md:h-auto  h-screen bg-white rounded-md shadow-sm xl:w-7/12 md:w-9/12 w-11/12 overflow-y-auto overflow-hidden ">
    {/* add user */}
    <div className="flex justify-between items-center p-4 border-b"><p className="text-base font-medium">Add new user</p><span onClick={()=>addUserModal(false)}  className="cursor-pointer material-icons p-2 bg-gray-100  hover:bg-red-500 hover:text-white text-gray-500 rounded-full" style={{fontSize: '18px'}}>close</span></div>
    {/* selected user */}
    <div className="flex items-center p-4 border-b"><p className="text-sm font-medium">Selected user :-</p><p className="text-sm pl-4">{selectedEmployeeData && selectedEmployeeData.name?selectedEmployeeData.name +" (Emp Id: "+selectedEmployeeData.employeeID+")":"Emp Id: "+selectedEmployeeData.employeeID}</p></div>
    {/* demograohical access */}
    <div className="border-b p-4">
      <h1 className="font-medium text-sm pb-4">Demographical Access</h1>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2  md:gap-6 gap-4  py-2 text-xs font-medium text-gray-500">
        {/* drop 1 */}
       
       
        <div className="flex items-center justify-between border p-2 relative">
          <div onClick={()=>handleOpenLevel(!showUserDmgLevel)} className="w-full cursor-pointer"><span >DEMOGRAPHIC</span> 
          <span className="text-blue-500 pl-1 text-sm font-normal">{DMGLevelList && DMGLevelList.length>0?DMGLevelList.length:0}</span></div> 
          <span onClick={()=>handleOpenLevel(!showUserDmgLevel)} className="cursor-pointer material-icons">arrow_drop_down</span> 
          {/* drop down */}

          {showUserDmgLevel?
          <div className="absolute top-10 border z-20 shadow-xl w-full right-0 bg-white p-4 font-normal 
          text-gray-500 h-44 overflow-hidden overflow-y-auto lang  ">
          {DemographicLevels && DemographicLevels.length>0?
            DemographicLevels.map((value,index)=>
            <div className="flex items-center py-2"><input type="checkbox"
             checked={DMGLevelList.some(prev=>prev===value)?true:false} 
             onClick={()=>selectDMGLevel(value)} onChange={()=>{}} /> 
            <label className="ml-2">{value}</label></div>
            )
          :null}

          </div>
          :null}
        </div> 


        {DMGLevelList && DMGLevelList.length>0?
        DMGLevelList.map((item)=>
         <div className="flex items-center justify-between border p-2 relative">
          <div onClick={()=>handleOpenValue(item)} className="w-full cursor-pointer"><span >{item}</span> 
          <span className="text-blue-500 pl-1 text-sm font-normal">{(finalDemoList && finalDemoList[item] && finalDemoList[item].length>0)?finalDemoList[item].length:0}</span></div> 
          <span onClick={()=>handleOpenValue(item)} className="cursor-pointer material-icons">arrow_drop_down</span> 
          {/* drop down */}

          {showUserDmgValue===item?
          <div className="absolute top-10 border z-20 shadow-xl w-full right-0 bg-white p-4 font-normal 
          text-gray-500 h-44 overflow-hidden overflow-y-auto lang  ">
          <input type="text" name="searchName" value={searchName} onChange={handleSearch} placeholder="Search" className="w-full border p-1.5 rounded-sm focus:outline-none text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" />

          {DemographicValues && DemographicValues[item] && DemographicValues[item].length>0?
              DemographicValues[item].map((value,index)=>
              value && value.match(new RegExp(`${searchName}`, 'gi'))?
            <div className="flex items-center py-2"><input type="checkbox"
            checked={(finalDemoList && finalDemoList[item] && finalDemoList[item].length>0)?finalDemoList[item].some(prev=>prev===value)?true:false:false} 
            onClick={()=>selectDMGValue(value,item)} onChange={()=>{}} /> 
            <label onClick={()=>selectDMGValue(value,item)} className="ml-2">{value}</label></div>
            :null)
          :null}

          </div>
          :null}
        </div> 
        ):null}





      </div>
    </div>
    {/* end */}
    {/* success */}
    <div className="p-4 border-b" onClick={()=>{
              handleOpenLevel(false);
              handleOpenValue(false);
            }}>
      <h1 className="pb-4 text-sm font-medium">Success Access</h1>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4 text-sm text-gray-500 text-sm">
        {AccessList && AccessList.length>0?
          AccessList.map((item)=>
          <div className="flex items-center "><input checked={AccessObj && AccessObj[item.value]?true:false}
           type="checkbox" onChange={()=>handleScreenAcess(!(AccessObj && AccessObj[item.value])?true:false,item.value)} /> 
           <label className={item.value==="viewSurvey"?"text-red-500 ml-2":"ml-2"}>{item.label}</label></div>
          )
        :null}
      </div>
    </div>
    {/* end */}
    <div className="p-4 flex justify-end">
        {type==="UPDATE"?
          <>
            <button onClick={()=>removeAccessSubmit(true)} className="text-xs mx-2 uppercase border border-red-500 py-3 px-4 focus:outline-none
          rounded-md text-white bg-red-500 hover:text-white">REMOVE USER</button>
              <button onClick={()=>removeAccessSubmit(false)} className="text-xs mx-2 uppercase border border-red-300 py-3 px-4 focus:outline-none
          rounded-md text-white bg-red-300 hover:text-white">CLEAR ACCESS</button>
          </>
        :null}

      <button onClick={()=>giveAccessSubmit()} className="text-xs mx-2 uppercase border border-green-500 py-3 px-4 focus:outline-none
       rounded-md text-white bg-green-500 hover:text-white">GIVE ACCESS</button>
    </div>
  </div>
</div>

   </>
  );
}
