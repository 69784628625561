import React, { Component } from 'react';
import { connect } from 'react-redux';

class IndexKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { indexType, getIndexScore, getIndexData, getAllBenchMarkData, getRespondantDetailsData } = this.props;


    let ParticipationObj={
      total:0,
      completed:0,
      mailSent:0,
      clicked:0,
      participated:0,
      performance:0,
      perfPlusSign:true
    }

    let benchmark="";
    if(getRespondantDetailsData && getRespondantDetailsData[0]){
        let apiData = getRespondantDetailsData[0]
        ParticipationObj['total'] = apiData['total']?apiData['total']:0;
        ParticipationObj['completed'] = apiData['completed']?apiData['completed']:0;
        ParticipationObj['mailSent'] = apiData['mailSent']?apiData['mailSent']:0;
        ParticipationObj['clicked'] = apiData['clicked']?apiData['clicked']:0;
        ParticipationObj['participated'] = apiData['completed']&&apiData['total']?(apiData['completed']*100/apiData['total']).toFixed(2):0;
        
        if(getAllBenchMarkData){
          benchmark = getAllBenchMarkData[indexType]
          let score = getIndexScore[indexType]

          ParticipationObj['performance'] = score<benchmark?'-'+(benchmark-score).toFixed(2)+'%':'+'+(score-benchmark).toFixed(2)+'%';
          ParticipationObj['perfPlusSign'] = (score<benchmark)?false:true;

        }


    }

    console.log('benchmark', benchmark)


    return (
      <>
      
      <div className={benchmark?"grid xl:grid-cols-4 md:grid-cols-3 grid-cols-1  gap-4 m-2":"grid xl:grid-cols-3 md:grid-cols-3 grid-cols-1  gap-4 m-2"}>
          {/* box 1 */}
          <div className=" bg-white flex shadow-xl">
            <div className="bg-purple-600 flex items-center"><span className="material-icons text-white px-6" style={{fontSize: '40px'}}>mark_email_read</span></div>
            <div className="text-center w-full py-6"><h1 className="text-3xl text-gray-800">{ParticipationObj['total']}</h1><p className="lg:text-sm text-xs text-gray-500">Invited</p></div>
          </div>
          {/* box 2 */}
          <div className=" bg-white flex shadow-xl">
            <div className="bg-yellow-300 flex items-center"><span className="material-icons text-white px-6" style={{fontSize: '40px'}}>mark_email_read</span></div>
            <div className="text-center w-full py-6"><h1 className="text-3xl text-gray-800">{ParticipationObj['completed']}</h1><p className="lg:text-sm text-xs text-gray-500">Responded</p></div>
          </div>
          {/* box 3 */}
          <div className=" bg-white flex shadow-xl">
            <div className="bg-blue-400 flex items-center"><span className="material-icons text-white px-6" style={{fontSize: '40px'}}>mark_email_read</span></div>
            <div className="text-center w-full py-6"><h1 className="text-3xl text-gray-800">{ParticipationObj['participated']+'%'}</h1><p className="lg:text-sm text-xs text-gray-500">Response Rate</p></div>
          </div>
          {/* box 4 */}

          {benchmark?
                    <div className=" bg-white flex shadow-xl">
                    <div className="bg-green-400 flex items-center"><span className="material-icons text-white px-6" style={{fontSize: '40px'}}>mark_email_read</span></div>
                    <div className="text-center w-full py-6"><h1 className="text-3xl text-gray-800">{ParticipationObj['performance']}</h1><p className="lg:text-sm text-xs text-gray-500">Score vs Benchmark</p></div>
                  </div>
          :null}

        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexKPI);
