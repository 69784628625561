import React, { Component } from 'react';
import { connect } from 'react-redux';

import Strength from './Components/Strength/Strength';
import Weaknesses from './Components/Weaknesses/Weaknesses';
// import RWAnalysis from './Components/RWAnalysis/RWAnalysis';


class StrengthAndWeaknesses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType:1
    }
  }
  componentDidMount() {

  }

  onOptionType=(val)=>{
    this.setState({optionType:val})
    if(val===2){
      let { onWeaknessQuestionCall } = this.props;
      onWeaknessQuestionCall();
    }
    else{
      let { onStrengthQuestionCall } = this.props;
      onStrengthQuestionCall();
    }
  }

  onSwitchOptionScreen=()=>{
    let { 
      indexType,getCommentData, EmpFilterData, getIndexFilterData, GetParameterLabel,
       
       getIndexScore, handleSummaryFilter,handleQuadrantFilter,getRespondantDetailsData,  handleSummaryCard, getDemographicIndexData, getIndexData,getIndexDistributionData,
      getQuestionData,getQuestionFilterData, handleQuestionFilter, handleCorrelationFilter, getQuestionFavorabilityData,
      getDistributionIndexData,handleDistributionFilter,getIndexDriverData,

      handleCorelation,

      getDemographicIndexScatterData, 

      handleStrengthQuestionFilter,
      handleWeaknessesQuestionFilter,

      getHappinessCorrelationData,
      getEngagementCorrelationData,
      getStressCorrelationData,
      getManagerCorrelationData,


      getRWAnalysisData,
      loading
    
    } = this.props;

    let {optionType} = this.state;

    switch(optionType){
      
      case 1: return <Strength 
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} loading={loading}
        GetParameterLabel={GetParameterLabel}
        getRespondantDetailsData={getRespondantDetailsData}
        getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter} handleStrengthQuestionFilter={handleStrengthQuestionFilter}
        indexType={indexType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
        getQuestionData={getQuestionData} getQuestionFavorabilityData={getQuestionFavorabilityData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getDemographicIndexData={getDemographicIndexData} getIndexData={getIndexData} />
      

      case 2: return <Weaknesses    loading={loading}
      indexType={indexType} optionType={optionType} getCommentData={getCommentData}
      getRespondantDetailsData={getRespondantDetailsData}
      getDemographicIndexScatterData={getDemographicIndexScatterData}
      GetParameterLabel={GetParameterLabel}

      getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter} handleWeaknessesQuestionFilter={handleWeaknessesQuestionFilter}

      indexType={indexType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
      getQuestionData={getQuestionData} getQuestionFavorabilityData={getQuestionFavorabilityData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getDemographicIndexData={getDemographicIndexData} getIndexData={getIndexData} />
    

      
      default: return <div>Default Option</div> 
    }
  }

  render() {
    let { CurrentSurveyId, indexType, getDemographicIndexData } = this.props;
    let { optionType} = this.state;

    return (
      <> 
{/* {true?
 <main className="flex-1 w-full bg-gray-100  overflow-hidden overflow-y-auto ">
   <div className="bg-white text-xl text-gray-500 text-center h-full pt-10">This section is under construction...</div>
</main> 
: */}

      <main className="flex-1 w-full bg-gray-100  overflow-hidden overflow-y-auto ">
        
        <nav className="scroll flex justify-left bg-white  overflow-x-auto  px-6 overscroll-x-contain">
          <div className="cursor-pointer flex justify-left">
            <a onClick={()=>this.onOptionType(1)} className={(optionType===1)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Strength
            </a>
             <a onClick={()=>this.onOptionType(2)} className={(optionType===2)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Weaknesses
            </a>
          </div>
        </nav>

          {this.onSwitchOptionScreen()}
      </main> 

{/* }  */}
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StrengthAndWeaknesses);
