
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const surveyService = {
    logout,
    getSurveyList,
    getQuestionTypeList,
    getSurveyById,


    getParameterList,

    updateSurveyWelcome,

    getParameterListbyParentId,


    setExpireSurvey,

    createSurvey,
    deleteSurvey,
    updateSurvey,

    responseAnalysisBySurveyId,
    getCampaignEmployeesByIDSurveyWise,

    resendCampain,
    createSurveyFromLib,
    getSurveyFromLib,

    getEmployeeList,
    getEmployeeSearch,
    getEmployeeBatchRange,
    getCustomerBatchRange,

    saveEmployeeList,


    updateEmployeeStatus,
    
    getLanguageLabel,
    getLanguage,

    getEmployeeColumnName,
    getEmployeeFilterListByCompanyId,
    createEmployeeFilter,
    updateEmployeeFilterStatus,
    deleteEmployeeFilter,
    assignRole,
    updateEmployee,
    getQuestionLibraryList,
    getAllFilterValues,

    getAssignedRoles,
    updateEmployeeFilterRank,
    getIndexByFilterDMG,
    getResponseAnalyticsPagination,
    removeRole,
    updateSurveyDesign,
    getAllResponseAnalyticsBySurveyId,

    customerResponsePaginationByCampaignId,
    customerResponseDaywiseByCampaignId
    
};

async function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}

async function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

async function getSurveyList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurveyList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                getSurveyListData: data.data
            }
            console.log();

            return surveyObj;
        });
}

async function getQuestionTypeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getQuestionTypeListData: data.data
            }
            console.log();
            return surveyObj;
        });
}


async function getSurveyById(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurveyById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                getSurveyData: data.data
            }
            console.log();

            return companyObj;
        });
}

async function getParameterList(data) {
    console.log("Data Ready For API: ",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getParameterList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                getParameterListData: data.data
            }

            return parameterObj;
        });
}


async function updateSurveyWelcome(data) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateSurveyWelcome`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                updateSurvey: data.data
            }
            console.log();

            return companyObj;
        });
}



async function getParameterListbyParentId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getParameterListbyParentId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                getParameterListbyParentData: data.data
            }

            return parameterObj;
        });
}


async function setExpireSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/setExpireSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                setExpireSurveyData: data.data
            }

            return surveyObj;
        });
}


async function createSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                createSurveyData: data.data
            }

            return surveyObj;
        });
}


async function deleteSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                deleteSurveyData: data.data
            }

            return surveyObj;
        });
}


async function updateSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let surveyObj = {
                updateSurveyData: data.data
            }

            return surveyObj;
        });
}

async function responseAnalysisBySurveyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/responseAnalysisBySurveyId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            // console.log('x responseAnalysisBySurveyIdData',data.data)
            let surveyObj = {
                responseAnalysisBySurveyIdData: data.data
            }

            return surveyObj;
        });
}


async function getCampaignEmployeesByIDSurveyWise(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignEmployeesByIDSurveyWise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getCampaignEmployeesByIDSurveyWiseData: data.data
            }
            console.log('x getCampaignEmployeesByIDSurveyWise',surveyObj)

            return surveyObj;
        });
}


async function resendCampain(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/resendCampain`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                resendCampainData: data.data
            }
            return surveyObj;
        });
}



async function createSurveyFromLib(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createSurveyFromLib`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                createSurveyFromLibData: data.data
            }
            return surveyObj;
        });
}


async function getSurveyFromLib(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurveyFromLib`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getSurveyFromLibData: data.data
            }
            return surveyObj;
        });
}



async function getEmployeeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeListData: data.data
            }
            return surveyObj;
        });
}


async function getEmployeeSearch(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log("data-----------------------------------------------------------------------------.",data.data)
            let surveyObj = {
                getEmployeeSearchData: data.data
            }
            return surveyObj;
        });
}




async function getEmployeeBatchRange(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmployeeListByFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeBatchRangeData: data.data
            }
            return surveyObj;
        });
}


async function getCustomerBatchRange(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCustomerBatchRange`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getCustomerBatchRangeData: data.data
            }
            return surveyObj;
        });
}



async function saveEmployeeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/saveEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                saveEmployeeListData: data.data
            }
            return surveyObj;
        });
}





async function updateEmployeeStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateEmployeeStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmployeeStatusData: data.data
            }
            return surveyObj;
        });
}


async function getLanguageLabel(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getLanguageLabel`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getLanguageLabelData: data.data
            }
            return surveyObj;
        });
}

async function getLanguage(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getLanguage`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getLanguageData: data.data
            }
            return surveyObj;
        });
}

async function getEmployeeColumnName(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmployeeColumnName`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeColumnNameData: data.data
            }
            return surveyObj;
        });
}


async function getEmployeeFilterListByCompanyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmployeeFilterListByCompanyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getEmployeeFilterListByCompanyIdData: data.data
            }
            return surveyObj;
        });
}

async function createEmployeeFilter(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createEmployeeFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                createEmployeeFilterData: data.data
            }
            return surveyObj;
        });
}

async function updateEmployeeFilterStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateEmployeeFilterStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmployeeFilterStatusData: data.data
            }
            return surveyObj;
        });
}

async function deleteEmployeeFilter(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteEmployeeFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                deleteEmployeeFilterData: data.data
            }
            return surveyObj;
        });
}


async function assignRole(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/assignRole`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                assignRoleData: data.data
            }
            return surveyObj;
        });
}


async function updateEmployee(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateEmployee`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateEmployeeData: data.data
            }
            return surveyObj;
        });
}

async function getQuestionLibraryList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionLibraryList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getQuestionLibraryListData: data.data
            }
            return surveyObj;
        });
}



async function getAllFilterValues(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllFilterValues`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getAllFilterValuesData: data.data
            }
            return surveyObj;
        });
}

async function updateEmployeeFilterRank(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateEmployeeFilterRank`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getAllFilterValuesData: data.data
            }
            return surveyObj;
        });
}

async function getAssignedRoles(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAssignedRoles`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getAssignedRolesData: data.data
            }
            return surveyObj;
        });
}


async function getIndexByFilterDMG(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexByFilterDMG`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getIndexByFilterDMGData: data.data
            }
            return surveyObj;
        });
}


async function getResponseAnalyticsPagination(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getResponseAnalyticsPagination`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getResponseAnalyticsPaginationData: data.data
            }
            return surveyObj;
        });
}


async function removeRole(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/removeRole`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                removeRoleData: data.data
            }
            return surveyObj;
        });
}

async function updateSurveyDesign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateSurveyDesign`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                updateSurveyDesignData: data.data
            }
            return surveyObj;
        });
}



async function getAllResponseAnalyticsBySurveyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllResponseAnalyticsBySurveyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                getAllResponseAnalyticsBySurveyIdData: data.data
            }
            return surveyObj;
        });
}



async function customerResponsePaginationByCampaignId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/customerResponsePaginationByCampaignId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                customerResponsePaginationByCampaignIdData: data.data
            }
            return surveyObj;
        });
}



async function customerResponseDaywiseByCampaignId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/customerResponseDaywiseByCampaignId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let surveyObj = {
                customerResponseDaywiseByCampaignIdData: data.data
            }
            return surveyObj;
        });
}
