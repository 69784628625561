import React from "react";
export default function ChooseSurvey(props) {
  let { handleCreateSurveyFromLibrary, handleCreateSurveyPopupClose,handleCreateEditableSurvey, handleAddSurveySubmit, surveyFormData, handleAddSurveyInput, surveyValidation } = props;
  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-3xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
          <div onClick={()=>handleCreateSurveyPopupClose()} className="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
          <div className="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8">
            <h2 className="font-bold text-xl  mb-2 text-gray-800  text-center pb-4">Create Survey</h2>

            <div style={{height:'250px'}} className="text-gray-600 justify-center grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-2 ">
               <div onClick={()=>handleCreateEditableSurvey()} className="m-3 cursor-pointer max-w-md bg-gray-500 shadow-lg rounded border-4 hover:border-gray-500">
                   <div className="justify-center align-center"> 
                      <h1 className="text-center text-white text-2xl  mt-14">Custom Survey</h1>
                      <p className="text-center text-white text-sm pt-2">Build Your Own Survey <br/>(Editable)</p>

                    </div>   
                </div>
                <div  onClick={()=>handleCreateSurveyFromLibrary()}  className="m-3 cursor-pointer max-w-md bg-blue-500 shadow-lg rounded border-4 hover:border-blue-500">
                   <div className="justify-center align-center"> 
                      <h1 className="text-center text-white text-2xl  mt-14">Survey Bank</h1>
                      <p className="text-center text-white text-sm pt-2">Build Survey Using <br/>Plus Survey Package <br/>(Non Editable)</p>

                    </div>   
                </div>

            </div>


          </div>
        </div>
        </div>
   </>
  );
}
