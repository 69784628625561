import { dataTool } from 'echarts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment  from 'moment';

// import Actions from '../Actions/Actions';
// import Resource from '../TaskResouce/Resource/Resource';
// import Task from '../TaskResouce/Task/Task';
// import TaskResource from '../TaskResouce/TaskResource';
// import CompleteModal from './CompleteModal';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }

  render() {
    let { onComplete,getManagerActionPlanData, CurrentActionId, navigate, CurrentActionData,onSelectResource,
      CurrentResourceId, CurrentResourceData, CurrentDimensionData  } = this.props;

    let ResourceList = [];
    if(getManagerActionPlanData && getManagerActionPlanData.length>0){
      getManagerActionPlanData.forEach((action)=>{
        if(action && action.id===CurrentActionId){
          if(action && action.ResourceList && action.ResourceList.length>0){
            ResourceList = action.ResourceList;
          }
        }
      });
    }


    // getManagerActionPlanData,currentParameterId


        //1: start
    //2: pending
    //3: completed


    let resourceStatus = 1
    if(CurrentResourceData && CurrentResourceData.isComplete){
      resourceStatus= 2;
    }




    return (
      <> 
  <div className="w-full lg:p-4  bg-gray-50 capitalize md:mt-0 mt-6" >  
    <div className="border-b flex items-center px-4">
      <span onClick={()=>navigate("resourcelist")} className="cursor-pointer material-icons text-gray-500">arrow_back_ios</span> 
      <h1 onClick={()=>navigate("resourcelist")} className="cursor-pointer text-lg font-medium py-3  px-3 ">{CurrentActionData&&CurrentActionData.name?CurrentActionData.name:""}</h1>
      <p onClick={()=>navigate("actionlist")} className="cursor-pointer text-xs pl-2 border-l text-gray-500 font-medium">{CurrentDimensionData&&CurrentDimensionData.name?CurrentDimensionData.name:""}</p> <span className="material-icons text-gray-500" style={{fontSize: '1rem'}}>chevron_right
      </span><p className="text-xs text-blue-500 font-medium">{CurrentActionData&&CurrentActionData.name?CurrentActionData.name:""}</p>
    </div>
    {/* prog */}


    {resourceStatus===1?
      <div style={{background: '#1ca8ff'}} className="h-1.5 w-1/12"> </div>
    :null}
    {resourceStatus===2?
      <div className="h-1.5 bg-green-400 w-12/12"> </div>
    :null}


    {/* boxes */}
    <div className="flex justify-center h-full">
      {/* box 1 */}
      <div className="max-w-sm mt-10 relative ">
        <span className="bg-gray-100 py-2 px-4 rounded-md text-gray-500 border text-sm font-medium">{
        CurrentResourceData&&CurrentResourceData.number?"Resource no. "+CurrentResourceData.number:"Resource"}</span>
        <h1 className="text-sm font-medium text-2xl font-semibold py-8 normal">
         {CurrentResourceData&&CurrentResourceData.name?CurrentResourceData.name:""}
        </h1>


        <div className="p-4">
          {CurrentResourceData&&CurrentResourceData.imageLinkV2?<img src={CurrentResourceData.imageLinkV2} alt="resouce image"/>:null}
        </div>

        <div className="text-xs py-2 font-light" dangerouslySetInnerHTML={{ __html: CurrentResourceData && CurrentResourceData.desc?CurrentResourceData.desc:""}} />

        {resourceStatus===1?
          <button onClick={()=>onComplete()} className="uppercase text-white font-semibold rounded-md w-full text-sm p-4  
          " style={{background: '#1ca8ff'}}>mark as complete</button>
        :null}
        {resourceStatus===2?
          <button   class="uppercase text-white font-semibold rounded-md w-full text-sm p-4 bg-green-400 
           flex items-center justify-center">completed 
          <span class="material-icons ml-2">check_circle</span></button>
        :null}

          
          
          <b className="text-gray-800">
        </b></div><b className="text-gray-800">
      </b></div><b className="text-gray-800">
    </b></div><b className="text-gray-800">
  </b>


      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
