import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {
    
    let { item, Benchmark } = props;

  // let Benchmark={
  //   score:4.5
  // }



    return (
      <>	
      <div className="pt-1 justify-center items-center" style={{height:Benchmark && Benchmark.score?null:"140px"}}>

      <ReactSpeedometer className="meter"
                                value={item.score}
                                minValue={0}
                                maxValue={100}
                                needleColor="#4db8ff"
                                segments={3}
                                segmentColors={[
                                "#d12e2e",
                                "#f4ae34",
                                "#a2e37d",
                                "#00cc66"
                                ]}
                                ringWidth={35}
                                customSegmentStops={[0, 58,75, 91, 100]}
                                // fluidWidth={true}
                                needleTransitionDuration={3333}
                                needleTransition="easeElastic"
                              />   
            {Benchmark && Benchmark.score || true?
            <div className="gauge-outer">
              <div className="gauge-inner" style={{transform: 'rotate(calc(1deg * (-45 + '+0+' * 29.95)))'}}></div>
            </div>
            :null}
      
      </div>

      </>
    );
  }
