import React, { Component } from 'react';
import { connect } from 'react-redux';
import BarChart from './Charts/BarChart';
import RadarChart from './Charts/RadarChart';
import CoreCircles from './Charts/CoreCircles';
import Commentary from '../../../Index/Components/Commentary/Commentary';
import Filter from './Filter/Filter';
import HeatMap from './Charts/HeatMap';
import { CSVLink } from "react-csv";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CurrentFilterName:'none',
      showTab:1,
      FilterValues:[],
      ListName:'Demographic'

    }
  }
  componentDidMount() {

  }


  
  handleMultifilterIndexScore2=(finalFilter, name)=>{
    let {handleMultifilterOrgDriverSummary}= this.props;
    this.setState({FilterValues:finalFilter, CurrentFilterName:name, ListName:name});
    handleMultifilterOrgDriverSummary(finalFilter,true, name);

  }




  handleTab=(data)=>{
    this.setState({showTab:data})
  }


  handleFilternameSave=(name)=>{
    this.setState({CurrentFilterName:name})
  }

  render() {
    let { CountRestrict, validateIndex, GetParameterLabel, getDistributionIndexData, getIndexFilterData,EmpFilterData, getIndexData, indexType, optionType, getCommentData, loading, getSurveyData } = this.props;
    let { ListName, CurrentFilterName,showTab ,FilterValues  } = this.state;

    let validateExtraObj = {
      "isManager":validateIndex&&validateIndex["5"]?true:false,
      "isSeniorLeadership":validateIndex&&validateIndex["6"]?true:false
    }
    console.log('getDistributionIndexData',getDistributionIndexData)

    let OrgCoreDriverData = {};
    if(getIndexData && getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length>0){
      getSurveyData.orgList.forEach((org)=>{
        OrgCoreDriverData[org.name] = getIndexData[org.name];
      })
    }

    if(validateExtraObj.isManager){
      OrgCoreDriverData['Manager'] = getIndexData&&getIndexData['Manager']?getIndexData['Manager']:0;
    }
    if(validateExtraObj.isSeniorLeadership){
      OrgCoreDriverData["SeniorLeadership"] = getIndexData&&getIndexData["SeniorLeadership"]?getIndexData["SeniorLeadership"]:0;
    }

    let valueList=[]
    if(OrgCoreDriverData){
      valueList = Object.keys(OrgCoreDriverData).map((key) => [ key, OrgCoreDriverData[key] ]);
    }

    let flag = 0;
    if(valueList && valueList.length>0){
      valueList.forEach((fil)=>{
        if(fil && fil[1]){
          flag += 1
        }
      });
    }




    console.log('getIndexData: ',getIndexData)

    console.log('---> OrgCoreDriverData: ',OrgCoreDriverData)

    console.log('valueList: ',valueList)

    console.log('FilterValues: ',FilterValues)

    let EmpFilterData2 = []
    if(EmpFilterData && EmpFilterData.length>0){
     EmpFilterData.forEach((emp)=>{
       if(emp.name !=='ManagerPID'){
         EmpFilterData2.push(emp);
       }
     })
    }
 
    let isDisable  = true;
    let DemographicDataScore = [];
    let DemographicDataDistribution = [];
 
    // if(FilterValues && FilterValues.length>0){
 
    //   if(isDisable){
    //      FilterValues.forEach((item)=>{
    //          if(item.value==='All'){
    //            isDisable = false
    //          }
    //      });
    //   }
 
 
    //   DemographicDataScore=getDemographicIndexMultiFilterData;
    //   DemographicDataDistribution=getIndexDistributionData;
 
    // }


    // Download Excel

    let ScoreExcel = []

    let filter = FilterValues && FilterValues.length > 0 ? " ("+FilterValues.map((element)=>{return element.value}).join(",")+")":" (Filter)"



      if(valueList && valueList.length>0){

        let Overall ={}
        Overall[ListName+filter] = "Overall";
        // Overall["Scores"] = getIndexScore && getIndexScore[indexType]?getIndexScore[indexType].toFixed(2):"NaN";
        
        if(valueList && valueList.length>0){
          valueList.forEach((data)=>{
            if(data && data[0]){
              Overall[data[0]] = data &&  data[1]? data[1].toFixed(2):0;
            }
          });
        }

        ScoreExcel.push(Overall);

      }

      if(getDistributionIndexData && getDistributionIndexData.length>0){
        getDistributionIndexData.forEach((item)=>{
              if(valueList && valueList.length>0){

                      let Temp ={}
                      Temp[ListName+filter] = item.name;
                      // Temp["Scores"] = getIndexScore && getIndexScore[indexType]?getIndexScore[indexType].toFixed(2):"NaN";
                      
                      if(valueList && valueList.length>0){
                        valueList.forEach((data)=>{
                          if(data && data[0]){
                            let Driver = data[0]+"Avg";
                            Temp[data[0]] = item &&  item[Driver]? item[Driver].toFixed(2):0;
                          }
                        });
                      }

                      ScoreExcel.push(Temp);

              }
        });
      }


      // if(getDistributionIndexData && getDistributionIndexData.length>0){
      //   getDistributionIndexData.forEach((data)=>{
      //       let dataScore = CountRestrict(data.count)?"NaN":data[indexType].toFixed(2);

      //       let tempData = {}
      //       if(data && data.name){
      //         tempData["Demographic"] = data.name;
      //         tempData["Scores"] = dataScore?dataScore.toString():"NaN";

      //         let getIndex = getIndexDistributionData?getIndexDistributionData.findIndex(prev=>prev.name===data.name):-1;

      //         if(getIndex!==-1){
      //           let Dist = getIndexDistributionData[getIndex];


      //           if(valueList && valueList.length>0){
      //             valueList.forEach((data,index)=>{
      //               if(data && data[0]){
      //                 tempData[data[0]] = data &&  data[1]? data[1].toFixed():0;
      //               }
      //             });
      //           }




      //           tempData["HighlyEngaged"] = Dist && Dist["HighlyEngaged"]?Dist["HighlyEngaged"].toFixed(2):"NaN";
      //           tempData["ModeratelyEngaged"] = Dist && Dist["ModeratelyEngaged"]?Dist["ModeratelyEngaged"].toFixed(2):"NaN";
      //           tempData["ActivelyDisengaged"] = Dist && Dist["ActivelyDisengaged"]?Dist["ActivelyDisengaged"].toFixed(2):"NaN";
      //           tempData["Passive"] = Dist && Dist["Passive"]?Dist["Passive"].toFixed(2):"NaN";
      //         }

      //       }

      //       ScoreExcel.push(tempData);


      //   })
      // }


    // console.log("------getDistributionIndexData-----------",getDistributionIndexData)
    // console.log("------valueList-----------",valueList)

    console.log("------IndexSummaryScoreExcel: filter-----------", filter  )


    return (
      <>
      <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
        <Filter
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

      </div>



      {FilterValues && FilterValues.length>0?
      <>

      {FilterValues && FilterValues.length > 0 ?
          <div className=" px-4 xl:flex lg:flex md:flex justify-between items-center bg-white border-b py-3 h-10 ">
            <>
            
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}
            


              {FilterValues && FilterValues.length > 0 ?
              <>
              {ScoreExcel ?
              <CSVLink data={ScoreExcel} filename={"IndexScore.csv"}>
              <p className="font-normal text-purple-700 text-sm cursor-pointer hover:text-purple-500">
                {"Download Excel"}
              </p>                
              </CSVLink>
              : '...'}
              </>
              :null}


            </>
            </div>
          : null}


          <div className=" " >
                  <div className="flex w-full" >



                        <div className="bg-white w-full shadow-lg m-4 pb-2 p-4" style={{minHeight:'300px'}}>
                            <div className="md:flex justify-between  mb-4">

                            </div>

                            {getDistributionIndexData?
                              <HeatMap 
                                handleDistributionFilterNameSave={this.handleDistributionFilterNameSave}
                                handleDistributionFilterValueSave={this.handleDistributionFilterValueSave}
                                getDistributionIndexData={getDistributionIndexData}
                                valueList={valueList}
                                GetParameterLabel={GetParameterLabel}
                                currFilterName={CurrentFilterName}
                                FilterValues={FilterValues}
                                validateExtraObj={validateExtraObj}
                                CountRestrict={CountRestrict}
                              />
                              :null}



                        </div>
            
          
          </div>
        </div>




      </>

      :
        <>
                      {flag?
                <div className=" mx-auto px-2 py-2">
                  <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 xl:grid-cols-1  gap-2">
        
                      <div className="bg-white rounded w-full shadow-lg px-4 py-2">
                      
                      <div className="px-2 py-4 text-base text-gray-700 font-semibold flex items-center">
                        <div className="text-lg w-10/12">Organization Core</div>

                        <div className="text-lg ">
                              <i onClick={()=>this.handleTab(1)} style={{opacity:(showTab===1)?1:0.3}} className="cursor-pointer fa fa-bar-chart mx-2"></i> 
                              <i onClick={()=>this.handleTab(2)} style={{opacity:(showTab===2)?1:0.3}} className="cursor-pointer fa fa-circle-o-notch mx-2"></i> 
                              {/* <i onClick={()=>this.handleTab(3)} style={{opacity:(showTab===3)?1:0.3}} className="cursor-pointer fa fa-snowflake-o mx-2"></i>  */}

                        </div>
                      </div>

                        {showTab===1?
                          <BarChart valueList={valueList} GetParameterLabel={GetParameterLabel} />
                        :null}
                        {showTab===2?
                          <CoreCircles valueList={valueList} GetParameterLabel={GetParameterLabel}/>
                          :null}
                        {/* {showTab===3?
                          <RadarChart valueList={valueList} GetParameterLabel={GetParameterLabel}/>
                        :null} */}

                      </div>
  
                  </div>
                </div>
              :
              <>
              {!loading?
              <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated organization core values!</div>
              :null}
              </>
              }

              </>}





                
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
