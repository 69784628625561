import React, { Component } from 'react';
import { connect } from 'react-redux';
import BarRWA from '../../Charts/BarRWA';
import Commentary from '../../../../../Index/Components/Commentary/Commentary';
import Filter from './Filter';

class DimensionIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilterName:'none',
      currentFilterValue:'none',
      currentSelectValue:'',
      FilterValues:[],
      showFilter:false,
      showValue:''
    }
  }
  componentDidMount() {

  }
  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }
  handleRWAFilterNameSave=(val)=>{
    this.setState({currentFilterName:val})
    if(val==='none'){
      this.setState({currentFilterValue:'none'})
    }
  }

  handleRWAFilterValueSave=(val)=>{
    this.setState({currentFilterValue:val})

  }



  
  handleSelectMultiFilter=(name)=>{
    let {FilterValues } = this.state;
    let Check = FilterValues && FilterValues.length>0?  FilterValues[(FilterValues.length-1)].value==="All"?false:true     :    true;

    if(Check){
      let getIndex = FilterValues.findIndex(prev=>prev.level === name);
      if(getIndex === -1){
          FilterValues.push({
            level:name,
            value:"All"
          });
      }
      else{
        FilterValues.splice(getIndex,1);
      }
      this.setState({FilterValues},()=>{
        // let { handleMultiFilterGetQuestion } = this.props;
        // handleMultiFilterGetQuestion(FilterValues);
      });
    }

  }


  handleSelectMultiFilterClose=(name)=>{
    let {FilterValues } = this.state;
    let getIndex = FilterValues.findIndex(prev=>prev.level === name);
    if(getIndex !== -1){
      FilterValues.splice(getIndex,1);
    }

    console.log('FilterValues: ',FilterValues);
    this.setState({FilterValues},()=>{
      let { handleFilterRWA, indexType } = this.props;
      handleFilterRWA(FilterValues,indexType);
    });

  }






    handleSelectedListDropdown=(name)=>{
    if(this.state.currentSelectValue === name){
      this.setState({currentSelectValue:""});
    }
    else{
      this.setState({currentSelectValue:name});
    }
  }

  handleSubmitFilter=(name, value)=>{
    let { FilterValues } = this.state;
    let getIndex = FilterValues.findIndex(prev=>prev.level === name);
    if(getIndex  === -1){
      FilterValues.push({
        level:name,
        value:value
      });
    }
    else{
      FilterValues[getIndex].value = value;
      // FilterValues.splice(getIndex,1)
    }

    this.setState({FilterValues, currentSelectValue:''},()=>{
      let { handleFilterRWA, rwaIndexType } = this.props;
      handleFilterRWA(FilterValues,rwaIndexType);
    });

  }

  handleFilterClear=()=>{
    this.setState({FilterValues:[]},()=>{
      let { handleFilterRWA, rwaIndexType } = this.props;
      handleFilterRWA([],rwaIndexType);
    });
  }



  
  handleMultifilterIndexScore2=(finalFilter, name)=>{
    let {handleFilterRWA, rwaIndexType}= this.props;
    this.setState({FilterValues:finalFilter});
    handleFilterRWA(finalFilter,rwaIndexType);

  }


  render() {
    let { GetParameterLabel,loading, indexType,optionType,getCommentData,Name, EmpFilterData, getIndexFilterData, handleRWAFilter, getRWAData, rwaIndexType} = this.props;



    console.log('getRWAData: ',getRWAData)

    let { CountRestrict } = this.props;
    let count = getRWAData && getRWAData.count?getRWAData.count:0;
    let isShowCount = CountRestrict(count,false, "rwa")?false:true;

    let { currentSelectValue, FilterValues }  = this.state;

    let DriverName = []
    let DriverValues = []

    let showData = false;
    if(getRWAData && getRWAData.rwa){
      if(getRWAData && getRWAData.rwa && getRWAData.rwa.driver && getRWAData.rwa.driver.length>0){
        getRWAData.rwa.driver.forEach((item)=>{
          DriverName.push(GetParameterLabel(item));
        });
      }      
      DriverValues = getRWAData.rwa.normRelaImpt;
      showData = true;
    }




    let EmpFilterData2 = []
    if(EmpFilterData && EmpFilterData.length>0){
     EmpFilterData.forEach((emp)=>{
       if(emp.name !=='ManagerPID'){
         EmpFilterData2.push(emp);
       }
     })
    }
 
    let isDisable  = true;
   //  let DemographicDataScore = [];
   //  let DemographicDataDistribution = [];
 
    if(FilterValues && FilterValues.length>0){
 
      if(isDisable){
         FilterValues.forEach((item)=>{
             if(item.value==='All'){
               isDisable = false
             }
         });
      }
 
 
     //  DemographicDataScore=getDemographicIndexMultiFilterData;
     //  DemographicDataDistribution=getIndexDistributionData;
 
    }


    return (
      <>
      <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
        <Filter
                  showFilter={this.state.showFilter}
                  showValue={this.state.showValue}
                  closeFilter={this.closeFilter}
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

      </div>






        <div className="xl:flex lg:flex md:flex justify-start items-center">

        {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}

        {/* <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}
        


          </div>


          {FilterValues && FilterValues.length > 0 ?
          <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null}


            <div onClick={()=>this.closeFilter(false)} className=" " >
              <div className="flex w-full" >
                
              
                <div className="bg-white w-full shadow-lg p-4 m-4 mr-2 justify-center" >
                <div className="font-medium text-lg">{Name} RWA</div>

                {showData?

                  <div className="py-4 h-screen">
                  {isShowCount?
                  <BarRWA DriverName={DriverName} DriverValues={DriverValues} />
                  :
                  null
                  }
                  <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 30</div>
                  </div>
                  :
                  <>
                  {!loading?
                  <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
                  :null}
                  </>
                  }


              </div>
          </div>
        </div>

                
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DimensionIndex);
