import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName:'',
      currentSurveyId:'',
      
      showDropdown:false,

      // showSidebar:false
    }
  }
  componentDidMount() {
  }

  onDropdown=()=>{
    if(this.state.showDropdown){
      this.setState({showDropdown:false})
    }
    else{
      this.setState({showDropdown:true})
    }
  }

  logout=()=>{
    this.props.dispatch(userActions.logout());
  }

  IndexHoverOptions=(surveyId,indexType)=>{ 
    let { onOptionType } = this.props;

    return(    
      <span>
        <i className="fa fa-scribd" onClick={()=>onOptionType(surveyId,indexType,1)} />
        <i className="fa fa-quora" onClick={()=>onOptionType(surveyId,indexType,2)}/>
        <i className="fa fa-align-left" onClick={()=>onOptionType(surveyId,indexType,3)}/> 
        {/* <i className="fa fa-facebook" onClick={()=>onOptionType(surveyId,indexType,4)}/>  */}
      </span>
    )

  }




  handleIndexType=(surveyId,indexType, isDisable)=>{
    let { onOptionType } = this.props;
    if(!isDisable){
      onOptionType(surveyId,indexType,1);
    }
    // this.setState({showSidebar:false});

    let {handleSidebar}=this.props;
    handleSidebar(false);

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },5000)
  }



  sidebarToggle=()=>{
    // this.setState({showSidebar:!this.state.showSidebar});

    let {handleSidebar,showSidebar}=this.props;
    handleSidebar(!showSidebar);

    
    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },6000)



    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },7000)
  }


  render() {
    let { SurveyList, getIndexData,
      // onHappinessIndex, onEngagementIndex, onHopeIndex, onStressIndex, onOptionType,
      onManagerList ,onOverview, indexType, onOrganizationCore, CurrentSurveyId} = this.props;
    let { 
      // currentSurveyName, showDropdown,
        showSidebar } = this.props;

    let surveyId = '';
    if(SurveyList && SurveyList.length>0){

      if(CurrentSurveyId){
        surveyId = CurrentSurveyId;
      }
      else{
        surveyId = SurveyList[0].id;
      }
      
    }

    //console.log('=============================================?=================CurrentSurveyId',CurrentSurveyId)
    //console.log('=============================================?=================surveyId',surveyId)





    let overviewScoreList = []
    let IndexList  = []

    function getIndexName(key){
      let name = ''
      if     (key===1){ name='Happiness' }
      else if(key===2){ name='Engagement' }
      else if(key===3){ name='Hope' }
      else if(key===4){ name='Stress' }
      else if(key===5){ name='Manager' }
      else if(key===6){ name='Leadership' }
      else if(key===7){ name='Safety' }
      else if(key===9){ name='Diversity' }
      else { name='NaN' }
      return name;
    }

    if(getIndexData){

      IndexList = Object.keys(getIndexData).map((key) => [ Number(key), getIndexData[key] ]);

      IndexList.forEach((data)=>{
        if(getIndexName(data[0]) !=='NaN' && data && data[0] && data[1]){

          overviewScoreList.push({
            template:data[0],
            score:data[1].toFixed(2),
            name:getIndexName(data[0])
          })

        }


      })
    }



    // //console.log('indexType:-------------------> ',indexType)





    return (
      <>
<span className="w-16">
   <div className={showSidebar ?
        "fixed lg:flex flex hidden left-0 h-full z-30  w-56 transition duration-300 transform bg-white border-r "
        :
        "fixed lg:flex flex hidden left-0 h-full z-30  w-16 transition duration-300 transform bg-white border-r "
      }
        id="customscroll" >

        {/* sidebar non expend */}
        <div>
        <div className="flex items-center border-b">
          <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-16 justify-center">


            <span onClick={()=>this.sidebarToggle()} className="cursor-pointer material-icons text-gray-500 rounded hover:bg-gray-100 p-2">{showSidebar?
        "chevron_left":
        "menu"
        }</span>

          </div>

          {showSidebar?
          <span className="font-normal ml-4 text-sm text-gray-500 cursor-default">Insight</span>
          :null}

        </div>



        <div id="chatbot" className="py-4 overflow-y-auto h-screen pb-24" style={{height:"500px", overflowY:"auto"}} >
       


            <div className={!showSidebar ?"flex items-center tooltip w-16 cursor-pointer":"flex items-center cursor-pointer"} onClick={()=>this.handleIndexType(surveyId,0, (indexType===0))}>
              <div className="flex items-center px-4 py-4 w-16 text-blue-500 h-12 justify-center ">
                <span className={(indexType===0)?"material-icons  rounded text-blue-500  p-2 ":"material-icons  rounded text-gray-500 "}>dashboard</span>
              </div>

              {!showSidebar ?
              <span className=" tooltiptext bg-gray-800 p-2  text-xs absolute left-20 text-white rounded-md z-30">{"Overview"}</span>
              :
              <span className={(indexType===0)?"font-medium text-xs text-blue-500":"font-medium text-xs text-gray-500"}>{"Overview"}</span>
              }
            </div>



            {overviewScoreList && overviewScoreList.length>0?
              overviewScoreList.map((item)=>  

              <div className={!showSidebar ?"flex items-center tooltip w-16 cursor-pointer":"flex items-center cursor-pointer"} onClick={()=>this.handleIndexType(surveyId,item.template, (indexType===item.template))}>
              <div className="flex items-center px-4 py-4 w-16 text-blue-500 h-12 justify-center ">

              <span className={(indexType===item.template)?"material-icons  rounded text-blue-500  p-2 ":"material-icons  rounded text-gray-500 "}>
                {
                      (item.template===1)?"mood":
                      (item.template===2)?"emoji_people":
                      (item.template===3)?"loyalty":
                      (item.template===4)?"sentiment_neutral":
                      (item.template===5)?"manage_accounts":
                      (item.template===6)?"leaderboard":
                      (item.template===7)?"security":
                      (item.template===9)?"dashboard":

                      "dashboard"
                
            }
                </span>
              </div>

              {!showSidebar ?
              <span className=" tooltiptext bg-gray-800 p-2  text-xs absolute left-20 text-white rounded-md z-30">{item.name+" Insight"}</span>
              :
              <span className={(indexType===item.template)?"font-medium text-xs text-blue-500":"font-medium text-xs text-gray-500"}>{item.name+" Insight"}</span>

              }
            </div>


            ):null}  






            <div className={!showSidebar ?"flex items-center tooltip w-16 cursor-pointer":"flex items-center cursor-pointer"} onClick={()=>this.handleIndexType(surveyId,8, (indexType===8))}>
              <div className="flex items-center px-4 py-4 w-16 text-blue-500 h-12 justify-center ">
                <span className={(indexType===8)?"material-icons  rounded text-blue-500  p-2 ":"material-icons  rounded text-gray-500 "}>business</span>
              </div>

              {!showSidebar ?
              <span className=" tooltiptext bg-gray-800 p-2  text-xs absolute left-20 text-white rounded-md z-30">{"Organization Core"}</span>
              :
              <span className={(indexType===8)?"font-medium text-xs text-blue-500":"font-medium text-xs text-gray-500"}>{"Organization Core"}</span>
              }
            </div>


      
            {CurrentSurveyId !== "60b9df102f68b86828ce1539" || true?
            <div className={!showSidebar ?"flex items-center tooltip w-16 cursor-pointer":"flex items-center cursor-pointer"} onClick={()=>this.handleIndexType(surveyId,102, (indexType===102))}>
              <div className="flex items-center px-4 py-4 w-16 text-blue-500 h-12 justify-center ">
                <span className={(indexType===102)?"material-icons  rounded text-blue-500  p-2 ":"material-icons  rounded text-gray-500 "}>assessment</span>
              </div>

              {!showSidebar ?
              <span className=" tooltiptext bg-gray-800 p-2  text-xs absolute left-20 text-white rounded-md z-30">{"Manager Score Card"}</span>
              :
              <span className={(indexType===102)?"font-medium text-xs text-blue-500":"font-medium text-xs text-gray-500"}>{"Manager Score Card"}</span>

              }
            </div>
            :null}


            <div className={!showSidebar ?"flex items-center tooltip w-16 cursor-pointer":"flex items-center cursor-pointer"} onClick={()=>this.handleIndexType(surveyId,103, (indexType===103))}>
              <div className="flex items-center px-4 py-4 w-16 text-blue-500 h-12 justify-center ">
                <span className={(indexType===103)?"material-icons  rounded text-blue-500  p-2 ":"material-icons  rounded text-gray-500 "}>engineering</span>
              </div>

              {!showSidebar ?
              <span className=" tooltiptext bg-gray-800 p-2  text-xs absolute left-20 text-white rounded-md z-30">{"Advance Insights"}</span>
              :
              <span className={(indexType===103)?"font-medium text-xs text-blue-500":"font-medium text-xs text-gray-500"}>{"Advance Insights"}</span>

              }
            </div>


  
            <div className={!showSidebar ?"flex items-center tooltip w-16 cursor-pointer":"flex items-center cursor-pointer"} onClick={()=>this.handleIndexType(surveyId,104, (indexType===104))}>
              <div className="flex items-center px-4 py-4 w-16 text-blue-500 h-12 justify-center ">
                <span className={(indexType===104)?"material-icons  rounded text-blue-500  p-2 ":"material-icons  rounded text-gray-500 "}>manage_search</span>
              </div>

              {!showSidebar ?
              <span className=" tooltiptext bg-gray-800 p-2  text-xs absolute left-20 text-white rounded-md z-30">{"Text Analysis"}</span>
              :
              <span className={(indexType===104)?"font-medium text-xs text-blue-500":"font-medium text-xs text-gray-500"}>{"Text Analysis"}</span>

              }
            </div>
            

  
            <div className={!showSidebar ?"flex items-center tooltip w-16 cursor-pointer":"flex items-center cursor-pointer"} onClick={()=>this.handleIndexType(surveyId,105, (indexType===105))}>
              <div className="flex items-center px-4 py-4 w-16 text-blue-500 h-12 justify-center ">
                <span className={(indexType===105)?"material-icons  rounded text-blue-500  p-2 ":"material-icons  rounded text-gray-500 "}>fitness_center</span>
              </div>

              {!showSidebar ?
              <span className=" tooltiptext bg-gray-800 p-2  text-xs absolute left-20 text-white rounded-md z-30">{"Strength Weakness Analysis"}</span>
              :
              <span className={(indexType===105)?"font-medium text-xs text-blue-500":"font-medium text-xs text-gray-500"}>{"Strength Weakness Analysis"}</span>

              }
            </div>

    
            {/* {CurrentSurveyId !== "60b9df102f68b86828ce1539"?
            <div className={!showSidebar ?"flex items-center tooltip w-16 cursor-pointer":"flex items-center cursor-pointer"} onClick={()=>this.handleIndexType(surveyId,106, (indexType===106))}>
              <div className="flex items-center px-4 py-4 w-16 text-blue-500 h-12 justify-center ">
                <span className={(indexType===106)?"material-icons  rounded text-blue-500  p-2 ":"material-icons  rounded text-gray-500 "}>add_task</span>
              </div>

              {!showSidebar ?
              <span className=" tooltiptext bg-gray-800 p-2  text-xs absolute left-20 text-white rounded-md z-30">{"Manager Action Plan"}</span>
              :
              <span className={(indexType===106)?"font-medium text-xs text-blue-500":"font-medium text-xs text-gray-500"}>{"Manager Action Plan"}</span>

              }
            </div>
            :null} */}


            {/* {accessAllow("uploadEmp") ?
            <div className={!showSidebar ?"flex items-center tooltip w-16 cursor-pointer":"flex items-center cursor-pointer"} onClick={() => routeLink("employees")}>
            <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center ">
                  <span className="material-icons text-gray-500 rounded hover:bg-blue-50 p-2  ">people</span>
                </div>
                {!showSidebar ?
                <span className=" tooltiptext bg-gray-800 p-2 text-xs absolute left-20 text-white rounded-md z-30">{LanguageObj && LanguageObj["my_employees_label" + "_" + CurrentLanguage] ? LanguageObj["my_employees_label" + "_" + CurrentLanguage] : "My Employees"}</span>
                :
                <span className="font-medium text-xs text-gray-500">{LanguageObj && LanguageObj["my_employees_label"+"_"+CurrentLanguage]? LanguageObj["my_employees_label"+"_"+CurrentLanguage]:"My Employees"}</span>
              }
              </div>
              : null} */}

           
            {/* {accessAllow("uploadUser") ?
            <div className={!showSidebar ?"flex items-center tooltip w-16 cursor-pointer":"flex items-center cursor-pointer"} onClick={() => routeLink("users")}>
            <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center ">
                  <span className="material-icons text-gray-500 rounded hover:bg-blue-50 p-2  ">admin_panel_settings</span>
                </div>
                {!showSidebar ?
                <span className=" tooltiptext bg-gray-800 p-2 text-xs absolute left-20 text-white rounded-md z-30">{"My Users"}</span>
                :
                <span className="font-medium text-xs text-gray-500">{"My Users"}</span>
                }
              </div>
              : null} */}



          </div>
        </div>
        {/* sidebar non expend end*/}



        
        {/* <div className="bg-white w-48 h-full ">
          <div className="h-16 flex items-center border-b">
            <span className="font-bold ml-6 text-2xl tracking-widest text-blue-500 ">Plus</span>
          </div>
          <div className="py-4">
            
            <div className="h-12 flex items-center w-full"><span className="font-medium text-xs text-blue-500 ">Home</span></div>
         
            <div className="h-12 flex items-center w-full"><span className="font-medium text-xs text-gray-500 ">People</span></div>
           
            <div className="h-12 flex items-center w-full"><span className="font-medium text-xs text-gray-500 ">Dashboard</span></div>
          </div>
        </div> */}
       
      </div>
      </span>  
      </>
    );
  }
}
function mapStateToProps(state) {
  // //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Sidebar);
