import React, { Component } from 'react';
import { connect } from 'react-redux';
import { surveyActions, dashboardActions } from '../../../../_actions';
import moment from 'moment';
import { CSVLink } from "react-csv";
import ConfirmMail from './Components/ConfirmMail';
import IndexKPI from '../Components/IndexKPI';
import MultiBarChart from './Charts/MultiBarChart';

import FilterDMG from './FilterDMG/Filter';
import ReponseCard from './Components/ResponseCards';
import MultiBarStaticChart from './Charts/MultiBarStaticChart';


class SurveyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {

      FilterValues: [],
      showFilter: false,
      showValue: '',



      selectFilterType: 1,
      surveyReportEmail: '',
      openModalComfirmSend: false,
      SaveEmployeeId: '',
      DemographValue: '',



      filterList: [
        {
          id: true,
          type: 'Show All'
        },
        {
          id: false,
          type: 'Show Filter'
        }
      ],

      isMailList: [
        {
          id: true,
          type: 'Delivered'
        },
        {
          id: false,
          type: 'Not Delivered'
        }
      ],

      isClickedList:
        [
          {
            id: true,
            type: 'Clicked',
          },
          {
            id: false,
            type: 'Not Clicked'
          }
        ],

      isCompleteList: [
        {
          id: true,
          type: 'Completed'
        },
        {
          id: false,
          type: 'Not Completed'
        }
      ],

      filterformData: {
        "showAll": true,
        "isMailSent": true,
        "isClicked": true,
        "isComplete": true
      },

      currFilterName:"Demographic"

    }
  }
  componentDidMount() {
    let { EmpFilterData, getIndexFilterData } = this.props;
    let { selectedFilterName, showViewBy } = this.state;

    let CurrentFilterName = 'Department';
    if (EmpFilterData && EmpFilterData.length > 0) {
      CurrentFilterName = EmpFilterData[0].name
    }

    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": CurrentFilterName
    }
    // this.props.dispatch(dashboardActions.responseAnalysisBySurveyId(data));
    this.props.dispatch(dashboardActions.getResponseAnalytics(data));

    this.props.dispatch(surveyActions.getIndexByFilterDMG({
      filters:[]
    }));

  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleCreateSurveyPopup = () => {

  }

  handleSetFilter = (value) => {

    let { filterformData } = this.state;

    if (value === 'true' || value === true) {
      this.setState({ selectFilterType: 1 });
      filterformData['showAll'] = true;
    }
    else {
      this.setState({ selectFilterType: 2 });
      filterformData['showAll'] = false;
    }

    this.setState({ filterformData });

  }


  handleIsMailSent = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isMailSent'] = true;
    }
    else {
      filterformData['isMailSent'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsClicked = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isClicked'] = true;
    }
    else {
      filterformData['isClicked'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsComplete = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isComplete'] = true;
    }
    else {
      filterformData['isComplete'] = false;

    }
    this.setState({ filterformData });

  }

  handleEmailInput = (val) => {
    this.setState({ surveyReportEmail: val });
  }

  HandleFilterType = (value) => {
    this.setState({ selectFilterType: value });
  }

  RefreshAPI = () => {
    let data = {
      surveyId: this.props.CurrentSurveyId
    }
    this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));
    this.props.dispatch(surveyActions.getCampaignEmployeesByIDSurveyWise(data));

  }


  forceMail = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    // this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }


  handleSendSingleMail = () => {
    let { SaveEmployeeId } = this.state;
    this.props.dispatch(surveyActions.resendCampain({ id: SaveEmployeeId }));
    this.setState({ SaveEmployeeId: '' });
  }

  resendCampain = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    //  this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }

  handleConfirmMailClose = () => {
    this.setState({ openModalComfirmSend: false, SaveEmployeeId: '' });
  }


  handleQuestionFilterName = (Name, Value) => {
    this.setState({ DemographValue: '' });

    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": Name
    }
    this.props.dispatch(dashboardActions.getResponseAnalytics(data));
  }

  handleQuestionFilterValue = (Name, Value) => {
    console.log('Value:', Value)
    if (Value === 'All') {
      this.setState({ DemographValue: '' });
    }
    else {
      this.setState({ DemographValue: Value });
    }
  }

  handleQuestionFilterValueSave = () => {

  }

  handleQuestionFilterNameSave = () => {

  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterBehaviour } = this.props;
    this.setState({ FilterValues: finalFilter, currFilterName: name });
    console.log("finalFilter, true, name====>",finalFilter, true, name)
    // handleMultifilterBehaviour(finalFilter, true, name);





    let FilterValues = finalFilter;

    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues;
      }

    }



    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": XAxis,
      "filters":Filters
    }
    this.props.dispatch(dashboardActions.getResponseAnalytics(data));


  }


  render() {
    let { survey, dashboard, closeResponseAnalytics,


      
      getIndexFilterData,
      currFilterName,
      getRespondantDetailsData,
      styleType

    } = this.props;
    // let {  getResponseAnalyticsData } = survey;
    let { getResponseAnalyticsData } = dashboard;
    let { getIndexByFilterDMGData } = survey;

    let { openModalComfirmSend, DemographValue , FilterValues} = this.state;


    let DemographicResponseNewData = getResponseAnalyticsData;

    function ResponseRate(item) {
      return (item.completed * 100) / item.totalInvite
    }


    if (DemographicResponseNewData && DemographicResponseNewData.length > 0) {
      DemographicResponseNewData.sort((a, b) => ResponseRate(b) - ResponseRate(a));
    }

    let EmpFilterData = [];
    let filterLevels =  this.props.EmpFilterData;
    if(filterLevels && filterLevels.length>0){filterLevels.forEach((item)=>{
      if(item.name!=="ManagerPID"){EmpFilterData.push(item);}
    })}
    

    console.log('EmpFilterData====?', EmpFilterData)



    console.log('==============>getIndexByFilterDMGData==============>',getIndexByFilterDMGData);

    console.log('==============>DemographicResponseNewData==============>',DemographicResponseNewData);


    let DemographicValueList = []
    if(DemographicResponseNewData && DemographicResponseNewData.length>0){
      DemographicResponseNewData.forEach((value, index)=>{
        DemographicValueList.push(value.name);
      });
    }
    
    console.log('==============>DemographicResponseNewData==============>',DemographicResponseNewData);



    let CurrentFilterName = 'Department';
    if (EmpFilterData && EmpFilterData.length > 0) {
      CurrentFilterName = EmpFilterData[0].name
    }



    let DownloadExcelResponse = [];
    if(DemographicResponseNewData && DemographicResponseNewData.length>0){
      DemographicResponseNewData.forEach((item)=>{
        let temp = {}
        temp[currFilterName?currFilterName:'Demographic'] = item.name;
        temp["Responded"] = item.completed;
        temp["Sent"] = item.totalInvite;
        temp["Response Rate"] = ((item.completed*100)/item.totalInvite);

        DownloadExcelResponse.push(temp)
      })
    }



    return (
      <>
<div className=" mx-auto  h-full overflow-hidden overflow-y-auto" style={{}}>
  {/* boxes */}
  <div className="grid  lg:grid-cols-3 md:grid-cols-3 gap-6 my-4 m-4">
 


  <ReponseCard getRespondantDetailsData={getRespondantDetailsData} />



  </div>
  <h1 className="text-xl font-medium px-4 pt-4">Overall Response</h1>
  {/* table */}
  <div className="bg-white border m-4 rounded-md capitalize">
    {/*sub nav bar 2 */}
    <div className="p-4 md:flex  justify-between items-center  border-b py-3  whitespace-nowrap">
      
        <FilterDMG
          showFilter={this.state.showFilter}
          showValue={this.state.showValue}
          EmpFilterData2={EmpFilterData}
          DemographicValueList2={getIndexByFilterDMGData}
          closeFilter={this.closeFilter}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />
      
      {/* <div className="flex ">
        <div className="flex items-center text-sm font-medium mr-6 text-gray-500 relative cursor-pointer"> <span className="material-icons text-gray-800 mr-2">filter_list</span>
          <p className="text-gray-800">Filters</p>
          <p className="text-white bg-blue-500 rounded-full h-5 flex inline-flex items-center w-5 justify-center mx-2">3</p> <span className="material-icons">arrow_drop_down</span>
          
          
          <div className="absolute left-0 top-8 bg-white shadow-lg p-3 font-normal font-xs w-48 hidden">
            <input type name placeholder="Search" className="w-full border p-1.5 rounded-sm focus:outline-none text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" />
            <div className="py-2 m-1 flex items-center">
              <input type="checkbox" name />
              <label className="ml-2">Select all</label>
            </div>
            <div className="py-2 m-1 flex items-center">
              <input type="checkbox" name />
              <label className="ml-2">Country</label>
            </div>
            <div className="py-2 m-1 flex items-center">
              <input type="checkbox" name />
              <label className="ml-2">Department</label>
            </div>
            <div className="py-2 m-1 flex items-center">
              <input type="checkbox" name />
              <label className="ml-2">Gender</label>
            </div>
            <div className="py-2 m-1 flex items-center">
              <input type="checkbox" name />
              <label className="ml-2">Location</label>
            </div>
          </div>
        </div>
        <div className="flex items-center text-sm font-medium mr-6 text-gray-500 cursor-pointer">
          <p className="mr-2">Country</p> <span className="material-icons">arrow_drop_down</span> </div>
        <div className="flex items-center text-sm font-medium mr-6 text-gray-500 cursor-pointer">
          <p className="mr-2">Location</p> <span className="material-icons">arrow_drop_down</span> </div>
        <div className="flex items-center text-sm  font-medium mr-6 text-gray-500 cursor-pointer">
          <p className="mr-2">Gender</p> <span className="material-icons">arrow_drop_down</span> </div>
        <span className="text-sm bg-red-500 px-4 py-2 text-white rounded-md hover:bg-red-600 font-medium ">Clear all</span> 
        </div> */}




        {DownloadExcelResponse && DownloadExcelResponse.length > 0 ?
        <CSVLink data={DownloadExcelResponse} filename={"CampaignResponseAnalytics.csv"}>
          <span className="text-sm text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-md capitalize flex items-center font-medium">
            <span className="material-icons mr-2">file_download</span> Download response</span>
        </CSVLink>
        : '...'}

    </div>
    {/* end */}
    <div className="divide-y">
     
     
    {(DemographicResponseNewData && DemographicResponseNewData.length > 0) ?
      DemographicResponseNewData.map((item) =>
        (DemographValue) ?
          (DemographValue === item.name) ?
            <div className="p-4 ">
              <div className="md:flex justify-between items-center ">
                <h1 className="font-medium md:pb-0 pb-4 text-lg capitalize">{item.name} </h1> <span className="text-sm text-blue-500">{' Response Rate: ' + ResponseRate(item).toFixed(2) + '% '}</span> </div>
                <MultiBarStaticChart item={item} />
            </div>
            : null
            :       
            <div className="p-4 ">
              <div className="md:flex justify-between items-center ">
                <h1 className="font-medium md:pb-0 pb-4 text-lg capitalize">{item.name} </h1> <span className="text-sm text-blue-500">{' Response Rate: ' + ResponseRate(item).toFixed(2) + '% '}</span> </div>
                <MultiBarStaticChart item={item} />
            </div>
         )
      : null}


    </div>
  </div>
  {/* end */}
</div>

      </>
    );
  }
}


function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(SurveyReport);
