import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList:[],
      EmpIndexA:0,
      EmpIndexB:4
    }
  }
  componentDidMount() {

  }

  handleMatrix=(question)=>{
    let { stage } = this.props;
    if(stage==2){
      let { handleMatrixDetails } = this.props;
      handleMatrixDetails(question,stage)
    }
    
  }


  render() {
    let { stage , renderType } = this.props;
            


  
  let { getQuestionMatrixResultData,questionsList } = this.props;

  let MatrixList = [];
  if(getQuestionMatrixResultData && getQuestionMatrixResultData.length>0){
    getQuestionMatrixResultData.forEach((item)=>{
      let temp = {
        "name":item && item.question && item.question[0] && item.question[0].name?item.question[0].name:"",
        "optionsList":[],
        "qtemplate":item && item.total && item.total[0] && item.total[0]._id.qtemplate?item.total[0]._id.qtemplate:"",
        "qparentId":item && item.total && item.total[0] && item.total[0]._id.qparentId?item.total[0]._id.qparentId:"",
        "questionId":item && item.total && item.total[0] && item.total[0]._id.questionId?item.total[0]._id.questionId:"",
      }
      if(item && item.total && item.total.length>0){
        item.total.forEach((otp)=>{
          if(otp && otp._id){
            temp["optionsList"].push({
              "answer":otp && otp._id && otp._id.answer?otp._id.answer:"",
              "answerText":otp && otp._id && otp._id.answerText?otp._id.answerText:"",
              "heading":otp && otp._id && otp._id.heading?otp._id.heading:"",
              "count":otp && otp.count?otp.count:"",

            });
          }
        });
      }
      // item && item.total && item.total._id && item.total[0] && item.total[0]._id
      MatrixList.push(temp);
    });
  }

  function GetOriginalOptionList(mainQuesId){
    let OptionsList = []
    if(questionsList && questionsList.length>0){
        let getIndex = questionsList.findIndex(prev=>prev.id === mainQuesId);
        if(getIndex!==-1){
          OptionsList = questionsList && questionsList[getIndex] && questionsList[getIndex]["optionsList"]?questionsList[getIndex]["optionsList"]:[]
        }
    }

    return OptionsList;
  }

  let qMatrixList = []
  let GroupList = []
  if(MatrixList && MatrixList.length>0){
    MatrixList.forEach((item)=>{


      if(!item.qparentId){
        qMatrixList.push({
          ...item,
          "optionsList":GetOriginalOptionList(item.questionId),
          "SubQuestionList":[]
        })
      }
      else{
        GroupList.push(item)
      }



    });
  }


  // let questionName = "";
  // let question = "";

  if(qMatrixList && qMatrixList.length>0){
    qMatrixList.forEach((item,index1)=>{
      if(GroupList && GroupList.length>0){
        GroupList.forEach((subQues,index2)=>{
          if(subQues.qparentId===item.questionId){
            qMatrixList[index1]["SubQuestionList"].push(subQues)
          }
        });
      }
    });


    // if(renderType==="Allmatrix"){
    //   questionName = qMatrixList[0].name;
    //   question = qMatrixList[0];
    // }
    // else{

    // }



  }

  let ALLMatrixQuestionList = [];

  if(stage==2){
    ALLMatrixQuestionList = qMatrixList;
  }
  else{
    ALLMatrixQuestionList = qMatrixList && qMatrixList.length>0?[ qMatrixList[0] ]:[];
  }

  console.log('==========================================================>ALLMatrixQuestionList: ',ALLMatrixQuestionList);


    function FormatMatriXOptionHeading(question, isOption){
        let paraObjShow = {};
        if(question && question.optionsList && question.optionsList.length>0){
          question.optionsList.forEach((item)=>{
            if(paraObjShow && paraObjShow[item.heading]){
              paraObjShow[item.heading] += 1
            }
            else{
              paraObjShow[item.heading] = 1
            }
          });
        }
    
        let headingList = []
        let tempKey = Object.keys(paraObjShow);
        if(tempKey && tempKey.length>0){
          tempKey.forEach((item)=>{
          headingList.push({
            name:item,
            span:paraObjShow[item]
          })
        });
        }

        if(isOption){
          let OptionList = []
          if(headingList && headingList.length>0){
            headingList.forEach((heading)=>{
              if(question && question.optionsList && question.optionsList.length>0){
                question.optionsList.forEach((option)=>{
                  if(heading.name === option.heading){
                    OptionList.push(option)
                  }
                });
              }
            });
          }
          return OptionList
        }
        else{
          return headingList
        }

      }





    function getCountPerc(SubQues,option,heading){
      let total = 0;
      let count = 0;
      if(SubQues && SubQues.optionsList && SubQues.optionsList.length>0){
        SubQues.optionsList.forEach((item)=>{
          if(item.heading === heading){
            total += parseInt(item.count);
            if(item.answerText===option){
              count += parseInt(item.count);
            }
          }
        });
      }
      return (parseFloat((count*100)/total)).toString()+"%";
    }



    return (
      <>
    
      {ALLMatrixQuestionList && ALLMatrixQuestionList.length>0?
      ALLMatrixQuestionList.map((question,index)=>
      <>
      <div className={stage==2?" bg-white p-4" :" bg-white p-4 border"} onClick={()=>this.handleMatrix(question)}>



        {/* <div className="flex justify-between">
        {stage!==3?
          <div><h1 className="text-xl font-medium">Matrix</h1>
            <p className="text-sm  py-1">{question && question.name?"1. "+question.name:""}</p>
          </div>
        :null}
          
          <div className="whitespace-nowrap ml-4"><span className="cursor-pointer text-blue-500 text-center hover:text-blue-700">{stage==2?"View Details":(stage==3?"":"View More")}</span></div>
        </div> */}


        {/* <div className="flex justify-between pb-2">
                            <h1 className="text-lg font-medium">Matrix</h1>
                            <div className="whitespace-nowrap ml-4 cursor-pointer"><span className="text-white text-center text-xs px-4 py-2 rounded-md  bg-blue-500 hover:bg-blue-600 ">{stage==2?"View Details":(stage==3?"":"View More")}</span></div>
                        </div> 


                        <p className="text-sm text-gray-800 py-1 truncate">
                                {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                }'
                        {question && question.name?"1. "+question.name:""}</p> */}


<div className="flex justify-between pb-2">
                            <h1 className="text-lg font-medium truncate">
                              {/* {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                } */}
                        {question && question.name?question.name:""}</h1>
                            <div className="whitespace-nowrap ml-4 cursor-pointer">
                              <span className="text-white text-center text-xs px-4 py-2 rounded-md  bg-blue-500 hover:bg-blue-600 ">
                                {stage==2?"View Details":(stage==3?"":"View More")}</span></div>
                        </div> 


        {/* table */}
        {/* component */}
        <div className=" w-full mt-4" id="chatbot" style={{height:'300px', overflowY:'auto'}}>
          <table className="w-full border mb-2">
            <thead className="z-10">

              <tr className="border-b">


              {FormatMatriXOptionHeading(question, false) && FormatMatriXOptionHeading(question, false).length>0?
                FormatMatriXOptionHeading(question, false).map((heading, optionindex) =>
                    <th className={optionindex!==1?" border-r cursor-pointer text-xs  text-white bg-blue-400":"border-r cursor-pointer text-xs  text-white bg-green-400 "}>
                      <p className="w-full border-b font-medium p-2 text-sm">{heading.name.split("-")[0]}</p>


                        <div className="flex font-normal ">
                        {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length>0?
                          FormatMatriXOptionHeading(question, true).map((option, optionindex) =>
                          option.heading === heading.name?
                              <p className="w-full border-r p-2">{option.name}</p>
                              :null
                          ):null}
                        </div>
        

                    </th>
                ):null}



              </tr>


            </thead>
            <tbody>
            {question && question.SubQuestionList && question.SubQuestionList.length>0?
              question.SubQuestionList.map((SubQues,index)=>
              <>
                <tr className="bg-white  border-b text-xs text-gray-600">
                  <td className="border-r p-1 text-center bg-gray-50" colSpan={2}> {SubQues.name} </td>
                </tr>
                <tr className="bg-white  border-b text-xs text-gray-600">
                  
                {FormatMatriXOptionHeading(question, false) && FormatMatriXOptionHeading(question, false).length>0?
                FormatMatriXOptionHeading(question, false).map((heading, optionindex) =>
                  <td className="border-r ">
                    <div className="flex">
                      {FormatMatriXOptionHeading(question, true) && FormatMatriXOptionHeading(question, true).length>0?
                        FormatMatriXOptionHeading(question, true).map((option, optionindex)=>
                        option.heading === heading.name?
                        <div className="w-full flex justify-center font-semibold">{getCountPerc(SubQues,option.name,heading.name)}</div>
                      :null):null}
                    </div>
                  </td>
                ):null}

                </tr>
              </>
            ):null}

            </tbody>
          </table>
        </div>
        {/* table end */}
      </div>
      </>
      ):null}










      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
