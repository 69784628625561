// import 'font-awesome/css/font-awesome.min.css';

import React, { Component } from 'react';

import { connect } from 'react-redux';
import { surveyActions } from '../../../../../_actions';



import { questionsActions } from '../../../../../_actions';


import QuestionV1 from './QuestionV1/questionsV1';
import QuestionV1Arabic from './QuestionV1/questionsV1Arabic';

import QuestionV2 from './QuestionV2/questionsV2';
import QuestionV2Arabic from './QuestionV2/questionsV2Arabic';

import QuestionV3 from './QuestionV3/questionsV3';
import QuestionV3Arabic from './QuestionV3/questionsV3Arabic';



class QuestionPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioButton: false,
      radioButton2: false,
      currQues: null,
      showWelcomeScreen: true,
      showQuestionScreen: false,
      showThankyouScreen: false,
      instructionPage: false,
      showRedFlag: false,
      saveMsg: "",
      CurrQuesIndex: 1,
      submitSucces:false,

      surveyLanguageName:'English',
      surveyLanguageCode:'',
      ShowSubmitButton:false,


      textAnswer: "",
      unattemptList: [],
      changeType: "fade",
      mandatoryOn:false,
      afterSubmitData:{
        "thankyouText":'',
        "bgColor":'',
        "bgImage":"",
        "textColor":"",
        "logo":""
      },
      alreadySubmitted:false,
      clicked:false,

      showDropdownQues:false,
      dropdownInput:"",
      surveyLanguageRTL:false
    }
  }

  componentDidMount() {


    // this.props.dispatch(questionsActions.getSurveyTemplateByCode({ code: this.props.match.params.id }));

    let data = {
      surveyId:this.props.match.params.id
    }
    this.props.dispatch(surveyActions.getSurveyById(data));


  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.questions.submitfail) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: false,
        instructionPage: false,

        alreadySubmitted:true
      }
    }
    if (nextProps.questions.answerSubmitSuccess) {
      return {
        ...nextProps,
        showWelcomeScreen: false,
        showQuestionScreen: false,
        showThankyouScreen: true,
        instructionPage: false

      }
    } else {
      return {
        ...nextProps
      }
    }

  }


  prevQues = () => {
    this.setState({ changeType: "fade1" })
    this.setState({ShowSubmitButton:false});

    let { questions } = this.props;
    let { questionsList } = questions;


    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length) {
      if (CurrQuesIndex > 1 && CurrQuesIndex < questionsList.length + 1) {
        CurrQuesIndex = CurrQuesIndex - 1;

        this.setState({ CurrQuesIndex },()=>{
          // this.handleSubmitAppear();
        });
      }
    }

    // console.log("answersList: ", answersList)

  }

  nextQues = () => {
    this.setState({ changeType: "fade", clicked:'' })

    let { questions } = this.props;
    let { questionsList, answersList } = questions;

    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length>0) {
      


      if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
        CurrQuesIndex = CurrQuesIndex + 1;

        this.setState({ CurrQuesIndex },()=>{
          this.handleSubmitAppear();
        });

      }
    }

    let flag = 0
    let unattemptList = [];
    answersList.forEach((item, index) => {
      if (item.isMandatory) {


          if (!item.answer) {
            flag = flag + 1;
            unattemptList.push(index + 1)
          }
      

      }
    })

    if(flag){
      this.setState({ unattemptList: unattemptList });

      if(this.state.mandatoryOn &&  questionsList && questionsList.length>0){


        if(this.state.unattemptList && this.state.unattemptList.length>0){
          this.setState({ CurrQuesIndex: unattemptList[0] },()=>{
            this.handleSubmitAppear();
          });

        }
  
      }

    }
    else{
      this.setState({ unattemptList: [] });

      if(this.state.mandatoryOn &&  questionsList && questionsList.length>0){
          this.setState({ CurrQuesIndex: questionsList.length },()=>{
            this.handleSubmitAppear();
          });
      }
    }


    

  }


  handleSubmitAppear =()=>{
    let { CurrQuesIndex } = this.state;
    let { questions } = this.props;
    let { questionsList } = questions;

    setTimeout(()=>{
      if(questionsList && questionsList.length > 0 && (questionsList.length === CurrQuesIndex)){
        this.setState({ShowSubmitButton:true});
      }
      else{
        this.setState({ShowSubmitButton:false});
      }
    },300)


  }

  handleRadio = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;


    if (this.state.radioButton === currentOption.id) {

      this.setState({ radioButton: null });
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, "", 0, surveyScreenData));
    }
    else {

      this.setState({ radioButton: currentOption.id })
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption, currentOption.weightage, surveyScreenData));
    }

    setTimeout(()=>this.nextQues(), 300);

  }


  
  handleCheckInput = (Index, ques, currentOption) => {
    this.setState({ clicked: currentOption.id });

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    if(true) {

      this.setState({ radioButton: currentOption.id })
      // console.log("currentOption::", currentOption)
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList, questionsList, currentOption, 
        currentOption.weightage, surveyScreenData));
    }

    // setTimeout(()=>this.nextQues(), 300);

  }

  goToInstructionPage = () => {
    let { questions } = this.props;
    let { surveyScreenData } = questions;
    
    if(surveyScreenData.isInstruction){
      this.setState({ showWelcomeScreen: false, showQuestionScreen: false, showThankyouScreen: false, instructionPage: true });
    }
    else{
      this.setState({ showWelcomeScreen: false, showQuestionScreen: true, showThankyouScreen: false, instructionPage: false });
    }
    window.scrollTo(0, 0)

  }

  goToQuestionPage = () => {
    this.setState({ showWelcomeScreen: false, showQuestionScreen: true, showThankyouScreen: false, instructionPage: false });
    window.scrollTo(0, 0)

  }


  saveAnswers(surveyScreenData) {

    if(surveyScreenData){
      this.setState({
        afterSubmitData:surveyScreenData
      });
    }


    let survey = {
      code: this.props.match.params.id,
    }
    let { questions } = this.props;
    let { answersList } = questions;


    let flag = 0;
    let unattemptList = [];
    answersList.forEach((item, index) => {
      if (item.isMandatory) {


          if (!item.answer) {
            flag = flag + 1;
            unattemptList.push(index + 1)
          }
      

      }
    })

    if (flag) {
      this.setState({ saveMsg: "Please attempt all mandatory questions!" })
      // console.log("Please Attempt Mandatory Questions!");
      this.setState({ showRedFlag: true });
      this.setState({ unattemptList: unattemptList });

    }
    else {
      // console.log("Saving...");
      this.setState({ saveMsg: "" })
      this.setState({ unattemptList: [], submitSucces:true });


      // this.props.dispatch(questionsActions.saveAnswers(answersList, survey, this.props));
    }

    // console.log("Final Answer: ", answersList)

  }

  ansTextBoxClick(ques) {
    this.setState({ currQues: ques });
  }

  ansTextBoxChange1 = (e,Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans:1
    }

    this.setState({ textAnswer: e.target.value })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));
  }

  ansNPSInput = (val,Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: val,
      ans:1
    }

    this.setState({ textAnswer: val })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));

    

    setTimeout(()=>this.clickOK(), 300);

  }

  ansTextBoxChange2 = (e,Ques) => {
    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: e.target.value,
      ans:2
    }

    this.setState({ textAnswer: e.target.value })

    // console.log("ansData; ", ansData)

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));
  }

  clickOK = ()=>{
    // setTimeout(()=>this.nextQues(), 300);
    this.nextQues()
  }

  clickOKLast=(Ques)=>{

    // console.log("clickOKLast; ")

    let { questions } = this.props;
    let { questionsList, answersList, surveyScreenData } = questions;

    let ansData = {
      _id: "",
      name: "",
      ans:3
    }

    this.props.dispatch(questionsActions.handleSubmitPreChange(Ques, answersList, questionsList, ansData, null, surveyScreenData));



    let { CurrQuesIndex } = this.state;
    if (questionsList && questionsList.length>0) {
      


      if (CurrQuesIndex > 0 && CurrQuesIndex < questionsList.length) {
        CurrQuesIndex = CurrQuesIndex + 1;

        this.setState({ CurrQuesIndex });

      }
    }

    let flag = 0
    let unattemptList = [];
    answersList.forEach((item, index) => {
      if (item.isMandatory) {

          if (!item.answer) {
            flag = flag + 1;
            unattemptList.push(index + 1)
          }
      

      }
    });

    if(flag){
      this.setState({ unattemptList: unattemptList });

      if(this.state.mandatoryOn &&  questionsList && questionsList.length>0){


        if(this.state.unattemptList && this.state.unattemptList.length>0){
          // console.log("-----working x")
          this.setState({ CurrQuesIndex: unattemptList[0] });
          // console.log("-----working x 1")

        }
  
      }

    }
    else{
      this.setState({ unattemptList: [] });

      if(this.state.mandatoryOn &&  questionsList && questionsList.length>0){
          // console.log("All mandatory questions are attempted , now go to last question i.e. submit! ")
          this.setState({ CurrQuesIndex: questionsList.length });
      }
    }


    //nothing




  }


  onMandatoryAttempt=(elem)=>{
    this.setState({ CurrQuesIndex:elem });
    this.setState({ mandatoryOn:true });

  }

  gotoSubmit = ()=>{
    let { questions } = this.props;
    let { questionsList } = questions;

    if(questionsList && questionsList.length>0){
      this.setState({CurrQuesIndex: questionsList.length})
    }
  }



  




  
  openDropdownQuestion=(check)=>{
    this.setState({showDropdownQues:check})
  }

  handleDropdownSelect=(value)=>{
    this.setState({dropdownInput:value,showDropdownQues:false});
  }







  handleLanguage=(item, langList )=>{

    if(item.shortName === 'en'){
      this.setState({surveyLanguageCode:item.shortName, surveyLanguageName: 'English', surveyLanguageRTL:false});
    }
    else{
      this.setState({surveyLanguageCode:item.shortName, surveyLanguageName: item.name, surveyLanguageRTL:item.isRTL});
    }
  }



  render() {
    let { questions, survey } = this.props;

    let { getSurveyData } = survey;

    let { questionsList, answersList, surveyScreenData, surveyStatus } = questions;

    let { surveyLanguageRTL, surveyLanguageName, surveyLanguageCode, CurrQuesIndex, afterSubmitData, clicked,showDropdownQues  } = this.state;

    console.log("getSurveyData:---------------------------------> ", getSurveyData)
    // console.log("questionsList:---------------------------------> ", questionsList)
    // console.log("surveyScreenData:---------------------------------> ", surveyScreenData)
    // console.log("surveyStatus:---------------------------------> ", surveyStatus)




    let bgImage = '';
    let bgColor = '';
    if (surveyScreenData && surveyScreenData.bgImage) {
      bgImage = "url(" + surveyScreenData.bgImage + ")";
    }

    if (surveyScreenData && surveyScreenData.bgColor) {
      bgColor = surveyScreenData.bgColor;

    }

    // console.log("CurrQuesIndex: ", CurrQuesIndex);

    // console.log("showQuestionScreen: ", this.state.showQuestionScreen);
    // console.log("showWelcomeScreen: ", this.state.showWelcomeScreen);
    // console.log("showThankyouScreen: ", this.state.showThankyouScreen);

    let welcomeTextDislay = []
    let instructionTextDislay = []


    if (surveyScreenData && surveyScreenData.welcomeText) {
      welcomeTextDislay = surveyScreenData.welcomeText;
      instructionTextDislay = surveyScreenData.instructionText
    }

    //console.log("welcomeTextDislay: ", welcomeTextDislay);

    let surveytitle = '';
    let bgType = '';

    if (surveyScreenData && surveyScreenData.name) {
      surveytitle = surveyScreenData.name;
      bgType = surveyScreenData.bgType;
    }
    // console.log("this.state.unattemptList : ", this.state.unattemptList );



    let LabelObj = questions.getLanguageLabelData;
    //console.log("surveyScreenData: ", surveyScreenData);

    //console.log("afterSubmitData: ", afterSubmitData);


    //console.log("surveyStatus: ", surveyStatus);

    let LanguageList = questions.langList;
    //console.log("LanguageList: ", LanguageList);

    console.log("getSurveyData: ========> ", getSurveyData);


    let designTemplate = 'v2';
    let langList = [{
      "name":"English",
      "shortName":"en"
    }];
    if(getSurveyData){
      designTemplate = getSurveyData.designTemplate;
      if(getSurveyData.langList && getSurveyData.langList.length>0){
        getSurveyData.langList.forEach((item)=>{
          langList.push(item);
        });
      }
    }


    console.log('langList:',langList)

    let codeValue =  this.props.match.params.id ;


    function LanguageComponent (props){
      let { langList, handleLanguage, } = props;

      let [showDrop, openLangModal ] = React.useState(false);

      return(<>
      <div className="flex justify-between">

        <div/>

        {/* <div className="m-4">
            {langList && langList.length>0?
            <div class="relative inline-flex mb-4">
              <svg class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fill-rule="nonzero"/></svg>
              <select onChange={(e)=>handleLanguage(e,langList)} class="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none">
                <option value={"en"}>{"English"}</option>
                {langList && langList.length>0?
                  langList.map((item,index)=>
                    <option keys={index} value={item.shortName}>{item.name}</option>
                  )
                :null}
              </select>
            </div>
            :null}
        </div> */}
      {/* <div style={{
        backgroundColor:(getSurveyData.bgType===1)? (getSurveyData.bgColor) ? getSurveyData.bgColor :  "#fff"    :"#fff",
        backgroundImage:(getSurveyData.bgType===2)? (getSurveyData.bgImage) ? getSurveyData.bgImage :  null   :null,
        minHeight: '800px',
        // backgroundRepeat: 'cover',
        backgroundSize: 'cover',
        overflowX: 'hidden',
        overflowY: 'hidden'
      }}></div> */}
        
        <div 
        
        
        
        
        
        className="lg:flex  border-r pr-3 m-4 items-center "><h1 onClick={() => openLangModal(!showDrop)} 
        className="text-sm cursor-pointer">{surveyLanguageName}</h1>
        <span onClick={() => openLangModal(!showDrop)} 
        className="cursor-pointer material-icons">arrow_drop_down</span>

        {showDrop ?
          <div className="absolute top-10 z-10 right-10 bg-white shadow-xl w-auto  py-1.5  lang" style={{ maxHeight: "150px", overflowY: 'auto' }}>
            {langList && langList.length > 0 ?
              langList.map((item) =>
              (surveyLanguageName !==item.name)?
                <span onClick={() => handleLanguage(item)} className="cursor-pointer block text-xs w-full hover:bg-gray-100 py-1.5 px-4">{item.name}</span>
              :null
              ): null}
          </div>
          : null}

        </div>


      </div>
      </>);
    }


    switch(designTemplate){

      case 'v1':return (
        <>
        <div 
        style={{
          backgroundColor:(getSurveyData && getSurveyData.bgType===1)? (getSurveyData.bgColor) ? getSurveyData.bgColor :  "#fff"    :"#fff",
          backgroundImage:(getSurveyData && getSurveyData.bgType===2)? (getSurveyData.bgImage) ? getSurveyData.bgImage :  null   :null,
        }}
        >
          <div className="justify-end item-end">
            <div onClick={()=>this.props.history.goBack()} className="cursor-pointer text-gray-500 absolute right-4 top-4 material-icons">close</div>
          </div>
          <div className="mr-10">
          <LanguageComponent langList={langList} handleLanguage={this.handleLanguage}/>
          </div>
          {surveyLanguageRTL?
          <QuestionV1Arabic codeValue={codeValue} surveyLanguageCode={surveyLanguageCode}/>
          :
          <QuestionV1 codeValue={codeValue} surveyLanguageCode={surveyLanguageCode}/>
          }
          </div>
        </>
        );
      case 'v2':return (
        <>
        <div 
        style={{
          backgroundColor:(getSurveyData && getSurveyData.bgType===1)? (getSurveyData.bgColor) ? getSurveyData.bgColor :  "#fff"    :"#fff",
          backgroundImage:(getSurveyData && getSurveyData.bgType===2)? (getSurveyData.bgImage) ? getSurveyData.bgImage :  null   :null,
        }}
        >
        <div className="justify-end item-end">
          <div onClick={()=>this.props.history.goBack()} className="cursor-pointer text-gray-500 absolute right-4 top-4 material-icons">close</div>
        </div>
        <div className="mr-10">
          <LanguageComponent langList={langList} handleLanguage={this.handleLanguage}/>
        </div>
    
        {surveyLanguageRTL?
          <QuestionV2Arabic  codeValue={codeValue} surveyLanguageCode={surveyLanguageCode}/>
          :
          <QuestionV2  codeValue={codeValue} surveyLanguageCode={surveyLanguageCode}/>
          }

          </div>
        </>
        );
      case 'v3':return (
        <>
                <div 
        style={{
          backgroundColor:(getSurveyData && getSurveyData.bgType===1)? (getSurveyData.bgColor) ? getSurveyData.bgColor :  "#fff"    :"#fff",
          backgroundImage:(getSurveyData && getSurveyData.bgType===2)? (getSurveyData.bgImage) ? getSurveyData.bgImage :  null   :null,
        }}
        >
        <div className="justify-end item-end">
          <div onClick={()=>this.props.history.goBack()} className="cursor-pointer text-gray-500 absolute right-4 top-4 material-icons">close</div>
        </div>
        <div className="mr-10">
          <LanguageComponent langList={langList} handleLanguage={this.handleLanguage}/>
        </div>

        {surveyLanguageRTL?
          <QuestionV3Arabic  codeValue={codeValue} surveyLanguageCode={surveyLanguageCode}/>
            :
          <QuestionV3  codeValue={codeValue} surveyLanguageCode={surveyLanguageCode}/>
          }
    </div>
    
        </>
        );
      
      default :return (
      <>
        <div 
        style={{
          backgroundColor:(getSurveyData && getSurveyData.bgType===1)? (getSurveyData.bgColor) ? getSurveyData.bgColor :  "#fff"    :"#fff",
          backgroundImage:(getSurveyData && getSurveyData.bgType===2)? (getSurveyData.bgImage) ? getSurveyData.bgImage :  null   :null,
        }}
        >
        <div className="justify-end item-end">
          <div onClick={()=>this.props.history.goBack()} className="cursor-pointer text-gray-500 absolute right-4 top-4 material-icons">close</div>
        </div>
        <div className="mr-10">
          <LanguageComponent langList={langList} handleLanguage={this.handleLanguage}/>
        </div>
        <QuestionV2  codeValue={codeValue} surveyLanguageCode={surveyLanguageCode}/>
        </div>
      </>
      );




    }

  }
}
QuestionPublish.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users, survey } = state;
  return {
    loggingIn,
    questions,
    users,
    survey
  };
}

export default connect(mapStateToProps)(QuestionPublish);
