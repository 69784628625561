import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../../../../_helpers';

import Header from '../../../Header/Header';
import { dashboardActions, surveyActions } from '../../../../../_actions';
import moment from 'moment';
import { CSVLink } from "react-csv";
import ConfirmMail from './Components/ConfirmMail';
import Sidebar from '../Sidebar/Sidebar';
import SurveyReport2 from '../../../../Dashboard/Overview/SurveyReport/SurveyReport';

class SurveyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {

      selectFilterType: 1,
      surveyReportEmail: '',
      openModalComfirmSend: false,
      SaveEmployeeId: '',

      responseType:1,

      filterList: [
        {
          id: true,
          type: 'Show All'
        },
        {
          id: false,
          type: 'Show Filter'
        }
      ],

      isMailList: [
        {
          id: true,
          type: 'Delivered'
        },
        {
          id: false,
          type: 'Not Delivered'
        }
      ],

      isClickedList:
        [
          {
            id: true,
            type: 'Clicked',
          },
          {
            id: false,
            type: 'Not Clicked'
          }
        ],

      isCompleteList: [
        {
          id: true,
          type: 'Completed'
        },
        {
          id: false,
          type: 'Not Completed'
        }
      ],

      filterformData: {
        "showAll": true,
        "isMailSent": true,
        "isClicked": true,
        "isComplete": true
      },

      showSidebar: false,
      CrrRoute: "survey",
      crrSurvey: "",

      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',
      showLanguageModal: false,

      campaignEmpPageNo:1,
      campaignEmpSize:5

    }
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getClientProfile());

    let data = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));
    // this.props.dispatch(surveyActions.getCampaignEmployeesByIDSurveyWise(data));

    this.props.dispatch(surveyActions.getAllResponseAnalyticsBySurveyId(data));



    this.props.dispatch(dashboardActions.getRespondantDetails(data));


    let dataX1 = {
      surveyId: this.props.match.params.id,
      pageNo:this.state.campaignEmpPageNo,
      size:this.state.campaignEmpSize
    }
    this.props.dispatch(surveyActions.getResponseAnalyticsPagination(dataX1));


    // this.props.dispatch(dashboardActions.getEmpFilter("getMeanScoreQwise",data.surveyId));
    // this.props.dispatch(dashboardActions.getIndexFilter(data));

  }


  handleCreateSurveyPopup = () => {

  }


  handleSetFilter = (value) => {

    let { filterformData } = this.state;

    if (value === 'true' || value === true) {
      this.setState({ selectFilterType: 1 });
      filterformData['showAll'] = true;
    }
    else {
      this.setState({ selectFilterType: 2 });
      filterformData['showAll'] = false;
    }

    this.setState({ filterformData });

  }


  handleIsMailSent = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isMailSent'] = true;
    }
    else {
      filterformData['isMailSent'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsClicked = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isClicked'] = true;
    }
    else {
      filterformData['isClicked'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsComplete = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isComplete'] = true;
    }
    else {
      filterformData['isComplete'] = false;

    }
    this.setState({ filterformData });

  }


  handleEmailInput = (val) => {
    this.setState({ surveyReportEmail: val });
  }


  HandleFilterType = (value) => {
    this.setState({ selectFilterType: value });
  }

  RefreshAPI = () => {
    let data = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));
    this.props.dispatch(surveyActions.getCampaignEmployeesByIDSurveyWise(data));

  }

  forceMail = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
  }


  handleSendSingleMail = () => {
    let { SaveEmployeeId } = this.state;
    this.props.dispatch(surveyActions.resendCampain({ id: SaveEmployeeId }));
    this.setState({ SaveEmployeeId: '', openModalComfirmSend:false });
  }

  resendCampain = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    //  this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }

  handleConfirmMailClose = () => {
    this.setState({ openModalComfirmSend: false, SaveEmployeeId: '' });
  }

  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }


  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }
  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }
  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }

  onEmployeeResponse = (employeeList) => {

  }

  handleResponseType = (value) => {
    this.setState({ responseType:value});



    let data = {
      surveyId: this.props.match.params.id
    }

    if(value===1){
      this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));
      this.props.dispatch(surveyActions.getCampaignEmployeesByIDSurveyWise(data));
    }
    else if(value===2){
      this.props.dispatch(dashboardActions.getRespondantDetails(data));
      this.props.dispatch(dashboardActions.getEmpFilter("getMeanScoreQwise",data.surveyId));
      // this.props.dispatch(dashboardActions.getIndexFilter(data));
    }
  

  }


  handlePaginaton=(type)=>{
    let { campaignEmpPageNo } = this.state;
    let PageNo = 1;
    if(type==="prev"){
      if(campaignEmpPageNo>1){
        PageNo = campaignEmpPageNo-1;
      }
    }
    else if(type==="next"){
      PageNo = campaignEmpPageNo+1;
    }

    this.setState({campaignEmpPageNo:PageNo},()=>{
      let dataX1 = {
        surveyId: this.props.match.params.id,
        pageNo:this.state.campaignEmpPageNo,
        size:this.state.campaignEmpSize
      }
      this.props.dispatch(surveyActions.getResponseAnalyticsPagination(dataX1));
    });






  }

  render() {
    let { survey } = this.props;
    let {  EmpFilterData, getIndexFilterData, getRespondantDetailsData } = this.props.dashboard;
    let { getAllResponseAnalyticsBySurveyIdData, getResponseAnalyticsPaginationData, getLanguageLabelData, getLanguageData, responseAnalysisBySurveyIdData } = survey;
    let { responseType, showCampaignOptions, showLanguageModal, CurrentLanguageName, CurrentLanguage, crrSurvey, CrrRoute, showSidebar, openModalComfirmSend, filterformData, selectFilterType, surveyReportEmail } = this.state;

    // console.log("<--------------------- getAllResponseAnalyticsBySurveyIdData--------------------->  ",getAllResponseAnalyticsBySurveyIdData );

    let getCampaignEmployeesByIDSurveyWiseData = getResponseAnalyticsPaginationData;

    // console.log("filterformData  ", filterformData )

    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    // console.log('moduleAccess: ',moduleAccess)


    let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------


    // clicked: 2684
    // completed: 2629
    // mailSent: 2970
    // total: 2970

    // console.log('surveyReportEmail: ',surveyReportEmail)

    let delivered = 0;
    let notdelivered = 0;

    let clicked = 0;
    let notclicked = 0;

    let completed = 0;
    let notcompleted = 0;

    let total = 0;

    let completedEmpList = [];
    let notCompletedEmpList = [];


    if(getRespondantDetailsData && getRespondantDetailsData[0]){
      let RespondantDetailsData = getRespondantDetailsData[0];

      total = RespondantDetailsData && RespondantDetailsData.total?RespondantDetailsData.total:0;
      
      delivered = RespondantDetailsData && RespondantDetailsData.mailSent?RespondantDetailsData.mailSent:0;
      notdelivered = (total - delivered)>=0?total - delivered:0;
      
      clicked = RespondantDetailsData && RespondantDetailsData.clicked?RespondantDetailsData.clicked:0;
      notclicked = (total - clicked)>=0?total - clicked:0;

      completed = RespondantDetailsData && RespondantDetailsData.completed?RespondantDetailsData.completed:0;
      notcompleted = (total - completed)>=0?total - completed:0;

    }


    if (getCampaignEmployeesByIDSurveyWiseData && getCampaignEmployeesByIDSurveyWiseData.length > 0) {
      getCampaignEmployeesByIDSurveyWiseData.forEach((item) => {

        // total = total + 1;

        // delivered
        if (item.isMailSent === true) {
          // delivered = delivered + 1;
        }
        else {
          // notdelivered = notdelivered + 1;
        }
        // clicked
        if (item.isClicked === true) {
          // clicked = clicked + 1;
        }
        else {
          // notclicked = notclicked + 1;
        }
        // completed
        if (item.isComplete === true) {
          // completed = completed + 1;
          completedEmpList.push(item);
        }
        else {
          // notcompleted = notcompleted + 1;
          notCompletedEmpList.push(item);
        }


      })
    }


    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }

    let ReportDataList = [
      {
        "name": LanguageObj && LanguageObj["total" + "_" + CurrentLanguage] ? LanguageObj["total" + "_" + CurrentLanguage] : "Total",
        "value": total,
        "color": '#99cc00'
      },
      {
        "name": LanguageObj && LanguageObj["delivered" + "_" + CurrentLanguage] ? LanguageObj["delivered" + "_" + CurrentLanguage] : "Delivered",
        "value": delivered,
        "color": '#00cc66'
      },
      {
        "name": LanguageObj && LanguageObj["not_delivered" + "_" + CurrentLanguage] ? LanguageObj["not_delivered" + "_" + CurrentLanguage] : "Not Delivered",
        "value": notdelivered,
        "color": '#ff4d4d'
      },
      {
        "name": LanguageObj && LanguageObj["clicked" + "_" + CurrentLanguage] ? LanguageObj["clicked" + "_" + CurrentLanguage] : "Clicked",
        "value": clicked,
        "color": '#bf00ff'
      },
      {
        "name": LanguageObj && LanguageObj["not_clicked" + "_" + CurrentLanguage] ? LanguageObj["not_clicked" + "_" + CurrentLanguage] : "Not Clicked",
        "value": notclicked,
        "color": '#3399ff'
      },
      {
        "name": LanguageObj && LanguageObj["completed" + "_" + CurrentLanguage] ? LanguageObj["completed" + "_" + CurrentLanguage] : "Completed",
        "value": completed,
        "color": '#008080'
      },
      {
        "name": LanguageObj && LanguageObj["not_completed" + "_" + CurrentLanguage] ? LanguageObj["not_completed" + "_" + CurrentLanguage] : "Not Completed",
        "value": notcompleted,
        "color": '#ff9933'
      },


    ]


    let filterList = [
      {
        id: true,
        type: LanguageObj && LanguageObj["show_all" + "_" + CurrentLanguage] ? LanguageObj["show_all" + "_" + CurrentLanguage] : 'Show All'
      },
      {
        id: false,
        type: LanguageObj && LanguageObj["show_filter" + "_" + CurrentLanguage] ? LanguageObj["show_filter" + "_" + CurrentLanguage] : 'Show Filter'
      }
    ]

    let isMailList = [
      {
        id: true,
        type: LanguageObj && LanguageObj["delivered" + "_" + CurrentLanguage] ? LanguageObj["delivered" + "_" + CurrentLanguage] : "Delivered"
      },
      {
        id: false,
        type: LanguageObj && LanguageObj["not_delivered" + "_" + CurrentLanguage] ? LanguageObj["not_delivered" + "_" + CurrentLanguage] : "Not Delivered"
      }
    ]

    let isClickedList = [
      {
        id: true,
        type: LanguageObj && LanguageObj["clicked" + "_" + CurrentLanguage] ? LanguageObj["clicked" + "_" + CurrentLanguage] : "Clicked",
      },
      {
        id: false,
        type: LanguageObj && LanguageObj["not_clicked" + "_" + CurrentLanguage] ? LanguageObj["not_clicked" + "_" + CurrentLanguage] : "Not Clicked"
      }
    ]

    let isCompleteList = [
      {
        id: true,
        type: LanguageObj && LanguageObj["completed" + "_" + CurrentLanguage] ? LanguageObj["completed" + "_" + CurrentLanguage] : "Completed"
      },
      {
        id: false,
        type: LanguageObj && LanguageObj["not_completed" + "_" + CurrentLanguage] ? LanguageObj["not_completed" + "_" + CurrentLanguage] : "Not Completed"
      }
    ]


    // console.log('getCampaignEmployeesByIDSurveyWiseData: ',getCampaignEmployeesByIDSurveyWiseData)


    // console.log('getCampaignEmployeesByIDSurveyWiseData:L ',getCampaignEmployeesByIDSurveyWiseData);
    let NeglectedList = ["isTest", "isDisable", "actionPlanStage", "id", "createdAt", "companyId", "currentStage"]

    let EmpFieldList = [];
    if (getCampaignEmployeesByIDSurveyWiseData && getCampaignEmployeesByIDSurveyWiseData.length > 0) {

      let tempLevelList = Object.keys(getCampaignEmployeesByIDSurveyWiseData[0].employeeId);
      if (tempLevelList && tempLevelList.length > 0) {
        tempLevelList.forEach((temp) => {
          if (NeglectedList.findIndex(prev => prev === temp) === -1) {
            EmpFieldList.push(temp);
          }
        });
      }

    }


    let NewEmployeeList = [];
    if (getCampaignEmployeesByIDSurveyWiseData && getCampaignEmployeesByIDSurveyWiseData.length > 0) {
      getCampaignEmployeesByIDSurveyWiseData.forEach((item, index) => {
        if (!index) {
          console.log('item.isClicked: ', item.isClicked)
        }
        let temp = {}

        if (EmpFieldList && EmpFieldList.length > 0) {
          EmpFieldList.forEach((filter) => {
            temp[filter] = item && item.employeeId && item.employeeId[filter] ? item.employeeId[filter] : "";
          });
        }

        temp["Delivered"] = item && item.isMailSent ? "Yes" : "No";
        temp["Clicked"] = item && item.isClicked ? item && item.clickedDate ? moment.unix(item.clickedDate / 1000).format("DD/MM/YYYY, HH:mm") : "####" : "No";
        temp["Completed"] = item && item.isComplete ? item && item.completeDate ? moment.unix(item.completeDate / 1000).format("DD/MM/YYYY, HH:mm") : "####" : "No";

        // temp["ClickedDate"]=item && item.isClicked?item && item.clickedDate?moment.unix(item.clickedDate / 1000).format("DD/MM/YYYY, HH:mm"):"####":"####";
        // temp["CompletedDate"]=item && item.isComplete?item && item.completeDate?moment.unix(item.completeDate / 1000).format("DD/MM/YYYY, HH:mm"):"####":"####";


        NewEmployeeList.push(temp);

      });
    }


    let EmployeeList = [];

    if (filterformData.showAll) {
      EmployeeList = getCampaignEmployeesByIDSurveyWiseData;
    }
    else {
      if (getCampaignEmployeesByIDSurveyWiseData && getCampaignEmployeesByIDSurveyWiseData.length > 0) {
        getCampaignEmployeesByIDSurveyWiseData.forEach((tempdata) => {
          if (
            (filterformData.isMailSent === tempdata.isMailSent) &&
            (filterformData.isClicked === tempdata.isClicked) &&
            (filterformData.isComplete === tempdata.isComplete)
          ) {
            EmployeeList.push(tempdata)
          }
        })
      }
    }

    console.log('filterformData: ', filterformData)
    console.log('EmployeeList: ', EmployeeList)

    let surveyId = this.props.match.params.id;



    
    console.log("NewEmployeeList----------->",NewEmployeeList)




    return (
      <>
        <div>
          <Sidebar sidebarToggle={this.sidebarToggle} accessAllow={accessAllow} showSidebar={showSidebar} routeLink={this.routeLink} CrrRoute={CrrRoute}
            LanguageLabel={this.LanguageLabel}

            LanguageObj={LanguageObj}
            CurrentLanguage={CurrentLanguage}
          />

          <main className="lg:ml-16 h-screen bg-gray-100">

            <Header onLogout={this.onLogout}
              CurrentLanguageName={CurrentLanguageName}
              getLanguageData={getLanguageData}
              handleLanguage={this.handleLanguage}
              openLanguageModal={this.openLanguageModal}
              showLanguageModal={showLanguageModal}

            />




            {accessAllow("responseAnalytics") ?

              <>

              <div className="flex px-2 m-2">
              
                  <div className="mt-2 ml-2"><span  onClick={() => this.props.history.goBack()} className="cursor-pointer material-icons mr-4 -ml-1">keyboard_backspace</span></div>

                  <div onClick={()=>this.handleResponseType(1)} 
                  className={responseType===1?"px-2 py-2 border-b text-blue-600  border-blue-500 font-semibold cursor-pointer":"px-2 py-2 font-semibold cursor-pointer"}>Survey Response Analytics</div>
                  <div onClick={()=>this.handleResponseType(2)} 
                  className={responseType===2?"px-2 py-2 border-b text-blue-600 border-blue-500 font-semibold cursor-pointer":"px-2 py-2 font-semibold cursor-pointer"}>Response DemographicWise</div>
              </div>



              {responseType===2?
              
                <>
                <div className="mx-4">
                  <SurveyReport2
                  CurrentSurveyId={surveyId}
                  closeResponseAnalytics={this.closeResponseAnalytics}

                  EmpFilterData={EmpFilterData}
                  getIndexFilterData={getIndexFilterData}
                  getRespondantDetailsData={getRespondantDetailsData}

                  styleType={2}
                />
              </div>

              </>



                :

                <div className="p-4 mx-4 -mt-4">
                  <div className="xl:flex lg:flex md:flex justify-between items-center pb-8">
                    <div className="xl:pb-0 lg:pb-0  xl:flex-none flex justify-between items-center">

                      {/* <div className="inline-flex text-lg font-medium text-gray-500">
                        <span  onClick={() => this.props.history.goBack()} className="cursor-pointer material-icons mr-4 -ml-1">keyboard_backspace</span>
                        <div className="-mt-1 font-bold">{LanguageObj && LanguageObj["survey_response_analytics" + "_" + CurrentLanguage] ? LanguageObj["survey_response_analytics" + "_" + CurrentLanguage] : "Survey Response Analytics"} </div>
                      </div> */}

                      {/* <div className="bg-white pr-2 border mx-2 xl:hidden lg:hidden md:hidden block">
                <select className="w-full py-2 text-xs text-black outline-none font-normal pl-2">
                    <option className="text-xs">Select</option>
                    <option className="text-xs">Last Week</option>
                    <option className="text-xs">Select</option>
                    <option className="text-xs">Last Week</option>
                </select></div> */}
                    </div>
                    {/* <div className="flex items-center justify-center">
                <div className="bg-white pr-2 border mx-2 xl:block lg:block md:block hidden">
                <select className="w-full py-2 text-xs text-black outline-none font-normal pl-2">
                    <option className="text-xs">Select</option>
                    <option className="text-xs">Last Week</option>
                    <option className="text-xs">Select</option>
                    <option className="text-xs">Last Week</option>
                </select></div>
                <div className=" relative  text-gray-600">
                <input type="search" name="search" onChange={this.handleSearchSurvey} placeholder="Search by name. . ." className="border border bg-white py-2  px-4 pr-14 text-xs focus:outline-none"  />
                <button type="submit" className="absolute right-0 top-0 my-3 mr-4">
                    <svg className="text-gray-600 h-3 w-3 fill-current" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style={{enableBackground: 'new 0 0 56.966 56.966'}} xmlSpace="preserve" width="512px" height="512px">
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                    </svg>
                </button>
                </div>
                <div className="flex items-center">
                <button className="bg-white border px-3 py-1 mx-2"><i className="fa fa-list text-xs text-gray-600" /></button>
                <button className="bg-white border px-3 py-1  "><i className="fa fa-th text-xs text-gray-600" /></button>
                </div>
            </div> */}
                  </div>
                  {/* boxes */}
                  {/* here is boxes */}
                  <div className="text-gray-600 justify-center grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2 ">

                    {ReportDataList && ReportDataList.length > 0 ?
                      ReportDataList.map((item) =>
                        <div className="bg-white flex h-20 mx-2 my-2 cursor-default shadow-lg">
                          <div className="bg-gray-500 h-full w-3/12" style={{ background: item.color }}></div>
                          <div className="w-8/12 pt-3">
                            <div className="text-center text-xl font-semibold">{item.value}</div>
                            <div className="text-center">{item.name}</div>
                          </div>
                        </div>
                      )
                      : null}

                  </div>

                  <div className="justify-center">
                    <div className="py-4  ">

                      <div className="flex pb-2">

                        <div className="px-2 text-xl mr-2">{LanguageObj && LanguageObj["employees" + "_" + CurrentLanguage] ? LanguageObj["employees" + "_" + CurrentLanguage] : "Employees"}</div>
                        <div className="material-icons pt-1">filter_list</div>
                        <div className="bg-white ml-2 border xl:block lg:block md:block block">
                          <select onChange={(event) => this.handleSetFilter(event.target.value)} className="w-full py-1 text-sm text-black outline-none font-normal pl-2">
                            {filterList && filterList.length > 0 ?
                              filterList.map((item) =>
                                <option value={item.id} className="text-xs">{item.type}</option>
                              )
                              : null}
                          </select>
                        </div>

                        <div onClick={() => this.RefreshAPI()} className="cursor-pointer bg-gray-400 hover:bg-gray-500 p-1 mx-2 px-2 text-white">{LanguageObj && LanguageObj["refresh" + "_" + CurrentLanguage] ? LanguageObj["refresh" + "_" + CurrentLanguage] : "Refresh"}</div>
                        
                        
                        {accessAllow("downloadResponse") ?
                        <>
                        {responseAnalysisBySurveyIdData ?
                          <CSVLink data={responseAnalysisBySurveyIdData} filename={"SurveyReport.csv"}>
                            <div className="cursor-pointer bg-blue-400 hover:bg-blue-500 p-1 mx-2 px-2 text-white">{LanguageObj && LanguageObj["export_csv_label" + "_" + CurrentLanguage] ? LanguageObj["export_csv_label" + "_" + CurrentLanguage] : "Export Response	"}</div>
                          </CSVLink>
                          : '...'}
                        </>
                        :
                        <div className="cursor-pointer bg-blue-300 cursor-not-allowed p-1 mx-2 px-2 text-white">{LanguageObj && LanguageObj["export_csv_label" + "_" + CurrentLanguage] ? LanguageObj["export_csv_label" + "_" + CurrentLanguage] : "Export Response	"}</div>
                        }

                        {/* {NewEmployeeList && NewEmployeeList.length > 0 ?
                          <CSVLink data={NewEmployeeList} filename={"CampaignResponseAnalytics.csv"}>
                            <div className="cursor-pointer bg-blue-400 hover:bg-blue-500 p-1 mx-2 px-2 text-white">{LanguageObj && LanguageObj["export_csv_label" + "_" + CurrentLanguage] ? LanguageObj["export_csv_label" + "_" + CurrentLanguage] : "Export Analytics	"}</div>
                          </CSVLink>
                          : '...'} */}



                        {getAllResponseAnalyticsBySurveyIdData && getAllResponseAnalyticsBySurveyIdData.length > 0 ?
                          <CSVLink data={getAllResponseAnalyticsBySurveyIdData} filename={"CampaignResponseAnalytics.csv"}>
                            <div className="cursor-pointer bg-blue-400 hover:bg-blue-500 p-1 mx-2 px-2 text-white">{LanguageObj && LanguageObj["export_csv_label" + "_" + CurrentLanguage] ? LanguageObj["export_csv_label" + "_" + CurrentLanguage] : "Export Analytics	"}</div>
                          </CSVLink>
                          : '...'}

                      <div onClick={()=>this.handlePaginaton("prev")} className="cursor-pointer bg-gray-300 hover:bg-blue-500 cursor-pointer rounded-xl p-1 mx-2 px-2 text-white ml-10 material-icons">
                      arrow_back
                      </div>
                      <div className="cursor-pointer  cursor-default p-1 mx-2 text-gray-700 font-bold">
                        {this.state.campaignEmpPageNo}
                      </div>
                      <div onClick={()=>this.handlePaginaton("next")} className="cursor-pointer bg-gray-300 hover:bg-blue-500 cursor-pointer rounded-xl p-1 mx-2 px-2 text-white material-icons">
                      arrow_forward
                      </div>
                      


                      </div>

                      <div className="overflow-y-auto" style={{ height: '300px' }}>
                        <table className="border-collapse w-full">
                          <thead>
                            <tr className="text-xs">
                              <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">S NO</th>

                              {selectFilterType === 1 && false ?
                                <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">{LanguageObj && LanguageObj["email_address_label" + "_" + CurrentLanguage] ? LanguageObj["email_address_label" + "_" + CurrentLanguage] : "Email Address"}</th>
                                :
                                <th className="p-1 font-semibold bg-gray-50 text-gray-800 border border-gray-300">
                                  <span >
                                    <input placeholder={LanguageObj && LanguageObj["search_by_email_text" + "_" + CurrentLanguage] ? LanguageObj["search_by_email_text" + "_" + CurrentLanguage] : "Search email..."} className="p-2 bg-white border" value={surveyReportEmail} onChange={(e) => this.handleEmailInput(e.target.value)} />
                                  </span>
                                </th>}

                              {selectFilterType === 1 ?
                                <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">{LanguageObj && LanguageObj["delivered" + "_" + CurrentLanguage] ? LanguageObj["delivered" + "_" + CurrentLanguage] : "Delivered"}</th>
                                :
                                <th className="p-1 font-semibold bg-gray-50 text-gray-800 border border-gray-300">
                                  <div onClick={() => this.HandleFilterType(2)} className="bg-white border mx-1 xl:block lg:block md:block block">
                                    <select onChange={(event) => this.handleIsMailSent(event.target.value)} className="w-full py-1 text-xs text-black outline-none font-normal pl-2">
                                      {isMailList && isMailList.length > 0 ?
                                        isMailList.map((item) =>
                                          <option value={item.id} className="text-xs">{item.type}</option>
                                        )
                                        : null}
                                    </select>
                                  </div>
                                </th>}


                              {selectFilterType === 1 ?
                                <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">{LanguageObj && LanguageObj["clicked" + "_" + CurrentLanguage] ? LanguageObj["clicked" + "_" + CurrentLanguage] : "Clicked"}</th>
                                :
                                <th className="p-1 font-semibold bg-gray-50 text-gray-800 border border-gray-300">
                                  <div onClick={() => this.HandleFilterType(3)} className="bg-white border mx-1 xl:block lg:block md:block block">
                                    <select onChange={(event) => this.handleIsClicked(event.target.value)} className="w-full py-1 text-xs text-black outline-none font-normal pl-2">
                                      {isClickedList && isClickedList.length > 0 ?
                                        isClickedList.map((item) =>
                                          <option value={item.id} className="text-xs">{item.type}</option>
                                        )
                                        : null}
                                    </select>
                                  </div>
                                </th>}

                              {selectFilterType === 1 ?
                                <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">{LanguageObj && LanguageObj["completed" + "_" + CurrentLanguage] ? LanguageObj["completed" + "_" + CurrentLanguage] : "Completed"}</th>
                                :
                                <th className="p-1 font-semibold bg-gray-50 text-gray-800 border border-gray-300">
                                  <div onClick={() => this.HandleFilterType(4)} className="bg-white border mx-1 xl:block lg:block md:block block">
                                    <select onChange={(event) => this.handleIsComplete(event.target.value)} className="w-full py-1 text-xs text-black outline-none font-normal pl-2">
                                      {isCompleteList && isCompleteList.length > 0 ?
                                        isCompleteList.map((item) =>
                                          <option value={item.id} className="text-xs">{item.type}</option>
                                        )
                                        : null}
                                    </select>
                                  </div>
                                </th>}


                              <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">{LanguageObj && LanguageObj["mail_label" + "_" + CurrentLanguage] ? LanguageObj["mail_label" + "_" + CurrentLanguage] : "Send Mail"}</th>

                            </tr>
                          </thead>
                          <tbody>

                            {EmployeeList && EmployeeList.length > 0 ?
                              EmployeeList.map((tempdata, index) =>
                                <>

                                  {(tempdata.employeeId && tempdata.employeeId.email.match(new RegExp(`${surveyReportEmail}`, 'gi'))) ?
                                    <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain} />
                                    : null}
                                  {/* 
                        {filterformData.showAll || selectFilterType===1?
                          <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain}/>
                          :null}
                  

                        {!filterformData.showAll?
                          (filterformData.isMailSent)?
                            (tempdata.isMailSent)?
                            <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain}/>
                            :null
                          :
                            (!tempdata.isMailSent)?
                            <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain}/>
                            :null
                        :null
                        }

                        {selectFilterType===3 && selectFilterType !==1 && selectFilterType !==2 && selectFilterType !==4 && selectFilterType!==5 && !filterformData.showAll?
                          (filterformData.isClicked)?
                            (tempdata.isClicked)?
                            <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain}/>
                            :null
                          :
                            (!tempdata.isClicked)?
                            <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain}/>
                            :null
                        :null
                        }


                        {selectFilterType===4 && selectFilterType !==1 && selectFilterType !==3 && selectFilterType !==2 && selectFilterType!==5 && !filterformData.showAll?
                          (filterformData.isComplete)?
                            (tempdata.isComplete)?
                            <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain}/>
                            :null
                          :
                            (!tempdata.isComplete)?
                            <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain}/>
                            :null
                        :null
                        }

                        {selectFilterType===5 && selectFilterType!==4 && selectFilterType !==1 && selectFilterType !==3 && selectFilterType !==2 && !filterformData.showAll?
                            (tempdata.employeeId && tempdata.employeeId.email.match(new RegExp(`${surveyReportEmail}`, 'gi')))?
                            <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain} />
                            :null
                        :null} */}


                                </>
                              )
                              : null}

                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>


                </div>
            }
              
              
              
              </> : null}
          </main>
        </div>


        {openModalComfirmSend ?

          <ConfirmMail handleConfirmMailClose={this.handleConfirmMailClose} handleSendSingleMail={this.handleSendSingleMail} />

          : null}

      </>
    );
  }
}


function EmployeeTableRows(props) {
  let { tempdata, index, forceMail, resendCampain, LanguageObj, CurrentLanguage } = props;
  return (
    <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
      <td className="p-3 border border-b ">
        {index + 1}
      </td>
      <td className="p-3 border border-b">
        {tempdata.employeeId && tempdata.employeeId.email ? tempdata.employeeId.email : '-'}
      </td>
      <td className="p-3 border border-b">
        {tempdata.isMailSent ?
          // <div className="p-1 bg-gray-200 border rounded cursor-default">{LanguageObj && LanguageObj["yes"+"_"+CurrentLanguage]? LanguageObj["yes"+"_"+CurrentLanguage]:"Yes"}</div>
          <span className="py-1 px-3 text-blue-500">{LanguageObj && LanguageObj["yes" + "_" + CurrentLanguage] ? LanguageObj["yes" + "_" + CurrentLanguage] : "Yes"}</span>

          :
          // onClick={()=>resendCampain(tempdata.id)} 
          <span className="py-1 px-3 text-red-500">{LanguageObj && LanguageObj["no" + "_" + CurrentLanguage] ? LanguageObj["no" + "_" + CurrentLanguage] : "No"}</span>

        }
      </td>

      <td className="p-3 border border-b">
        <span className="py-1 px-3">{tempdata.isClicked ? moment.unix(tempdata.clickedDate / 1000).format("DD/MM/YYYY, HH:mm") : 'No'}</span>
      </td>
      <td className="p-3 border border-b">
        <span className="py-1 px-3 ">{tempdata.isComplete ? moment.unix(tempdata.completeDate / 1000).format("DD/MM/YYYY, HH:mm") : 'No'}</span>
      </td>

      <td className="p-3 border border-b">
        <i onClick={() => forceMail(tempdata.id)} className="fa fa-envelope"></i>
      </td>


    </tr>



  )
}





function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(SurveyReport);
