import React, { Component } from 'react';
import { connect } from 'react-redux';

class QuestionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OptimizedQuesDynaObj: {}

    }
  }

  componentDidMount() {

  }


  render() {
    let { ParaLabelList, GetParameterLabel, DemographicRestriction, getQuestionOverallData, 
      getQuestionData, Name, optionTypeM, QuesDynaObj, ManagerIndexQuestionData, 
      EmpFilterData, ManagerDynaObj, CurrentManagerData } = this.props;
    let { OptimizedQuesDynaObj } = this.state;



    let QuestionList = []

    if(ParaLabelList && ParaLabelList.length>0){
      ParaLabelList.forEach((para)=>{

        if(getQuestionOverallData && getQuestionOverallData.length>0){
          getQuestionOverallData.forEach((ques)=>{
    

            if(para===ques.parameterDimensionName){

            let total = ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
            let sum = ques["5"] + ques["6"];
            let score = (((sum * 100) / total).toFixed(2)).toString()

            QuestionList.push({
              "name":ques.name,
              "para":ques.parameterDimensionName,
              "score": score
            });

          }


    
          });
        }

      });
    }


    console.log('DemographicRestriction: ',DemographicRestriction)
    console.log('QuestionList: ',QuestionList)
    console.log('QuesDynaObj: ',QuesDynaObj)


    let questionObj = {};
    let validLevelList = [];
    let questionLevelObj = {};

    if(DemographicRestriction && DemographicRestriction.length>0){
      DemographicRestriction.forEach((level)=>{
        if(QuesDynaObj && QuesDynaObj[level]){
          validLevelList.push(level);
          questionObj[level] = QuesDynaObj[level];

          let innerQuesObj = {}
          if(QuesDynaObj && QuesDynaObj[level] && QuesDynaObj[level].length>0){
            QuesDynaObj[level].forEach((ques)=>{

              let total = ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
              let sum = ques["5"] + ques["6"];
              let score = (((sum * 100) / total).toFixed(2)).toString()

              innerQuesObj[ques.name] = score;

            });
          }
          questionLevelObj[level] = innerQuesObj;




        }
      });
    }


    let paraStore = [];
    let previousPara = '';
    let paraObjShow = {};
    if(QuestionList && QuestionList.length>0){
      QuestionList.forEach((question)=>{

        if(paraObjShow && paraObjShow[question.para]){
          paraObjShow[question.para] += 1
        }
        else{
          paraObjShow[question.para] = 1
        }


        
        let show = 1;
        if (question.para === previousPara) {
          show = 0;
        }
        else {
          show = 1
        }

        paraStore.push({
          "para": question.para,
          "show": show
        })

        show = 1;

        previousPara = question.para;


      });
    }

    console.log('paraStore: ',paraStore)
    // console.log('previousPara: ',previousPara)

    // console.log('questionObj: ',questionObj)
    // console.log('validLevelList: ',validLevelList)
    // console.log('questionLevelObj: ',questionLevelObj)


    function simplifyQuestion (LevelObj, level, ques){

      let score =  LevelObj && LevelObj[level] && LevelObj[level][ques.name]?LevelObj[level][ques.name]:"0"
      // console.log('score=====>',score)
      // console.log('LevelObj=====>',LevelObj)
      // console.log('level=====>',ques.name)

      return parseFloat(score)
    }

    console.log('getQuestionOverallData: ',getQuestionOverallData)



    

    function getColor(overallScore, currentScore) {

      if (parseFloat(currentScore) === 0) {
        return { Color: '#ffffff', diffTextColor: '#000000' }

      }
      else {

        let diffNo = parseInt(Math.abs(parseFloat(overallScore) - parseFloat(currentScore)));
        // console.log("diffNo:",diffNo)
        if (parseFloat(overallScore) <= parseFloat(currentScore)) { //greater (blue)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#33cc33', diffTextColor: '#ffffff' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#70db70', diffTextColor: '#00000' }

          }
          else {
            return { Color: '#adebad', diffTextColor: '#00000' }
          }

        }
        else { //lesse (red)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#f5babd', diffTextColor: '#000000' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#ea95a1', diffTextColor: '#000000' }

          }
          else {
            return { Color: '#d03e4d', diffTextColor: '#ffffff' }

          }
        }
      }

    }

    function showParaRow(PStore,queIndex, isCheck, para, obj){
      let show = 0;
      if(isCheck){
        show = obj && obj[para] && obj[para]?obj[para]:0;
      }
      else{
        show = PStore && PStore[queIndex] && PStore[queIndex].show?PStore[queIndex].show:0;
      }
      console.log('show=>: ',para,show)
      return show
    }

    return (
      <>
      {QuestionList && QuestionList.length > 0 ?

  <div className="pb-2 whitespace-nowrap overflow-x-auto ">
  <table className="w-full border  ">
    <thead>
      <tr className="bg-gray-100 border-b text-xs   text-gray-800">

        {/* <th className="w-1/4 text-left border-separate border border-black-800 bg-blue-500 text-white"></th> */}
        <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{"Dimension"}</div></th>
        <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-left">{"Question"}</div></th>
        <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{"Overall (%)"}</div></th>

        {validLevelList && validLevelList.length>0?
        validLevelList.map((level)=>
        <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{level==="ManagerPID"?"Manager":level}</div></th>
        ):null}

      </tr>
    </thead>
    <tbody>
      <>
        {QuestionList && QuestionList.length > 0 ?
          QuestionList.map((ques, queIndex) =>
            <tr className="border-separate border border-black-800">
              {showParaRow(paraStore,queIndex,false)?
              <td className="p-2 text-sm border border-b font-semibold text-center"  rowSpan={showParaRow(paraStore,queIndex,true,ques.para,paraObjShow)}>
                {GetParameterLabel(ques.para)}
                <br />
              </td>
              :
              null
              }

              <td className="tooltip-response-question1 text-sm pl-4 border border-b" style={{ fontSize: '13px' }}>
                <div className="cursor-pointer whitespace-pre-line">{ques.name}</div>
                {/* <div className="tooltip-response-question2" style={{ padding: '2px 5px' }}>
                  {ques.name}
                </div> */}
              </td>

              <td className="p-2 text-sm border border-b text-center" style={{ fontSize: '13px' }}>
                {ques.score}
              </td>

              {validLevelList && validLevelList.length>0?
              validLevelList.map((level)=>
                  
                  <td className="p-2 text-sm border border-b text-center" 
                    style={{

                      background: getColor(ques.score, simplifyQuestion(questionLevelObj,level,ques)).Color,
                      color: getColor(ques.score, simplifyQuestion(questionLevelObj,level,ques)).diffTextColor

                    }}
                  >
                    {simplifyQuestion(questionLevelObj,level,ques)?simplifyQuestion(questionLevelObj,level,ques):"-"}
                  </td> 
         
              ):null}



            </tr>
          )
          : null}
      </>
    </tbody>
  </table>
</div>

: null}
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionTable);

