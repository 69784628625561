import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ReactEcharts from "echarts-for-react";
import StackChart from "./Chart/StackChart";

class IndexMeanOverall extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }


  render() {
    let { OverviewList, getMeanScoreData,ItemSaveData, currFilterName } = this.props;

    console.log('OverviewList: ',OverviewList)
    // console.log('getMeanScoreData: ',getMeanScoreData)
    // console.log('ItemSaveData: ',ItemSaveData)
    // console.log('currFilterName: ',currFilterName)

    return (
      <>
                    <div className="bg-white rounded w-full shadow-lg px-4 justify-center">
                      <div className="px-1 py-4 text-base text-gray-700 font-semibold flex justify-between items-center">
                        <h1 className="py-4">
                        <p className="text-base text-gray-700 font-semibold">Overall Index Wise Mean <span className="text-blue-500">{(currFilterName!=='none')?"( "+currFilterName+" wise )":""}</span></p> 
                        </h1> 
                      </div>

                      <StackChart 
                        OverviewList={OverviewList}

                        ItemSaveData ={ItemSaveData}
                        getMeanScoreData={getMeanScoreData}
                        currFilterName={currFilterName}
                      />

                    </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexMeanOverall);
