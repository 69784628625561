import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeatMap from './Charts/HeatMap';
import RadarChart from './Charts/RadarChart';
import Commentary from '../../../Components/Commentary/Commentary';
import Filter from './Filter/Filter';

class HappinessBehaviour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue:'none',
      currFilterName:'none',

      showHeatMap:true,

      currentPage:1,


      currentSelectValue:'',
      FilterValues:[],
      showFilter:false,
      showValue:''

    }
  }

  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }

  componentDidMount() {

  }

  handleViewType=(check)=>{
    this.setState({showHeatMap:!check})
  }

  storeFilterName=(val)=>{
    this.setState({currFilterName:val})
  }

  handleDistributionFilterValueSave=(val)=>{
    this.setState({currFilterValue:val})
  }
  handleDistributionFilterNameSave=(val)=>{
    this.setState({currFilterName:val})
  }


  handlePageChange=(page)=>{
    this.setState({currentPage:page})
  }

  handlePageReset=()=>{
    this.setState({currentPage:1})
  }






  handleMultifilterIndexScore2=(finalFilter, name)=>{
    let {handleMultifilterBehaviour}= this.props;
    this.setState({FilterValues:finalFilter, currFilterName:name});
    handleMultifilterBehaviour(finalFilter,true, name);

  }



  render() {
    let { 
      indexType, optionType,getCommentData,  loading,name,CountRestrict,

      EmpFilterData, FilteredIndexData, getDistributionIndexData,handleDistributionFilter,getIndexDriverData, getIndexFilterData
    
    } = this.props;

    let list = []
    let level = ''

    if(FilteredIndexData && FilteredIndexData.list && FilteredIndexData.list.length>0){
      list = FilteredIndexData.list;
      level = FilteredIndexData.level;
    }

    let { currFilterName , showHeatMap, currentPage, currentSelectValue, FilterValues } = this.state;

    let filterNames = [];
    if(EmpFilterData && EmpFilterData.length>0){
      EmpFilterData.forEach((item)=>{
        filterNames.push(item.name)
      })
    }
    let CurrentFilterName = '';
    if(currFilterName!=='none'){
      CurrentFilterName = currFilterName;
    }
    else if(filterNames && filterNames.length>0){
      CurrentFilterName = filterNames[0];
    }
    



    
   let EmpFilterData2 = []
   if(EmpFilterData && EmpFilterData.length>0){
    EmpFilterData.forEach((emp)=>{
      if(emp.name !=='ManagerPID'){
        EmpFilterData2.push(emp);
      }
    })
   }

   let isDisable  = true;
   let DistributionIndexData = [];
  //  let DemographicDataDistribution = [];

   if(FilterValues && FilterValues.length>0){

     if(isDisable){
        FilterValues.forEach((item)=>{
            if(item.value==='All'){
              isDisable = false
            }
        });
     }


    //  DistributionIndexDataScore=getIndexDriverData;
    //  DemographicDataDistribution=getIndexDistributionData;
    DistributionIndexData = getDistributionIndexData

    // getDistributionIndexData && getIndexDriverData

   }


    console.log('getIndexDriverData: ',getIndexDriverData)
    console.log('getDistributionIndexData: ',getDistributionIndexData)

    let {GetParameterLabel}= this.props;


    

    return (
      <>
      <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
        <Filter
          showFilter={this.state.showFilter}
          showValue={this.state.showValue}
          closeFilter={this.closeFilter}

          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

      </div>


      {true?
      <>
        <div className="xl:flex lg:flex md:flex justify-start items-center">
{/* 
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



        </div>

        {FilterValues && FilterValues.length > 0 ?
          <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null}


          <div onClick={()=>this.closeFilter(false)}  className=" " >
                  <div className="flex w-full" >

                  <div className="bg-white w-full shadow-lg m-4 pb-2 p-4" style={{minHeight:'300px'}}>
                  <div className="md:flex justify-between  mb-4">
                  <div className="text-lg font-semibold">{name} Behaviour - Overall Score (%)</div>
                              <div className="text-xs flex items-center">
                              {/* {FilterValues && FilterValues.length>0?
                              <>
                                <div onClick={()=>this.handleViewType(false)} className={showHeatMap?"cursor-pointer border mx-1 bg-blue-50 ring-1 ring-blue-500 text-blue-500 py-1.5 px-4 rounded-full outline-none":"cursor-pointer border mx-1 py-1.5 px-4 rounded-full"}>Table view</div>
                                <div onClick={()=>this.handleViewType(true)} className={!showHeatMap?"cursor-pointer border mx-1 bg-blue-50 ring-1 ring-blue-500 text-blue-500 py-1.5 px-4 rounded-full outline-none":"cursor-pointer border mx-1 py-1.5 px-4 rounded-full"}>Radar view</div>
                              </>
                              :null}                               */}
                              </div>
                            </div>
                            {showHeatMap?
                            <>
                            {getDistributionIndexData && getIndexDriverData?
                              <HeatMap 
                                handleDistributionFilterNameSave={this.handleDistributionFilterNameSave}
                                handleDistributionFilterValueSave={this.handleDistributionFilterValueSave}
                                getDistributionIndexData={DistributionIndexData}
                                getIndexDriverData={getIndexDriverData}
                                currFilterName={CurrentFilterName}
                                FilterValues={FilterValues}
                                GetParameterLabel={GetParameterLabel}
                                CountRestrict={CountRestrict}
                              />
                              :null}
                              </>
                              :
                              <>
                              {getDistributionIndexData && getIndexDriverData?

                                      <RadarChart 
                                        handleDistributionFilterNameSave={this.handleDistributionFilterNameSave}
                                        handleDistributionFilterValueSave={this.handleDistributionFilterValueSave}
                                        getDistributionIndexData={DistributionIndexData}
                                        getIndexDriverData={getIndexDriverData}
                                        currentPage={currentPage}
                                        handlePageChange={this.handlePageChange}
                                        GetParameterLabel={GetParameterLabel}
                                      />
                              :null}
                               </>
                              }
                        </div>
            
          
          </div>
        </div>
</>
:
<>
{!loading?
<div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
:null}
</>
}
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HappinessBehaviour);
