import { keys } from '@material-ui/core/styles/createBreakpoints';
import React from 'react';
import Parameter from '../Parameter/Parameter';
import ParameterView from '../Parameter/ParameterView';

  export default function SingleChoice(props){
    let { SurveyTemplateId,question,quesindex, currQuesEditId,showTranslatePage,

      translQuestion, handleTranslateQuestion, handleTranslateOption, onTranslateQuestionSave,translShortName,

      handleQuestionInput, handleOptionInput, handleWeightageInput, onDeleteOption, onAddNewOption, onQuesMandatory, onSaveQues,
      handleParameterBucket, parameterList, handleParameterQuestion,paraBucketName,
      showBucketDropdown,showDimensionDropdown, handleParameterDimension, getParameterListbyParentData, paraDimensionName, showParameterModal, onParamaterModal,
      innerEdit,handleQuesCancel ,GetParameterLabel,translSubQuestionsMatrix,handleInputTranslateMatrixQues,
      handleTranslateOptionHeadingInput,

      addMatrixQFn, handleInputMatrixQues,  onDeleteConfirm,handleAddOptionHeading,showOptionHeading,handleOptionHeadingInput,
    } = props;



    function FormatHeadingSpan(option){

    }

    let paraStore = [];
    let previousPara = '';
    let paraObjShow = {};


    if(question && question.optionsList && question.optionsList.length>0){
      question.optionsList.forEach((item)=>{


        if(paraObjShow && paraObjShow[item.heading]){
          paraObjShow[item.heading] += 1
        }
        else{
          paraObjShow[item.heading] = 1
        }


      });
    }


    let headingList = []
    let tempKey = Object.keys(paraObjShow);
    if(tempKey && tempKey.length>0){
      tempKey.forEach((item)=>{
        headingList.push({
          name:item,
          span:paraObjShow[item]
        })
      });
    }

    console.log('headingList=>',headingList)

    let HSortOptionList = []
    if(headingList && headingList.length>0){
      headingList.forEach((heading)=>{
        if(question && question.optionsList && question.optionsList.length>0){
          question.optionsList.forEach((option)=>{
            if(heading.name === option.heading){
              HSortOptionList.push(option);
            }
          });
        }
      });

    }



    function TranslateSubQues(SubQues){


      let value = ""
      if(translSubQuestionsMatrix && translSubQuestionsMatrix[SubQues.id] && 
        translSubQuestionsMatrix[SubQues.id]["transl"] &&
        translSubQuestionsMatrix[SubQues.id]["transl"][translShortName] && 
        translSubQuestionsMatrix[SubQues.id]["transl"][translShortName].name){
          value = translSubQuestionsMatrix[SubQues.id]["transl"][translShortName].name



      }
      return value

    }
    console.log('translSubQuestionsMatrix: ',translSubQuestionsMatrix)
    console.log('question: ',question)

    return (
      <>
{currQuesEditId === question.id?
                  <>
                  {showTranslatePage?
                  <>
                  <div className="flex justify-between items-center">
                    <div className="text-sm font-semibold text-gray-400">Question</div>
                    <div className="flex px-2">
                      <div onClick={()=>onTranslateQuestionSave(true, translShortName)} className="py-1 px-3 rounded-sm border border-1 border-green-500 cursor-pointer text-sm text-green-500 mr-2">Save</div>
                      <div onClick={()=>onTranslateQuestionSave(false, translShortName)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500">Cancel</div>
                    </div>
                  </div>
                  <input 
                    type="text" 
                    value={question.name}
                    onChange={{}}
                    disabled={true}
                    placeholder={"Q."+ (quesindex + 1) + " " + question.name}
                    className="py-2 px-4 border-2 outline-none w-full mt-2" 
                  />
                                      <input 
                      type="text" 
                      value={translQuestion["transl"][translShortName].name}
                      onChange={(event)=>handleTranslateQuestion(event.target.value,question.id,translShortName)}
                      placeholder="Translate here..."
                      className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                    />
                  <hr/>
                  <ul className="pb-4">


                     <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  ">

                    {question && question.SubQuestionList && question.SubQuestionList.length>0?
                          
                          <table className="border-collapse w-full ">
                          <thead>
                       

                       {/* Heading */}
                          <tr>
                            <td className="px-2 bg-gray-200 border border-gray-300 p-2"></td>
                            {question && question.optionsList && question.optionsList.length?
                              question.optionsList.map((option, optionindex) =>
                              <td className="px-2 bg-gray-200 border border-gray-300 text-blue-500 text-center font-semibold"> 
                                <input        
                                  type="text" value={option.heading}
                                  onChange={{}}
                                  disabled={true}
                                  className="m-2 border border-blue-500 p-1" 
                                
                                  placeholder={optionindex + 1 + ') ' + option.heading}
                                  margin="normal"
                                />
                              </td>
                              ):null}
                          </tr>

                          <tr className="bg-gray-100 border-b border-gray-500  text-xs   text-gray-800">
                            <td className="px-2 justify-between flex">
                              <span></span>
                              <span className="pt-4 text-purple-500">Translate Heading:-</span>
                            </td>

                            {question && question.optionsList && question.optionsList.length?
                              question.optionsList.map((option, optionindex) =>
                              <td className="px-2 border border-gray-300">
                              <div className="flex">
                              <input        
                                  type="text" 
                                  // value={option.heading}
                                  value={translQuestion && translQuestion["transl"] && translQuestion["transl"][translShortName] && translQuestion["transl"][translShortName].headingList && 
                                    translQuestion["transl"][translShortName].headingList[option.id]?translQuestion["transl"][translShortName].headingList[option.id]:""}
                                  
                                  onChange={(event)=>handleTranslateOptionHeadingInput(event.target.value,question.id,option.id,translShortName)}
                                  className="m-2 border border-purple-500 p-2" 
                                  placeholder={"Translate Heading..."}
                                />
                              </div>
                              </td>
                              ):null}
                          </tr>


                        {/* Options */}

                          <tr className="bg-gray-100 border-b border-gray-500  text-xs   text-gray-800">
                          
                          <td className="px-2 bg-gray-200 border border-gray-300 p-2"></td>
                          
                          {question && question.optionsList && question.optionsList.length?
                          question.optionsList.map((option, optionindex) =>
                            <td className="px-2 border border-gray-300">

                              <div className="flex">
                                <input        
                                  type="text" value={option.name}
                                  onChange={{}}
                                  disabled={true}
                                  className="m-2 border border-blue-500 p-2" 
                                
                                  placeholder={optionindex + 1 + ') ' + option.name}
                                  margin="normal"
                                />
                              </div>

                            </td>
                            ):null}
                        </tr>


                        <tr className="bg-gray-100 border-b border-gray-500  text-xs   text-gray-800">
                        <td className="px-2 justify-between flex">
                          <span></span>
                          <span className="pt-4 text-purple-500">Translate Options:-</span>
                        </td>
                          
                          {question && question.optionsList && question.optionsList.length?
                          question.optionsList.map((option, optionindex) =>
                          <td className="px-2 border border-gray-300">

                            <div className="flex">
                              <input 
                              type="text"
                              value={translQuestion && translQuestion["transl"] && translQuestion["transl"][translShortName] && translQuestion["transl"][translShortName].optionsList && translQuestion["transl"][translShortName].optionsList[option.id]?translQuestion["transl"][translShortName].optionsList[option.id]:""}
                              onChange={(event)=>handleTranslateOption(event.target.value,question.id,option.id,translShortName)}
                              className="m-2 border border-purple-500 p-2" 
                              placeholder={"Translate Options..."}
                              />
                              </div>

                            </td>
                            ):null}
                        </tr>


                      </thead>







                      <tbody className="">
                        {question && question.SubQuestionList && question.SubQuestionList.length>0?
                        question.SubQuestionList.map((SubQues,index)=>
                          <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                          <td className="p-3 border border-b text-center">
                          <div className="">
                            <div>
                              <input type="text" 
                              value={SubQues.name} 
                              onChange={{}}
                              disabled={true}
                              className="m-2 border border-blue-500 p-2" />
                            </div>
                            <div>
                              <input type="text" 
                              value={TranslateSubQues(SubQues)} 
                              onChange={(e)=>handleInputTranslateMatrixQues(e.target.value,question,SubQues, translShortName)} 
                              className="m-2 border border-purple-500 p-2" 
                              placeholder="Translate here..."/>
                            </div>
                          </div>
                          </td>
                            { question && question.optionsList &&  question.optionsList.length>0?
                          question.optionsList.map((option, optionindex)=>
                          <td className="p-3 border border-b text-center"><input type="radio" className="mx-auto ml-5" /></td>
                          ):null}
                        </tr>
                        ):null}

                      </tbody>
                  </table>

                  :null}

                </div>

                  </ul>


                </>
                  :
                  <>
                    <div className="flex justify-between items-center">
                      <div className="text-sm font-semibold text-gray-400">Question</div>
                      <div onClick={()=>handleQuesCancel(false)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500 mr-2">Cancel</div>
                    </div>
                    <input 
                      type="text" 
                      value={question.name}
                      onChange={(event)=>handleQuestionInput(event.target.value,question.id)}
                      placeholder={"Q."+ (quesindex + 1) + " " + question.name}
                      className="py-2 px-4 border-2 outline-none w-full mt-2" 
                    />
                    <hr/>
                    <ul className="pb-4">
                      {/* <p className="text-xs text-gray-500 pb-2">Answer</p> */}
                      <div className="mb-1.5 mt-2" style={{height:"30px"}}>

                        <div className="py-1" >
                          <input
                          id="mandatory-check"
                          value={question.isMandatory}
                          checked={question.isMandatory}
                          type="checkbox"
                          onChange={()=>{}}
                          className="cursor-pointer ml-1"
                          onClick={()=>onQuesMandatory(question.isMandatory,question.id)}
                          />
                          <label htmlFor="mandatory-check" className="text-sm text-gray-500 pb-8 pl-2">Mandatory</label>
                        </div>
                         {/* <div className="ml-6 mt-1 cursor-pointer text-sm text-purple-500 uppercase font-semibold" onClick={()=>onParamaterModal(!showParameterModal)} ><i className={showParameterModal?"fa fa-times":"pr-2 fa fa-plus"} />{showParameterModal?"":"Add Paramaters"}</div> */}
                      
                      
                      
                        
                        {/* <Parameter
                        showBucketDropdown={showBucketDropdown} 
                        parameterList={parameterList}
                        handleParameterBucket={handleParameterBucket}
                        handleParameterQuestion={handleParameterQuestion}
                        getParameterListbyParentData={getParameterListbyParentData}
                        paraBucketName={paraBucketName}
                        question={question}
                        showDimensionDropdown={showDimensionDropdown}
                        paraDimensionName={paraDimensionName}
                        handleParameterDimension={handleParameterDimension}
                        GetParameterLabel={GetParameterLabel}
                        />
                       */}


                       </div>



                       <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  ">

                      {question && question.SubQuestionList && question.SubQuestionList.length>0?
                            
                            <table className="border-collapse w-full ">
                            <thead>
                         
                            <tr>
                              <td className="px-2 bg-gray-200 border border-gray-300 p-2"></td>
                              {question && question.optionsList && question.optionsList.length?
                                question.optionsList.map((option, optionindex) =>
                                <td className="px-2 bg-gray-200 border border-gray-300 text-blue-500 text-center font-semibold"> 
                                  <input        
                                    type="text" value={option.heading}
                                    onChange={(event)=>handleOptionHeadingInput(event.target.value,question.id,option.id)}
                                    className="m-2 border border-blue-500 p-1" 
                                  
                                    placeholder={optionindex + 1 + ') ' + option.heading}
                                    margin="normal"
                                  />
                                </td>
                                ):null}
                            </tr>
                            

                            <tr className="bg-gray-100 border-b border-gray-500  text-xs   text-gray-800">
                                 <td className="px-2"></td>
                            
                            {question && question.optionsList && question.optionsList.length?
                            question.optionsList.map((option, optionindex) =>
                              <td className="px-2 border border-gray-300">

                                <div className="flex">
                                  <input        
                                    type="text" value={option.name}
                                    onChange={(event)=>handleOptionInput(event.target.value,question.id,option.id)}
                                    className="m-2 border border-blue-500 p-2" 
                                  
                                    placeholder={optionindex + 1 + ') ' + option.name}
                                    margin="normal"
                                  />
                                  <i className="fa fa-trash" aria-hidden="true"
                                  onClick={()=>onDeleteOption(question.id,option.id)}
                                  style={{marginTop: '15px' ,fontSize: '19px',color:'#808080', marginLeft:"15px"  ,  marginLeft:"15px"  , display:'inline' }}
                                  ></i>
                                </div>

                              </td>
                              ):null}
                          </tr>
                        </thead>
                        <tbody className="">
                          {question.SubQuestionList.map((SubQues,index)=>
                            <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                            <td className="p-3 border border-b text-center">
                            <div className="flex">
                              <span><i className="fa fa-trash" aria-hidden="true"
                                title="Delete"
                                onClick={() => onDeleteConfirm(SubQues)}
                                  style={{ margin: '15px',fontSize:'25px'  }}></i>
                              </span>
                              <span><input type="text" value={SubQues.name} onChange={(e)=>handleInputMatrixQues(e.target.value,question,SubQues)} className="m-2 border border-blue-500 p-2" />
                              </span>
                            </div>
                            </td>
                              { question && question.optionsList &&  question.optionsList.length>0?
                            question.optionsList.map((option, optionindex)=>
                            <td className="p-3 border border-b text-center"><input type="radio" className="mx-auto ml-5" /></td>
                            ):null}
                          </tr>
                          )}

                        </tbody>
                    </table>

                    :null}

                  </div>

                    </ul>

                    <div className="flex justify-between mx-20">
                      <div className="flex justify-center">
                        <span onClick={()=>addMatrixQFn(question, 'row')} className="cursor-pointer text-sm text-blue-500 uppercase font-semibold mx-4"><i className="fa fa-plus" /> Add Rows</span>
                      </div>
                      <div className="flex justify-center">
                        <span onClick={()=>addMatrixQFn(question, 'col')} className="cursor-pointer text-sm text-blue-500 uppercase font-semibold mx-4"><i className="fa fa-plus" /> Add Columns</span>
                      </div>


                      {/* <div onClick={()=>addMatrixQFn(question, 'row')} className="flex my-4 mx-2 cursor-pointer">
                        <span className="text-blue-500">Add Matrix Rows</span>
                        <i className="fa fa-plus-circle" aria-hidden="true"
                          
                          style={{ marginLeft: '5px' ,fontSize: '25px' }}></i>
                      </div>
                      <div onClick={()=>addMatrixQFn(question, 'col')} className="flex my-4 mx-2 cursor-pointer">
                      <span className="text-red-500">Add Matrix Columns</span>
                        <i className="fa fa-plus-circle" aria-hidden="true"
                          style={{ marginLeft: '5px' ,fontSize: '25px' }}></i>
                      </div> */}


                  </div>

                    {/* <div className="flex justify-center">
                      <span onClick={()=>onAddNewOption(question)} className="cursor-pointer text-sm text-blue-500 uppercase font-semibold"><i className="fa fa-plus" /> Add more options</span>
                    </div> */}
                    
                  
                    <div className="flex justify-center pb-2 pt-8">
                      <span className="cursor-pointer text-sm rounded uppercase   bg-blue-500 py-2 px-4 text-white w-28  mx-1 text-center" onClick={()=>onSaveQues(question)}> save</span>
                    </div>

                      </>
                      }

                  </>
              :
                  <>
                  <div title="Double Click to Edit!" onDoubleClick={()=>innerEdit(question,SurveyTemplateId)}>
                    <div  className="text-black font-semibold text-sm inline-flex"><p className="pr-2">Q{quesindex+1}.</p> <h1>{question.name}{(question.isMandatory)?'*':null}</h1> </div>
                      <ParameterView question={question} GetParameterLabel={GetParameterLabel}/>

                    <ul className="py-4 pl-6">
                    <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  ">

                    {question && question.SubQuestionList && question.SubQuestionList.length>0?
                                       
                                       <table className="border-collapse w-full ">
                                          <thead>
                                              <tr>
                                                <td className="px-2 bg-gray-200 border border-gray-300 p-2"></td>
                                                {headingList&& headingList.length?
                                                  headingList.map((heading, optionindex) =>
                                                  <td colSpan={heading.span} className="px-2 bg-gray-200 border border-gray-300 text-blue-500 text-center font-semibold"> 
                                                    {heading.name}
                                                  </td>
                                                  ):null}

                                              </tr>
                                               
                                              <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                                               <td className="px-2"></td>
                                               
                                               {HSortOptionList.map((option, optionindex) =>
                                                 <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{option.name}</div>


                                                 </th>
                                                  
                                                 )}
                                             </tr>
                                           </thead>
                                           <tbody className="">
                                             {question.SubQuestionList.map((SubQues,index)=>
                                              <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                                              <td className="p-3 border border-b text-center">
                                               <div className="flex">
                                                 <span>
                                                   <div>{SubQues.name}</div>
                                                 </span>
                                               </div>
                                               </td>
                                               { HSortOptionList &&  HSortOptionList.length>0?
                                               HSortOptionList.map((option, optionindex)=>
                                               <td className="p-3 border border-b text-center"><input type="radio" className="mx-auto ml-5" /></td>
                                               ):null}
                                             </tr>
                                             )}

                                           </tbody>
                                       </table>

                                       :null}
                                       </div>
                    </ul>
                    </div>
                  </>
              }
      </>
    );
  }