import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class RadarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList:[],

    }
  }
  componentDidMount() {

  }


  render() {

    let { valueList, GetParameterLabel }= this.props;

    let list = valueList;

    let ParaNameList = []
    let RadarValueList = []

    let insertData = {
      value:[],
      name:'Organization Core Values:'
    }

    if(list && list.length>0){

      list.forEach((axisData)=>{
        ParaNameList.push({
          text: GetParameterLabel(axisData[0]), 
          max: 100
        })

        if(axisData[1]){
          insertData.value.push(axisData[1].toFixed(2))
        }
        else{
          insertData.value.push(axisData[1])
        }


      })

      RadarValueList.push(insertData)

    }

    console.log('RadarValueList:',RadarValueList)
     
    return (
      <>


      <div className="py-10" style={{height:'800px',marginTop:'50px'}}>
                                    
      {list && list.length>0?
      <div  className=" px-4 py-30">
          <ReactEcharts
            style={{height:"400px"}}
            option={{
              aria: {
                show: true
              },
              tooltip: {
                  show: true,
                  trigger: 'item',
                  formatter: function(a) {
                    console.log('a:',a)
                        return (
                          `<div>`+
                          a.value.map((item,index)=>
                          `<p>`+ParaNameList[index].text.replace('_','')+': '+'<b>'+item+`%</b></p><br/>`
                          )
                          +`</div>`
                        );
                    
                  }
              },
              radar: [
                  {
                      indicator: (function (a){
                          var res = ParaNameList;
                          return res;
                      })(),
                      // radius: 140
                  }
              ],
              series: [
                  {
                      type: 'radar',
                      tooltip: {
                          trigger: 'item'
                      },
                      label: {
                        show: false,
                        position: 'insideRight',
                        formatter: function(d) {
                          console.log('d:',d)

                              return ParaNameList[d.dimensionIndex].text;
                          
                        }
                      },
                      // areaStyle: {normal: {}},
                      data: RadarValueList
                  }
              ]
          }}
                            
          />
     </div>
      :null}
                           



    </div>    

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(RadarChart);
