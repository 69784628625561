import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Graph } from "react-d3-graph";
// import ReactEcharts from "echarts-for-react";
// import Graph from 'react-graph-network';
import Graph from "react-graph-vis";
import DoubleBarChart from './Components/DoubleBarChart';
import WordDetails from '../../WordCloud/Content/WordDetails';

class CooccurGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NetworkValue:''
    }
  }



  componentDidMount() {
  }



    render(){

      let { cooccurmatrixData, handleKeywordCall, wordCloudTextData, wordTextKeyWord }= this.props;


      let words = []

      let cooccurmatrix = cooccurmatrixData && cooccurmatrixData.list && cooccurmatrixData.list.length>0?cooccurmatrixData.list:[];
      let cooccurmatrixTotal = cooccurmatrixData && cooccurmatrixData.count ?cooccurmatrixData.count:0;

      // if(wordCloudData && wordCloudData.list &&  wordCloudData.list.length>0){
      //   wordCloudData.list.forEach((item)=>{
      //     words.push({
      //       text: item[0],
      //       value: item[1]
      //     })
      //   })
      // }

      // console.log('words: ',words)
      console.log('cooccurmatrix: ',cooccurmatrix)
      console.log('cooccurmatrixTotal: ',cooccurmatrixTotal)

      // const words = [
      //   {
      //     text: 'told',
      //     value: 64,
      //   },
      //   {
      //     text: 'mistake',
      //     value: 11,
      //   },
      //   {
      //     text: 'thought',
      //     value: 16,
      //   },
      //   {
      //     text: 'bad',
      //     value: 17,
      //   },
      // ]

      let LinkList = []
      let NodeList = []
      let MAX_COUNT = 0
      if(cooccurmatrix && cooccurmatrix.length>0){

        cooccurmatrix.forEach((item)=>{

          if(MAX_COUNT<item[1]){
            MAX_COUNT = item[1]
          }

          if(NodeList.findIndex(prev=>prev===item[0][0])===-1){

            NodeList.push(item[0][0])
          
          }

          if(NodeList.findIndex(prev=>prev===item[0][1])===-1){

            NodeList.push(item[0][1])

          }


          LinkList.push({
            source:item[0][0],
            target:item[0][1],
            value:item[1]
          })
        });




      }

      let Colors = [
        "#ff8080",
        "#9fff80",
        "#66b3ff",
        "#b366ff",
        "#ffc266",
        "#66d9ff",
        "#b3b3cc",
        "#e6e600",
        "#ff66ff",
        "#99cc00",
        "#ff9966",
        "#cccc00",
        "#66ff33",
        "#ff6666",
        "#ff6600",

      ]

      console.log('NodeList--->',NodeList)
      console.log('LinkList--->',LinkList)

      let NodeValue = []
      if(NodeList && NodeList.length>0){
        NodeList.forEach((node,index)=>{
          NodeValue.push({
            id:index,
            label:node,
            color:Colors[Math.floor(Math.random() * Colors.length)]
          })
        })
      }


      let LinkValue = []
      if(LinkList && LinkList.length>0){
        LinkList.forEach((item,index)=>{
          LinkValue.push({
            from:NodeValue.findIndex(prev=>prev.label===item.source),
            to:NodeValue.findIndex(prev=>prev.label===item.target)
          })
        })
      }


      console.log('NodeValue',NodeValue)

      console.log('LinkValue',LinkValue)



      const data = {
        nodes: NodeValue,
        links: LinkList
      };

      const graph = {
        nodes: NodeValue,
        edges: LinkValue
      };
    
      
      const options = {
        layout: {
          randomSeed: undefined,
          improvedLayout:true,
          clusterThreshold: 150,
          hierarchical: {
            enabled:false,
            levelSeparation: 150,
            nodeSpacing: 100,
            treeSpacing: 200,
            blockShifting: true,
            edgeMinimization: true,
            parentCentralization: true,
            direction: 'UD',  
            sortMethod: 'hubsize',  
            shakeTowards: 'leaves'  
          }
        },
        edges: {
          color: "#000000"
        },
        height: "500px"
      };
    
      const events = {
        select: function(event) {
          var { nodes, edges } = event;
        }
      };



      console.log("wordTextKeyWordData---------->",wordTextKeyWord)
      


        return(
            <>
                <div className="bg-white rounded w-full shadow-md p-4 md:mx-1 md:my-0 mt-4">
                    <div className="flex justify-between items-center">
                    <h1 className="text-sm text-gray-900 font-semibold pb-4">Cooccurence Graph</h1>
                    </div>
                    {/* <div className="cursor-pointer" onClick={()=>this.handleKeyword('policy')}>Policy</div>
                    <div className="h-60" /> */}
                    <div style={{height:'500px',width:'1000px'}} className="pb-4">
                      <Graph
                        graph={graph}
                        options={options}
                        events={events}
                        getNetwork={network => {
                        }}
                      />
                    </div>
                    <div className="pt-4 text-gray-500">Use mouse scroll for <b>zoom-in</b> and <b>zoom-out</b>.</div>

                </div>


              <div className={wordCloudTextData && wordCloudTextData.length>0?"flex ":""}>
                <div className={wordCloudTextData && wordCloudTextData.length>0?
                "bg-white rounded shadow-md p-4 md:mx-1 my-4 w-6/12":
                "bg-white rounded shadow-md p-4 md:mx-1 my-4 "}>
                    <div className="flex justify-between items-center">
                    <h1 className="text-sm text-gray-900 font-semibold pb-4">Cooccurence Percentage</h1>
                    </div>
 
                    {cooccurmatrix && cooccurmatrix.length>0?
                    cooccurmatrix.map((item)=>
                    <>
                    <div onClick={()=>handleKeywordCall(item[0], true)}>
                      <div className="font-bold">{item[0][0]},{item[0][1]}</div>
                      <DoubleBarChart
                        list={[item]}
                        count={cooccurmatrixTotal}
                        MAX_COUNT={MAX_COUNT}
                        isData={wordCloudTextData && wordCloudTextData.length>0}
                      />
                    </div>
                    </>
                    )
                    :null}

                </div>


              {wordCloudTextData && wordCloudTextData.length>0?
                <div className="bg-white rounded shadow-md  md:mx-1 my-4 w-6/12">

                  <WordDetails 
                      loading={false} 
                      wordCloudTextData={wordCloudTextData} 
                      wordTextKeyWord={wordTextKeyWord}
                    />

                </div>
                :null}

              </div>

                
            </>
        );
    }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(CooccurGraph);
