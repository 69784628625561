import React, { Component } from 'react';
import { connect } from 'react-redux';
// import CoreValue from './Components/CoreValue/CoreValue';
import Overview from './Components/Overview/Overview';
import RWA from './Components/RWA/RWA';
import DimensionPriorities from './Components/DimensionPriorities/DimensionPriorities';
// import ResponseDistribution from './Components/ResponseDistribution/ResponseDistribution';

import ResponseDistribution from '../Index/Components/ResponseDistribution/ResponseDistribution';


class OrgCore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType:1,
      DimensionList:[
        {
          index:1,
          name:'Happiness',
          cutOff:59
        },
        {
          index:2,
          name:'Engagement',
          cutOff:78

        },
        {
          index:4,
          name:'Stress',
          cutOff:28

        },
        {
          index:5,
          name:'Manager',
          cutOff:50
        },
        {
          index:6,
          name:'Leader',
          cutOff:50
        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],
    }
  }
  componentDidMount() {

  }


  onOptionType=(data, validateList)=>{
    this.setState({optionType:data});
    if(data===2){
      let { onRWAnalysis } = this.props;
      onRWAnalysis([],validateList&&validateList.length?validateList[0].template:1);
    }
    else if(data===3){
      let { onOrgQuestion } = this.props;
      onOrgQuestion(8);
    }
    else if(data===4){
      let { onOrgQuestion } = this.props;
      onOrgQuestion(8);
    }
  }

  onSwitchOptionScreen=(validateList)=>{
    let { handleMultifilterOrgDriverSummary, indexType, getCommentData ,loading,GetParameterLabel, CountRestrict ,
       EmpFilterData, getIndexFilterData, getEngagementRWAData, rwaIndexType,handleRWAFilter,getSurveyData,validateIndex,
       
      getAllBenchMarkData, getIndexScore, handleSummaryFilter, handleSummaryCard, getDemographicIndexData, getIndexData,getIndexDistributionData,
      getQuestionData,getQuestionFilterData, handleQuestionFilter,  handleMultiFilterGetQuestion,
      getDistributionIndexData,
      // getDistributionIndexData,handleDistributionFilter,getIndexDriverData, getIndexQuantileData
      onRWAnalysis, handleFilterRWA
    } = this.props;

    let { optionType } = this.state;

    switch(optionType){

        case 1: return <Overview CountRestrict={CountRestrict} validateIndex={validateIndex}  GetParameterLabel={GetParameterLabel} getDistributionIndexData={getDistributionIndexData}   handleMultifilterOrgDriverSummary={handleMultifilterOrgDriverSummary} loading={loading} getSurveyData={getSurveyData}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
        getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getDemographicIndexData={getDemographicIndexData} getIndexData={getIndexData} />


        case 2: return <RWA CountRestrict={CountRestrict}  validateIndex={validateIndex}  GetParameterLabel={GetParameterLabel}   validateList={validateList}     loading={loading} handleFilterRWA={handleFilterRWA} validateIndex={validateIndex}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
        getEngagementRWAData={getEngagementRWAData}
        onRWAnalysis={onRWAnalysis} rwaIndexType={rwaIndexType} handleRWAFilter={handleRWAFilter}
        />


        case 3: return <DimensionPriorities  CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel}   loading={loading}    handleMultiFilterGetQuestion={handleMultiFilterGetQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
        getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter}/>
      
        case 4: return <ResponseDistribution  CountRestrict={CountRestrict}   loading={loading}   handleMultiFilterGetQuestion={handleMultiFilterGetQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
        getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter}/>
      
      
      
      default: return <div>Default Option</div> 
    }
  }

  render() {
    let { optionType ,DimensionList} = this.state;

    let { validateIndex } = this.props;

    let validateList = [];
    let validObj = Object.keys(validateIndex).map((key)=>[key,validateIndex[key]])
    console.log('validObj: ',validObj);
    if(validObj && validObj.length>0){
      validObj.forEach((item)=>{
        if(item[1]){
          let getIndex = DimensionList.findIndex(prev=>prev.index===parseInt(item[0]));
          if(getIndex!==-1?DimensionList[getIndex].name:""){
            validateList.push({
              "name":getIndex!==-1?DimensionList[getIndex].name:"",
              "template":parseInt(item[0])
            });
          }

        }
      })
    }
    console.log('org validateList: ',validateList);


    return (
      <> 
      <main className="flex-1 w-full bg-gray-100 overflow-hidden overflow-y-auto ">
      <div className="px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b h-10">
        <div className="cursor-pointer text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden">
          <span onClick={()=>this.onOptionType(1)} className={(optionType===1)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Overview</span>
          <span onClick={()=>this.onOptionType(2,validateList)} className={(optionType===2)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>RWA</span>
          <span onClick={()=>this.onOptionType(3)} className={(optionType===3)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Item Ranking</span>
          <span onClick={()=>this.onOptionType(4)} className={(optionType===4)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Detailed Item Distribution</span>
        </div>
      </div>
          {this.onSwitchOptionScreen(validateList)}
      </main> 
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(OrgCore);
