import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import MngrIntroduction from './Components/MngrIntroduction';
import MngrTopics from './Components/MngrTopics';
import MngrMethodology from './Components/MngrMethodology';
import MngrIndex from './Components/MngrIndex';
import LeaderIndex from './Components/LeaderIndex';
import MngrQuestion from './Components/MngrQuestion/MngrQuestion';
import MngrWeContinue from './Components/MngrWeContinue';
import MngrActionPlanStatic1 from './Components/MngrActionPlanStatic1';
import MngrActionPlanStatic2 from './Components/MngrActionPlanStatic2';
import Header from './Components/Header';

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

// const pdfExportComponent = React.useRef(null);

class ReportModal extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      showUploadBox:false,
      showManagerDropdown:false,
      CurrentManagerData:'',
      reportFormData:{
        "all_sections":false,

        "mngr_dashboard":false,

        "mngr_happiness_question":false,
        "mngr_happiness_driver":false,

        "mngr_engagement_question":false,
        "mngr_engagement_driver":false,


        "mngr_stress_question":false,
        "mngr_stress_driver":false,

        "mngr_manager_question":false,
        "mngr_manager_driver":false
      },

      isContinue:false,
      managerSearchName:""
    }
  }

  componentDidMount() {

  }

  handleUploadCheck=(check)=>{
    console.log('handleUploadCheck');
    this.setState({showUploadBox:!check});
  }

  showManagerListDropdown=(check)=>{
    console.log('check: ',check)

    this.setState({showManagerDropdown:!check});
  }

  selectManager=(mngr)=>{
    this.setState({CurrentManagerData:mngr});
    this.showManagerListDropdown(true);
  }

  handleSearchManager=(e)=>{
    this.setState({managerSearchName:e.target.value})
  }

  selectReportSections=(name, value)=>{
    let { reportFormData } = this.state;
    if(name === "all_sections"){
      let data = {
        "all_sections":!value,

        "mngr_dashboard":!value,

        "mngr_happiness_question":!value,
        "mngr_happiness_driver":!value,

        "mngr_engagement_question":!value,
        "mngr_engagement_driver":!value,


        "mngr_stress_question":!value,
        "mngr_stress_driver":!value,

        "mngr_manager_question":!value,
        "mngr_manager_driver":!value

      }
      this.setState({reportFormData:data});
    }
    else{
      reportFormData[name] =  !value;
      reportFormData["all_sections"] =  false;
      this.setState({reportFormData});
    }

  }

  handleCollectAPI=(validateFn)=>{
    let { SelectManager, getReportMngrQuestion, getReportMngrDriver } = this.props;
    let { CurrentManagerData, reportFormData } = this.state;



    if(CurrentManagerData){
      if(reportFormData["mngr_dashboard"]){
        SelectManager(CurrentManagerData,CurrentManagerData.name);
      }
//Happiness---------------------------------------------------------------
      if(reportFormData["mngr_happiness_question"] && validateFn(1)){
        getReportMngrQuestion(CurrentManagerData,1)
        console.log('mngr_happiness_question')
      }
      if(reportFormData["mngr_happiness_driver"] && validateFn(1)){
        getReportMngrDriver(CurrentManagerData,1)
        console.log('mngr_happiness_driver')
      }
//Engagement---------------------------------------------------------------
      if(reportFormData["mngr_engagement_question"] && validateFn(2)){
        getReportMngrQuestion(CurrentManagerData,2)
        console.log('mngr_engagement_question')

      }
      if(reportFormData["mngr_engagement_driver"] && validateFn(2)){
        getReportMngrDriver(CurrentManagerData,2)
        console.log('mngr_engagement_driver')
      }
//Stress---------------------------------------------------------------
      if(reportFormData["mngr_stress_question"] && validateFn(4)){
        getReportMngrQuestion(CurrentManagerData,4)
        console.log('mngr_stress_question')
      }
      if(reportFormData["mngr_stress_driver"] && validateFn(4)){
        getReportMngrDriver(CurrentManagerData,4)
        console.log('mngr_stress_driver')
      }
//Manager---------------------------------------------------------------
      if(reportFormData["mngr_manager_question"] && validateFn(5)){
        getReportMngrQuestion(CurrentManagerData,5)
        console.log('mngr_manager_question')
      }
      if(reportFormData["mngr_manager_driver"] && validateFn(5)){
        getReportMngrDriver(CurrentManagerData,5)
        console.log('mngr_manager_driver')
      }

      this.handleClosePreview(true);
    }

  }


handleClosePreview=(value)=>{
  this.setState({isContinue:value})

}



closeReportModal=(value)=>{
  let { onCloseManagerReportModal }= this.props;
  onCloseManagerReportModal(value)
  let tempreportFormData={
    "mngr_dashboard":false,

    "mngr_happiness_question":false,
    "mngr_happiness_driver":false,

    "mngr_engagement_question":false,
    "mngr_engagement_driver":false,


    "mngr_stress_question":false,
    "mngr_stress_driver":false,

    "mngr_manager_question":false
  }
  this.setState({reportFormData:tempreportFormData})
}



exportPDFWithComponent = () => {
  if (this.pdfExportComponent.current) {
    this.pdfExportComponent.current.save();
  }
};



  loadManagerReport=()=>{
    let { CurrentManagerData } = this.state;
    let { onLoadManagerReport }= this.props;

    console.log("--->CurrentManagerData-->",CurrentManagerData)

    if(CurrentManagerData){
      onLoadManagerReport(CurrentManagerData);


      this.handleClosePreview(true);
    }





  }


  render() {
  let { 

    GetParameterLabel,
    getDemographicIndexForManagerData, 


    getHappinessQuestionData,
    getHappinessManagerQuestionData,
    getHappinessDMGQuestionData,


    getEngagementQuestionData,
    getEngagementManagerQuestionData,
    getEngagementDMGQuestionData,


    getStressQuestionData,
    getStressManagerQuestionData,
    getStressDMGQuestionData,

    IndexDimension,


    getManagerQuestionData,
    getManagerManagerQuestionData,
    getManagerDMGQuestionData,

    getLeaderQuestionData,
    getLeaderManagerQuestionData,
    getLeaderDMGQuestionData,


    getDemographicIndexHappinessData,
    getDemographicIndexEngagementData,
    getDemographicIndexStressData,
    getDemographicIndexManagerData,
    getDemographicIndexLeaderData,

    

    QuesMngrHappinessReportObject,
    QuesMngrEngagementReportObject,
    QuesMngrStressReportObject,
    QuesMngrManagerReportObject,

    DriverMngrHappinessReportObject,
    DriverMngrEngagementReportObject,
    DriverMngrStressReportObject,
    DriverMngrManagerReportObject,

    getHappinessIndexDriverData,
    getEngagementIndexDriverData,
    getStressIndexDriverData,
    getManagerIndexDriverData,

    getHappinessIndexByFilterData,
    getEngagementIndexByFilterData,
    getStressIndexByFilterData,
    getManagerIndexByFilterData,
    getLeaderIndexByFilterData,

    surveyLogo,


    DynaObj,QuesDynaObj, EmpFilterData, getIndexData, getQuestionReportData, validateList,
  
    ManagerReportFConfig
  
  } = this.props;


  let { showUploadBox , showManagerDropdown, CurrentManagerData , reportFormData,
    isContinue, managerSearchName


  } = this.state;




  let getSurveyData = {
    "logo":surveyLogo
  }



  // if(surveyId==="618b673c9503f41bad61667f"){

  // }
  // getSurveyData["logo"] = 'img/logo/tcillogo.jpeg'


  // getSurveyData["logo"] = 'img/logo/tcillogo.jpeg'

  // console.log('getDemographicIndexForManagerData: ',getDemographicIndexForManagerData);
  // console.log('showManagerListDropdown: ',this.state.showManagerDropdown);
  // console.log('CurrentManagerData: ',this.state.CurrentManagerData);
  // console.log('reportFormData: ',this.state.reportFormData);

  console.log('QuesDynaObj--->: ');

  let IndexLabelList = [
    {
      "index":"Happiness",
      "template":1,
      "overall":getIndexData && getIndexData["1"]?getIndexData["1"]:0
    },
    {
      "index":"Engagement",
      "template":2,
      "overall":getIndexData && getIndexData["2"]?getIndexData["2"]:0
    },
    {
      "index":"Stress",
      "template":4,
      "overall":getIndexData && getIndexData["4"]?getIndexData["4"]:0
    },
    {
      "index":"Manager",
      "template":5,
      "overall":getIndexData && getIndexData["5"]?getIndexData["5"]:0
    },
    {
      "index":"Leader",
      "template":6,
      "overall":getIndexData && getIndexData["6"]?getIndexData["6"]:0
    },
  ]

  let DataList = []
  let OverallIndex = {}
  if(getIndexData){
    OverallIndex = {
      "level":"Overall",
      "data":{}
    }
    if(IndexLabelList && IndexLabelList.length>0){
      IndexLabelList.forEach((label)=>{
        OverallIndex.data[label.template] = (typeof label.overall !== 'string' || label.overall instanceof Number) && label.overall?label.overall.toFixed(2)+"%":'NaN';
        ;
      });
    }
   
  }


  if(EmpFilterData && EmpFilterData.length>0){
    EmpFilterData.forEach((emp)=>{
      if(DynaObj && DynaObj.length>0){
          DynaObj.forEach((data)=>{
            if(data.name[0] === emp.name){
              let Item ={
                "level":emp.name,
                "data":{}
              }
              if(IndexLabelList && IndexLabelList.length>0){
                IndexLabelList.forEach((label)=>{
                  Item.data[label.template] = (typeof data[label.template] !== 'string' || data[label.template] instanceof Number) && data &&  data[label.template]?data[label.template].toFixed(2)+"%":'NaN';
                  ;
                })
              }
              let getIndex = DataList.findIndex(prev=>prev.level===emp.name);
              if(getIndex===-1){
                DataList.push(Item);
              }
            }

          });
      }
    });
  }

  // console.log('getQuestionReportData--->: ',getQuestionReportData);



  console.log('QuesDynaObj--->: ',QuesDynaObj);
  console.log('EmpFilterData--->: ',EmpFilterData);


  function QuestionListFn (QuesDynaObj,Behaviour){

    let tempQuesList = [];
    let ParaList = [];
    if(QuesDynaObj && QuesDynaObj["ManagerPID"]){
      const quesData = QuesDynaObj["ManagerPID"];
      
      quesData.forEach((que)=>{
        let getIndex = ParaList.findIndex(prev=>prev.name === que.parameterDimensionName);
        let show = true;
        if(getIndex === -1){
          ParaList.push({
            "name":que.parameterDimensionName, 
            "count":1
          });
          show = true;
        }
        else{
          ParaList[getIndex].count+=1;
          show = false;
          let getIndex2 = tempQuesList.findIndex(prev=>prev.parameter === ParaList[getIndex].name);
          if(getIndex2 !==-1){
            tempQuesList[getIndex2].countPara =  ParaList[getIndex].count;
          }
        }

        let Item ={
          "question":que.name,
          "parameter":que.parameterDimensionName,
          "showPara":show,
          "countPara":ParaList && ParaList[getIndex] &&  ParaList[getIndex].count?ParaList[getIndex].count:1,
          "demographic":{}
        }
        let ScoreData = {}
        if(EmpFilterData && EmpFilterData.length>0){
          EmpFilterData.forEach((emp)=>{
            let getIndex = QuesDynaObj[emp.name].findIndex(prev=>prev.name===que.name)
            if(getIndex !== -1){
              const demoData = QuesDynaObj[emp.name][getIndex];
              const total = demoData["1"] + demoData["2"] + demoData["3"] + demoData["4"] + demoData["5"] + demoData["6"];
              const favourable = demoData["5"] + demoData["6"];
              const score = (favourable*100)/total;
              ScoreData[emp.name] = (typeof score !== 'string' || score instanceof Number) && score?score.toFixed(2)+"%":'NaN';
            }

          });
        }

        Item.demographic = ScoreData;
        
        tempQuesList.push(Item);
      });




    }

    let QuestionList = [];
    if(Behaviour && Behaviour.length>0){
      Behaviour.forEach((item)=>{
        if(tempQuesList && tempQuesList.length>0){
          tempQuesList.forEach((que)=>{
            if(item === que.parameter){
              QuestionList.push(que);
            }
          });
        }
      });
    }


    return QuestionList;
  }


  function DriverListFn(data,paraList){
    let dataList = []
    if(paraList && paraList.length>0){
      paraList.forEach((para)=>{
        let temp = {
          "parameter":para
        }
        if(EmpFilterData && EmpFilterData.length>0){
          EmpFilterData.forEach((emp)=>{
            temp[emp.name] = data&&data[emp.name]&&data[emp.name][0]&&data[emp.name][0][para+"Avg"]?data[emp.name][0][para+"Avg"]:0;
          });
        }
        dataList.push(temp)
      });
    }
    return dataList
  }

  let HappinessBehaviour = ["Time", "Being","WorkLife","Achivement", "Relationship","Meeningfull"];
  let QuestionListHappiness = QuestionListFn(QuesMngrHappinessReportObject, HappinessBehaviour);
  let DriverListHappiness = DriverListFn(DriverMngrHappinessReportObject, HappinessBehaviour);
  console.log('QuesMngrHappinessReportObject--->: ',QuesMngrHappinessReportObject);
  console.log('EmpFilterData--->: ',EmpFilterData);

  let EngagementBehaviour = ["Pride", "Presence","Performance"];
  let QuestionListEngagement = QuestionListFn(QuesMngrEngagementReportObject, EngagementBehaviour);
  let DriverListEngagement = DriverListFn(DriverMngrEngagementReportObject, EngagementBehaviour);

  let StressBehaviour = ["Collaboration", "Empowerment","Inclusion", "Recognition","WorkSatisfaction"];
  let QuestionListStress = QuestionListFn(QuesMngrStressReportObject, StressBehaviour);
  let DriverListStress = DriverListFn(DriverMngrStressReportObject, StressBehaviour);

  let ManagerBehaviour = ["Connect", "ACT","Empower", "Respect"];
  let QuestionListManager = QuestionListFn(QuesMngrManagerReportObject, ManagerBehaviour);
  let DriverListManager = DriverListFn(DriverMngrManagerReportObject, ManagerBehaviour);



  // console.log('x---->QuesMngrHappinessReportObject--->: ',QuesMngrHappinessReportObject);
  // console.log('x---->HappinessBehaviour--->: ',HappinessBehaviour);

  // console.log('x---->QuesMngrEngagementReportObject--->: ',QuesMngrEngagementReportObject);
  // console.log('x---->EngagementBehaviour--->: ',EngagementBehaviour);


  console.log('x---->QuesMngrHappinessReportObject--->: ',QuesMngrHappinessReportObject);
  console.log('x---->QuesMngrEngagementReportObject--->: ',QuesMngrEngagementReportObject);
  console.log('x---->QuesMngrStressReportObject--->: ',QuesMngrStressReportObject);
  console.log('x---->QuesMngrManagerReportObject--->: ',QuesMngrManagerReportObject);

  console.log('ssdsdsDataList--->: ',DataList);

  console.log('x---->QuestionListHappiness--->: ',QuestionListHappiness);
  console.log('x---->QuestionListEngagement--->: ',QuestionListEngagement);
  console.log('x---->QuestionListStress--->: ',QuestionListStress);
  console.log('x---->QuestionListManager--->: ',QuestionListManager);



  let avoidList = ["ManagerPID","Department","Function","Business"];

function validateFn(template){
  let getIndex = validateList.findIndex(prev=>prev.template===template);
  if(getIndex!==-1){
    return true
  }
  else{
    return false
  }
}



let IconsList = {
  "Happiness":{
    "Time":"img/managerreport/icons_scorecards/png/Time.png",
    "Being":"img/managerreport/icons_scorecards/png/Being.png",
    "Relationship":"img/managerreport/icons_scorecards/png/Relationship.png",
    "WorkLife":"img/managerreport/icons_scorecards/png/worklife.png",
    "Achivement":"img/managerreport/icons_scorecards/png/Achivement.png",
    "Meeningfull":"img/managerreport/icons_scorecards/png/Meaningful.png",
  },
  "Engagement":{
    "Pride":"img/managerreport/icons_scorecards/png/Pride.png",
    "Presence":"img/managerreport/icons_scorecards/png/Presence.png",
    "Performance":"img/managerreport/icons_scorecards/png/Performance.png"
  },
  "Stress":{
    "Recognition":"img/managerreport/icons_scorecards/png/Recognition.png",
    "Work Satisfaction":"img/managerreport/icons_scorecards/png/Work_Satisfaction.png",
    "Inclusion":"img/managerreport/icons_scorecards/png/Inclusion.png",
    "Collaboration":"img/managerreport/icons_scorecards/png/Collaboration.png",
    "Empowerment":"img/managerreport/icons_scorecards/png/Empowerment.png",
  },
  "Manager":{
    "Connect":"img/managerreport/icons_scorecards/png/connect.png",
    "ACT":"img/managerreport/icons_scorecards/png/act.png",
    "Respect":"img/managerreport/icons_scorecards/png/respect.png",
    "Empower":"img/managerreport/icons_scorecards/png/empower.png",
  }
}


let SentenceList = {
  "Happiness":{
    "Time":"Our ability to take care of our timescale plays an important role in our happiness",
    "Being":"This context and being is so important that without it, we can lose the balance of a happy life",
    "Relationship":"Whether at home or at the workplace, we interact with people; and if we have deep relationships with people around us, these interactions become sources of happiness",
    "WorkLife":"Sometimes, work gives us an identity and allows us to express ourselves very well. Around 75% of our waking hours are spent at work and it is one of the most critical aspects of our life",
    "Achivement":"In life, people want to learn and grow. Adding to self is something which gives happiness to everyone",
    "Meeningfull":"Knowing your strengths and doing something to bring a larger change is key to a meaningful life",
  },
  "Engagement":{
    "Pride":"Take Pride, Talk Positively & Recommend the Organization. Employees who feel a strong sense of pride in their work are more ambitious, energetic, focused and motivated",
    "Presence":"The intent of an employee to have an intense desire to be a member of the organization mentally and physically. Having continuous positive push to associate with organization mission.",
    "Performance":"Hi-Performance & Strive to go beyond the goals to make Organization successful. Having an aligned purpose with business"
  },
  "Stress":{
    "Recognition":"",
    "Work Satisfaction":"",
    "Inclusion":"",
    "Collaboration":"",
    "Empowerment":"",
  },
  "Manager":{
    "Connect":"Manager’s to care and support with team and knowing them individually",
    "ACT":"Develop Hi-performing team and continuously perform to achieve organization's goal",
    "Respect":"Build an environment of TRUST and RESPECT",
    "Empower":"Manager to empower team and bring the best",
  }
}




  return (
   <>
        <div style={{zIndex:102,backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 mx-xl relative absolute left-0 right-0 overflow-hidden mt-2">
          
          
          <div onClick={()=>this.closeReportModal(false)} className="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>



          <div className="bg-white  shadow w-full rounded-lg overflow-auto block p-8 h-screen" >
            <div className="flex justify-center">
            {isContinue?
              <>
              <span onClick={()=>this.handleClosePreview(false)} className='mr-4 cursor-pointer material-icons p-1'>arrow_back</span>
              </>
              :null}
              <h2 className="font-bold text-xl  mb-2 text-gray-800  text-center pb-4">
                {"Manager Report"}</h2>
              {isContinue?
              <>
              <div onClick={()=>this.exportPDFWithComponent()} 
              className="cursor-pointer rounded-lg font-bold text-md ml-4 h-8  mb-2 bg-green-500 text-white  text-center pt-1 px-4">
                Export </div>
              {/* <h2  className="cursor-pointer font-normal text-md ml-4  mb-2 text-red-600  text-center pt-1">Back</h2> */}

              </>
              :null}


            </div>





                {!isContinue?
                <>
  <div className="mt-8  " >

    <div className='bg-white h-full'>
          <Header surveyLogo={surveyLogo}/>
          <main className="  mx-auto relative"> 
            <div className="w-5/6 flex justify-between ">
              
              
              <div className="text-sm font-bold w-full mt-16">
                
                
              <h1 className="leading-tight font-normal text-3xl ml-10">
                  Generate Manager Report
              </h1>


              <div className="flex justify-start items-center ml-10">

              <div className=" mt-2">
                    {/* Level Filter */}
                    <div className={false?"flex items-center justify-between border border-blue-500 p-2 relative bg-white rounded-lg mt-6":"flex items-center justify-between border p-2 relative bg-white rounded-lg mt-6"}>
                      <div onClick={()=>this.showManagerListDropdown(showManagerDropdown)}  className="w-60 py-2 cursor-pointer truncate"><span >{
                        CurrentManagerData && CurrentManagerData.name?CurrentManagerData.name:"SELECT MANAGER"
                         }</span> 
                      <span className="text-blue-500 pl-1 text-sm font-normal"></span></div> 
                      <span onClick={()=>this.showManagerListDropdown(showManagerDropdown)}  className="cursor-pointer material-icons">arrow_drop_down</span> 
                    

                      {showManagerDropdown?
                      <div className="absolute top-14 border z-20 shadow-xl w-full right-0 bg-white  font-normal 
                      text-gray-500 h-44 overflow-hidden overflow-y-auto lang  ">
                        <input type="text" value={managerSearchName} name="managerName" onChange={this.handleSearchManager} placeholder="Search" 
                              className="w-full border p-1.5 rounded-sm focus:outline-none text-xs text-gray-500 focus:ring-blue-500 focus:ring-1" />

                      {getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0?
                        getDemographicIndexForManagerData.map((mngr)=>
                        mngr && mngr.name.match(new RegExp(`${managerSearchName}`, 'gi'))?
                        <>
                        <div 
                          className={
                            CurrentManagerData.id === mngr.id?
                            "flex items-center py-2 bg-blue-500 text-white":"flex items-center py-2"}
                            onClick={()=>this.selectManager(mngr)}
                        >
                        <label className="ml-2 font-semibold">{mngr.name}</label>
                        </div>
                        </>
                        :null)
                      :null}




                      </div>
                      :null}
                    </div> 




                    <div 
                    onClick={()=>{
                      if(CurrentManagerData && CurrentManagerData.id){
                        this.loadManagerReport()
                      }
                    }}
                    className="cursor-pointer bg-blue-500 text-white p-2 mt-4 rounded-lg font-semibold text-center py-4">VIEW REPORT</div>


                    </div> 



              </div>




              </div>



              <div className="flex justify-center w-full">
                <img src="img/managerreport/manager_scorecard.png" className="w-12/12 z-10" style={{height: ''}} />
              </div>
            </div>


            <div className=" absolute -bottom-16 h-2/6 bg-blue-200 w-full" style={{height:'140px'}} />
          </main>
        </div>

        </div>

                {/* <div className="px-2">
                  <div className="flex py-1 my-2 justify-center items-center overflow-y-hidden" >
                      
                      <div className="dropdown inline-block relative pl-2">
                        <button  className={CurrentManagerData && CurrentManagerData.name?
                        "border-2  border-purple-500 bg-white text-gray-700 max-w-md py-2 px-4 rounded flex justify-between items-center":
                          "border-2 bg-white text-gray-700 max-w-md py-2 px-4 rounded flex justify-between items-center"}>
                          <span onClick={()=>this.showManagerListDropdown(showManagerDropdown)} style={{width:"200px"}} className="font-semibold text-xs text-left pr-10">{CurrentManagerData && CurrentManagerData.name?CurrentManagerData.name:"Select Manager"} </span> <i onClick={()=>this.showManagerListDropdown(showManagerDropdown)} className="fa fa-angle-down" />
                        </button>
                        
                        {showManagerDropdown?
                          <ul className="dropdown-content relative overflow-y-auto visible text-gray-700 w-full pt-1 pl-2" style={{zIndex:1,height:"300px", width:"240px"}}>
                            
                            <input type="text" value={managerSearchName} name="managerName" onChange={this.handleSearchManager} placeholder="Search" 
                              className="w-full border p-1.5 rounded-sm focus:outline-none text-xs text-gray-500 focus:ring-blue-500 focus:ring-1" />

                            {getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0?
                              getDemographicIndexForManagerData.map((mngr)=>
                              mngr && mngr.name.match(new RegExp(`${managerSearchName}`, 'gi'))?
                              <li  onClick={()=>this.selectManager(mngr)}><a   
                              className={CurrentManagerData.id === mngr.id?
                                "border cursor-pointer rounded-t bg-blue-500 text-white  py-2 px-4 block text-xs font-semibold pl-6":
                                "border cursor-pointer rounded-t bg-white hover:bg-gray-100 py-2 px-4 block text-xs font-semibold pl-6"} href>{mngr.name}</a></li>

                                :null)
                            :null}

                            
                          </ul>
                        :null}


                      </div>

                      {!showManagerDropdown && CurrentManagerData?   
                      <div className="ml-4 text-center">
                        <button 
                        onClick={()=>this.loadManagerReport()} 
                        type="button" 
                        className="bg-blue-500 text-white font-semibold py-2 px-6  shadow-sm" >
                          Load Report
                        </button> 
                      </div>
                      :null}


                  </div>

          
                    




                </div> */}
                </>
                :
                <div className="mt-8 text-center overflow-y-auto" >

                <>
                <PDFExport ref={this.pdfExportComponent} 
                scale={0.6}
                forcePageBreak=".page-break"
                paperSize="A4"
                margin={40}
                fileName={`Manager ID - ${(CurrentManagerData&&CurrentManagerData["emp_id"]?CurrentManagerData["emp_id"]:"")} - ${moment().format("DD/MM/YYYY")}`}
                author="Happyplus Team"
                landscape={true}
                >
                {/* <h4 className="border-b-2 border-gray-200"></h4>
                <div className="text-xl my-2 font-bold text-blue-500 text-center">{isContinue?CurrentManagerData && CurrentManagerData.name?"Manager Name: "+CurrentManagerData.name:"":""}</div>
                <h4 className="border-b-2 border-gray-200"></h4> */}



                <MngrIntroduction 
                CurrentManagerData={CurrentManagerData}
                getSurveyData={getSurveyData}
                />


                <h3 className="page-break mt-4"></h3>


                <MngrTopics
                  getSurveyData={getSurveyData}
                />


                <h3 className="page-break mt-4"></h3>


                <MngrMethodology
                
                  getSurveyData={getSurveyData}

                />



                {/* {getIndexData && getIndexData["5"]?
                  <>
                  <h3 className="page-break"></h3>

                    <MngrIndex
                    IndexName={"Manager"}
                    IndexNumber={5}
                    getDemographicIndexData={getDemographicIndexManagerData}
                    CurrentManagerData={CurrentManagerData}
                    getIndexData={getIndexData}
                    getSurveyData={getSurveyData}

                    getQuestionData = {getManagerQuestionData}

                    GetParameterLabel={GetParameterLabel}

                    getIndexByFilterData={getManagerIndexByFilterData}
                    IndexDimensionList={IndexDimension["Manager"]}
                    getManagerQuestionData={getManagerManagerQuestionData}



                  />   
                </>:null} */}
 
                <div className="py-2"/>


                {/* {getIndexData && getIndexData["6"]?
                  <>
                        <h3 className="page-break"></h3>

                    <LeaderIndex
                    IndexName={"Leader"}
                    IndexNumber={6}
                    getDemographicIndexData={getDemographicIndexLeaderData}
                    CurrentManagerData={CurrentManagerData}
                    getIndexData={getIndexData}
                    getSurveyData={getSurveyData}

                    getQuestionData = {getLeaderQuestionData}

                    GetParameterLabel={GetParameterLabel}

                    getIndexByFilterData={getManagerIndexByFilterData}
                    IndexDimensionList={IndexDimension["Leader"]}
                    getManagerQuestionData={getManagerManagerQuestionData}
                  />   
                </>:null} */}


                {getIndexData && getIndexData["1"]?
                <>

                <h3 className="page-break"></h3>
                <MngrIndex
                  IndexName={"Happiness"}
                  IndexNumber={1}
                  getDemographicIndexData={getDemographicIndexHappinessData}
                  CurrentManagerData={CurrentManagerData}
                  getIndexData={getIndexData}
                  getSurveyData={getSurveyData}

                  getQuestionData = {getHappinessQuestionData}

                  GetParameterLabel={GetParameterLabel}

                  getIndexByFilterData={getHappinessIndexByFilterData}
                  IndexDimensionList={IndexDimension["Happiness"]}
                  getManagerQuestionData={getHappinessManagerQuestionData}
                  SentenceObj={SentenceList["Happiness"]}
                  IconsObj={IconsList["Happiness"]}

                  ManagerReportFConfig={ManagerReportFConfig}

                />  



                <h3 className="page-break"></h3>

                {/* Happiness */}
                
                <MngrQuestion
                  SectionName={"Happiness"}
                  getQuestionOverallData = {getHappinessQuestionData}
                  getQuestionManagerData={getHappinessManagerQuestionData}
                  getDMGQuestionData={getHappinessDMGQuestionData}
                  ManagerDetails={CurrentManagerData}
                  IndexDimensionList={IndexDimension["Happiness"]}
                  GetParameterLabel={GetParameterLabel}
                  getDemographicIndexData={getDemographicIndexHappinessData}
                  CurrentManagerData={CurrentManagerData}
                  getSurveyData={getSurveyData}
                />
                </>
                :null}




                {/* -----------------------------------------Engagement----------------------------------------------  */}
                {getIndexData && getIndexData["2"]?
                <>

                <h3 className="page-break"></h3>
                <MngrIndex
                  IndexName={"Engagement"}
                  IndexNumber={2}
                  getDemographicIndexData={getDemographicIndexEngagementData}
                  CurrentManagerData={CurrentManagerData}
                  getIndexData={getIndexData}
                  getSurveyData={getSurveyData}

                  getQuestionData = {getEngagementQuestionData}

                  GetParameterLabel={GetParameterLabel}

                  getIndexByFilterData={getManagerIndexByFilterData}
                  IndexDimensionList={IndexDimension["Engagement"]}
                  getManagerQuestionData={getEngagementManagerQuestionData}
                  SentenceObj={SentenceList["Engagement"]}
                  IconsObj={IconsList["Engagement"]}

                  ManagerReportFConfig={ManagerReportFConfig}

                />  

                <h3 className="page-break mt-4"></h3>
                <MngrQuestion
                  SectionName={"Engagement"}
                  getQuestionOverallData = {getEngagementQuestionData}
                  getQuestionManagerData={getEngagementManagerQuestionData}
                  getDMGQuestionData={getEngagementDMGQuestionData}
                  ManagerDetails={CurrentManagerData}
                  IndexDimensionList={IndexDimension["Engagement"]}
                  GetParameterLabel={GetParameterLabel}
                  getDemographicIndexData={getDemographicIndexEngagementData}
                  CurrentManagerData={CurrentManagerData}
                  getSurveyData={getSurveyData}

                />


                </>
                :null}












                {getIndexData && getIndexData["4"]?
                <>


                
                <h3 className="page-break"></h3>
                <MngrIndex
                  IndexName={"Stress"}
                  IndexNumber={2}
                  getDemographicIndexData={getDemographicIndexStressData}
                  CurrentManagerData={CurrentManagerData}
                  getIndexData={getIndexData}
                  getSurveyData={getSurveyData}

                  getQuestionData = {getStressQuestionData}

                  GetParameterLabel={GetParameterLabel}

                  getIndexByFilterData={getManagerIndexByFilterData}
                  IndexDimensionList={IndexDimension["Stress"]}
                  getManagerQuestionData={getStressManagerQuestionData}
                  SentenceObj={SentenceList["Stress"]}
                  IconsObj={IconsList["Stress"]}

                  ManagerReportFConfig={ManagerReportFConfig}

                />  




                <h3 className="page-break mt-4"></h3>


                {/* Stress */}
                <MngrQuestion
                  SectionName={"Stress"}
                  getQuestionOverallData = {getStressQuestionData}
                  getQuestionManagerData={getStressManagerQuestionData}
                  getDMGQuestionData={getStressDMGQuestionData}
                  ManagerDetails={CurrentManagerData}
                  IndexDimensionList={IndexDimension["Stress"]}
                  GetParameterLabel={GetParameterLabel}
                  getDemographicIndexData={getDemographicIndexStressData}
                  CurrentManagerData={CurrentManagerData}
                  getSurveyData={getSurveyData}

                />
                </>
                :null}



                {getIndexData && getIndexData["5"]?
                <>

                <h3 className="page-break"></h3>

                <MngrIndex
                IndexName={"Manager"}
                IndexNumber={5}
                getDemographicIndexData={getDemographicIndexManagerData}
                CurrentManagerData={CurrentManagerData}
                getIndexData={getIndexData}
                getSurveyData={getSurveyData}

                getQuestionData = {getManagerQuestionData}

                GetParameterLabel={GetParameterLabel}

                getIndexByFilterData={getManagerIndexByFilterData}
                IndexDimensionList={IndexDimension["Manager"]}
                getManagerQuestionData={getManagerManagerQuestionData}
                SentenceObj={SentenceList["Manager"]}
                IconsObj={IconsList["Manager"]}

                ManagerReportFConfig={ManagerReportFConfig}

                />   

                <h3 className="page-break mt-4"></h3>

                {/* Manager */}
                <MngrQuestion
                  SectionName={"Manager"}
                  getQuestionOverallData = {getManagerQuestionData}
                  getQuestionManagerData={getManagerManagerQuestionData}
                  getDMGQuestionData={getManagerDMGQuestionData}
                  ManagerDetails={CurrentManagerData}
                  IndexDimensionList={IndexDimension["Manager"]}
                  GetParameterLabel={GetParameterLabel}
                  getDemographicIndexData={getDemographicIndexManagerData}
                  CurrentManagerData={CurrentManagerData}
                  getSurveyData={getSurveyData}

                />

                </>
                :null}



                
                {/* {getIndexData && getIndexData["6"]?
                <>

                <MngrQuestion
                  SectionName={"Leader"}
                  getQuestionOverallData = {getLeaderQuestionData}
                  getQuestionManagerData={getLeaderManagerQuestionData}
                  getDMGQuestionData={getLeaderDMGQuestionData}
                  ManagerDetails={CurrentManagerData}
                  IndexDimensionList={IndexDimension["Leader"]}
                  GetParameterLabel={GetParameterLabel}
                />
                </>
                :null} */}

                <h3 className="page-break mt-4"></h3>

                <MngrWeContinue 
                  CurrentManagerData={CurrentManagerData}
                  getSurveyData={getSurveyData}
                />

                <h3 className="page-break mt-4"></h3>

                <MngrActionPlanStatic1
                  getSurveyData={getSurveyData}
                />
                
                <h3 className="page-break mt-4"></h3>

                <MngrActionPlanStatic2
                  getSurveyData={getSurveyData}
                />


                </PDFExport>
                </>
                </div>

                }




          </div>
        </div>
        </div>
   </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ReportModal);
