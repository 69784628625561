import React, { Component } from 'react';
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FilterName:'',
      FilterList2:[],
      selectedFilterList:[],
      FinalFilterList:[],
      StackObject:{},


      selectedFilterName:'none',
      selectedFilterValue:'none',

      showViewBy:false
    }
  }
  componentDidMount() {
  }

  openDropdown=(check)=>{
    this.setState({showViewBy:!check})
  }


  handleFilterName=(val)=>{
    let { handleDemographicFilter, handleFilterSave } =this.props;
    this.setState({selectedFilterName:val, showViewBy:false})
    if(val !=='none'){
      handleDemographicFilter(val);
    }
    handleFilterSave(val);

  }

  render() {
        let {  EmpFilterData } = this.props;
        let { selectedFilterName, showViewBy } = this.state;

        let filterNames = []
        let filterValues = []
    
        if(EmpFilterData && EmpFilterData.length>0){
          EmpFilterData.forEach((item)=>{
            filterNames.push(item.name)
          })
        }
        let CurrentFilterName = selectedFilterName;
        // if(selectedFilterName!=='none'){
        //   CurrentFilterName = selectedFilterName;
        // }
        // else if(filterNames && filterNames.length>0){
        //   CurrentFilterName = filterNames[0];
        // }
        // console.log('filterNames:',filterNames);

    return (
      <>
         <div className="xl:flex lg:flex md:flex justify-end items-center pr-6">
          <div className=" flex justify-left z-30 "> 
            <div className="dropdown inline-block relative py-2 pl-2">
              <button onClick={()=>this.openDropdown(showViewBy)} className="bg-white text-gray-700 max-w-md py-2 px-4 rounded flex justify-between items-center">
                <span className="font-semibold text-xs pr-6">{(CurrentFilterName!=='none')?CurrentFilterName:"View By"} </span> <i className="fa fa-angle-down" />
              </button>
              
              {showViewBy?
                <ul className="dropdown-content absolute visible text-gray-700 w-full pt-1">
                {(CurrentFilterName !=='none')?
                  <li onClick={()=>this.handleFilterName("none")}><a className="border border-gray-100 cursor-pointer rounded-t bg-white hover:bg-gray-100 py-2 px-4 block text-xs font-semibold " href>{"View By"}</a></li>
                :null}

                  {filterNames && filterNames.length>0?
                    filterNames.map((item, index)=>
                      (CurrentFilterName !== item)?
                        <li key={index} onClick={()=>this.handleFilterName(item)}><a  className="border border-gray-100 cursor-pointer rounded-t bg-white hover:bg-gray-100 py-2 px-4 block text-xs font-semibold " href>{item}</a></li>
                      :null
                    )
                  :null}                  
                </ul>
              :null}
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Filter);
