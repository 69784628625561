import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardActions, surveyActions } from '../../_actions';

import {  userActions } from '../../_actions';

import Header from '../SurveyMgmt/Header/Header';
import Sidebar from '../SurveyMgmt/Survey/Components/Sidebar/Sidebar';
import { accessModule } from '../../_helpers';

import LoadingOverlay from 'react-loading-overlay';
import { SheetJSFT } from './types';
import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import AddUserEmployeeList from './Components/AddUserEmployeeList';
import UserAccessModal from './Components/UserAccessModal';

import AllUserRoles from './Components/AllUserRoles';

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysize:10000,

      showSurveyList:true,
      showAddNewSurvey:false,
      showEditSurvey:false,
      confirmDeleteSurvey:false,
      CurrentSurveyId:'',
      CurrentSurveyName:'',
      showChooseSurveyModal:false,
      showSurveyLibrary:false,
      isPackageSelected:false,
      CurrentSurveyData:'',
      showQuestionLibrary:'',

      showUserModal:false,
      showUserModalUPDATE:false,

      checkDMGValueAll:{},
      searchEmployee:"",
      
      EmpIndexA:0,
      EmpIndexB:6,

      currentSurveyPage:1,

      employeeData:'',
      savePackage:'',
      packageList:[],
      surveyValidation:{
        "name":true,
        "desc":true
      },


      surveyFormData:{
        "name":'',
        "desc":''
      },

      showSidebar:false,


      employeesize2:6,

      showCreateSurveyPopup:false,


      
      CurrentLanguage:'en',
      CurrentLanguageName:'English',
      
      showLanguageModal:false,

      openEmpFilterModal:false,


      formDataFilter: {
        "name": "",
        "label": "",
        "isDisable": false
      },

      showFilterNames:false,
      showEmployeeDetails:"",


      
      showALLUserRoles:true,






      showUserDmgLevel:false,
      showUserDmgValue:false,
      DMGLevelList:[],
      DMGValueList:{},
      finalDemoList:{},
      selectedEmployeeData:{
        "name":"",
        "emp_id":"",
        "employeeID":"",
        "userId":""
      },
      AccessObj:{
      },
      AccessList:[

        {
          label:"View Survey*",
          value:"viewSurvey"
        },
        {
          label:"Create Survey",
          value:"createSurvey"
        },
        {
          label:"Publish Survey",
          value:"publishSurvey"
        },
        {
          label:"Dashboard",
          value:"dashboard"
        },
        {
          label:"Response Analytics",
          value:"responseAnalytics"
        },
        {
          label:"Download Response",
          value:"downloadResponse"
        },
        {
          label:"Add Users",
          value:"uploadUser"
        },
        {
          label:"Upload Employees",
          value:"uploadEmp"
        },

      ],

      searchName:""


    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    this.props.dispatch(surveyActions.getSurveyList(data));
    this.props.dispatch(dashboardActions.getClientProfile());

    this.props.dispatch(surveyActions.getEmployeeFilterListByCompanyId());

    let data13 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 20000
    }
    this.props.dispatch(surveyActions.getAssignedRoles(data13));











    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));
  
  


    let data3 = {
      "filters": []
    }
    this.props.dispatch(dashboardActions.getEmpFilter());
    this.props.dispatch(surveyActions.getAllFilterValues(data3));

  
  
  
  
  
  }

  handleChange = (e) => {
    const files = e.target.files;
    // console.log("files; ",files)
    if (files && files[0]) this.setState({ file: files[0] });
  };
  handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      // console.log("datadatadatadata ", data);
      data = data.map(v => ({ ...v, "companyId": this.props.match.params.id }))

      let flag = 0;
      let locList = [];
      if(data && data.length>0){
        for(var i = 0 ; i < data.length ; i++){
          let value = validateEmail(data[i].email);
          console.log("emails:x:",data[i].email);

          if(value){
            //all valid
          }
          else{
            flag = flag + 1;
            locList.push(i+1);
          }
        }
      }
      function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
      
      if(flag){
        this.setState({isNotMail:true,isNotMailPos:locList})
      }
      else{
          /* Update state */
          let datatemp0 = {
            "keyWord": this.state.keyWord,
            "pageNo": 1,
            "companyId": this.props.match.params.id,
            "size": this.state.employeesize
          }
          this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
            this.props.dispatch(surveyActions.saveEmployeeList(this.state.data, datatemp0));
          this.setState({isNotMail:false,isNotMailPos:[]})

          });
      }



    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    };
  }



  onDisableEmployee = (data) => {
    console.log("data Employee: ", data);
    let tempdata = {
      "id": data.id.toString(),
      "keyWord": this.state.keyWord,
      "pageNo": this.state.employeepage,
      "size": this.state.size

    }

    console.log("asdf :: ", tempdata);
    this.props.dispatch(surveyActions.updateEmployeeStatus(tempdata));
  }


  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("search value:",value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.setState({employeeData:''});

    this.props.dispatch(surveyActions.getEmployeeFilterListByCompanyId(data));
  }

  // handleSearchEmployee = (event) => {
  //   event.preventDefault();
  //   let { value } = event.target;
  //   console.log("search value:",value)
  //   this.setState({ keyWord: value, offset: 0 });
  //   let data = {
  //     "keyWord": value,
  //     "pageNo": 1,
  //     "companyId": this.props.match.params.id,
  //     "size": this.state.size
  //   }
  //   this.setState({employeeData:''});

  //   this.props.dispatch(surveyActions.getEmployeeList(data));
  // }


  handleSearchEmployee = (event) => {
    event.preventDefault();
    let { value } = event.target;

    this.setState({searchEmployee:value})

  }


  handleSearchEmployeeAPI = (event) => {
    event.preventDefault();
    let { searchEmployee } = this.state;
    

    this.setState({ keyWord: searchEmployee, offset: 0 });
    

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.id,
      "size": this.state.employeesize2
    }
    if(searchEmployee){
      data = {
        "keyWord": searchEmployee,
        "pageNo": 1,
        "companyId": this.props.match.params.id,
        "size": this.state.size
      }
    }


    this.setState({employeeData:''});

    this.props.dispatch(surveyActions.getEmployeeList(data));
  }




  handleADDpackage=(survey)=>{
    this.setState({showQuestionLibrary:true})

    let { packageList } = this.state;
    if(packageList.findIndex(prev=>prev === survey.id)===-1){
      packageList.push(survey.id);
      this.setState({isPackageSelected:false})
    }
    else{
      let getIndex = packageList.findIndex(prev=>prev === survey.id);
      packageList.splice(getIndex,1);
      this.setState({isPackageSelected:true})
    }
    this.setState({ packageList });
  }


  onSurveyDelete=()=>{
    let data = {
      id:this.state.CurrentSurveyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }
    this.props.dispatch(surveyActions.deleteSurvey(data,datatempsurvey));
    this.setState({confirmDeleteSurvey:false, CurrentSurveyId:'',CurrentSurveyName:''})
  }

  onEditSurvey=(data)=>{
    this.props.history.push(`/app/question/${data.id}`)
  }
  
  onPreviewSurvey=()=>{
    
  }
  
  onReportSurvey=(data)=>{
    this.props.history.push(`/app/surveyreport/${data.id}`)
  }
  
  onPublishSurvey=(data)=>{
    this.props.history.push(`/app/publish/${data.id}`)
  }

  handleChooseSurveyPopup=()=>{
    this.setState({showCreateSurveyPopup:false,showChooseSurveyModal:true})
  }

  handleCreateSurveyPopup=()=>{
    this.handleChooseSurveyPopupClose();
    this.setState({showCreateSurveyPopup:true})
  }

  handleCreateSurveyFromLibrary=()=>{
    this.handleChooseSurveyPopupClose();
    this.setState({showSurveyLibrary:true});


    this.props.dispatch(surveyActions.getSurveyFromLib());
  }

  handleSurveyLibraryClose=(check)=>{
    if(check){
      this.setState({CurrentSurveyData:false});
    }
    else{
      this.setState({showSurveyLibrary:false});
    }
  }


  QuestionLibraryClose=()=>{
      this.setState({CurrentSurveyData:false, savePackage:'',showQuestionLibrary:false});
   }



  handleCreateSurveyPopupClose=()=>{
    this.handleChooseSurveyPopupClose();

    let validate = {
      "name":true,
      "desc":true
    }
    let tempData={
      "name":'',
      "desc":''
    }
    this.setState({showCreateSurveyPopup:false, surveyValidation:validate, surveyFormData:tempData})
  }

  handleChooseSurveyPopupClose=()=>{
    this.setState({showChooseSurveyModal:false})
  }

  onLogout = () =>{
    console.log('logout')
    this.props.dispatch(userActions.logout());
  }


  goToDashboard = () =>{
    
    this.props.history.push('/app/dashboard');

  }

  goToGeneralDashboard = () =>{
    let SurveyId = '5fa04d316ede0f0336066cce';

    this.props.history.push('/app/generaldashboard/' + SurveyId);

  }


  onDashboard=(survey) =>{
    console.log('survey: ',survey);

    // let SurveyId = '5fa04d316ede0f0336066cce';

    if(survey && survey.templateId===1){
      this.props.history.push('/app/dashboard/' + survey.id);
    }
    else if(survey && survey.templateId===2){
      this.props.history.push('/app/dashboard/' + survey.id);
    }
    else if(survey && survey.templateId===3){
      this.props.history.push('/app/generaldashboard/' + survey.id);
    }

  }



  handleSurveyLibQuestions=(survey)=>{
    this.setState({CurrentSurveyData:survey});
  }

  handleUseSurveyPage=()=>{
    let { CurrentSurveyData } = this.state;
    console.log('CurrentSurveyData: ',CurrentSurveyData)

    let data = {
      "surveyId": "605993ff4e91a52e9f001f80",
      "surveyLib": ["60598a67ade90e2a7fbf2830","6058a75c6ba424628f903463"]
    }
  }
  onLogout = () =>{
    console.log('logout')
    this.props.dispatch(userActions.logout());
  }
  handleSuveyPackageSubmit=()=>{
    let { SurveySubmitData, packageList } = this.state;
 

    let resData = {
      "name":SurveySubmitData.name,
      "desc":SurveySubmitData.desc,
      "templateId":2
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey,false, packageList));

    let validate = {
       "name":true,
       "desc":true
    }
    let tempData={
       "name":'',
       "desc":''
    }
    this.setState({showSurveyLibrary:false,showCreateSurveyPopup:false,showChooseSurveyModal:false, surveyValidation:validate, surveyFormData:tempData})

}

addUserModal=(value)=>{
  if(value){
    this.setState({showUserModal:value, showUserModalUPDATE:value});
  }
  else{
    this.setState({showUserModalUPDATE:false ,showUserModal:value, selectedEmployeeData:{"name":"","emp_id":"","employeeID":"","userId":""}, finalDemoList:{}, AccessObj:{},DMGLevelList:[],DMGValueList:{} , checkDMGValueAll:{}});
  }
}

prevEmpPage=()=>{
  let { EmpIndexA, EmpIndexB } = this.state;
  if(EmpIndexA>0){
      this.setState({EmpIndexA:EmpIndexA-1, EmpIndexB:EmpIndexB-1});
  }
}
nextEmpPage=(IndexListIndex)=>{
  let { EmpIndexA, EmpIndexB } = this.state;
  if(IndexListIndex && IndexListIndex.length && (EmpIndexB<IndexListIndex.length)){
    this.setState({EmpIndexA:EmpIndexA+1, EmpIndexB:EmpIndexB+1});
  }
}


SelectEmpPage=(val,IndexListIndex)=>{

  if(val>0 && val < (IndexListIndex && IndexListIndex.length>0?IndexListIndex.length:0)){

    this.setState({currentSurveyPage:val});
    let datatempemployee = {
      "keyWord": "",
      "pageNo": val,
      "companyId": this.props.match.params.id,
      "size": this.state.employeesize2
    }
  
  this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));
  }


}


DownloadEmployees =(employeesItem, isFilter)=>{
  let list = [];
  let keyList = []
  let keyNewList = []


  if(employeesItem && employeesItem.length>0 && employeesItem[0]){
    keyList = Object.keys(employeesItem[0])
  }

  if(keyList && keyList.length>0){
    keyList.forEach((key,index)=>{
      if(key != 'id' 
      // && key != 'role' 
      && key != 'companyId' && key != 'currentStage' && key != 'isDisable' && key != 'createdAt'){
        keyNewList.push(key);
      }
    })
  }

  if(employeesItem && employeesItem.length>0){

    employeesItem.forEach((emp,index)=>{

      let data = {}

      if(keyNewList && keyNewList.length>0){
        keyNewList.forEach((keY,indey)=>{
          data[keY] = emp[keY]
        })
      }

      list.push(data)

    })


  let finalList = [];
  if(isFilter){
      let listFiltered = []
      let {getEmployeeFilterListByCompanyIdData} = this.props.survey;

      console.log('asdadlist: ',list)

      if(list && list.length>0){
      list.forEach((emp)=>{
        let tempObj ={}
        tempObj["email"]=emp["email"];
        tempObj["emp_id"]=emp["emp_id"];
        if(getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length>0){
          getEmployeeFilterListByCompanyIdData.forEach((filter)=>{
            if(!filter.isDisable){
              tempObj[filter.name] = emp[filter.name] 
            }
          });
        }
        finalList.push(tempObj);

      });
      }
    }

  else{
    finalList=list;
  }
  



  let binary_univers = finalList;
    console.log('binary_univers: ',binary_univers)

  let binaryWS = XLSX.utils.json_to_sheet(binary_univers); 

  // Create a new Workbook
  var wb = XLSX.utils.book_new() 

  // Name your sheet
  XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values') 

  // export your excel
  XLSX.writeFile(wb, 'employees.xlsx');


  }

}


handleViewDetails=(emp)=>{
  this.setState({employeeData:emp})
}


sidebarToggle = () =>{
  this.setState({showSidebar:!this.state.showSidebar});

  
  // setTimeout(()=>{
  //   this.setState({showSidebar:false});
  // },4000);

}

routeLink=(link)=>{
  this.props.history.push('/app/'+link);
  this.setState({CrrRoute:link});
}



handleLanguage=(item)=>{
  this.setState({CurrentLanguage:item.shortName, CurrentLanguageName:item.name, showLanguageModal:false});
}


openLanguageModal=(check)=>{
  this.setState({showLanguageModal:!check});
  setTimeout(()=>{
    this.setState({showLanguageModal:false});
  },7000);
}


  onEmployeeFilter=(getEmployeeListData)=>{
    this.props.dispatch(surveyActions.getEmployeeColumnName());
    this.setState({ openEmpFilterModal: true });

  }

  closeEmployeeFilter=(check)=>{
    this.setState({ openEmpFilterModal: check });
  }


  handleFilterInput=(e)=>{
    let { formDataFilter } = this.state;
    formDataFilter["label"] = e.target.value;
    this.setState({formDataFilter});
  }


  onEmployeeFilterDropdown=(check)=>{
    this.setState({showFilterNames:check});
  }


  handleEmployeeColumnSelect=(name)=>{
    let { formDataFilter } = this.state;
    formDataFilter["name"] = name;
    formDataFilter["label"] = name;
    this.setState({formDataFilter,showFilterNames:false});
  }

  handleFilterStatus=(value)=>{
    console.log("handleFilterStatus::", value);
    this.props.dispatch(surveyActions.updateEmployeeFilterStatus({ id: value }));
  }


  handleFilterCheck = () => {
    let { formDataFilter } = this.state;
    if (formDataFilter.isDisable) {
      formDataFilter['isDisable'] = false;
    }
    else {
      formDataFilter['isDisable'] = true;
    }
    this.setState({ formDataFilter });
  }

  addEmployeeFilter=()=>{
    let { formDataFilter } = this.state;

    if(formDataFilter.name !== "Choose Name"){
      console.log("formDataFilter final", formDataFilter)
      let tempData ={
        "name": formDataFilter.name ,
        "label": formDataFilter.label,
        "isDisable": false
      }
      this.props.dispatch(surveyActions.createEmployeeFilter(tempData));
    }

    let tempClear ={
      "name": "",
      "label": "",
      "isDisable": false
    }
    this.setState({formDataFilter:tempClear});
  }


  downLoadSampleCSV=()=>{
  
  let binary_univers = [{
    "email":"sample@mail.com",
    "emp_id":"001",
  }];
  console.log('binary_univers: ',binary_univers)

  let binaryWS = XLSX.utils.json_to_sheet(binary_univers); 

  // Create a new Workbook
  var wb = XLSX.utils.book_new() 

  // Name your sheet
  XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values') 

  // export your excel
  XLSX.writeFile(wb, 'sample employee.xlsx');
  }



  onDeleteFilter=(empFilter)=>{
    let tempData ={
      id:empFilter.id
    }
    this.props.dispatch(surveyActions.deleteEmployeeFilter(tempData));
  }



  handleEmployeeDetails=(emp)=>{
    this.setState({showEmployeeDetails:emp});
  }

  onEditDetails=(check)=>{
    this.setState({showALLUserRoles:check});


    this.addUserModal(false)
  }


  handleOpenLevel=(check)=>{
    this.setState({showUserDmgLevel:check});
  }

  handleOpenValue=(check)=>{
    this.setState({searchName:""});
    if(this.state.showUserDmgValue===check){
      this.setState({showUserDmgValue:"",showUserDmgLevel:false});
    }
    else{
      this.setState({showUserDmgValue:check,showUserDmgLevel:false});
    }
  }


  handleSearch=(e)=>{
    this.setState({searchName:e.target.value});
  }

  selectDMGLevel=(value)=>{
    let { DMGLevelList } = this.state;
    let getIndex = DMGLevelList.findIndex(prev=>prev===value);
    if(getIndex===-1){
      DMGLevelList.push(value);
    }else{
      DMGLevelList.splice(getIndex,1);
    }
    this.setState({DMGLevelList});
  }

  
  selectDMGValue=(value,level)=>{
    let { finalDemoList , checkDMGValueAll} = this.state;

    if(finalDemoList && finalDemoList[level] && finalDemoList[level].length>0){
      let getIndex = finalDemoList[level].findIndex(prev=>prev===value);
      if(getIndex===-1){
        finalDemoList[level].push(value);
      }
      else{
        finalDemoList[level].splice(getIndex,1);
      }
    }
    else{
      finalDemoList[level] = [];
      finalDemoList[level].push(value);
    }
    this.setState({finalDemoList},()=>{
      if(!(finalDemoList && finalDemoList[level] && finalDemoList[level].length>0)){
        delete finalDemoList[level];
        this.setState({finalDemoList});
      }
    });
    checkDMGValueAll[level]=false
    this.setState({checkDMGValueAll});

  }

  selectDMGValueAll=(DemographicValues,item)=>{
    let { finalDemoList,checkDMGValueAll } = this.state;

    if(checkDMGValueAll&&checkDMGValueAll[item]){
              finalDemoList[item]=[];
              checkDMGValueAll[item]=false;
              this.setState({finalDemoList, checkDMGValueAll },()=>{
                if(!(finalDemoList && finalDemoList[item] && finalDemoList[item].length>0)){
                  delete finalDemoList[item];
                  this.setState({finalDemoList});
                }
              });
              console.log('checkDMGValueAll: ',checkDMGValueAll[item])

    }
    else{
              this.setState({finalDemoList:{}},()=>{

                if(DemographicValues && DemographicValues[item] && DemographicValues[item].length>0){
                  finalDemoList[item]=[];
                  DemographicValues[item].forEach((value)=>{
                    finalDemoList[item].push(value);
                  });
                }
                checkDMGValueAll[item]=true;
                console.log('checkDMGValueAll: ',checkDMGValueAll[item])
                this.setState({finalDemoList, checkDMGValueAll },()=>{
                  if(!(finalDemoList && finalDemoList[item] && finalDemoList[item].length>0)){
                    delete finalDemoList[item];
                    this.setState({finalDemoList});
                  }
                });
            
          
              });
    }







    // if(finalDemoList && finalDemoList[level] && finalDemoList[level].length>0){
    //   let getIndex = finalDemoList[level].findIndex(prev=>prev===value);
    //   if(getIndex===-1){
    //     finalDemoList[level].push(value);
    //   }
    //   else{
    //     finalDemoList[level].splice(getIndex,1);
    //   }
    // }
    // else{
    //   finalDemoList[level] = [];
    //   finalDemoList[level].push(value);
    // }
    // this.setState({finalDemoList},()=>{
    //   if(!(finalDemoList && finalDemoList[level] && finalDemoList[level].length>0)){
    //     delete finalDemoList[level];
    //     this.setState({finalDemoList});
    //   }
    // });



  }


  selectEmployee=(empId)=>{

    console.log('empId||||||------------>',empId);
    let data = {
      "name":empId.name,
      "emp_id":empId.id,
      "employeeID":empId.emp_id
    }
    console.log('data||||||------------>',data);

    this.setState({selectedEmployeeData:data, showEmployeeDrop:false});
  }


  handleOpenEmployeeDrop=(check)=>{
    this.setState({showEmployeeDrop:check});
  }



  handleOpenEmployeeRoleUpdate=(check)=>{
    this.setState({showUserModalUPDATE:check});

  }


  giveAccessSubmit=()=>{
    let { finalDemoList,selectedEmployeeData, AccessObj, checkDMGValueAll } = this.state;

    if(selectedEmployeeData && selectedEmployeeData.emp_id){
      let data={
        "empId": selectedEmployeeData.emp_id,
        "access":finalDemoList,
        "moduleAccess":AccessObj
      }
      console.log('data:',data)


      let tempData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 20000
      }



      this.props.dispatch(surveyActions.assignRole(data,tempData));
      this.setState({showUserModalUPDATE:false,showUserModal:false, selectedEmployeeData:{"name":"","emp_id":"","employeeID":"","userId":""}, finalDemoList:{}, AccessObj:{}, checkDMGValueAll:{} });
    


      let data13 = {
        "keyWord": "",
        "pageNo": 1,
        "size": 20000
      }
      this.props.dispatch(surveyActions.getAssignedRoles(data13));



      this.addUserModal(false);

    
    }
  }




  removeAccessSubmit=(boolData)=>{
    let { finalDemoList,selectedEmployeeData, AccessObj, checkDMGValueAll } = this.state;

    if(selectedEmployeeData && selectedEmployeeData.emp_id){
      let data={
        "empId": selectedEmployeeData.emp_id,
        "userId":selectedEmployeeData.userId,
        "isDelete":boolData
      }
      console.log('data:',data)


      let tempData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 20000
      }



      this.props.dispatch(surveyActions.removeRole(data,tempData));
      this.setState({showUserModalUPDATE:false,showUserModal:false, selectedEmployeeData:{"name":"","emp_id":"","employeeID":"","userId":""}, finalDemoList:{}, AccessObj:{}, checkDMGValueAll:{} });
    


      let data13 = {
        "keyWord": "",
        "pageNo": 1,
        "size": 20000
      }
      this.props.dispatch(surveyActions.getAssignedRoles(data13));



      this.addUserModal(false);

    
    }
  }




  openAccessTab=(emp)=>{
    this.selectEmployee(emp);
    this.addUserModal(true);
  }


//update------------
  openAccessUpdateTab=(emp)=>{

    let { getAllFilterValuesData } = this.props.survey;

    let { finalDemoList, DMGLevelList , AccessObj,  checkDMGValueAll} = this.state;

    let empData = {
    "name": emp && emp.employeeId && emp.employeeId.name?emp.employeeId.name:"",
    "emp_id": emp && emp.employeeId && emp.employeeId.id?emp.employeeId.id:"",
    "employeeID": emp && emp.employeeId && emp.employeeId.emp_id?emp.employeeId.emp_id:"",

    "userId": emp && emp.id?emp.id:"",

    }

    let moduleAObj = emp && emp.moduleAccess?emp.moduleAccess:{};
    let accessAObj = emp && emp.access?emp.access:{}
    finalDemoList = accessAObj;
    let ListKeys = Object.keys(accessAObj);


    let DemographicValues = {};

    if(ListKeys && ListKeys.length>0){
      ListKeys.forEach((item)=>{

        DemographicValues[item] = getAllFilterValuesData[item];

        if(finalDemoList && finalDemoList[item] && DemographicValues && DemographicValues[item] && (finalDemoList[item].length===DemographicValues[item].length)){
          checkDMGValueAll[item]=true;
        }


        DMGLevelList.push(item);
      });
    }

    console.log('emp----------->',emp)

    this.setState({checkDMGValueAll, showUserModalUPDATE:true, finalDemoList, DMGLevelList , AccessObj: moduleAObj, selectedEmployeeData:empData});

    // this.selectEmployee(emp);
    // this.addUserModal(true);
  }

  

  handleScreenAcess=(value,name)=>{
    let { AccessObj } = this.state;
    AccessObj[name] = value;
    this.setState({AccessObj});
  }


  handleChangeTab=(check)=>{
    this.setState({showALLUserRoles:check});

    
    let data13 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 20000
    }
    this.props.dispatch(surveyActions.getAssignedRoles(data13));

    
    let datatempemployee = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.id,
      "size": this.state.employeesize2
    }

    this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));


  }

  render() {
    let { survey } = this.props;

    let { getAssignedRolesData, getAllFilterValuesData, loading,getEmployeeColumnNameData, getEmployeeFilterListByCompanyIdData,  getLanguageLabelData,getLanguageData,getEmployeeListData,getEmployeeListTotal,createSurveyData, getSurveyFromLibData, getSurveyListData, getSurveyListTotal, error } = survey;
    
    let { searchName, checkDMGValueAll, showUserModalUPDATE, AccessList,AccessObj,showEmployeeDrop, selectedEmployeeData, finalDemoList, DMGLevelList,DMGValueList, showUserDmgLevel, showUserDmgValue ,showALLUserRoles, showEmployeeDetails,showFilterNames,formDataFilter,openEmpFilterModal,showUserModal, 
      showLanguageModal, CurrentLanguageName, CurrentLanguage,  showSidebar,CrrRoute, employeeData,savePackage, isPackageSelected, showQuestionLibrary, packageList,CurrentSurveyData, showSurveyLibrary, showChooseSurveyModal, showSurveyList,CurrentSurveyName, 
      confirmDeleteSurvey, surveyFormData, showCreateSurveyPopup, surveyValidation } = this.state;


    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;

 
    console.log('EmpFilterData: ',EmpFilterData);
    console.log('getAssignedRolesData: ',getAssignedRolesData);

    // console.log('getEmployeeFilterListByCompanyIdData: ',getEmployeeFilterListByCompanyIdData)


    let EmployeeFilterCompanyList = []
    if(getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length>0){
      getEmployeeFilterListByCompanyIdData.forEach((filter)=>{
        if(!filter.isDisable){
          EmployeeFilterCompanyList.push(filter);
        }
      });
    }
       //--------------------------------------------------------------------------------

       let IndexList = []
       let pageCount = 7
       let IndexListIndex = []
   
       if(getEmployeeListTotal){
       
   
        if(getEmployeeListTotal && getSurveyListData && getSurveyListData.length>0){
         [...Array(getEmployeeListTotal).keys()].forEach((item,index)=>{
           if(index % pageCount === 0){
             IndexList.push(index)
           }
           if(index===(getSurveyListData.length-1)){
             IndexList.push(index)
           }
         })
        }
        
        console.log('IndexList: ',IndexList)
   
        if(IndexList && IndexList.length>0){
         IndexList.forEach((item,index)=>{
           if(index!==(IndexList.length-1)){
             IndexListIndex.push(index+1)
           }
         })
        }
        console.log('IndexListIndex: ',IndexListIndex)

   
     }
        //--------------------------------------------------------------------------------
 



  //ROLE MANAGEMENT ----------------------------------------------

  let moduleAccess = {
    "isAdmin":false,
    "access":{}
  };
  if(accessModule()){
    moduleAccess["access"] = accessModule();
  }
  console.log('moduleAccess: ',moduleAccess)

//ROLE MANAGEMENT ----------------------------------------------

  let SHOW_ELEMENT = false;
  let clientData = this.props.dashboard.ClientProfileData;
  if(clientData && clientData.role){

      if(clientData.role === "ADMIN"){
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if(clientData.role === "MNGR"){
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if(clientData.role === "CROLE"){
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if(clientData.role === "EMP"){
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
  }
  function accessAllow (item){
    return (moduleAccess.isAdmin?true:(moduleAccess && moduleAccess.access && moduleAccess.access[item]?true:false))
  }

  //END -------------------------------------------------------

  
  let LanguageObj = {};
  if(getLanguageLabelData && getLanguageLabelData[CurrentLanguage]){
    LanguageObj =  getLanguageLabelData[CurrentLanguage];
  }


  let { ClientProfileData } = this.props.dashboard;
  if(ClientProfileData && ClientProfileData.role && ClientProfileData.role==="MNGR"){
      this.props.history.push(`/app/manageractionplan`)
  }


  let EmployeeListData = [];
  // if(getEmployeeListData && getEmployeeListData.length>0){
  //   EmployeeListData = Object.key(getEmployeeListData)
  // }
  // console.log('getEmployeeListData: ',getEmployeeListData)
  // console.log('EmployeeListData: ',EmployeeListData)

  let EmployeeFieldList = []
  if(getEmployeeListData && getEmployeeListData.length>0){
    EmployeeFieldList =  Object.keys(getEmployeeListData[0]);
  }
  console.log('EmployeeFieldList: ',EmployeeFieldList)

  let NewEmployeeFieldList = []
  if(EmployeeFieldList && EmployeeFieldList.length>0){
      EmployeeFieldList.forEach((item)=>{
        if(item != "isDisable" && item != "isTest" && item != "createdAt" &&  item != "id" && item!="actionPlanStage"&&
        item !="companyId" && item !="email"  && item !="emp_id" ){
          NewEmployeeFieldList.push(item);      
        }
      });
    }

  


  if(EmployeeFieldList){

  }
  console.log('NewEmployeeFieldList: ',NewEmployeeFieldList)

  console.log('EmployeeListData: ',EmployeeListData)


  // let DemographicLevels = ["Department","Gender","Country"];
  // let DemographicValues = {
  //   "Department":["IT","HR","TECHNICAL","MANAGERIAL"],
  //   "Gender":["Male","Female"],
  //   "Country":["India"]
  // };


  let DemographicLevels = [];
  let DemographicValues = {};
  if(EmpFilterData && EmpFilterData.length>0){
    EmpFilterData.forEach((emp)=>{
        DemographicLevels.push(emp.name);
    });
  }
  if(DMGLevelList && DMGLevelList.length>0){
    DMGLevelList.forEach((level)=>{
      DemographicValues[level]= getAllFilterValuesData[level];
    });
  }

  // DemographicValues[]



  console.log('DMGLevelList: ',DMGLevelList);
  console.log('DMGValueList: ',DMGValueList);
  console.log('finalDemoList: ',finalDemoList);



  let FinalDemoshowShow = Object.keys(finalDemoList).map((key) => [key, finalDemoList[key]]);
  console.log('AccessObj: ',AccessObj);


  return (
    <>
        <LoadingOverlay
      active={loading}
      // active={false}

      spinner
      text='Loading Data...'
      className="loader"
      >


      
          <div>
  {/*this is side bar  */}
  <Sidebar sidebarToggle={this.sidebarToggle} accessAllow={accessAllow} showSidebar={showSidebar} routeLink={this.routeLink} CrrRoute={CrrRoute}
         
         LanguageLabel={this.LanguageLabel}

         LanguageObj={LanguageObj}
         CurrentLanguage={CurrentLanguage}
  
  />

  {/* side bar end */} 
  <main className="lg:ml-16 h-screen ">
    {/* component */}
    <Header  onLogout={this.onLogout} CurrentLanguageName={CurrentLanguageName}
        getLanguageData={getLanguageData} 
        handleLanguage={this.handleLanguage}
        openLanguageModal={this.openLanguageModal}
        showLanguageModal={showLanguageModal} 
    />

{accessAllow("uploadUser")?
    <>
    {/* header end */}
    {/* main work*/}
    {showALLUserRoles?
<>

<div className=" py-8   px-4 h-full overflow-hidden overflow-y-auto bg-gray-100" style={{height: 'calc(100% - 4rem)'}}>
  <div className="md:flex justify-between">
    {/* left side */}
    <div className="flex items-center">
      <h1 className="text-lg font-medium text-gray-800 ">My Users</h1>
      <div className="flex text-blue-500 text-xs items-center ml-2 border-l border-blue-500  pl-2 ">
        <p>Home</p> <span className="material-icons" style={{fontSize: '12px'}}>navigate_next</span>
        <p>All users</p>
      </div>
    </div>
    {/* right side */}
    <div className="md:flex items-center lg:mt-0 mt-4 ">
      <div className="flex">
        {/* search box */}
        <div className="w-full m-5">

        </div>
      </div>
    </div>
  </div>
  {/* end */}
  {/* employess list */}
  <div className="flex flex-col  bg-white rounded-lg overflow-hidden mt-4 border">
    {/* filter and btns*/}
    <div className="md:flex justify-between p-4 border-b">
      <div className="flex space-x-4">
        {/* btn 1 */}
        <div className="flex items-center text-sm font-medium  text-white relative cursor-pointer  bg-blue-500 rounded-md py-2 px-4">
          <p className=" capitalize">All user roles </p>
          {/* end */}
        </div>
        {/* btn 1 */}
        <div onClick={()=>this.handleChangeTab(false)} className="flex items-center text-sm font-medium  text-blue-500 relative cursor-pointer border border-blue-500  bg-white rounded-md py-2 px-4">
          <p className=" capitalize">Select user roles </p>
          {/* end */}
        </div>
      </div>
    </div>
    {/* end */}
    <div className=" overflow-x-auto whitespace-nowrap w-full ">
      <AllUserRoles
        getAssignedRolesData={getAssignedRolesData}
        LanguageObj={LanguageObj}
        CurrentLanguage={CurrentLanguage}
        openAccessUpdateTab={this.openAccessUpdateTab}
        AccessList={AccessList}
      />
    </div>
  </div>
  {/*end  */}
  {/* page no 1 2 3  */}


  {/* <div className="bg-white h-16 w-full flex items-center justify-center mt-2">
    <div className="flex items-center"> <span className="material-icons bg-gray-100 text-gray-500 p-2 rounded-full">chevron_left</span>
      <div className="flex items-center mx-4 text-gray-500 text-sm font-medium">
        <p>1</p>
        <p className="px-0.5">-</p>
        <p>10</p>
        <p className="px-1">of</p>
        <p>25</p>
      </div> <span className="material-icons bg-blue-500 text-white p-2 rounded-full">chevron_right</span></div>
  </div> */}



  {/* end */}
</div>



</>
      :




      <div className=" py-8   px-4 h-full overflow-hidden overflow-y-auto bg-gray-100" style={{height: 'calc(100% - 4rem)'}}>
  <div className="md:flex justify-between">
    {/* left side */}
    <div className="flex items-center">
      <h1 className="text-lg font-medium text-gray-800 ">My Users</h1>
      
      
      <div className="flex text-blue-500 text-xs items-center ml-2 border-l border-blue-500  pl-2 ">
        <p>Home</p> <span className="material-icons" style={{fontSize: '12px'}}>navigate_next</span>
        <p>All users</p>
      </div>



    </div>
    {/* right side */}
    <div className="md:flex items-center lg:mt-0 mt-4 ">
      <div className="flex">
        {/* search box */}
        <div className="w-full flex justify-between bg-white rounded-full text-sm items-center border overflow-hidden">
          <input type="search" name="search" onChange={this.handleSearchEmployee} placeholder={LanguageObj && LanguageObj["search_by_email_text"+"_"+CurrentLanguage]? LanguageObj["search_by_email_text"+"_"+CurrentLanguage]:"Search by email..."} className="md:w-56 w-full focus:outline-none px-4 py-2" /> 
          
          <span onClick={this.handleSearchEmployeeAPI} className="cursor-pointer material-icons text-blue-500 p-2  rounded-l-full" 
          style={{fontSize: '1.4rem'}}>search</span> 
          
          </div>
      </div>
    </div>
  </div>
  {/* end */}
  {/* employess list */}
  <div className="flex flex-col  bg-white rounded-lg overflow-hidden mt-4 border">
    {/* filter and btns*/}
    <div className="md:flex justify-between p-4 border-b">
      <div className="flex space-x-4">
        {/* btn 1 */}
        <div onClick={()=>this.handleChangeTab(true)} className="flex items-center text-sm font-medium  text-blue-500 relative cursor-pointer border border-blue-500  bg-white rounded-md py-2 px-4">
          <p className=" capitalize">All user roles </p>
          {/* end */}
        </div>
        {/* btn 1 */}
        <div  className="flex items-center text-sm font-medium  text-white relative cursor-pointer  bg-blue-500 rounded-md py-2 px-4">
          <p className=" capitalize">Select user roles </p>
          {/* end */}
        </div>
      </div>
    </div>
    {/* end */}
    <div className=" overflow-x-auto whitespace-nowrap w-full ">
      <AddUserEmployeeList
        getEmployeeListData={getEmployeeListData}
        LanguageObj={LanguageObj}
        CurrentLanguage={CurrentLanguage}
        openAccessTab={this.openAccessTab}
      />
    </div>
  </div>
  {/*end  */}



  <div className="bg-white h-16 w-full flex items-center justify-center mt-2">
    <div className="flex items-center"> 
    
    <span onClick={()=>this.SelectEmpPage(this.state.currentSurveyPage-1,IndexListIndex)} className="cursor-pointer material-icons bg-gray-100 hover:bg-blue-500 hover:text-white text-gray-500 p-2 rounded-full">chevron_left</span>
     
     <div className="flex items-center mx-4 text-gray-500 text-sm font-medium">
        <p>{this.state.currentSurveyPage}</p>
        {/* <p className="px-0.5">-</p>
        <p>10</p> */}
        <p className="px-1">of</p>
        <p>{IndexListIndex && IndexListIndex.length>0? IndexListIndex.length:0}</p>
      </div> 


    <span onClick={()=>this.SelectEmpPage(this.state.currentSurveyPage+1,IndexListIndex) } className="cursor-pointer material-icons bg-gray-100 hover:bg-blue-500 hover:text-white text-gray-500 p-2 rounded-full">chevron_right</span>
      
      </div>
  </div>




</div>






    }

            {showUserModal?
            <UserAccessModal
            handleOpenEmployeeDrop={this.handleOpenEmployeeDrop}
            addUserModal={this.addUserModal} 
            handleOpenLevel={this.handleOpenLevel}
            handleOpenValue={this.handleOpenValue}
            selectDMGLevel={this.selectDMGLevel}
            selectDMGValue={this.selectDMGValue}
            selectDMGValueAll={this.selectDMGValueAll}
            checkDMGValueAll={checkDMGValueAll}
            handleSearch={this.handleSearch}
            handleScreenAcess={this.handleScreenAcess}
            giveAccessSubmit={this.giveAccessSubmit}
            searchName={searchName} 
            type={showALLUserRoles?"UPDATE":"EDIT"} 

            showEmployeeDrop={showEmployeeDrop} selectedEmployeeData={selectedEmployeeData} 
            showUserDmgLevel={showUserDmgLevel} showUserDmgValue={showUserDmgValue} 
            DMGLevelList={DMGLevelList} finalDemoList={finalDemoList} AccessObj={AccessObj} 
            AccessList={AccessList} DemographicLevels={DemographicLevels} DemographicValues={DemographicValues}
            />
            :
            null}



            {showUserModalUPDATE?
            <UserAccessModal
            handleOpenEmployeeDrop={this.handleOpenEmployeeRoleUpdate}
            addUserModal={this.addUserModal} 
            handleOpenLevel={this.handleOpenLevel}
            handleOpenValue={this.handleOpenValue}
            selectDMGLevel={this.selectDMGLevel}
            selectDMGValue={this.selectDMGValue}
            selectDMGValueAll={this.selectDMGValueAll}
            checkDMGValueAll={checkDMGValueAll}
            handleSearch={this.handleSearch}
            handleScreenAcess={this.handleScreenAcess}
            giveAccessSubmit={this.giveAccessSubmit} 
            removeAccessSubmit={this.removeAccessSubmit} 
            type={showALLUserRoles?"UPDATE":"EDIT"} 

            searchName={searchName} 

            showEmployeeDrop={showUserModalUPDATE} selectedEmployeeData={selectedEmployeeData} 
            showUserDmgLevel={showUserDmgLevel} showUserDmgValue={showUserDmgValue} 
            DMGLevelList={DMGLevelList} finalDemoList={finalDemoList} AccessObj={AccessObj} 
            AccessList={AccessList} DemographicLevels={DemographicLevels} DemographicValues={DemographicValues}
            />
            :
            null}




{/* EMPLOYEE FILTER */}
{openEmpFilterModal?
    <div className="top-0 xl:h-screen h-full  w-full flex justify-center items-center z-40 fixed" style={{background: '#0000005c'}}>
        <div className="bg-white rounded-lg  shadow-sm  xl:w-7/12  lg:w-6/12 md:w-7/12 w-11/12 ">
          <div className="flex justify-between p-4  border-b"> <h2 className="text-base font-semibold text-center test-gray-800 ">Employee Filter</h2> <span onClick={()=>this.closeEmployeeFilter(false)} className="cursor-pointer text-sm font-medium text-red-500">Close</span></div>

          <div className="flex p-1">
            <div className="pt-2 px-2 font-bold cursor-default">ADD</div>
            <div className="flex items-center justify-center lg:float-left ">
              <div className="w-full">
                <div className="py-1 ml-1 bg-white mr-1.5 lg:w-60 sm:w-full md:w-full rounded-lg border text-black flex justify-between px-4 items-center relative ">
                  <span className="pr-4 cursor-pointer w-full text-gray-500" onClick={()=>this.onEmployeeFilterDropdown(!showFilterNames)}>{formDataFilter.name?formDataFilter.name:"Choose Name"}</span> 
                  <span className="material-icons text-gray-500 cursor-pointer " onClick={()=>this.onEmployeeFilterDropdown(!showFilterNames)}>expand_more</span>
                  {showFilterNames?
                  <>
                  <div className="z-10 absolute top-10 left-0 bg-white  shadow-md rounded-md w-full text-gray-500 overflow-y-auto" style={{height:'300px'}}>
                  {getEmployeeColumnNameData && getEmployeeColumnNameData.length>0?
                    getEmployeeColumnNameData.map((item,index)=>
                    (EmployeeFilterCompanyList.findIndex(prev=>prev.name===item.name)===-1)?
                      <span key={index}  onClick={()=>this.handleEmployeeColumnSelect(item.name)} className="cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"> 
                        {item.name}</span>
                    :null
                    )
                  :null}
                  </div>
                  </>
                  :null}

                </div>
              </div>
            </div>

            {/* <div className="py-1">
              <input className="p-1 border rounded-lg px-2" placeholder="Write Label" value={formDataFilter.label} onChange={this.handleFilterInput} />
            </div> */}

            <div className="flex p-1 pl-2">
              <div className="pt-1 cursor-default ">Disable</div>
              <div className="pt-1 px-2"><input
                id="mandatory-check"
                value={formDataFilter.isDisable}
                checked={formDataFilter.isDisable}
                type="checkbox"
                onChange={()=>{}}
                className="cursor-pointer pt-2"
                onClick={()=>this.handleFilterCheck(formDataFilter.isDisable)}
              /></div>
            </div>


          <div className="pt-1.5 pl-1">
            <button onClick={()=>this.addEmployeeFilter()} className="cursor-pointer w-full border border-blue-500 bg-blue-500 text-white rounded py-1.5  text-xs  px-4">
              {"Save Filter"}
            </button> 
          </div>

        </div>


          <div className="flex flex-col mx-1 overflow-y-auto pb-4 mx-2" style={{height:'300px'}}>
              <div className="py-2 overflow-x-auto whitespace-nowrap w-full ">
                <div className="align-middle inline-block min-w-full overflow-hidden  sm:rounded-lg border border-gray-200">
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-bold text-gray-800 font-semibold tracking-wider">
                          {/* <input type="checkbox" name="list-check" className="mr-4" /> */}
                          {LanguageObj && LanguageObj["s_no_label"+"_"+CurrentLanguage]? LanguageObj["s_no_label"+"_"+CurrentLanguage]:"S No."}</th>
                        <th className=" py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-bold text-gray-800 font-semibold tracking-wider">
                        {LanguageObj && LanguageObj["name_label"+"_"+CurrentLanguage]? LanguageObj["name_label"+"_"+CurrentLanguage]:"Name"}</th>
                        {/* <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-bold text-gray-800 font-semibold tracking-wider">
                        {LanguageObj && LanguageObj["label_text"+"_"+CurrentLanguage]? LanguageObj["label_text"+"_"+CurrentLanguage]:"Label"}</th> */}
                        <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-bold text-gray-800 font-semibold tracking-wider">
                        {LanguageObj && LanguageObj["enable_label"+"_"+CurrentLanguage]? LanguageObj["enable_label"+"_"+CurrentLanguage]:"Enable"}</th>
                        <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-bold text-gray-800 font-semibold tracking-wider">
                        {LanguageObj && LanguageObj["delete_label"+"_"+CurrentLanguage]? LanguageObj["delete_label"+"_"+CurrentLanguage]:"Delete"}</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                    {getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length>0?
                        getEmployeeFilterListByCompanyIdData.map((empFilter, index)=>
                              <tr className={empFilter.isDisable?"bg-gray-100":"bg-white"}>
                                <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                                  <div className="flex items-center">
                                    {/* <div className="flex-shrink-0 ">
                                      <input type="checkbox" name />
                                    </div> */}
                                    <div className="ml-4">
                                      <div className={!empFilter.isDisable?"text-xs leading-5 text-gray-800 font-semibold font-bold":"text-xs leading-5 text-gray-400"}>{index+1}</div>
                                    </div>
                                  </div>
                                </td>
                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                  <div className={!empFilter.isDisable?"text-xs leading-5 text-gray-800 font-semibold font-bold":"text-xs leading-5 text-gray-400"}>{empFilter && empFilter.label?empFilter.label:""}</div>
                                </td> */}
                                <td className={!empFilter.isDisable?"text-xs leading-5 text-gray-800 font-semibold font-bold":"text-xs leading-5 text-gray-400"}>
                                {empFilter && empFilter.label?empFilter.label:""}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs font-normal text-gray-800 font-semibold">
                                  <div className="">
                                    <input
                                    id="mandatory1"
                                    value={!empFilter.isDisable}
                                    checked={!empFilter.isDisable}
                                    type="checkbox"
                                    onChange={()=>{}}
                                    className="cursor-pointer ml-4"
                                    onClick={()=>this.handleFilterStatus(empFilter.id)}
                                    />
                                </div>
                                </td>
                                <td className={!empFilter.isDisable?"text-xs leading-5 text-gray-800 font-semibold font-bold":"text-xs leading-5 text-gray-400"}>
                                  <span onClick={()=>this.onDeleteFilter(empFilter)} className="material-icons pl-7 cursor-pointer">delete_outline</span>
                                </td>

                              </tr>
                        ):null
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </div>






        </div>
    </div>
:
null}

</>
:
<>
{!loading?
<div className="container py-12 mx-auto px-4 h-full overflow-hidden overflow-y-auto" style={{height: 'calc(100% - 4rem)'}}>
<div className="w-full h-full flex justify-center items-center">
  {/*  text start*/}
  <div className="text-center">
    <img src="img/empty_survey.png" className="mb-4" />
    <h1 className="text-gray-500 text-xl  font-semibold cursor-default">You have no access <br/> to this module!</h1>
  </div>
</div>
</div>
:null}
</>
}



  </main>
</div>


</LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard,user } = state;
  return {
    loggingIn,
    survey,
    dashboard,
    user
  };
}
export default connect(mapStateToProps)(Employees);
