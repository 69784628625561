import React, { Component } from 'react';
import { connect } from 'react-redux';
import StackChart from './Charts/StackChart';
import StackChartDistribution from './Charts/StackChartDistribution';
import Commentary from '../../../Components/Commentary/Commentary';
import SummaryScore from './Cards/SummaryScore/SummaryScore';
import SummaryDistribution from './Cards/SummaryDistribution/SummaryDistribution';
import IndexKPI from './Components/IndexKPI';
import Filter from './Filter/Filter';
import { CSVLink } from "react-csv";

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      showLeaderBoard: false,
      isMultifilter: false,
      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      ListName:'Demographic'
    }
  }
  componentDidMount() {

  }

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ FilterValues: finalFilter , ListName:name});
    handleMultifilterIndexScore(finalFilter, true, name);

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }



  render() {
    let {

      indexType, optionType, getCommentData, loading, name, CountRestrict,

      EmpFilterData, getIndexFilterData,
      getAllBenchMarkData, getRespondantDetailsData,
      getIndexData, getIndexScore, getIndexDistributionData, cronbatchAlphaVarianceData, getDemographicIndexMultiFilterData
    } = this.props;
    let { currFilterName, FilterValues , ListName} = this.state;



    let cronBachAlpha = {
      score: 0
    }
    if (cronbatchAlphaVarianceData && cronbatchAlphaVarianceData.score && cronbatchAlphaVarianceData.score.length > 0) {
      cronBachAlpha["score"] = (typeof cronbatchAlphaVarianceData.score[0] !== 'string' || cronbatchAlphaVarianceData.score[0] instanceof Number) && cronbatchAlphaVarianceData && cronbatchAlphaVarianceData.score && cronbatchAlphaVarianceData.score[0] ? cronbatchAlphaVarianceData.score[0].toFixed(2) : ''
    }






    let Item = {
      score: 0
    };
    if (getIndexFilterData) {
      Item = {
        score: (typeof getIndexFilterData[indexType] !== 'string' || getIndexFilterData[indexType] instanceof Number) && getIndexFilterData && getIndexFilterData[indexType] ? getIndexFilterData[indexType].toFixed(2) : ''
      }
    }

    let Benchmark = {
      score: 0
    };
    if (getAllBenchMarkData) {
      Benchmark = {
        score: (typeof getAllBenchMarkData[indexType] !== 'string' || getAllBenchMarkData[indexType] instanceof Number) && getAllBenchMarkData && getAllBenchMarkData[indexType] ? getAllBenchMarkData[indexType].toFixed(2) : ''
      }
    }


    console.log('FilterValues: ', FilterValues)

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    let DemographicDataScore = [];
    let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      DemographicDataScore = getDemographicIndexMultiFilterData;
      DemographicDataDistribution = getIndexDistributionData;

    }


    const ref = React.createRef();


    // getIndexScore={getIndexScore}
    // getDemographicIndexData={DemographicDataScore}
    // currFilterName={currFilterName}
    // indexType={indexType}
    // CountRestrict={CountRestrict}

    


    // Download Excel

    let ScoreExcel = []
    let filter = FilterValues && FilterValues.length > 0 ? " ("+FilterValues.map((element)=>{return element.value}).join(",")+")":" (Filter)"

      if(getIndexScore){

        let Overall ={}
        Overall[ListName+filter] = "Overall";
        Overall["Scores"] = getIndexScore && getIndexScore[indexType]?getIndexScore[indexType].toFixed(2):"NaN";
        
        if(getIndexData){
          Overall["HighlyEngaged"] = getIndexData && getIndexData["HighlyEngaged"]?getIndexData["HighlyEngaged"].toFixed(2):0;
          Overall["ModeratelyEngaged"] = getIndexData && getIndexData["ModeratelyEngaged"]?getIndexData["ModeratelyEngaged"].toFixed(2):0;
          Overall["ActivelyDisengaged"] = getIndexData && getIndexData["ActivelyDisengaged"]?getIndexData["ActivelyDisengaged"].toFixed(2):0;
          Overall["Passive"] = getIndexData && getIndexData["Passive"]?getIndexData["Passive"].toFixed(2):0;
        }

        ScoreExcel.push(Overall);

      }
  
      if(DemographicDataScore && DemographicDataScore.length>0){
        DemographicDataScore.forEach((data)=>{
            let dataScore = CountRestrict(data.count)?"NaN":data[indexType].toFixed(2);

            let tempData = {}
            if(data && data.name){
              tempData[ListName+filter] = data.name;
              tempData["Scores"] = dataScore?dataScore.toString():"NaN";

              let getIndex = getIndexDistributionData?getIndexDistributionData.findIndex(prev=>prev.name===data.name):-1;

              if(getIndex!==-1){
                let Dist = CountRestrict(data.count)?"":getIndexDistributionData[getIndex]; 
                tempData["HighlyEngaged"] = Dist && Dist["HighlyEngaged"]?Dist["HighlyEngaged"].toFixed(2):"NaN";
                tempData["ModeratelyEngaged"] = Dist && Dist["ModeratelyEngaged"]?Dist["ModeratelyEngaged"].toFixed(2):"NaN";
                tempData["ActivelyDisengaged"] = Dist && Dist["ActivelyDisengaged"]?Dist["ActivelyDisengaged"].toFixed(2):"NaN";
                tempData["Passive"] = Dist && Dist["Passive"]?Dist["Passive"].toFixed(2):"NaN";
              }

            }

            ScoreExcel.push(tempData);


        })
      }

      console.log("------IndexSummaryScoreExcel: ScoreExcel-----------",ScoreExcel)



    // CountRestrict,getIndexDistributionData,getIndexData

    // let DistributionExcel = []
    // function IndexSummaryDistributionExcel (){

    // }






    return (
      <>
        <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        </div>

        <div className="xl:flex lg:flex md:flex justify-start items-center">

          {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}

          {/* <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



        </div>

        {FilterValues && FilterValues.length > 0 ?
          <div className=" px-4 xl:flex lg:flex md:flex justify-between items-center bg-white border-b py-3 h-10 ">
            <>
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}


              {FilterValues && FilterValues.length > 0 ?
                <>
                {ScoreExcel ?
                <CSVLink data={ScoreExcel} filename={"IndexScore.csv"}>
                <p className="font-normal text-purple-700 text-sm cursor-pointer hover:text-purple-500">
                  {"Download Excel"}
                </p>                
                </CSVLink>
                : '...'}
                </>
                :null}


              
            </>
            </div>
          : null}


        <div onClick={() => this.closeFilter(false)} className=" " >
          <div className="flex w-full" >


            <div className="bg-white w-6/12 shadow-lg p-4 m-4 mr-2 justify-center" >
              {/* <div className="font-medium text-lg">{name} Scores</div> */}
              <div className="font-medium text-lg flex justify-between items-center">{name} Scores</div>

                {FilterValues && FilterValues.length > 0 ?
                  <div className="py-4">
                    <StackChart
                      getIndexScore={getIndexScore}
                      getDemographicIndexData={DemographicDataScore}
                      currFilterName={currFilterName}
                      indexType={indexType}
                      CountRestrict={CountRestrict}
                    />
                    
                   <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>

                  </div>
                  :
                  <div className="py-4">
                    <SummaryScore cronBachAlpha={cronBachAlpha} item={Item} DistributionData={getIndexData} Benchmark={Benchmark} />
                  </div>
                  }



            </div>
            <div className="bg-white w-6/12 shadow-lg p-4 m-4 ml-2 justify-center" >
              <div className="font-medium text-lg flex justify-between items-center">
                <p>{name} Distribution</p>
                
                {FilterValues && FilterValues.length > 0 ?
                <>
                {/* {ScoreExcel ?
                <CSVLink data={ScoreExcel} filename={"IndexDistribution.csv"}>
                <p className="font-normal text-purple-700 text-sm cursor-pointer hover:text-purple-500">
                  {"Download Excel"}
                </p>                
                </CSVLink>
                : '...'} */}
                {/* <p className="font-normal text-purple-700 text-sm cursor-pointer hover:text-purple-500">
                  {"Download Excel"}
                </p> */}
                </>
                :null}
              
              
              </div>
              {FilterValues && FilterValues.length > 0 ?
                <div className="py-4">

                  <StackChartDistribution
                    getIndexData={getIndexData}
                    getIndexDistributionData={DemographicDataDistribution}
                    currFilterName={currFilterName}
                    indexType={indexType}
                    CountRestrict={CountRestrict}
                  />
                            <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>

                </div>
                :
                <div className="py-4">
                  <SummaryDistribution cronBachAlpha={cronBachAlpha} item={Item} DistributionData={getIndexData} Benchmark={Benchmark} />
                </div>
              }
            </div>

          </div>

        </div>

        <div className="px-2">
          {FilterValues && FilterValues.length > 0 ?
            null :
            <IndexKPI
              indexType={indexType}
              getIndexData={getIndexData}
              getIndexScore={getIndexScore}
              getAllBenchMarkData={getAllBenchMarkData}
              getRespondantDetailsData={getRespondantDetailsData}
            />
          }
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
