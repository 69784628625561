import {
  userConstants
} from '../_constants';

export function user(state = {}, action) {

  switch (action.type) {

    //LOGIN
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        UserLoginEmailSuccess: true,
        UserLoginSuccess: false,
        UserLoginFailure: false,
        UserEmailToken:action.user.userinfoToken.token

      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        UserLoginFailure: true


      };

    //LOGIN OTP
    case userConstants.LOGIN_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_OTP_SUCCESS:
      return {
        ...state,
        UserLoginSuccess: true,
        UserLoginEmailSuccess: false,
        UserLoginOTPFailure: false,
        UserCurrentStage:action.user.userinfo.currentStage
      };
    case userConstants.LOGIN_OTP_FAILURE:
      return {
        ...state,
        error: action.error,
        UserLoginOTPFailure: true


      };


      case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
        return {
          ...state, 
          imageUploadSuccess:true,
          filesDetails: action.uploadImage.filesDetails
        };
  
      case userConstants.FILE_UPLOAD_STATUS_FAILURE:
        return {
          ...state
        };



  
        case userConstants.LOGO_FILE_UPLOAD_STATUS_SUCCESS:
          return {
            ...state, 
            logoUploadSuccess:true,
            logo2UploadSuccess:false,
            filesDetailsLogo: action.uploadImage.filesDetailsLogo
          };
    
        case userConstants.LOGO_FILE_UPLOAD_STATUS_FAILURE:
          return {
            ...state
          };
  
      
  
        case userConstants.LOGO_FILE_UPLOAD_2_STATUS_SUCCESS:
          console.log("LOGO_FILE_UPLOAD_2_STATUS_SUCCESS",state)  
          return {
            ...state, 
            logo2UploadSuccess:true,
            logoUploadSuccess:false,
            filesDetailsLogo2: action.uploadImage.filesDetailsLogo2
          };
    
        case userConstants.LOGO_FILE_UPLOAD_2_STATUS_FAILURE:
          return {
            ...state
          };
  



    default:
      return state
  }
}