import React from 'react';

 
  export default function SingleChoice(props){
    let {  questionsList, handleLogicQuestionOrOption, crrQues, translQuestion  } = props;

    let [ showDropdown, handleDropdown ] = React.useState(false);
    let [ showCheck, handleCheck ] = React.useState(false);

    let [ showDropdownOption, handleDropdownOption ] = React.useState(false);

    let [ templateNo, selectTemplate ] = React.useState(0);
    let [ optionsList, handleOptions ] = React.useState([]);


    let NewOptionList = []
    if(templateNo===1 || templateNo===2 || templateNo===5 || templateNo===9){
        NewOptionList = optionsList;
    }
    else if(templateNo===6){
        NewOptionList = [ {"name":'1'},{"name":'2'},{"name":'3'},{"name":'4'},{"name":'5'},{"name":'6'},{"name":'7'},{"name":'8'},{"name":'9'},{"name":'10'} ]
    }

    const handleOptionSelect=(OptionName)=>{

    handleLogicQuestionOrOption(OptionName,"Options");

    }


    const handleQuestionSelect=(question)=>{
        // selectLogic(question.id);
        selectTemplate(question.templateId);

        handleLogicQuestionOrOption(question.id,"QuestionId");

        // selectQuestionId(question.id);
        handleCheck(true);

        handleOptions(question.optionsList);
        handleDropdown(!showDropdown);
        handleDropdownOption(!showDropdownOption);
    }


    let isEnable = translQuestion && translQuestion['transl'] && translQuestion['transl']["questionLogic"] && translQuestion['transl']["questionLogic"]["enable"];

    return (
      <>      
        <div className="px-4 border border-blue-400 rounded-md p-2 mt-14">
        <div className="text-blue-500">{"Logic (Question will appear when user select following question's option)"}</div>



        <div className="lg:flex justify-center ml-2 py-2">
            <div className="flex items-center justify-center lg:float-left ">

                <div className="w-full flex">
                    <span className="mb-2 font-semibold w-24">{"Enable Logic"}</span>
                    <span><input checked={isEnable}
                     onClick={()=>handleLogicQuestionOrOption(!isEnable,"EnableLogic")} onChange={()=>{}} className="mx-2 mt-2" type="checkbox" />
                    </span>
                </div>


                <div className="w-full">
                    <div className="bg-white mx-1.5 lg:w-60 sm:w-full md:w-full rounded-lg border text-black flex justify-between px-4 items-center relative py-1">
                        <span  className="text-black text-xs font-semibold pr-4 cursor-pointer w-full" onClick={()=>{
                            if(isEnable){
                                handleDropdown(!showDropdown);
                            }
                        }}>{'Select Question'}</span> 
                        <span className="material-icons text-gray-500 cursor-pointer" onClick={()=>{
                            if(isEnable){
                                handleDropdown(!showDropdown);
                            }
                        }}>expand_more</span>
                        {showDropdown?
                        <>
                        <div className="z-10 absolute top-10 left-0 bg-white  shadow-md rounded-md w-full text-gray-500 overflow-y-auto" style={{ height:'400px' }} >
                        {/* <span onClick={()=>handleParameterQuestion({id:"",name:""}, question.id, 1)}
                        className="cursor-pointer inline-flex items-center py-3 px-4 
                        text-xs w-full border-b hover:bg-gray-100">{'None'}</span> */}


                        {questionsList && questionsList.length>0?
                            questionsList.map((question,index)=>((question.id !== crrQues.id) &&  (question.templateId!==4) && (question.templateId!==3) && (question.templateId!==10) && (question.templateId!==13) && (question.templateId!==14))?
                            
                            <>
                            <span key={index}  
                                onClick={()=>handleQuestionSelect(question)} 
                                className=
                                {translQuestion && translQuestion['transl'] && translQuestion['transl']["questionLogic"] && translQuestion['transl']["questionLogic"]["id"] && (translQuestion['transl']["questionLogic"]["id"]===question.id)?
                                "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b bg-blue-500 text-white"
                                :
                                "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"
                                }
                                > 
                                {question.name}
                            </span>
                            </>

                            :null ):null}


                        </div>
                        </>
                        :null}

                    </div>
                </div>





                <div className="w-full">
                    {showCheck?
                    <div className="bg-white mx-1.5 lg:w-60 sm:w-full md:w-full rounded-lg border text-black flex justify-between px-4 items-center relative py-1" >
                        <span className="text-black text-xs font-semibold pr-4 cursor-pointer w-full" onClick={()=>handleDropdownOption(!showDropdownOption)}>{'Select Answers'}</span> 
                        <span className="material-icons text-gray-500 cursor-pointer" onClick={()=>handleDropdownOption(!showDropdownOption)}>expand_more</span>
                        {showDropdownOption?
                        <>
                        <div className="z-10 absolute top-10 left-0 bg-white  shadow-md rounded-md w-full text-gray-500 overflow-y-hidden">
                        {/* <span onClick={()=>handleParameterQuestion({id:"",name:""}, question.id, 1)}
                        className="cursor-pointer inline-flex items-center py-3 px-4 
                        text-xs w-full border-b hover:bg-gray-100">{'None'}</span> */}


                        {NewOptionList && NewOptionList.length>0?
                            NewOptionList.map((option,index)=>
                            <>
                            <span key={index}  
                                onClick={()=>handleOptionSelect(option.name)} 
                                className={
                                    translQuestion && translQuestion['transl'] && translQuestion['transl']["questionLogic"] && translQuestion['transl']["questionLogic"]["answers"].some(prev=>prev===option.name)?
                                    "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b bg-blue-500 text-white"
                                    :
                                    "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}
                                    > 
                                {option.name}
                            </span>
                            </>

                            ):null}


                        </div>
                        </>
                        :null}

                    </div>
                    :null}
                </div>




            </div>
        </div>
      
      
      
        </div>
      </>
    );
  }