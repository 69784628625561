import {
  questionsConstants
} from '../_constants';

export function questions(state = {}, action) {

  switch (action.type) {


      case questionsConstants.QUESTIONS_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case questionsConstants.QUESTIONS_GETALL_SUCCESS:
        return {
          ...state,
          loading: false,
          questionsList: action.questions.questionsList,
          answersList: action.questions.answersList,
          surveyScreenData: action.questions.surveyScreenData
        };
      case questionsConstants.QUESTIONS_GETALL_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };



    case questionsConstants.ADD_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true

      };
    case questionsConstants.ADD_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        addQuestionsSuccess: true,
        createQuestion:action.questions.createQuestion
      };
    case questionsConstants.ADD_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      case questionsConstants.DELETE_QUESTIONS_REQUEST:
        return {
          ...state,
          loading: true

        };
      case questionsConstants.DELETE_QUESTIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          deleteQuestionsSuccess: true
        };
      case questionsConstants.DELETE_QUESTIONS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };

    case questionsConstants.UPDATE_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true

      };
    case questionsConstants.UPDATE_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateQuestionsSuccess: true
        // questionsList: action.questions.questionsList,
        // answersList: action.questions.answersList
      };
    case questionsConstants.UPDATE_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      case questionsConstants.DELETE_QUESTIONOPTION_REQUEST:
        return {
          ...state,
          loading: true
        };
      case questionsConstants.DELETE_QUESTIONOPTION_SUCCESS:
        return {
          ...state,
          loading: false,
          deleteQuestionOption: true
        };
      case questionsConstants.DELETE_QUESTIONOPTION_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };

      case questionsConstants.ANSWERS_SUBMIT_REQUEST:
        return {
          ...state,
          loading: true
        };
      case questionsConstants.ANSWERS_SUBMIT_SUCCESS:
        return {
          ...state,
          loading: false,
          answerSubmitSuccess: true
        };
      case questionsConstants.ANSWERS_SUBMIT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };



        case questionsConstants.UPDATE_QUESTIONS_RANK_REQUEST:
          return {
            ...state,
            loading: true
          };
        case questionsConstants.UPDATE_QUESTIONS_RANK_SUCCESS:
          return {
            ...state,
            loading: false,
            updateQuestionRankSuccess: true
          };
        case questionsConstants.UPDATE_QUESTIONS_RANK_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
        


          case questionsConstants.COPY_QUES_LIBRARY_REQUEST:
            return {
              ...state,
              loading: true
            };
          case questionsConstants.COPY_QUES_LIBRARY_SUCCESS:
            return {
              ...state,
              loading: false,
              copyFromQuestionLibrarySuccess: true,
              copyFromQuestionLibraryData: action.questions.copyFromQuestionLibraryData,
            };
          case questionsConstants.COPY_QUES_LIBRARY_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };



            case questionsConstants.TRANSLATE_QUESTION_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.TRANSLATE_QUESTION_SUCCESS:
              return {
                ...state,
                loading: false,
                translateQuestionSuccess: true,
                translateQuestionData: action.questions.translateQuestionData,
              };
            case questionsConstants.TRANSLATE_QUESTION_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };
  

            case questionsConstants.TRANSLATE_MANY_QUESTION_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.TRANSLATE_MANY_QUESTION_SUCCESS:
              return {
                ...state,
                loading: false,
                translateManyQuestionSuccess: true,
              };
            case questionsConstants.TRANSLATE_MANY_QUESTION_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };



            case questionsConstants.TRANSLATE_SURVEY_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.TRANSLATE_SURVEY_SUCCESS:
              return {
                ...state,
                loading: false,
                translateSurveySuccess: true,
                translateSurveyData: action.questions.translateSurveyData,
              };
            case questionsConstants.TRANSLATE_SURVEY_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };
    

          // case questionsConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST:
          //   return {
          //     ...state,
          //     loading: true
          //   };
          // case questionsConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS:
          //   return {
          //     ...state,  
          //     addParameterSuccess: false,      
          //     updateParameterSuccess: false,
     
          //     getParameterListbyParentId: action.questions.getParameterListbyParentId
          //   };
          // case questionsConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE:
          //   return {
          //     ...state,
          //     error: action.error
          //   };







    default:
      return state
  }
}