import React, { Component } from 'react';
import { connect } from 'react-redux';
import BoxPlot from './Charts/BoxPlot';
import Commentary from '../../../Components/Commentary/Commentary';
import Filter from './Filter/Filter';

class DriverComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue:'none',
      currFilterName:'none',

      currentSelectValue:'',
      FilterValues:[],
      showFilter:false,
      showValue:''
    }
  }
  componentDidMount() {

  }

  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }

  handleMultifilterIndexScore2=(finalFilter)=>{
    let {handleMultiFilterQuantile}= this.props;
    this.setState({FilterValues:finalFilter});
    handleMultiFilterQuantile(finalFilter);

  }

  render() {
    let { selectedFilterValue, loading,CountRestrict,getIndexQuantileCount,
      indexType, optionType,getCommentData, 

      getQuestionData, getQuestionFilterData,handleQuantileFilter, EmpFilterData,getIndexFilterData, getIndexQuantileData
    
    } = this.props;

    let { currentSelectValue, FilterValues }  = this.state;


    let EmpFilterData2 = []
    if(EmpFilterData && EmpFilterData.length>0){
     EmpFilterData.forEach((emp)=>{
       if(emp.name !=='ManagerPID'){
         EmpFilterData2.push(emp);
       }
     })
    }
 
    let isDisable  = true;
   //  let DemographicDataScore = [];
   //  let DemographicDataDistribution = [];
 
    if(FilterValues && FilterValues.length>0){
 
      if(isDisable){
         FilterValues.forEach((item)=>{
             if(item.value==='All'){
               isDisable = false
             }
         });
      }
 
 
     //  DemographicDataScore=getDemographicIndexMultiFilterData;
     //  DemographicDataDistribution=getIndexDistributionData;
 
    }

    let {GetParameterLabel, XAxis, name}= this.props;

    console.log('getIndexQuantileData----->',getIndexQuantileData)



    let IndexQuantile = CountRestrict(getIndexQuantileCount)?"NaN":getIndexQuantileData;



    return (
      <>
      <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
        <Filter
          showFilter={this.state.showFilter}
          showValue={this.state.showValue}
          closeFilter={this.closeFilter}

          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

      </div>



      {true?
      <>
 
        <div className="xl:flex lg:flex md:flex justify-start items-center">

        {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



      </div>

      {FilterValues && FilterValues.length > 0 ?
          <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null}





        <div onClick={()=>this.closeFilter(false)}  className=" " >
              <div className="flex w-full" >
                

      <div className="bg-white rounded w-full shadow-lg px-4 m-4">
      <div className="text-lg font-semibold py-4">{name} Behaviour-Range</div>
        <BoxPlot getIndexQuantileData={IndexQuantile} GetParameterLabel={GetParameterLabel} XAxis={XAxis} name={name}/>
      </div>
  </div>
</div>
</>
:
<>
{!loading?
<div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
:null}
</>
}
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverComparision);
