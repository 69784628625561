import { surveyConstants } from '../_constants';
import { surveyService } from '../_services';

import { questionsActions } from '../_actions';
import { dashboardActions } from './dashboard.actions';
import { dashboard } from '../_reducers/dashboard.reducer';

export const surveyActions = {
    getSurveyList,
    getQuestionTypeList,
    getSurveyById,

    getParameterList,
    getParameterListbyParentId,

    updateSurveyWelcome,

    setExpireSurvey,

    createSurvey,
    deleteSurvey,
    updateSurvey,


    responseAnalysisBySurveyId,
    getCampaignEmployeesByIDSurveyWise,

    resendCampain,



    createSurveyFromLib,
    getSurveyFromLib,


    getEmployeeList,
    getEmployeeBatchRange,
    getCustomerBatchRange,
    saveEmployeeList,
    updateEmployeeStatus,

    getLanguageLabel,
    getLanguage,

    getEmployeeColumnName,
    getEmployeeFilterListByCompanyId,
    createEmployeeFilter,
    updateEmployeeFilterStatus,
    deleteEmployeeFilter,
    updateEmployeeFilterRank,

    assignRole,
    updateEmployee,

    getQuestionLibraryList,
    getAllFilterValues,

    getAssignedRoles,
    getEmployeeSearch,
    getIndexByFilterDMG,

    getResponseAnalyticsPagination,
    removeRole,
    updateSurveyDesign,
    getAllResponseAnalyticsBySurveyId,

    customerResponsePaginationByCampaignId,
    customerResponseDaywiseByCampaignId
    
};

function getSurveyList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyList(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.SURVEY_GETALL_REQUEST } }
    function success(survey) { return { type: surveyConstants.SURVEY_GETALL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SURVEY_GETALL_FAILURE, error } }
}

function getQuestionTypeList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getQuestionTypeList(data)
            .then(
                survey => dispatch(success(survey)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.QUESTIONTYPE_GETALL_REQUEST } }
    function success(survey) { return { type: surveyConstants.QUESTIONTYPE_GETALL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.QUESTIONTYPE_GETALL_FAILURE, error } }
}

function getSurveyById(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyById(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_DATA_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_DATA_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_DATA_FAILURE, error } }
}


function getParameterList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getParameterList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.PARAMETERLIST_GETALL_REQUEST } }
    function success(survey) { return { type: surveyConstants.PARAMETERLIST_GETALL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.PARAMETERLIST_GETALL_FAILURE, error } }
}


function updateSurveyWelcome(data,props, temp) {

    return dispatch => {
        dispatch(request());
        surveyService.updateSurveyWelcome(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    props.dispatch(questionsActions.getQuestionListBySurveyID(temp));
                    dispatch(this.getSurveyById(temp));

                    // dispatch(this.getSurveyById(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.WELCOME_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.WELCOME_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.WELCOME_SURVEY_FAILURE, error } }
}

function getParameterListbyParentId(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getParameterListbyParentId(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST } }
    function success(survey) { return { type: surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE, error } }
}

function setExpireSurvey(data,surveyId) {
    return dispatch => {
        dispatch(request());
        surveyService.setExpireSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyById({surveyId:surveyId}));

                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.SET_EXPIRY_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.SET_EXPIRY_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SET_EXPIRY_SURVEY_FAILURE, error } }
}


function createSurvey(data,tempdata,check,packageList,props) {
    return dispatch => {
        dispatch(request());
        surveyService.createSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    if(check){
                        dispatch(this.getSurveyList(tempdata));
                        if(survey && survey.createSurveyData && survey.createSurveyData.id ){
                            props.history.push(`/app/question/${survey.createSurveyData.id}`);
                        }
                    }
                    else{
                        if(survey && survey.createSurveyData && survey.createSurveyData.id ){
                            let reqData = {
                                "surveyId": survey.createSurveyData.id,
                                "surveyLib": packageList
                              } 
                              console.log("--->>>>999 __>",reqData);
                              dispatch(this.createSurveyFromLib(reqData, tempdata,props));
                        }
                    }
  
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.CREATE_NEW_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.CREATE_NEW_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CREATE_NEW_SURVEY_FAILURE, error } }
}

function deleteSurvey(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.deleteSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyList(tempdata));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.DELETE_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.DELETE_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.DELETE_SURVEY_FAILURE, error } }
}


function updateSurvey(data,tempdata) {
    return dispatch => {
        dispatch(request());
        surveyService.updateSurvey(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyById(tempdata));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_SURVEY_FAILURE, error } }
}


function responseAnalysisBySurveyId(data) {
    return dispatch => {
        dispatch(request());
        surveyService.responseAnalysisBySurveyId(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.RESPONSE_ANALYTICS_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.RESPONSE_ANALYTICS_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.RESPONSE_ANALYTICS_SURVEY_FAILURE, error } }
}



function getCampaignEmployeesByIDSurveyWise(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getCampaignEmployeesByIDSurveyWise(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_FAILURE, error } }
}

function resendCampain(data) {
    return dispatch => {
        dispatch(request());
        surveyService.resendCampain(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.RESEND_CAMPAIGN_REQUEST } }
    function success(survey) { return { type: surveyConstants.RESEND_CAMPAIGN_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.RESEND_CAMPAIGN_FAILURE, error } }
}



function createSurveyFromLib(data, tempData,props) {
    return dispatch => {
        dispatch(request());
        surveyService.createSurveyFromLib(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getSurveyList(tempData))
                    props.history.push(`/app/question/${data.surveyId}`);

                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.CREATE_SURVEY_FROM_LIB_REQUEST } }
    function success(survey) { return { type: surveyConstants.CREATE_SURVEY_FROM_LIB_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CREATE_SURVEY_FROM_LIB_FAILURE, error } }
}



function getSurveyFromLib(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getSurveyFromLib(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_SURVEY_FROM_LIB_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_SURVEY_FROM_LIB_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_SURVEY_FROM_LIB_FAILURE, error } }
}


function getEmployeeList(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_LIST_FAILURE, error } }
}

function getEmployeeSearch(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeSearch(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_SEARCH_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_SEARCH_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_SEARCH_FAILURE, error } }
}


function getEmployeeBatchRange(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeBatchRange(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_BATCH_RANGE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_BATCH_RANGE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_BATCH_RANGE_FAILURE, error } }
}
function getCustomerBatchRange(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getCustomerBatchRange(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_CUSTOMER_BATCH_RANGE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_CUSTOMER_BATCH_RANGE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_CUSTOMER_BATCH_RANGE_FAILURE, error } }
}


function saveEmployeeList(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.saveEmployeeList(data)
            .then(
                survey => {
                    dispatch(success(survey));
                    dispatch(this.getEmployeeList(temp));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.SAVE_EMPLOYEE_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.SAVE_EMPLOYEE_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.SAVE_EMPLOYEE_LIST_FAILURE, error } }
}






function updateEmployeeStatus(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeStatus(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_STATUS_FAILURE, error } }
}


function getLanguageLabel(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getLanguageLabel(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_ALL_LANGUGAGE_LABEL_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_ALL_LANGUGAGE_LABEL_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_ALL_LANGUGAGE_LABEL_FAILURE, error } }
}




function getLanguage(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getLanguage(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: surveyConstants.GET_ALL_LANGUGAGE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_ALL_LANGUGAGE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_ALL_LANGUGAGE_FAILURE, error } }
}



function getEmployeeColumnName(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeColumnName(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_COLUMN_NAME_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_COLUMN_NAME_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_COLUMN_NAME_FAILURE, error } }
}


function getEmployeeFilterListByCompanyId(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getEmployeeFilterListByCompanyId(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_EMPLOYEE_FILTER_LIST_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_EMPLOYEE_FILTER_LIST_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_EMPLOYEE_FILTER_LIST_FAILURE, error } }
}


function createEmployeeFilter(data) {
    return dispatch => {
        dispatch(request());
        surveyService.createEmployeeFilter(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.CREATE_EMPLOYEE_FILTER_REQUEST } }
    function success(survey) { return { type: surveyConstants.CREATE_EMPLOYEE_FILTER_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.CREATE_EMPLOYEE_FILTER_FAILURE, error } }
}

function updateEmployeeFilterRank(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeFilterRank(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_FAILURE, error } }
}

function updateEmployeeFilterStatus(data) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployeeFilterStatus(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE, error } }
}


function deleteEmployeeFilter (data) {
    return dispatch => {
        dispatch(request());
        surveyService.deleteEmployeeFilter(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.DELETE_EMPLOYEE_FILTER_REQUEST } }
    function success(survey) { return { type: surveyConstants.DELETE_EMPLOYEE_FILTER_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.DELETE_EMPLOYEE_FILTER_FAILURE, error } }
}


function assignRole(data,tempData) {
    return dispatch => {
        dispatch(request());
        surveyService.assignRole(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getAssignedRoles(tempData));
                    
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.USER_ASSIGN_ROLE_REQUEST } }
    function success(survey) { return { type: surveyConstants.USER_ASSIGN_ROLE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.USER_ASSIGN_ROLE_FAILURE, error } }
}


function updateEmployee(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.updateEmployee(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeList(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_EMPLOYEE_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_EMPLOYEE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_EMPLOYEE_FAILURE, error } }
}




function getQuestionLibraryList(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.getQuestionLibraryList(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeList(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.QUESTION_LIBRARY_REQUEST } }
    function success(survey) { return { type: surveyConstants.QUESTION_LIBRARY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.QUESTION_LIBRARY_FAILURE, error } }
}


function getAllFilterValues(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.getAllFilterValues(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getEmployeeList(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.ALL_FILTER_VALUES_REQUEST } }
    function success(survey) { return { type: surveyConstants.ALL_FILTER_VALUES_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.ALL_FILTER_VALUES_FAILURE, error } }
}


function getAssignedRoles(data,temp) {
    return dispatch => {
        dispatch(request());
        surveyService.getAssignedRoles(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_ASSIGNED_ROLES_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_ASSIGNED_ROLES_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_ASSIGNED_ROLES_FAILURE, error } }
}



function getIndexByFilterDMG(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getIndexByFilterDMG(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_INDEX_BY_FILTER_DMG_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_INDEX_BY_FILTER_DMG_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_INDEX_BY_FILTER_DMG_FAILURE, error } }
}


function getResponseAnalyticsPagination(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getResponseAnalyticsPagination(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_FAILURE, error } }
}


function removeRole(data) {
    return dispatch => {
        dispatch(request());
        surveyService.removeRole(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.REMOVE_USER_ROLE_REQUEST } }
    function success(survey) { return { type: surveyConstants.REMOVE_USER_ROLE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.REMOVE_USER_ROLE_FAILURE, error } }
}


function updateSurveyDesign(data, temp) {
    return dispatch => {
        dispatch(request());
        surveyService.updateSurveyDesign(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                    dispatch(this.getSurveyById(temp));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.UPDATE_SURVEY_DESIGN_REQUEST } }
    function success(survey) { return { type: surveyConstants.UPDATE_SURVEY_DESIGN_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.UPDATE_SURVEY_DESIGN_FAILURE, error } }
}



function getAllResponseAnalyticsBySurveyId(data) {
    return dispatch => {
        dispatch(request());
        surveyService.getAllResponseAnalyticsBySurveyId(data)
            .then(
                survey => {
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_FAILURE, error } }
}


function customerResponsePaginationByCampaignId(data) {
    let obj = {
        customerResponsePaginationByCampaignIdData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        surveyService.customerResponsePaginationByCampaignId(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_FAILURE, error } }
}


function customerResponseDaywiseByCampaignId(data) {
    let obj = {
        customerResponseDaywiseByCampaignIdData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        surveyService.customerResponseDaywiseByCampaignId(data)
            .then(
                survey => {
                    console.log(survey)
                    dispatch(success(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: surveyConstants.GET_CUSTOMER_RESPONSE_DAYWISE_REQUEST } }
    function success(survey) { return { type: surveyConstants.GET_CUSTOMER_RESPONSE_DAYWISE_SUCCESS, survey } }
    function failure(error) { return { type: surveyConstants.GET_CUSTOMER_RESPONSE_DAYWISE_FAILURE, error } }
}
