import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeatMap from './Charts/HeatMap';
import IndexCards from './Charts/IndexCards';


import IndexComponent from './Components/IndexComponent/IndexComponent';
import Commentary from '../Index/Components/Commentary/Commentary';


import ReportModal from './Components/ReportModal/ReportModal';


class ManagerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        managerDetailsIndexType:0,
        showManagerList:true,

        mgrIndexA:0,
        mgrIndexB:8,
        CurrentManagerName:'',
        CurrentManagerId:'',
        dynamicList:[],

        optionTypeM:101,

        CurrentManagerData:'',

        showCircle:true,

        crrManagersList:[],


        crrManagerName:'none',
        crrMangerId:'',
        showMultiple:false,

        showManagerReportModal:false,
        AllSelected:false,

        managerSearchName:'',
        DimensionList:[
          {
            index:1,
            name:'Happiness',
            cutOff:59
          },
          {
            index:2,
            name:'Engagement',
            cutOff:78
  
          },
          {
            index:4,
            name:'Stress',
            cutOff:28
  
          },
          {
            index:5,
            name:'Manager',
            cutOff:50
          },
          // {
          //   index:6,
          //   name:'Leader',
          //   cutOff:50
          // },
          // {
          //   index:8,
          //   name:'OrgCore',
          //   cutOff:59
          // },
        ],
        DemographicRestriction:["ManagerPID","Department","Function","Business"]
    }
  }

  componentDidMount() {

  }


  handleViewType=(data)=>{
    this.setState({showCircle:data});

  }

  goPrev=()=>{
    let { getDemographicIndexForManagerData } = this.props;
    let { mgrIndexA, mgrIndexB } = this.state;
    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      if(mgrIndexA>0){
        this.setState({mgrIndexA:mgrIndexA-1,mgrIndexB:mgrIndexB-1})
      }

    }
    
  }
  goNext=()=>{
    let { getDemographicIndexForManagerData } = this.props;
    let { mgrIndexA, mgrIndexB } = this.state;
    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      if(mgrIndexB<getDemographicIndexForManagerData.length-1){
        this.setState({mgrIndexA:mgrIndexA+1,mgrIndexB:mgrIndexB+1})
        
      }
    }
  }


  SelectManager=(data,ManagerName1)=>{
    let { handleManagerHeatmap, EmpFilterData } = this.props;
    let { optionTypeM, DemographicRestriction } = this.state;

    // this.setState({CurrentManagerName:data.name, CurrentManagerId:data.ManagerPID, CurrentManagerData:data})
    this.setState({dynamicList:[]})
    // DynaObj=[]


    if(EmpFilterData && EmpFilterData.length>0){
      // handleManagerHeatmap({
      //   level:'ManagerPID',
      //   value:data['ID']
      // });
       EmpFilterData.forEach((filter)=>{
        if(DemographicRestriction.findIndex(prev=>prev===filter.name)!==-1){

        if(data && data[filter.name]){
          let filterdata = {
            level:filter.name,
            value:data[filter&&filter.name==="ManagerPID"?"id":filter.name]
          }
          // console.log('filterdata: ',filterdata);
          handleManagerHeatmap(filterdata);
        }
      }

      })
    }



      if(optionTypeM!==101){
        // if(optionTypeM==1){
          // this.handleOption(optionTypeM,data.ManagerPID,data, ManagerName1);
        // }
      }

  }

  handleOption=(optionTypeM,managerPID,managerData, ManagerName1)=>{
    let {crrManagersList,DemographicRestriction} = this.state;
    this.setState({optionTypeM:optionTypeM});
    

    if(true){
      if(crrManagersList.findIndex(prev=>prev===managerData)===-1){
        crrManagersList.push(managerData);
        this.setState({crrManagersList});
      }
    }


    this.setState({CurrentManagerId:managerPID, CurrentManagerData:managerData, CurrentManagerName: ManagerName1})
    this.setState({showViewBy:false});


    let { handleManagerQuestion,handleManagerDashboardCall,handleMultiManagerWiseDriver,getDemographicIndexForManagerData, handleManagerWiseDriver,  EmpFilterData,handleManagerListIndexQuestionCall, QuesDynaObj, ManagerListQuestionDataObj  } = this.props;

    

      if(optionTypeM!==101){
      let {crrManagersList}= this.state;

      if(crrManagersList && crrManagersList.length>0){
   
            if(crrManagersList.length===1){

                this.setState({showMultiple:false});

                if(EmpFilterData && EmpFilterData.length>0){
                  // handleManagerQuestion({
                  //   template:optionTypeM,
                  //   filters:[
                  //     {
                  //       "level":"ManagerPID",
                  //       "value":crrManagersList[0]["id"]
                  //     }
                  //   ]
                  // });
                  EmpFilterData.forEach((emp)=>{
                    if(DemographicRestriction.findIndex(prev=>prev===emp.name)!==-1){

                    let data = {
                      template:optionTypeM,
                      filters:[
                        {
                          "level":emp.name,
                          "value":crrManagersList[0][emp&&emp.name==="ManagerPID"?"id":emp.name]
                        }
                      ]
                    }
                    // console.log('///////////////////////////////////---------------------------------:data',data)
                    handleManagerQuestion(data);
                  }
                  })
                }

                for(let i=0;i<2;i++){
                  if(EmpFilterData && EmpFilterData.length>0){
                    // handleManagerWiseDriver(optionTypeM,crrManagersList[0].id,"ManagerPID");
                    EmpFilterData.forEach((emp)=>{
                      if(DemographicRestriction.findIndex(prev=>prev===emp.name)!==-1){

                      handleManagerWiseDriver(optionTypeM,crrManagersList[0].id,emp.name);
                      }
                    })
                  }
                }


            }
            else{
              this.setState({showMultiple:true});

              for(let i=0;i<2;i++){

                    if(crrManagersList && crrManagersList.length>0){
                      crrManagersList.forEach((mngr)=>{

                        handleManagerListIndexQuestionCall(optionTypeM,mngr);

                        handleMultiManagerWiseDriver(optionTypeM,mngr);

                      });
                    }
              }

            }

    

                      }       
                      else{
                        // console.log('testing---getDemographicIndexForManagerData',getDemographicIndexForManagerData)
                        if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
                          // console.log('testing---getDemographicIndexForManagerData',getDemographicIndexForManagerData)
                          if(EmpFilterData && EmpFilterData.length>0){
                            handleManagerQuestion({
                              template:optionTypeM,
                              filters:[
                                {
                                  "level":"ManagerPID",
                                  "value":getDemographicIndexForManagerData[0]["ManagerPID"]
                                }
                              ]
                            });
                            EmpFilterData.forEach((emp)=>{
                              if(DemographicRestriction.findIndex(prev=>prev===emp.name)!==-1){

                              let data = {
                                template:optionTypeM,
                                filters:[
                                  {
                                    "level":emp.name,
                                    "value":getDemographicIndexForManagerData[0][emp.name]
                                  }
                                ]
                              }
                              handleManagerQuestion(data);
                              // console.log('testing---handleManagerQuestion',data)
                              // console.log('testing---getDemographicIndexForManagerData',getDemographicIndexForManagerData)

                            }
                            })
                          }

                          for(let i=0;i<2;i++){
                            if(EmpFilterData && EmpFilterData.length>0){
                              handleManagerWiseDriver(optionTypeM,getDemographicIndexForManagerData[0].id,'ManagerPID');
                              EmpFilterData.forEach((emp)=>{
                                if(DemographicRestriction.findIndex(prev=>prev===emp.name)!==-1){

                                  handleManagerWiseDriver(optionTypeM,getDemographicIndexForManagerData[0].id,emp.name);
                                
                                }
                              })
                            }
                          }

                        }
                      }

      }
      else{
        if(crrManagersList && crrManagersList.length>0){

          if(crrManagersList.length===1){
            this.SelectManager(crrManagersList[0],crrManagersList[0].name);
          }
          else{
            crrManagersList.forEach((mngr)=>{
              handleManagerDashboardCall(mngr);
            });
          }
    
        }
      }






  }


  handleManagerQuestion=(optionType)=>{
    let {CurrentManagerId,CurrentManagerData} = this.state;
    this.handleOption(optionType,CurrentManagerId,CurrentManagerData)
  }



  handleManagerDriver=(Mindextype)=>{
    let { handleManagerWiseDriver, EmpFilterData  } = this.props;

    let { CurrentManagerId, DemographicRestriction } = this.state;

    if(EmpFilterData && EmpFilterData.length>0){
      handleManagerWiseDriver(Mindextype,CurrentManagerId,"ManagerPID");
      EmpFilterData.forEach((emp)=>{
        if(DemographicRestriction.findIndex(prev=>prev===emp.name)!==-1){
        handleManagerWiseDriver(Mindextype,CurrentManagerId,emp.name);
        }
      })
    }


  }

  openDropdown=(check)=>{
    this.setState({showViewBy:!check});
  }



  handleManagerALLSelection=()=>{
    let { AllSelected,crrManagersList } = this.state;
    if(AllSelected){
      this.setState({crrManagersList:[]});
      this.setState({AllSelected:false});

    }
    else{
      let { dashboard } = this.props;
      let { getDemographicIndexForManagerData } = dashboard;
      if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
        getDemographicIndexForManagerData.forEach((mngr)=>{
          crrManagersList.push(mngr);
        });
      }
      this.setState({crrManagersList,AllSelected:true});
    }


    let defaultMngr = [(this.prop.dashboard && this.prop.dashboard.sgetDemographicIndexForManagerData && this.prop.dashboard.getDemographicIndexForManagerData.length>0)?this.prop.dashboard.getDemographicIndexForManagerData[0]:{}]

  }

  


  handleManagerSelection=(mngr, isCheck)=>{
    let {crrManagersList } = this.state;
    this.setState({AllSelected:false});

    let getIndex = crrManagersList.findIndex(prev=>prev.emp_id===mngr.emp_id);
    if(getIndex===-1){
      crrManagersList.push(mngr);

    }
    else{
      crrManagersList.splice(getIndex,1);
    }
    this.setState({crrManagersList});

    if(isCheck){
      this.setState({showViewBy:false});
    }

  }

  handleManagersCall=(optionTypeM)=>{
    let { crrManagersList, DemographicRestriction} = this.state;
    if(crrManagersList && crrManagersList.length>1){
      this.setState({showMultiple:true});
    }
    else{
      this.setState({showMultiple:false});
    }
    let { handleManagerDashboardCall } = this.props;
    let { handleManagerQuestion,handleMultiManagerWiseDriver, handleManagerWiseDriver,  EmpFilterData,handleManagerListIndexQuestionCall, QuesDynaObj  } = this.props;
    




    if(optionTypeM !== 101){
      if(crrManagersList && crrManagersList.length>0){
          if(crrManagersList.length===1){

                        if(EmpFilterData && EmpFilterData.length>0){
                          // handleManagerQuestion({
                          //   template:optionTypeM,
                          //   filters:[
                          //     {
                          //       "level":"ManagerPID",
                          //       "value":crrManagersList[0]["id"]
                          //     }
                          //   ]
                          // });
                          EmpFilterData.forEach((emp)=>{
                            if(DemographicRestriction.findIndex(prev=>prev===emp.name)!==-1){

                            let data = {
                              template:optionTypeM,
                              filters:[
                                {
                                  "level":emp.name,
                                  "value":crrManagersList[0][emp&&emp.name==="ManagerPID"?"id":emp.name]
                                }
                              ]
                            }
                            handleManagerQuestion(data);
                          }
                          })
                        }

                        for(let i=0;i<2;i++){
                          if(EmpFilterData && EmpFilterData.length>0){
                            handleManagerWiseDriver(optionTypeM,crrManagersList[0].id,"ManagerPID");
                            EmpFilterData.forEach((emp)=>{
                              if(DemographicRestriction.findIndex(prev=>prev===emp.name)!==-1){

                              handleManagerWiseDriver(optionTypeM,crrManagersList[0].id,emp.name);
                              }
                            })
                          }
                        }

                        this.setState({showMultiple:false,CurrentManagerData:crrManagersList[0]});

          }
          else{
            this.setState({showMultiple:true});
            for(let i=0;i<2;i++){

              if(crrManagersList && crrManagersList.length>0){
                crrManagersList.forEach((mngr)=>{

                  handleManagerListIndexQuestionCall(optionTypeM,mngr);

                  handleMultiManagerWiseDriver(optionTypeM,mngr);

                });
              }
            }


          }

      }

  }
  else{
    if(crrManagersList && crrManagersList.length>0){

      if(crrManagersList.length===1){
        this.SelectManager(crrManagersList[0],crrManagersList[0].name);
      }
      else{
        crrManagersList.forEach((mngr)=>{
          handleManagerDashboardCall(mngr);
        });
      }

    }
  }















    this.setState({showViewBy:false});

  }


  onManagerReportModal=(value)=>{
    this.setState({showManagerReportModal:value, optionTypeM:101});
  }


  getReportMngrQuestion=(mngr, template)=>{
    let {EmpFilterData, handleManagerQuestion} = this.props;
    let { DemographicRestriction } = this.state;
    if(EmpFilterData && EmpFilterData.length>0){
      // handleManagerQuestion({
      //   template:template,
      //   filters:[
      //     {
      //       "level":"ManagerPID",
      //       "value":mngr["ManagerPID"]
      //     }
      //   ]
      // });
      EmpFilterData.forEach((emp)=>{
        if(DemographicRestriction.findIndex(prev=>prev===emp.name)!==-1){

        let data = {
          template:template,
          filters:[
            {
              "level":emp.name,
              "value":mngr[emp&&emp.name==="ManagerPID"?"id":emp.name].toString()
            }
          ]
        }
        handleManagerQuestion(data);
      }
      });
    }
  }

  getReportMngrDriver=(mngr, template)=>{
    let {EmpFilterData,handleManagerWiseDriver} = this.props;
    let { DemographicRestriction } = this.state;
    
    if(EmpFilterData && EmpFilterData.length>0){
      EmpFilterData.forEach((emp)=>{
        if(DemographicRestriction.findIndex(prev=>prev===emp.name)!==-1){

        let filters = {
          "level":emp.name,
          "value":mngr[emp&&emp.name==="ManagerPID"?"id":emp.name].toString()
        }
        handleManagerWiseDriver(template,mngr.id,emp.name,filters, true);
      }
      });
    }
  }


  handleSearchManager=(e)=>{
    this.setState({managerSearchName:e.target.value});
  }

  OrgLevelDriverScore=()=>{
    let { optionTypeM } = this.state;
    let { IndexDriverScore } = this.props;

    if(optionTypeM!==101){
      IndexDriverScore(optionTypeM);
    }

  }

  render() {
    let { 
      onLoadManagerReport,


      getHappinessQuestionData,
      getHappinessManagerQuestionData,
      getHappinessDMGQuestionData,
      getDemographicIndexHappinessData,
      
      getEngagementQuestionData,
      getEngagementManagerQuestionData,
      getEngagementDMGQuestionData,
      getDemographicIndexEngagementData,
      
      getStressQuestionData,
      getStressManagerQuestionData,
      getStressDMGQuestionData,
      getDemographicIndexStressData,

      getManagerQuestionData,
      getManagerManagerQuestionData,
      getManagerDMGQuestionData,
      getDemographicIndexManagerData,

      getLeaderQuestionData,
      getLeaderManagerQuestionData,
      getLeaderDMGQuestionData,
      getDemographicIndexLeaderData,

      getSurveyData,
      indexType,getCommentData, getIndexData, getDemographicIndexForManagerData, getManagerListDomainFilterData, ManagerIndexDriverData,
      ManagerDynaObj,EmpFilterData ,QuesDynaObj, ManagerIndexQuestionData,

      ManagerListDasboardDataObj,
      ManagerListQuestionDataObj,

      QuesMngrHappinessReportObject,
      QuesMngrEngagementReportObject,
      QuesMngrStressReportObject,
      QuesMngrManagerReportObject,

      
      DriverMngrHappinessReportObject,
      DriverMngrEngagementReportObject,
      DriverMngrStressReportObject,
      DriverMngrManagerReportObject,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,

      getHappinessIndexByFilterData,
      getEngagementIndexByFilterData,
      getStressIndexByFilterData,
      getManagerIndexByFilterData,
      getLeaderIndexByFilterData,


      surveyLogo

    
    } = this.props;

    console.log('DriverMngrHappinessReportObject: ',DriverMngrHappinessReportObject)
    console.log('DriverMngrEngagementReportObject: ',DriverMngrEngagementReportObject)
    console.log('DriverMngrStressReportObject: ',DriverMngrStressReportObject)
    console.log('DriverMngrManagerReportObject: ',DriverMngrManagerReportObject)


    let {managerSearchName, crrManagerName,crrMangerId,crrManagersList,showViewBy ,DemographicRestriction

    } = this.state;

    let { 

      CurrentManagerName,
      CurrentManagerId,
      optionTypeM,

      CurrentManagerData ,

      showCircle
    
    } = this.state;


    // dynamicList.push(getManagerListDomainFilterData)

    console.log('crrManagersList: ',crrManagersList)
    // console.log('----------crrManagersList---------------------------------->: ',crrManagersList)


    let DefaultManagerId = '';
    let DefaultManagerName = '';

    let IndexForManagerData={}
    let IndexData={}


    function getIndexName(key){
      let name = ''
        if(key===1){ name='Happiness' }
        else if(key===2){ name='Engagement' }
        else if(key===3){ name='Hope' }
        else if(key===4){ name='Stress' }
        else if(key===5){ name='Manager' }

      return name;
    }

    let IndexList=[]
    let IndexLabels=[]
    if(getIndexData){
      IndexList = Object.keys(getIndexData).map((key) => [ Number(key), getIndexData[key] ]);
    }
    if(IndexList && IndexList.length>0){
      IndexList.forEach((item)=>{

        if(item[1]){
          IndexData[item[0]]=(item[1])?(item[1]).toFixed(2):'NaN';
        }

        if(getIndexName(item[0])){
          IndexLabels.push({
            indexType:item[0],
            indexName:getIndexName(item[0])
          });
        }
      })
    }
    this.state.FilterList=IndexLabels



    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      if(!CurrentManagerId){
          DefaultManagerId=getDemographicIndexForManagerData[0].ManagerPID;
          DefaultManagerName=getDemographicIndexForManagerData[0].name;

          if(IndexList && IndexList.length>0){
            IndexList.forEach((item)=>{
              IndexForManagerData[item[0]]=getDemographicIndexForManagerData[0][item[0]];
            })
          }
      }
     else{
      getDemographicIndexForManagerData.forEach((mgr)=>{

        if(mgr.ManagerPID===CurrentManagerId){
          if(IndexList && IndexList.length>0){
            IndexList.forEach((item)=>{
              IndexForManagerData[item[0]] = mgr[item[0]];
            })
          }
        }
    
        })
     }
    }


    let managerPID='';
    let managerData='';
    let managerNewName='';

    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      if(!CurrentManagerId){
        managerPID=getDemographicIndexForManagerData[0].id;
        managerData=getDemographicIndexForManagerData[0];
        managerNewName=getDemographicIndexForManagerData[0].name;

      }
      else{
        managerPID=CurrentManagerId;
        managerData=CurrentManagerData;
        managerNewName=CurrentManagerName
      }
    }

  

    // console.log('managerPID: ',managerPID);
    // console.log('managerData: ',managerData);
    // console.log('managerNewName: ',managerNewName);
    // console.log('getDemographicIndexForManagerData: ',getDemographicIndexForManagerData);

    // console.log('IndexLabels: ',IndexLabels);



    let { DynaObj, getQuestionData,getQuestionReportData, tempList, loading, getQuestionOverallData, IndexDimension } = this.props;


    let ManagerName1 = ''
    if(CurrentManagerName){
      ManagerName1 = CurrentManagerName
    }
    else{
      ManagerName1 = DefaultManagerName
    }
    

    // console.log('tempList: ',tempList);


    let selectedManagerName = '';
    let selectedManagerId = '';

    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      if(crrManagerName && crrMangerId){
        selectedManagerName = crrManagerName;
        selectedManagerId = crrMangerId;
      }
      else{
        selectedManagerName = getDemographicIndexForManagerData[0].name;
        selectedManagerId = getDemographicIndexForManagerData[0].id;
      }
      //------
    }
    let { showMultiple } = this.state;



    let DemographicDriverManagerData = [];
    let temShowMultiple =false;
    if(crrManagersList && crrManagersList.length>0 && crrManagersList.length>1){
    if(ManagerIndexDriverData && ManagerIndexDriverData.length>0){
      ManagerIndexDriverData.forEach((item)=>{
        crrManagersList.forEach((mngr)=>{
          if(item.name === (mngr.id).toString()){
            let tempObj = {
              ...item,
              id:(mngr.id).toString()
            }
            tempObj["name"] = mngr.name;
            DemographicDriverManagerData.push(tempObj);
          }
        })
      });
    }
    temShowMultiple=true;
  }
  else{
    temShowMultiple=false;
  }
  // console.log('DemographicDriverManagerData: ',DemographicDriverManagerData);
  
  
  // console.log('//////////////////DemographicDriverManagerData: ',DemographicDriverManagerData)
  // console.log('//////////////////ManagerIndexDriverData: ',ManagerIndexDriverData)



  // console.log('-------------------------------------------->: ');

  // console.log('ManagerListDasboardDataObj: ',ManagerListDasboardDataObj);
  // console.log('ManagerListQuestionDataObj: ',ManagerListQuestionDataObj);

  // console.log('-------------------------------------------->: ');


  let NEW_ManagerListDasboardDataObj = [];
  let NEW_ManagerListQuestionDataObj = [];

  if(ManagerListDasboardDataObj && ManagerListDasboardDataObj.length>0){
    ManagerListDasboardDataObj.forEach((item1)=>{
      let getIndex = crrManagersList.findIndex(prev=>prev.id === item1.ManagerPID);
      if(getIndex!==-1){
        NEW_ManagerListDasboardDataObj.push(item1);
      }
    });
  }

  if(ManagerListQuestionDataObj && ManagerListQuestionDataObj.length>0){
    ManagerListQuestionDataObj.forEach((item1)=>{
      let getIndex = crrManagersList.findIndex(prev=>prev.id === item1.ManagerPID);
      if(getIndex!==-1){
        NEW_ManagerListQuestionDataObj.push(item1);
      }
    });
  }


  // console.log('-------------------------------------------->: ');

  // console.log('NEW_ManagerListDasboardDataObj: ',NEW_ManagerListDasboardDataObj);

  // console.log('-------------------------------------------->: ');
  let DemographicIndexForManagerData = []
  if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
    getDemographicIndexForManagerData.forEach((item)=>{
      DemographicIndexForManagerData.push(item);
    });
  }

  function SortList(list){
    if(list && list.length>0){
      // return list.sort((b,a)=>(a.name-b.name))
      return list.sort((a, b) => a.name.localeCompare(b.name))

    }
    else{
      return [];
    }
  }

  console.log('getDemographicIndexForManagerData=>',getDemographicIndexForManagerData)
  // console.log('DemographicIndexForManagerData=>',DemographicIndexForManagerData)



  let { DimensionList} = this.state;

  let { validateIndex, GetParameterLabel } = this.props;

  // console.log('asdsd validateIndex=>',validateIndex)

  let validateList = [];
  // let validObj = Object.keys(validateIndex).map((key)=>[key,validateIndex[key]])
  let validObj = []
  if(validateIndex){
    validObj = Object.keys(validateIndex).map((key)=>[key,validateIndex[key]])
  }
  console.log('validObj: ',validObj);
  if(validObj && validObj.length>0){
    validObj.forEach((item)=>{
      if(item[1]){
        let getIndex = DimensionList.findIndex(prev=>prev.index===parseInt(item[0]));
        if(getIndex!==-1?DimensionList[getIndex].name:""){
          validateList.push({
            "name":getIndex!==-1?DimensionList[getIndex].name:"",
            "template":parseInt(item[0])
          });
        }

      }
    })
  }


  let singleManagerData = "";
  if(crrManagersList && crrManagersList.length===1){
    singleManagerData = crrManagersList[0];
  }
  else{
    singleManagerData = getDemographicIndexForManagerData&&getDemographicIndexForManagerData.length>0?getDemographicIndexForManagerData[0]:""
  }


  console.log('assaaasssaa singleManagerData: ',singleManagerData);



    return (
      <>
  <main className="flex-1 overflow-x-hidden overflow-y bg-gray-100">


        <div className="px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b h-10">
        <div className="cursor-pointer text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden">
          <span onClick={()=>this.handleOption(101,managerPID,managerData, ManagerName1)} className={(optionTypeM===101)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Manager Dashboard</span>
          
          {validateList&&validateList.length>0?
          validateList.map((item)=>
          (item.template!==5)?
          <span onClick={()=>this.handleOption(item.template,managerPID,managerData, ManagerName1)} className={(optionTypeM===item.template)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>{item.name} Behaviour</span>
          :null
          ):null}

          <span onClick={()=>this.handleOption(5,managerPID,managerData, ManagerName1)} className={(optionTypeM===5)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Manager Behaviour</span>
          <span onClick={()=>this.onManagerReportModal(true)} className={"py-3 px-4 block text-green-600"}>Export Report</span>
        </div>
      </div>




        {getDemographicIndexForManagerData?

    <div class=" pb-8 ">





        <div className="bg-white px-4 flex w-full justify-between items-center  border-b py-3">
          <div className="flex items-center border py-2 px-4 w-full relative">
            
            {/* <input onClick={()=>this.openDropdown(showViewBy)} type="text" name placeholder="Select Managers" value={
              crrManagersList && crrManagersList.length>0?
                crrManagersList.map((element)=>{return element.name}).join(", "):
              getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0?getDemographicIndexForManagerData[0].name:""
            
            } className="w-full text-xs focus:outline-none font-bold cursor-pointer" />  */}

            <div className="w-full text-xs focus:outline-none font-bold cursor-pointer ">
            {crrManagersList && crrManagersList.length>0?
              crrManagersList.map((element,index)=>
              <>
              <span >{element.name}<span onClick={()=>this.handleManagerSelection(element)} className="text-red-500 ">{" (x)"}</span></span>
              {index+1===crrManagersList.length?<span></span>:<span className="mr-2">{", "}</span>}
              </>
              )

            :
            <div className="text-xs">
                <span >{getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0?getDemographicIndexForManagerData[0].name:""}</span>
            </div>
            }
            </div>
          {/*             
            {showViewBy?
              <span onClick={()=>this.openDropdown(showViewBy)} className="cursor-pointer material-icons text-gray-500">expand_less</span>
            :
              <span onClick={()=>this.openDropdown(showViewBy)} className="cursor-pointer material-icons text-gray-500">expand_more</span>
            } */}
            {/* drop down */}
            <div className="absolute left-0 top-10 bg-white shadow-lg   font-normal font-xs w-full  " style={{ zIndex:103 }}>
              
              {showViewBy?
              <>
              <div className="flex">
                    <div className="p-3 w-10/12">
                      <input type="text" value={managerSearchName} name="managerName" onChange={this.handleSearchManager} placeholder="Search" 
                      className="w-full border p-1.5 rounded-sm focus:outline-none text-xs text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" />
                    </div>

                    <div className="p-3 w-2/12">
                      {/* <button onClick={()=>this.openDropdown(showViewBy)} className="text-sm  text-red-500  py-2 px-10 ml-2 focus:outline-none">Close</button> */}
                    </div>
              </div>

                <div className="h-72 overflow-y-auto px-3" id="customscroll3"> 

                {SortList(DemographicIndexForManagerData) && DemographicIndexForManagerData && DemographicIndexForManagerData.length>0?
                  SortList(DemographicIndexForManagerData).map((mngr, index)=>
                  mngr && mngr.name.match(new RegExp(`${managerSearchName}`, 'gi'))?
                    <div key={index} className="py-2 m-1 flex items-center">
                      <input type="checkbox" checked={crrManagersList.some(prev=>prev.emp_id===mngr.emp_id)?true:false} onClick={()=>this.handleManagerSelection(mngr)} onChange={()=>{}}/><label className="ml-2 text-xs text-gray-500 ">{mngr.name}</label>   
                    </div>
                  :null):null}

                </div> 
                </>
              :null}

            </div>
            

          </div>
          {showViewBy?
              <span onClick={()=>this.openDropdown(showViewBy)} className="cursor-pointer py-2 px-2 material-icons text-red-500 font-bold">close</span>
              :
              <span onClick={()=>this.openDropdown(showViewBy)} className="cursor-pointer py-2 px-2 material-icons text-blue-500 font-bold">arrow_downward</span>
          }
          <button onClick={()=>this.handleManagersCall(optionTypeM)} className="text-xs bg-blue-500 text-white  py-2 px-10 ml-2 focus:outline-none">Apply</button>
        </div>

        
        





        <div onClick={()=>this.openDropdown(true)}>

        {optionTypeM===101?
        <>
        {DynaObj && DynaObj.length>0?
        <>
                  <div className="bg-white shadow-lg m-4" style={{minHeight:'300px'}}>



                                    <div className="md:flex justify-between  p-4">
                                    <div className="text-lg font-semibold">{"Manager"} Dashboard</div>                                      
                                    <div className="text-xs flex items-center">
                                        <div onClick={()=>this.handleViewType(true)} className={showCircle?"cursor-pointer border mx-1 bg-blue-50 ring-1 ring-blue-500 text-blue-500 py-1.5 px-4 rounded-full outline-none":"cursor-pointer border mx-1 py-1.5 px-4 rounded-full"}>Card view</div>
                                        <div onClick={()=>this.handleViewType(false)} className={!showCircle?"cursor-pointer border mx-1 bg-blue-50 ring-1 ring-blue-500 text-blue-500 py-1.5 px-4 rounded-full outline-none":"cursor-pointer border mx-1 py-1.5 px-4 rounded-full"}>Table view</div>
                                      </div>
                                    </div>




                          <div className="mx-4">
                            {showCircle?
                              <IndexCards
                              IndexForManagerData={IndexForManagerData}
                              getIndexData={getIndexData}
                              getManagerListDomainFilterData={getManagerListDomainFilterData}
                              IndexLabels={IndexLabels}
                              DynaObj={DynaObj}
                              EmpFilterData={EmpFilterData}
                              showMultiple={showMultiple}
                              ManagerListDasboardDataObj={NEW_ManagerListDasboardDataObj}
                              validateList={validateList}
                              DemographicRestriction={DemographicRestriction}
                              GetParameterLabel={GetParameterLabel}
                              singleManagerData={singleManagerData}
                            />
                          
                            :
                            
                            <HeatMap
                            IndexForManagerData={IndexForManagerData}
                            getIndexData={getIndexData}
                            getManagerListDomainFilterData={getManagerListDomainFilterData}
                            IndexLabels={IndexLabels}
                            DynaObj={DynaObj}
                            EmpFilterData={EmpFilterData}
                            showMultiple={showMultiple}
                            ManagerListDasboardDataObj={NEW_ManagerListDasboardDataObj}
                            validateList={validateList}
                            DemographicRestriction={DemographicRestriction}
                            GetParameterLabel={GetParameterLabel}
                            singleManagerData={singleManagerData}
                          />
                            }
                          </div>


                    
        
        </div>
        </>
        :
        <>
        {!loading?
        <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated Manager Insight Data!</div>
        :null}
        </>
        }
        </>
        :null}

      {optionTypeM!==101?
        <>
          <IndexComponent
          indexType={indexType} optionType={optionTypeM} getCommentData={getCommentData}
             getQuestionData={getQuestionData}
             Name={
               (optionTypeM===1)?"Happiness":
               (optionTypeM===2)?"Engagement":
               (optionTypeM===5)?"Manager":
               ""}
             handleManagerDriver={this.handleManagerDriver}
             ManagerIndexDriverData={ManagerIndexDriverData}
             CurrentManagerId={CurrentManagerId}
             optionTypeM={optionTypeM}
             ManagerDynaObj={ManagerDynaObj}
             EmpFilterData={EmpFilterData}
             CurrentManagerData={CurrentManagerData}

             

             QuesDynaObj={QuesDynaObj}

             handleManagerQuestion={this.handleManagerQuestion}

             ManagerIndexQuestionData={ManagerIndexQuestionData}


             ManagerName={ManagerName1}

             CurrentManagerName={CurrentManagerName}

             loading={loading}
             
             ManagerListQuestionDataObj={NEW_ManagerListQuestionDataObj}

             showMultiple={showMultiple}

             DemographicDriverManagerData={DemographicDriverManagerData}


             temShowMultiple={temShowMultiple}

             ParaLabelList = {
              (optionTypeM===1)?IndexDimension.Happiness:
              (optionTypeM===2)?IndexDimension.Engagement:
              (optionTypeM===4)?IndexDimension.Stress:
              (optionTypeM===5)?IndexDimension.Manager:
              []
             }


             
             IndexDriverData = {
              (optionTypeM===1)?getHappinessIndexDriverData:
              (optionTypeM===2)?getEngagementIndexDriverData:
              (optionTypeM===4)?getStressIndexDriverData:
              (optionTypeM===5)?getManagerIndexDriverData:
              {}
             }

             getQuestionOverallData={getQuestionOverallData}
             DemographicRestriction={DemographicRestriction}
             GetParameterLabel={GetParameterLabel}

             OrgLevelDriverScore={this.OrgLevelDriverScore}

          /> 
        </>
        :null}


        {this.state.showManagerReportModal? 
          <ReportModal
          onCloseManagerReportModal={this.onManagerReportModal}
          getDemographicIndexForManagerData={getDemographicIndexForManagerData}
          getSurveyData={getSurveyData}
          SelectManager={this.SelectManager}
          EmpFilterData={EmpFilterData}
          DynaObj={DynaObj}
          getIndexData={getIndexData}
          getReportMngrQuestion={this.getReportMngrQuestion}
          getReportMngrDriver={this.getReportMngrDriver}
          QuesDynaObj={QuesDynaObj}
          getQuestionReportData={getQuestionReportData}

          QuesMngrHappinessReportObject={QuesMngrHappinessReportObject}
          QuesMngrEngagementReportObject={QuesMngrEngagementReportObject}
          QuesMngrStressReportObject={QuesMngrStressReportObject}
          QuesMngrManagerReportObject={QuesMngrManagerReportObject}

          DriverMngrHappinessReportObject={DriverMngrHappinessReportObject}
          DriverMngrEngagementReportObject={DriverMngrEngagementReportObject}
          DriverMngrStressReportObject={DriverMngrStressReportObject}
          DriverMngrManagerReportObject={DriverMngrManagerReportObject}
          DemographicRestriction={DemographicRestriction}
          validateList={validateList}

          getHappinessIndexDriverData={getHappinessIndexDriverData}
          getEngagementIndexDriverData={getEngagementIndexDriverData}
          getStressIndexDriverData={getStressIndexDriverData}
          getManagerIndexDriverData={getManagerIndexDriverData}



          getDemographicIndexHappinessData={getDemographicIndexHappinessData}
          getDemographicIndexEngagementData={getDemographicIndexEngagementData}
          getDemographicIndexStressData={getDemographicIndexStressData}
          getDemographicIndexManagerData={getDemographicIndexManagerData}
          getDemographicIndexLeaderData={getDemographicIndexLeaderData}



          getHappinessQuestionData={getHappinessQuestionData}
          getHappinessManagerQuestionData={getHappinessManagerQuestionData}
          getHappinessDMGQuestionData={getHappinessDMGQuestionData}

          getEngagementQuestionData={getEngagementQuestionData}
          getEngagementManagerQuestionData={getEngagementManagerQuestionData}
          getEngagementDMGQuestionData={getEngagementDMGQuestionData}

          getStressQuestionData={getStressQuestionData}
          getStressManagerQuestionData={getStressManagerQuestionData}
          getStressDMGQuestionData={getStressDMGQuestionData}



          getManagerQuestionData={getManagerQuestionData}
          getManagerManagerQuestionData={getManagerManagerQuestionData}
          getManagerDMGQuestionData={getManagerDMGQuestionData}

          getLeaderQuestionData={getLeaderQuestionData}
          getLeaderManagerQuestionData={getLeaderManagerQuestionData}
          getLeaderDMGQuestionData={getLeaderDMGQuestionData}


          IndexDimension={IndexDimension}
          onLoadManagerReport={onLoadManagerReport}
          GetParameterLabel={GetParameterLabel}

          getHappinessIndexByFilterData={getHappinessIndexByFilterData}
          getEngagementIndexByFilterData={getEngagementIndexByFilterData}
          getStressIndexByFilterData={getStressIndexByFilterData}
          getManagerIndexByFilterData={getManagerIndexByFilterData}
          getLeaderIndexByFilterData={getLeaderIndexByFilterData}


          surveyLogo={surveyLogo}

          />
        :null}
     
       



     </div>
          </div>
        :null}


      </main>
    
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ManagerList);
