import React from "react";
export default function ConfirmMail(props) {
  let { currQuesEditId, closeQuestionRestrictionModal  } = props;
  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
          <div onClick={()=>closeQuestionRestrictionModal()} className="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
          
          
          
          <div className="bg-white shadow w-full rounded-lg  overflow-hidden w-full block ">
            <div className="p-3 bg-blue-600 text-white cursor-default">Warning!</div>
            <div className="bg-white p-5">
            <div className="flex px-2 cursor-default justify-center items-center">
                {/* <div className="pr-4"><img className="" style={{height:"35px",width:"35px"}} src={"imagesplushr/icons/dialog_ques_mark.png"} alt="question mark icon"/></div> */}
                <div className="text-center pt-1">Please Save Your Question!</div>
              </div>
              <div className="flex justify-center items-center pt-10">
                  <button  onClick={()=>closeQuestionRestrictionModal()} className="bg-blue-600 rounded-md text-white py-1 px-2 mx-2 w-3/12">Ok</button>
              </div>
            </div>
          </div>



        </div>
        </div>
   </>
  );
}
