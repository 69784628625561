
import { authHeader } from '../_helpers';
import { CONST } from '../_config';
let backendUrl = JSON.parse(localStorage.getItem('backendUrl'));

export const generalService = {
    logout,
    getSurvey,
    getDashboardCard,
    getQuestionDist,
    getMeanScore,
    getMeanScoreQwise,
    getQuestionDistOverall,
    getQuestionMatrixResult,
    getQuestionOthersResult
    
};

function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


async function getSurvey(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                SurveyData: data.data
            }
            return SurveyObj;
        });
}


async function getDashboardCard(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDashboardCard`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getDashboardCardData: data.data
            }
            return SurveyObj;
        });
}


async function getQuestionDist(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionDist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionDistData: data.data
            }
            return SurveyObj;
        });
}

async function getQuestionDistOverall(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionDist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionDistOverallData: data.data
            }
            return SurveyObj;
        });
}

async function getMeanScore(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getMeanScore`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getMeanScoreData: data.data.data
            }
            return SurveyObj;
        });
}


async function getMeanScoreQwise(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getMeanScoreQwise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getMeanScoreQwiseData: data.data.data
            }
            return SurveyObj;
        });
}

async function getQuestionMatrixResult(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionResult`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionMatrixResultData: data.data
            }
            return SurveyObj;
        });
}

async function getQuestionOthersResult(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionResult`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionOthersResultData: data.data
            }
            return SurveyObj;
        });
}

