import React, { Component } from 'react';
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
        // showParaFilter:false,
        // showParaValue:'',
        currFilterList:[],
        finalFilter:[],
        searchName:''

    }
  }
  componentDidMount() {

  }


  handleParameterQuestion=()=>{

  }

  handleSelectFilter=(name)=>{
    // this.setState({showParaFilter:false});
    this.props.closeParaFilter(false);

    let {currFilterList,finalFilter}= this.state;
    let { handleMultifilterIndexScore } = this.props;
    let getIndex = currFilterList.findIndex(prev=>prev===name)
    if(getIndex===-1){
        currFilterList.push(name);
        finalFilter.push({
            "level":name,
            "value":"All"
        });  

        this.setState({currFilterList,finalFilter},()=>{
            handleMultifilterIndexScore(finalFilter,name); 
        });
    }
    else{
        currFilterList.splice(getIndex,1);
        let getIndex2 = finalFilter.findIndex(prev=>prev.level===name);
        finalFilter.splice(getIndex2,1);

        this.setState({currFilterList,finalFilter},()=>{
            if(finalFilter && finalFilter.length>0){
                handleMultifilterIndexScore(finalFilter,finalFilter[finalFilter.length-1].level); 
            }
            else{
                handleMultifilterIndexScore([]); 
                // this.setState({showParaFilter:false})
                this.props.closeParaFilter(false);

            }
        });
    }

    }

  handleOpenFilter=(check)=>{
    // this.setState({showParaFilter:!check,showParaValue:''});
    this.props.closeParaFilter(!check,'');

    }


    handleOpenValue=(name)=>{
        if(this.state.showParaValue===name){
            // this.setState({showParaValue:'',showParaFilter:false})
            this.props.closeParaFilter(false,'');
        }
        else{
            // this.setState({showParaValue:name,showParaFilter:false})
            this.props.closeParaFilter(false,name);
        }
    }



    handleFilterClear=()=>{
    let { handleMultifilterIndexScore } = this.props;
    this.setState({finalFilter:[],currFilterList:[]},()=>{
        handleMultifilterIndexScore([]);
    })
    }

    handleSelectAllFilter=()=>{

    }

    handleSearch=(e)=>{
      this.setState({searchName:e.target.value});
    }

  render() {

    const ref = React.createRef();
    let { isSelectAll, EmpFilterData2, getIndexFilterData , showParaValue,showParaFilter,GetParameterLabel} = this.props;
    let { searchName, currFilterList, finalFilter } = this.state;

    let DemographicValueList = {};
    if(getIndexFilterData){
        DemographicValueList = getIndexFilterData;
        // DemographicValueList.sort();
    }



    console.log('EmpFilterData2: ',EmpFilterData2)
    console.log('currFilterList: ',currFilterList)
    console.log('DemographicValueList: ',DemographicValueList)
    console.log('finalFilter: ',finalFilter)



    function selectedValue (item){
        let Value = '';
        let getIndex = finalFilter.findIndex(prev=>prev.level===item);
        if(getIndex!==-1){
            if(finalFilter && finalFilter[getIndex] && finalFilter[getIndex].value){
                Value = finalFilter[getIndex].value;
            }
        }
        return Value;
    }


    function SortList(list){
      // let temp = [];
      // if(list && list.length>0){
      //   temp= list.sort()
      // }
      return list;
    }


    let { showTargetDropdown,handleTargetDropdown, selectedTargetValue, selectTargetDimension, validParameterList,
      handleCompareDropdown,showCompareDropdown,compareDimenList,selectMultiDimension , submitTargetCompareCorrelation} = this.props;

    


      let InitTargetValue = "";
      if(selectedTargetValue){
        InitTargetValue = selectedTargetValue;
      }
      else{
        InitTargetValue = validParameterList&&validParameterList.length>0?validParameterList[0]:"Choose Target";
      }


      // if(selectedTargetValue){
      //   InitTargetValue = GetParameterLabel(selectedTargetValue);
      // }
      // else{
      //   InitTargetValue = validParameterList&&validParameterList.length>0?"Default: "+GetParameterLabel(validParameterList[0]):"Choose Target";
      // }

    return (
      <>
        <div className="flex ">
          {/* 1 */}

          {/* 2 */}



        {/* {currFilterList && currFilterList.length>0?
        currFilterList.map((item)=> */}
          <div className="cursor-pointer flex items-center text-xs font-medium  text-gray-500 relative">
            <p style={{width:'110px'}} onClick={()=>handleTargetDropdown(!showTargetDropdown, InitTargetValue)} className="ml-2 text-gray-600 font-bold">
              {GetParameterLabel(InitTargetValue)}</p>   <span onClick={()=>handleTargetDropdown(!showTargetDropdown, InitTargetValue)} className="material-icons">arrow_drop_down</span>

            {showTargetDropdown?
            <>
            <div className="z-10 absolute left-0 top-8 bg-white shadow-lg p-3 font-normal font-xs w-48" style={{overflowY:'auto',height:'400px'}}>
              {/* <input type="text" name="searchName" value={searchName} onChange={this.handleSearch} placeholder="Search" className="w-full border p-1.5 rounded-sm focus:outline-none text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" /> */}
              {validParameterList && validParameterList.length>0?
                validParameterList.map((value,index)=>
                   <div className={selectedTargetValue===value?'p-2 bg-blue-500 text-white rounded-md':'p-2'} onClick={()=>selectTargetDimension(value)} >{GetParameterLabel(value)}</div> 
                )
              :null}
            </div>
            </>
            :null}

          </div>
        {/* ):null} */}


        <div className="mx-6 text-gray-500 font-bold">VS</div>


        <div className="cursor-pointer flex items-center text-xs font-medium mr-6 ml-1 text-gray-500 relative">
            <span className=" mr-2 text-gray-600 font-bold"  onClick={()=>handleCompareDropdown(!showCompareDropdown, InitTargetValue)}>Dimensions</span> 
            <p  onClick={()=>handleCompareDropdown(!showCompareDropdown, InitTargetValue)} className="text-white bg-blue-500 rounded-full h-5 flex inline-flex items-center w-5 justify-center mx-2">
            {compareDimenList && compareDimenList.length>0?compareDimenList.length:0}
            </p> <span  onClick={()=>handleCompareDropdown(!showCompareDropdown, InitTargetValue)} className="material-icons">arrow_drop_down</span>
            {showCompareDropdown?
            <div className="z-10 absolute left-0 top-8 bg-white shadow-lg p-3 font-normal font-xs w-48" style={{height:'300px',overflowY:'auto'}}>
              
              <div className="py-2 m-1 flex items-center">
                  <input type="checkbox" 
                  checked={isSelectAll} 
                  onClick={()=>selectMultiDimension("all",validParameterList)} onChange={()=>{}}/>
                  <label  onClick={()=>selectMultiDimension('all',validParameterList)} className="ml-2">
                    {"Select All"}</label> 
              </div>
              
              {validParameterList && validParameterList.length>0?
                validParameterList.map((value,index)=>
                selectedTargetValue!==value?
                <div key={index}  className="py-2 m-1 flex items-center">
                    <input type="checkbox" checked={compareDimenList.some(prev=>prev===value)?true:false} onClick={()=>selectMultiDimension(value)} onChange={()=>{}}/><label  onClick={()=>selectMultiDimension(value)} className="ml-2">{GetParameterLabel(value)}</label> 
                </div>
                :null):null}
              </div>
             :null}
          </div>


        <div className="cursor-pointer flex items-center text-xs font-medium mr-6 text-gray-500 relative">
            <p className="mr-2 text-white bg-blue-400 px-2 py-1 rounded-sm" onClick={()=>submitTargetCompareCorrelation(false,[])} >{"Submit"}</p>  
        </div>


        </div>
        
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Filter);
