import React from "react";
export default function AddSurvey(props) {
  let { isCrole,handleCreateSurveyPopupClose,handleSurveyRole, handleAddSurveySubmit,handleSurveyType, surveyFormData, handleAddSurveyInput, surveyValidation } = props;
  return (
   <>
<div className="top-0 xl:h-screen h-full  w-full flex justify-center items-center z-40 fixed capitalize" style={{background: '#0000005c'}}>
  <div className="bg-white rounded-lg  shadow-sm  xl:w-5/12  lg:w-6/12 md:w-8/12 w-11/12 p-6">
    <div className="flex justify-between pb-4">
      <h2 className="text-xl font-semibold text-center text-gray-800 ">Create New Survey</h2> <span onClick={()=>handleCreateSurveyPopupClose()} className="
      material-icons text-sm font-medium text-red-500 hover:bg-red-300 hover:text-white rounded-full p-1 px-1.5 cursor-pointer">close</span></div>
    <div className="space-y-3">
      <input type="text" name="name" value={surveyFormData.name} onChange={handleAddSurveyInput} 
      className="w-full border p-2 mt-2 text-sm focus:outline-none rounded-md  focus:ring-2" placeholder="Survey title" />
      <br />
      <textarea name="desc" value={surveyFormData.desc} onChange={handleAddSurveyInput} rows={4} 
      className="border w-full h-20 p-2 text-sm focus:outline-none rounded-md focus:ring-2 resize-none" placeholder="Survey description.." defaultValue={""} />
    </div>
    <div className="py-6">
      <h1 className="text-base font-medium pb-1">Choose survey type</h1>
      <p className="text-sm text-gray-700 ">Select survey package you want to continue with:- </p>
    </div>
    <div className="flex items-center gap-8 ">
      
      <div onClick={()=>handleSurveyType("custom")} className={surveyFormData.type==="custom"?
      "border-2 flex justify-center text-center p-6 rounded-md text-gray-700  text-blue-500  border-blue-500 cursor-pointer relative":
      "border-2 flex justify-center text-center p-6 rounded-md text-gray-700 hover:text-blue-500 hover:border-blue-500 cursor-pointer"}
      >
        
        
        <div className="flex md:flex-row flex-col items-center space-x-2">
          <span className="material-icons-outlined " style={{fontSize: '2rem'}}>article</span>
          <h1 className="font-medium text-sm py-2">own survey</h1> 
          {surveyFormData.type==="custom"?
          <span className="material-icons absolute top-1 right-1 text-blue-500" style={{fontSize: '1.3rem'}}>check_circle</span>
          :null}
        </div>


      </div>



      <div onClick={()=>handleSurveyType("package")} className={surveyFormData.type==="package"?
      "border-2 flex justify-center text-center p-6 rounded-md text-gray-700  text-blue-500  border-blue-500 cursor-pointer relative":
      "border-2 flex justify-center text-center p-6 rounded-md text-gray-700 hover:text-blue-500 hover:border-blue-500 cursor-pointer"}
      >
        
        
      <div className="flex md:flex-row flex-col items-center space-x-2">
          <span className="material-icons-outlined " style={{fontSize: '2rem'}}>post_add</span>
          <h1 className="font-medium text-sm py-2">Plus survey</h1>
          {surveyFormData.type==="package"?
          <span className="material-icons absolute top-1 right-1 text-blue-500" style={{fontSize: '1.3rem'}}>check_circle</span>
          :null}
        </div>


      </div>




    </div>

    <div className="mt-8 text-center text-red-400">
      {!surveyValidation.name?
      <p>Please enter survey name.</p>
      :null}
    </div>


    {/* upload details */}
    <div className="flex justify-end mt-8">
      <button onClick={()=>handleAddSurveySubmit()} type="button" className="border border-blue-500 rounded-md px-6 py-2.5 text-sm bg-blue-500 text-white focus:outline-none flex items-center hover:bg-blue-600">
        {surveyFormData.type==="custom"?"Create Survey":"Continue"}
        <span className="material-icons ml-2" style={{fontSize: '1rem'}}>arrow_forward</span>
      </button>
    </div>
    {/*  */}
  </div>
</div>

   </>
  );
}
