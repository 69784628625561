import {
  surveyConstants
} from '../_constants';

export function survey(state = {}, action) {

  switch (action.type) {

    case surveyConstants.SURVEY_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.SURVEY_GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        getSurveyListSuccess: true,
        getSurveyListData:action.survey.getSurveyListData.list,
        getSurveyListTotal:action.survey.getSurveyListData.total,

      };
    case surveyConstants.SURVEY_GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



      
    case surveyConstants.QUESTIONTYPE_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.QUESTIONTYPE_GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionTypeListSuccess: true,
        getQuestionTypeListData:action.survey.getQuestionTypeListData.list,
        getQuestionTypeListDataTotal:action.survey.getQuestionTypeListData.total,

      };
    case surveyConstants.QUESTIONTYPE_GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



      case surveyConstants.GET_SURVEY_DATA_REQUEST:
        return {
          ...state
        };
      case surveyConstants.GET_SURVEY_DATA_SUCCESS:
        return {
          ...state,
          expireSurveySuccess: false,
          loading: false,
          getSurveySuccess: true,
          getSurveyData: action.survey.getSurveyData

        };
      case surveyConstants.GET_SURVEY_DATA_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };



        case surveyConstants.PARAMETERLIST_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case surveyConstants.PARAMETERLIST_GETALL_SUCCESS:
          return {
            ...state,  
            loading: false,
            addParameterSuccess: false,      
            updateParameterSuccess: false,
   
            parameterList: action.survey.getParameterListData.list,
            total: action.survey.getParameterListData.total
          };
        case surveyConstants.PARAMETERLIST_GETALL_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };


          case surveyConstants.WELCOME_SURVEY_REQUEST:
            return {
              ...state,
              loading:  true
            };
          case surveyConstants.WELCOME_SURVEY_SUCCESS:
            return {
              ...state,
              expireSurveySuccess: false,
              loading: false,

              welcomeSurveySuccess: true
            };
          case surveyConstants.WELCOME_SURVEY_FAILURE:
            return {
              ...state,
              loading: false,

              error: action.error
            };


          case surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST:
            return {
              ...state,
              loading: true
            };
          case surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS:
            return {
              ...state,  
              addParameterSuccess: false,      
              updateParameterSuccess: false,
              loading: false,

              getParameterListbyParentData: action.survey.getParameterListbyParentData
            };
          case surveyConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE:
            return {
              ...state,
              loading: false,

              error: action.error
            };




            case surveyConstants.SET_EXPIRY_SURVEY_REQUEST:
              return {
                ...state,
                loading: true
              };
            case surveyConstants.SET_EXPIRY_SURVEY_SUCCESS:
              return {
                ...state,     
                setExpireSurveySuccess: true,
                loading: false,
                setExpireSurveyData: action.survey.setExpireSurveyData
              };
            case surveyConstants.SET_EXPIRY_SURVEY_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


              case surveyConstants.CREATE_NEW_SURVEY_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case surveyConstants.CREATE_NEW_SURVEY_SUCCESS:
                return {
                  ...state,     
                  createSurveySuccess: true,
                  loading: false,
                  createSurveyData: action.survey.createSurveyData
                };
              case surveyConstants.CREATE_NEW_SURVEY_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };

                


                case surveyConstants.DELETE_SURVEY_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case surveyConstants.DELETE_SURVEY_SUCCESS:
                  return {
                    ...state,   
                    loading: false,  
                    deleteSurveySuccess: true,
                  };
                case surveyConstants.DELETE_SURVEY_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
                  


                  case surveyConstants.UPDATE_SURVEY_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case surveyConstants.UPDATE_SURVEY_SUCCESS:
                    return {
                      ...state,     
                      loading: false,
                      updateSurveySuccess: true,
                    };
                  case surveyConstants.UPDATE_SURVEY_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };
            




                  case surveyConstants.RESPONSE_ANALYTICS_SURVEY_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case surveyConstants.RESPONSE_ANALYTICS_SURVEY_SUCCESS:
                    return {
                      ...state,     
                      responseAnalysisBySurveyIdSuccess: true,
                      loading: false,
                      responseAnalysisBySurveyIdData: action.survey.responseAnalysisBySurveyIdData

                    };
                  case surveyConstants.RESPONSE_ANALYTICS_SURVEY_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };


                    case surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_SUCCESS:
                      return {
                        ...state,     
                        getCampaignEmployeesByIDSurveyWiseSuccess: true,
                        loading: false,
                        getCampaignEmployeesByIDSurveyWiseData: action.survey.getCampaignEmployeesByIDSurveyWiseData
  
                      };
                    case surveyConstants.CAMPAIGN_EMPLOYEES_SURVEY_FAILURE:
                      return {
                        ...state,
                        loading: false,
                        error: action.error
                      };




                      case surveyConstants.RESEND_CAMPAIGN_REQUEST:
                        return {
                          ...state,
                          loading: true
                        };
                      case surveyConstants.RESEND_CAMPAIGN_SUCCESS:
                        return {
                          ...state,     
                          resendCampainSuccess: true,
                          loading: false,
                          resendCampainData: action.survey.resendCampainData
    
                        };
                      case surveyConstants.RESEND_CAMPAIGN_FAILURE:
                        return {
                          ...state,
                          loading: false,
                          error: action.error
                        };
              
                        

                        case surveyConstants.CREATE_SURVEY_FROM_LIB_REQUEST:
                          return {
                            ...state,
                            loading: true
                          };
                        case surveyConstants.CREATE_SURVEY_FROM_LIB_SUCCESS:
                          return {
                            ...state,     
                            createSurveyFromLibSuccess: true,
                            loading: false,
                            createSurveyFromLibData: action.survey.createSurveyFromLibData
      
                          };
                        case surveyConstants.CREATE_SURVEY_FROM_LIB_FAILURE:
                          return {
                            ...state,
                            loading: false,
                            error: action.error
                          };


                          



                          
                        case surveyConstants.GET_SURVEY_FROM_LIB_REQUEST:
                          return {
                            ...state,
                            loading: true
                          };
                        case surveyConstants.GET_SURVEY_FROM_LIB_SUCCESS:
                          return {
                            ...state,     
                            getSurveyFromLibSuccess: true,
                            loading: false,
                            getSurveyFromLibData: action.survey.getSurveyFromLibData.list
      
                          };
                        case surveyConstants.GET_SURVEY_FROM_LIB_FAILURE:
                          return {
                            ...state,
                            loading: false,
                            error: action.error
                          };





        case surveyConstants.GET_EMPLOYEE_LIST_REQUEST:
          return {
            ...state,
            loading: true
          };
        case surveyConstants.GET_EMPLOYEE_LIST_SUCCESS:
          return {
            ...state,     
            getEmployeeListSuccess: true,
            loading: false,
            getEmployeeListData: action.survey.getEmployeeListData.list,
            getEmployeeListTotal: action.survey.getEmployeeListData.total

          };
        case surveyConstants.GET_EMPLOYEE_LIST_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };



        case surveyConstants.GET_EMPLOYEE_SEARCH_REQUEST:
          return {
            ...state,
            loading: true
          };
        case surveyConstants.GET_EMPLOYEE_SEARCH_SUCCESS:
          return {
            ...state,     
            getEmployeeSearchSuccess: true,
            loading: false,
            getEmployeeSearchData: action.survey.getEmployeeSearchData

          };
        case surveyConstants.GET_EMPLOYEE_SEARCH_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };





        case surveyConstants.SAVE_EMPLOYEE_LIST_REQUEST:
          return {
            ...state,
            loading: true
          };
        case surveyConstants.SAVE_EMPLOYEE_LIST_SUCCESS:
          return {
            ...state,     
            saveEmployeeListSuccess: true,
            loading: false

          };
        case surveyConstants.SAVE_EMPLOYEE_LIST_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  


          case surveyConstants.GET_ALL_LANGUGAGE_LABEL_REQUEST:
            return {
              ...state,
              loading: true
            };
          case surveyConstants.GET_ALL_LANGUGAGE_LABEL_SUCCESS:
            return {
              ...state,     
              saveEmployeeListSuccess: true,
              loading: false,
              getLanguageLabelData: action.survey.getLanguageLabelData

            };
          case surveyConstants.GET_ALL_LANGUGAGE_LABEL_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };



            

          case surveyConstants.GET_ALL_LANGUGAGE_REQUEST:
            return {
              ...state,
              loading: true
            };
          case surveyConstants.GET_ALL_LANGUGAGE_SUCCESS:
            return {
              ...state,     
              saveEmployeeListSuccess: true,
              loading: false,
              getLanguageData: action.survey.getLanguageData

            };
          case surveyConstants.GET_ALL_LANGUGAGE_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };



            case surveyConstants.GET_EMPLOYEE_COLUMN_NAME_REQUEST:
              return {
                ...state,
                loading: true
              };
            case surveyConstants.GET_EMPLOYEE_COLUMN_NAME_SUCCESS:
              return {
                ...state,     
                loading: false,
                getEmployeeColumnNameSuccess: true,
                getEmployeeColumnNameData: action.survey.getEmployeeColumnNameData

              };
            case surveyConstants.GET_EMPLOYEE_COLUMN_NAME_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


              case surveyConstants.GET_EMPLOYEE_FILTER_LIST_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case surveyConstants.GET_EMPLOYEE_FILTER_LIST_SUCCESS:
                return {
                  ...state,     
                  loading: false,
                  getEmployeeFilterListByCompanyIdSuccess: true,
                  getEmployeeFilterListByCompanyIdData: action.survey.getEmployeeFilterListByCompanyIdData

                };
              case surveyConstants.GET_EMPLOYEE_FILTER_LIST_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };




                case surveyConstants.GET_EMPLOYEE_BATCH_RANGE_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case surveyConstants.GET_EMPLOYEE_BATCH_RANGE_SUCCESS:
                  return {
                    ...state,     
                    loading: false,
                    getEmployeeBatchRangeSuccess: true,
                    getEmployeeBatchRangeData: action.survey.getEmployeeBatchRangeData
  
                  };
                case surveyConstants.GET_EMPLOYEE_BATCH_RANGE_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };


                  case surveyConstants.GET_CUSTOMER_BATCH_RANGE_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case surveyConstants.GET_CUSTOMER_BATCH_RANGE_SUCCESS:
                    return {
                      ...state,     
                      loading: false,
                      getCustomerBatchRangeSuccess: true,
                      getCustomerBatchRangeData: action.survey.getCustomerBatchRangeData
    
                    };
                  case surveyConstants.GET_CUSTOMER_BATCH_RANGE_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };


                case surveyConstants.CREATE_EMPLOYEE_FILTER_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case surveyConstants.CREATE_EMPLOYEE_FILTER_SUCCESS:
                  return {
                    ...state,     
                    loading: false,
                    createEmployeeFilterSuccess: true,
  
                  };
                case surveyConstants.CREATE_EMPLOYEE_FILTER_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };


                  
                case surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS:
                  return {
                    ...state,     
                    loading: false,
                    updateEmployeeFilterStatusSuccess: true,
  
                  };
                case surveyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
  


                                    
                case surveyConstants.DELETE_EMPLOYEE_FILTER_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case surveyConstants.DELETE_EMPLOYEE_FILTER_SUCCESS:
                  return {
                    ...state,     
                    loading: false,
                    deleteEmployeeFilterSuccess: true,
  
                  };
                case surveyConstants.DELETE_EMPLOYEE_FILTER_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
  


                  case surveyConstants.USER_ASSIGN_ROLE_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case surveyConstants.USER_ASSIGN_ROLE_SUCCESS:
                    return {
                      ...state,     
                      loading: false,
                      assignRoleSuccess: true,
    
                    };
                  case surveyConstants.USER_ASSIGN_ROLE_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };
    
  

                    case surveyConstants.UPDATE_EMPLOYEE_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case surveyConstants.UPDATE_EMPLOYEE_SUCCESS:
                      return {
                        ...state,     
                        loading: false,
                        updateEmployeeSuccess: true
      
                      };
                    case surveyConstants.UPDATE_EMPLOYEE_FAILURE:
                      return {
                        ...state,
                        loading: false,
                        error: action.error
                      };
      

                      case surveyConstants.REMOVE_USER_ROLE_REQUEST:
                        return {
                          ...state,
                          loading: true
                        };
                      case surveyConstants.REMOVE_USER_ROLE_SUCCESS:
                        return {
                          ...state,     
                          loading: false,
                          removeRoleSuccess: true
        
                        };
                      case surveyConstants.REMOVE_USER_ROLE_FAILURE:
                        return {
                          ...state,
                          loading: false,
                          error: action.error
                        };
      
                        

                        case surveyConstants.UPDATE_SURVEY_DESIGN_REQUEST:
                          return {
                            ...state,
                            loading: true
                          };
                        case surveyConstants.UPDATE_SURVEY_DESIGN_SUCCESS:
                          return {
                            ...state,     
                            loading: false,
                            updateSurveyDesignSuccess: true
          
                          };
                        case surveyConstants.UPDATE_SURVEY_DESIGN_FAILURE:
                          return {
                            ...state,
                            loading: false,
                            error: action.error
                          };
        



            case surveyConstants.QUESTION_LIBRARY_REQUEST:
              return {
                ...state,
                loading: true
              };
            case surveyConstants.QUESTION_LIBRARY_SUCCESS:
              return {
                ...state,     
                loading: false,
                getQuestionLibraryListSuccess: true,
                getQuestionLibraryListData: action.survey.getQuestionLibraryListData.list

              };
            case surveyConstants.QUESTION_LIBRARY_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };







    case surveyConstants.ALL_FILTER_VALUES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case surveyConstants.ALL_FILTER_VALUES_SUCCESS:
      return {
        ...state,     
        getAllFilterValuesSuccess: true,
        loading: false,
        getAllFilterValuesData: action.survey.getAllFilterValuesData
      };
    case surveyConstants.ALL_FILTER_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


      case surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_REQUEST:
        return {
          ...state,
          loading: true
        };
      case surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_SUCCESS:
        return {
          ...state,     
          updateEmployeeFilterRankSuccess: true,
          loading: false,
          // getAllFilterValuesData: action.survey.getAllFilterValuesData
        };
      case surveyConstants.UPDATE_EMPLOYEE_FILTER_RANK_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };
  

      case surveyConstants.GET_ASSIGNED_ROLES_REQUEST:
        return {
          ...state,
          loading: true
        };
      case surveyConstants.GET_ASSIGNED_ROLES_SUCCESS:
        return {
          ...state,     
          getAssignedRolesSuccess: true,
          loading: false,
          getAssignedRolesData: action.survey.getAssignedRolesData
        };
      case surveyConstants.GET_ASSIGNED_ROLES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };




        case surveyConstants.GET_INDEX_BY_FILTER_DMG_REQUEST:
          return {
            ...state,
            loading: true
          };
        case surveyConstants.GET_INDEX_BY_FILTER_DMG_SUCCESS:
          return {
            ...state,     
            getIndexByFilterDMGSuccess: true,
            loading: false,
            getIndexByFilterDMGData: action.survey.getIndexByFilterDMGData

          };
        case surveyConstants.GET_INDEX_BY_FILTER_DMG_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };




          case surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_REQUEST:
            return {
              ...state,
              loading: true
            };
          case surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_SUCCESS:
            return {
              ...state,     
              getResponseAnalyticsPaginationSuccess: true,
              loading: false,
              getResponseAnalyticsPaginationData: action.survey.getResponseAnalyticsPaginationData.list,
              getResponseAnalyticsPaginationTotal: action.survey.getResponseAnalyticsPaginationData.total

            };
          case surveyConstants.GET_RESPONSE_ANALYTICS_PAGINATION_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };





        case surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_REQUEST:
          return {
            ...state,
            loading: true
          };
        case surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_SUCCESS:
          return {
            ...state,     
            loading: false,
            getAllResponseAnalyticsBySurveyIdSuccess: true,
            getAllResponseAnalyticsBySurveyIdData: action.survey.getAllResponseAnalyticsBySurveyIdData

          };
        case surveyConstants.GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
    
    
    





    case surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_REQUEST:
      return {
        ...state,
        loading: true,
        resCustomerAnalyticsLoading:true
      };
    case surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_SUCCESS:
      return {
        ...state,     
        loading: false,
        customerResponsePaginationByCampaignIdSuccess: true,
        customerResponsePaginationByCampaignIdData: action.survey.customerResponsePaginationByCampaignIdData,
        resCustomerAnalyticsLoading:false

      };
    case surveyConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_FAILURE:
      return {
        ...state,
        loading: false,
        resCustomerAnalyticsLoading:false,
        error: action.error
      };



      
    case surveyConstants.GET_CUSTOMER_RESPONSE_DAYWISE_REQUEST:
      return {
        ...state,
        loading: true,
        resCustomerAnalyticsLoading:true
      };
    case surveyConstants.GET_CUSTOMER_RESPONSE_DAYWISE_SUCCESS:
      return {
        ...state,     
        loading: false,
        customerResponseDaywiseByCampaignIdSuccess: true,
        customerResponseDaywiseByCampaignIdData: action.survey.customerResponseDaywiseByCampaignIdData,
        resCustomerAnalyticsLoading:false

      };
    case surveyConstants.GET_CUSTOMER_RESPONSE_DAYWISE_FAILURE:
      return {
        ...state,
        loading: false,
        resCustomerAnalyticsLoading:false,
        error: action.error
      };





    default:
      return state
  }
}