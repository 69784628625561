import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { dashboardActions, questionsActions, surveyActions, userActions } from '../../_actions';

//Layout
import Sidebar from './Sidebar/Sidebar';

//Components...Index..
import HappinesIndex from './Index/HappinessIndex/HappinessIndex';
import EngagementIndex from './Index/EngagementIndex/EngagementIndex';
import StressIndex from './Index/StressIndex/StressIndex';
import ManagerIndex from './Index/ManagerIndex/ManagerIndex';

import OrgCore from './OrgCore/OrgCore';

//Managerr
import ManagerList from './ManagerList/ManagerList';

//libraries
import LoadingOverlay from 'react-loading-overlay';

import Overview from './Overview/Overview';

import Header from './Header/Header';
import AdvanceComparision from './AdvanceComparision/AdvanceComparision';
import TextAnalysis from './TextAnalysis/TextAnalysis';
import StrengthAndWeaknesses from './StrengthAndWeaknesses/StrengthAndWeaknesses';
import LeadershipIndex from './Index/LeadershipIndex/LeadershipIndex';
import ManagerActionPlanSelect from './ManagerActionPlanSelect/ManagerActionPlanSelect';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexType:0,
      CurrentSurveyName:'',
      CurrentSurveyId:this.props.match.params.id,
      optionType:0,
      showFilterDropdownName:false,
      showFilterDropdownValue:false,
      CurrentIndexName:'',
      filterSelectIndex:0,
      loaderActive:false,
      filterOn:false,
      FilterList2:[],
      showFilter:true,

      showOverview:true,
      showIndex:false,
      showManagerList:false,
      showOrgCore:false,
      showAdvanceComparision:false,
      showTextAnalysis:false,
      showStrengthAndWeaknesses:false,
      showManagerActionPlan:false,

      StackObject:[],
      StackObjectTotal:[],
      ReturnFinalFilterList:[],
      selectedFilterList:[],
      selectedFilterValue:'none',
      HeatMapSelectedFilter:'Department',

      DynaObj:[],

      Mindextype:'',

      ManagerDynaObj:[],

      QuesDynaObj:{},

      QuesMngrHappinessReportObject:{},
      QuesMngrEngagementReportObject:{},
      QuesMngrStressReportObject:{},
      QuesMngrManagerReportObject:{},

      DriverMngrHappinessReportObject:{}, 
      DriverMngrEngagementReportObject:{}, 
      DriverMngrStressReportObject:{},
      DriverMngrManagerReportObject:{},

      MQuesIndextype:'',
      rwaIndexType:1,
      ManagerListDasboardDataObj:[],
      ManagerListQuestionDataObj:[],

      showSidebar:true
      
    }
  }

  
  componentDidMount() {

    let data1 = {
        "keyWord": "",
        "pageNo": 1,
        "size": 500
    }

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }

    
    let data5 = {
      "surveyId": this.state.CurrentSurveyId
    }




    //=============== org core


    let data19 = {
      filters: [{level: "ManagerPID", value: "11000341"}],
      surveyId: "60b9df102f68b86828ce1539",
      template: 2
    }
    this.props.dispatch(dashboardActions.getQuestion(data19));



    //-------



    this.props.dispatch(dashboardActions.getAllParameterList(data1));



    this.props.dispatch(dashboardActions.getRespondantDetails(data5));
    this.props.dispatch(dashboardActions.getIndex(data5));
    this.props.dispatch(surveyActions.getSurveyById(data5));


    

    this.props.dispatch(dashboardActions.getClientProfile());


    this.props.dispatch(dashboardActions.getEmpFilter());

    // this.props.dispatch(dashboardActions.getCompanyEmployeeList(data1));

    this.props.dispatch(dashboardActions.getIndexFilter(data2));

    this.props.dispatch(dashboardActions.getAllBenchMark());

    
    this.props.dispatch(dashboardActions.getComment(
      {
        "keyWord": "",
        "pageNo": 1,
        "size": 60
    }
    ));



    // HAPPINESS_INDEX: 1,
    // ENGAGEMENT_INDEX: 2,
    // HOPE_INDEX: 3,
    // STRESS_INDEX: 4,
    // MANAGER_INDEX: 5,
    // LEADER_INDEX: 6,
    // SENIORLEADERSHIP_INDEX: 7,
    // ORGANIZATIONCORE_INDEX: 8,



  //   let data5 = {
  //     "surveyId": "5fa04d316ede0f0336066cce",
  //     "xAxis": "",
  //     "filters": null
  // }
  //   this.props.dispatch(dashboardActions.getOrgCoreIndex(data5));






 


  }

  handleLogout=()=>{
    this.props.dispatch(userActions.logout());
  }

  handleSidebar=(check)=>{
    this.setState({showSidebar:check})
  }


  handleSummaryCard=(name)=>{
    let { CurrentSurveyId } = this.state;

    let { indexType } = this.state;
    let data={
      "surveyId": CurrentSurveyId,
      "xAxis": name
    }
    this.props.dispatch(dashboardActions.getDemographicIndex(data));
    
    if(indexType===1){
      this.props.dispatch(dashboardActions.getHappinessIndexV2Demographic(data));
    }
    else if(indexType === 2){
      this.props.dispatch(dashboardActions.getEngagementIndexV2Demographic(data));
    }
    else if(indexType === 4){
      this.props.dispatch(dashboardActions.getStressIndexV2Demographic(data));
    }
    else if(indexType === 5){
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if(indexType === 6){
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
  }

  handleSummaryFilter=(name,value)=>{
    let { CurrentSurveyId } = this.state;
    let data={
      "surveyId": CurrentSurveyId,
      "filters": [
          {"level": name,"value":value}
      ]
    }
    this.props.dispatch(dashboardActions.getIndexByFilter(data));
  }

  handleQuestionFilter=(name,value)=>{
    let { indexType , CurrentSurveyId } = this.state;
    let data={
      "surveyId": CurrentSurveyId,
      "template": indexType,
      "filters": [
          {"level": name,"value":value}
      ]
    }
    // //console.log('data: ',data)
    this.props.dispatch(dashboardActions.getQuestionFilter(data));
  }

  handleDistributionFilter=(name)=>{
    let { indexType } = this.state;
    let data={
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": name
  }
    if(indexType===1){
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
    }
    else if(indexType === 2){
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
    }
    else if(indexType === 4){
      this.props.dispatch(dashboardActions.getDemographicIndexStress(data));
    }
    else if(indexType === 5){
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if(indexType === 6){
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
    else if(indexType === 8){
      this.props.dispatch(dashboardActions.getDemographicIndexOrg(data));
    }
  }




  onOptionType=(NoData,indexType,optionType)=>{
    
    this.handleSidebar(false);

    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;

    let filterNames = ''
    if(EmpFilterData && EmpFilterData.length>0){
        filterNames =  EmpFilterData[0].name;
    }




    // this.setState({CurrentSurveyId:surveyId})

    let surveyId = this.state.CurrentSurveyId;

    let { 
        getHappinessIndexDriverData,
        getEngagementIndexDriverData,
        getStressIndexDriverData,
        getManagerIndexDriverData,
        getLeaderIndexDriverData,
        getOrgIndexDriverData
     } = dashboard;

    this.setState({
      showOverview:false,
      showIndex:true,
      showManagerList:false,
      showOrgCore:false,
      showAdvanceComparision:false,
      showTextAnalysis:false,
      showStrengthAndWeaknesses:false,
      showManagerActionPlan:false


    })
    this.setState({indexType:indexType, optionType:optionType})

    function callIndexapi(indexType,props,data){
      if(indexType===1){
        props.dispatch(dashboardActions.getHappinessIndexV2(data));
      }
      else if(indexType === 2){
        props.dispatch(dashboardActions.getEngagementIndexV2(data));
      }
      else if(indexType === 4){
        props.dispatch(dashboardActions.getStressIndexV2(data));
      }
      else if(indexType === 5){
        // props.dispatch(dashboardActions.getManagerIndexV2(data));
      }
      else if(indexType === 6){
        // props.dispatch(dashboardActions.getLeaderIndexV2(data));
      }
      else if(indexType === 8){
        props.dispatch(dashboardActions.getOrgIndexV2(data));
      }
    }

    function callDemographicapi(indexType,props,data,
      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,
      getOrgIndexDriverData,
      
      ){
      if(indexType===1){
        props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
        props.dispatch(dashboardActions.getHappinessIndexDriver({surveyId:data.surveyId}));

      }
      else if(indexType === 2){
        props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
        props.dispatch(dashboardActions.getEngagementIndexDriver({surveyId:data.surveyId}));

      }
      else if(indexType === 4){
        props.dispatch(dashboardActions.getDemographicIndexStress(data));
        props.dispatch(dashboardActions.getStressIndexDriver({surveyId:data.surveyId}));

      }
      else if(indexType === 5){
        props.dispatch(dashboardActions.getDemographicIndexManager(data));
        props.dispatch(dashboardActions.getManagerIndexDriver({surveyId:data.surveyId}));

      }
      else if(indexType === 6){
        props.dispatch(dashboardActions.getDemographicIndexLeader(data));
        props.dispatch(dashboardActions.getLeaderIndexDriver({surveyId:data.surveyId}));
        
      }
      else if(indexType === 8){
        props.dispatch(dashboardActions.getDemographicIndexOrg(data));
        props.dispatch(dashboardActions.getOrgIndexDriver({surveyId:data.surveyId}));

      }
    }


    if(indexType){
      if(indexType===102){ //MANAGER INSIGHT
        this.onManagerList(surveyId);
      }
      else if(indexType===103){ // ADVANCE INSIGHT
        this.onAdvanceComparision(surveyId,filterNames);
      }
      else if(indexType===104){ // TEXT ANALYSIS
        this.onTextAnalysis(surveyId);
      }
      else if(indexType===105){ // STRENGHT & WEAKNESS
        this.onStrengthAndWeaknessess(surveyId);
      }
      else if(indexType===106){ // MANAGER ACTION PLAN
        // this.managerActionPlan(surveyId);
        this.onManagerActionPlanSelect(surveyId);

      }
      else{
        
            if(optionType===1){
              let data1 ={
                "surveyId": surveyId
              }
              let data3 ={
                "surveyId": surveyId,
                "index": indexType

              }
              this.props.dispatch(dashboardActions.cronbatchAlphaVariance(data3));
              // this.props.dispatch(dashboardActions.getRespondantDetails(data1));
              // this.props.dispatch(dashboardActions.getIndex(data1));

              if(indexType===2){
                let data6={
                  "keyWord": "",
                  "pageNo": 1,
                  "size": 10
              }
                this.props.dispatch(dashboardActions.getHistoryIndex(data6));

              }

              callIndexapi(indexType,this.props,data1);


            }
            else if(optionType===2){
              let data3 ={
                "surveyId": surveyId,
                "xAxis": filterNames
              }
              callDemographicapi(indexType,this.props,data3,
                getHappinessIndexDriverData,
                getEngagementIndexDriverData,
                getStressIndexDriverData,
                getManagerIndexDriverData,
                getLeaderIndexDriverData,
                getOrgIndexDriverData,)
            }
            else if(optionType===3){
              let data2 ={
                "surveyId": surveyId,
                "template": indexType
              }
              this.props.dispatch(dashboardActions.getQuestion(data2));
            }
            else if(optionType===4){
              let data2 ={
                "surveyId": surveyId,
                "template": indexType
              }
              this.props.dispatch(dashboardActions.getQuestion(data2));
            }
            else if(optionType===5){
              let data2 ={
                "surveyId": surveyId,
                "index": indexType
              }
              this.props.dispatch(dashboardActions.getIndexQuantile(data2));
            }
      }
      
      
    }
    else{
      this.setState({
        showOverview:true,
        showIndex:false,
        showManagerList:false,
        showOrgCore:false,
        showAdvanceComparision:false,
        showTextAnalysis:false,
        showStrengthAndWeaknesses:false,
        showManagerActionPlan:false,



      })
    }



  }




  managerActionPlan=()=>{

    this.props.dispatch(dashboardActions.getAllManagerActionPlan({surveyId:this.state.CurrentSurveyId}));
    this.props.dispatch(dashboardActions.getDemographicIndexForManager({surveyId:this.state.CurrentSurveyId}))

    this.setState({
      showOverview:false,
      showIndex:false,
      showManagerList:false,
      showOrgCore:false,
      showAdvanceComparision:false,
      showTextAnalysis:false,
      showStrengthAndWeaknesses:false,
      showManagerActionPlan:true,


      SelectedAPDimension:'',
      SelectedAPAction:'',
      SelectedAPTask:''
    });
  }



  onStrengthAndWeaknessess=(surveyId)=>{
    this.setState({
      showOverview:false,
      showIndex:false,
      showManagerList:false,
      showOrgCore:false,
      showAdvanceComparision:false,
      showTextAnalysis:false,
      showStrengthAndWeaknesses:true,
      showManagerActionPlan:false
    })



    let data2 =

    {
      "surveyId": this.state.CurrentSurveyId,
      // "index": 5,
      "limit": 10,
      "top": true,
      "filters":[]
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));

  }



  handleStrengthQuestionFilter=(FilterName,FilterValue)=>{
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      // "index": 5,
      "limit": 10,
      "top": true,
      "filters":[
        {"level": FilterName,"value":FilterValue}
      ]
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));
  }





  handleWeaknessesQuestionFilter=(FilterName,FilterValue)=>{
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      // "index": 5,
      "limit": 10,
      "top": false,
      "filters":[
        {"level": FilterName,"value":FilterValue}
      ]
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));
  }



  onWeaknessQuestionCall=()=>{
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      // "index": 5,
      "limit": 10,
      "top": false,
      "filters":[]
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));
  }

  onStrengthQuestionCall=()=>{
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      // "index": 5,
      "limit": 10,
      "top": true,
      "filters":[]
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));
  }







  callQuestionWordCloud=(QuesId)=>{
    let { CurrentSurveyId } = this.state;
    let data2 ={
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": []
    }
    this.props.dispatch(dashboardActions.wordCloud(data2));
  }


  callQuestionNetworkCloud=(QuesId, graphType, FilterList)=>{
    let { CurrentSurveyId } = this.state;
    let data2 ={
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": FilterList&&FilterList.length>0?FilterList:[]
    }

    if(graphType===1){
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));

    }
    else if(graphType===2){
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }


  }



  

  onTextAnalysis=(surveyId)=>{
    this.setState({
      showOverview:false,
      showIndex:false,
      showManagerList:false,
      showOrgCore:false,
      showAdvanceComparision:false,
      showTextAnalysis:true,
      showStrengthAndWeaknesses:false,
      showManagerActionPlan:false


    });


    let data2 ={
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));

  }


  handleWordCloudDemographicFilter=(QuesId, FilterValues)=>{

    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }

    let { CurrentSurveyId } = this.state;
    let data2 ={
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.wordCloud(data2));
  }


  callWordCloudText=(QuesId, keyWord, FilterList, FilterValue, isMultiKeyword)=>{
    let { CurrentSurveyId } = this.state;


    if(isMultiKeyword){
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "keyWord1": keyWord && keyWord[0]?keyWord[0]:"",
        "keyWord2": keyWord && keyWord[1]?keyWord[1]:"",
        "filters": FilterList && FilterList.length>0?FilterList:[]
      }
      this.props.dispatch(dashboardActions.wordCloudMultiText(data2));
    }
    else{
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "keyWord": keyWord,
        "filters": FilterList && FilterList.length>0?FilterList:[]
      }
        this.props.dispatch(dashboardActions.wordCloudText(data2));
    }
  }




  handleQuantileFilter = (Name, Value) =>{
    let { CurrentSurveyId, indexType } = this.state;
    let data2 ={
      "surveyId": CurrentSurveyId,
      "index": indexType,
      "filters": [
        {"level": Name,"value":Value}
      ]
    }
    this.props.dispatch(dashboardActions.getIndexQuantile(data2));
  }







  onFilterDropdownName=(data)=>{
    if(this.state.showFilterDropdownName === data){
      this.setState({showFilterDropdownName:''})
    }
    else{
      this.setState({showFilterDropdownName:data})
    }
  }

  onFilterDropdownValue=()=>{
    if(this.state.showFilterDropdownValue){
      this.setState({showFilterDropdownValue:false})
    }
    else{
      this.setState({showFilterDropdownValue:true})
    }
  }


  onAdvanceComparision=(surveyId,Demographic)=>{
    let { dashboard } = this.props;
    
    // this.setState({CurrentSurveyId:surveyId})

    this.setState({
      showOverview:false,
      showIndex:false,
      showManagerList:false,
      showOrgCore:false,
      showAdvanceComparision:true,
      showTextAnalysis:false,
      showStrengthAndWeaknesses:false,
      showManagerActionPlan:false

    })
    
    let data2={
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": Demographic
    }
    this.props.dispatch(dashboardActions.getDemographicIndex(data2));

    this.props.dispatch(dashboardActions.getDemographicIndexScatter(data2));

  
  }




  onManagerList=(surveyId)=>{
    let { DynaObj } = this.state;
    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;

    this.setState({
      showOverview:false,
      showIndex:false,
      showManagerList:true,
      showOrgCore:false,
      showAdvanceComparision:false,
      showTextAnalysis:false,
      showStrengthAndWeaknesses:false,
      showManagerActionPlan:false



    })

    let data = {
      "surveyId": this.state.CurrentSurveyId,
    }

  // this.props.dispatch(dashboardActions.getManagerList(data))
  
  this.props.dispatch(dashboardActions.getDemographicIndexForManager(data, 5, DynaObj, EmpFilterData,surveyId))

  // this.setState({CurrentSurveyId:surveyId})

  let data2={
    "surveyId": this.state.CurrentSurveyId,
    "xAxis": "ManagerPID"
  }
  // this.props.dispatch(dashboardActions.getDemographicIndex(data2));

  }



  onManagerActionPlanSelect=(surveyId)=>{
    let { DynaObj } = this.state;
    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;

    this.setState({
      showOverview:false,
      showIndex:false,
      showManagerList:false,
      showOrgCore:false,
      showAdvanceComparision:false,
      showTextAnalysis:false,
      showStrengthAndWeaknesses:false,
      showManagerActionPlan:true

    })

    let data = {
      "surveyId": this.state.CurrentSurveyId,
    }

  // this.props.dispatch(dashboardActions.getManagerList(data))

  this.props.dispatch(dashboardActions.getAllManagerActionPlan(data));

  this.props.dispatch(dashboardActions.getDemographicIndexForManager(data, 5, DynaObj, EmpFilterData,surveyId))

  // this.setState({CurrentSurveyId:surveyId})

  let data2={
    "surveyId": this.state.CurrentSurveyId,
    "xAxis": "ManagerPID"
  }
  // this.props.dispatch(dashboardActions.getDemographicIndex(data2));

  }


  handleManagerHeatmap=(filterdata)=>{

    let { CurrentSurveyId, DynaObj } = this.state;
    let data={
      "surveyId": CurrentSurveyId,
      "filters": [
          {"level": filterdata.level,"value":filterdata.value}
      ]
    }
    // DynaObj=[]
    // //console.log('filterdata:',data)
    
    this.setState({DynaObj})

    // this.props.dispatch(dashboardActions.getIndexFilter(data,5,DynaObj));
    this.props.dispatch(dashboardActions.getManagerListDomainFilter(data,5,DynaObj));

  }

  handleManagerListIndexQuestionCall=(option,mngr)=>{
    let {CurrentSurveyId,ManagerListQuestionDataObj}= this.state;
    this.setState({Mindextype:option});


    let data2 ={
      "surveyId": CurrentSurveyId,
      "template": option,
      "filters":[
        {
          "level":"ManagerPID",
          "value":mngr.id
        }
      ]
    }
    this.props.dispatch(dashboardActions.getQuestionOverall({
      "surveyId": CurrentSurveyId,
      "template": option,
      "filters":[]
    }));
    this.props.dispatch(dashboardActions.getMultiManagerQuestionList(data2, mngr, ManagerListQuestionDataObj));
  }

  handleManagerQuestion=(data, isReport)=>{
    let { CurrentSurveyId, QuesDynaObj,

      QuesMngrHappinessReportObject, 
      QuesMngrEngagementReportObject, 
      QuesMngrStressReportObject,
      QuesMngrManagerReportObject
    
    } = this.state;
    // //console.log('-------------------------------> isReport',isReport)

    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;


    if(EmpFilterData && EmpFilterData.length>0){
      console.log('-------------------------------> EmpFilterData',EmpFilterData)

      QuesDynaObj["ManagerPID"] = []
      QuesMngrHappinessReportObject["ManagerPID"] = []
      QuesMngrEngagementReportObject["ManagerPID"] = []
      QuesMngrStressReportObject["ManagerPID"] = []
      QuesMngrManagerReportObject["ManagerPID"] = []

      EmpFilterData.forEach((emp)=>{
        QuesDynaObj[emp.name] = []
        QuesMngrHappinessReportObject[emp.name] = []
        QuesMngrEngagementReportObject[emp.name] = []
        QuesMngrStressReportObject[emp.name] = []
        QuesMngrManagerReportObject[emp.name] = []
      });
    }

    

    this.setState({MQuesIndextype:data.template})

    let data2 ={
      "surveyId": CurrentSurveyId,
      "template": data.template,
      "filters":data.filters
    }
    console.log("getQuestion-->2",data2)


    // //console.log('data2: ',data2)
    if(!isReport){
      this.props.dispatch(dashboardActions.getQuestion(data2));
      this.props.dispatch(dashboardActions.getQuestionOverall({
        "surveyId": CurrentSurveyId,
        "template": data.template,
        "filters":[]
      }));
    }

    for(let i =  0; i < 2 ; i ++){


        if(data.template===1){
          this.props.dispatch(dashboardActions.getQuestionManagerOfHappiness(data2,5,QuesDynaObj, isReport, QuesMngrHappinessReportObject));
        }
        if(data.template===2){
          this.props.dispatch(dashboardActions.getQuestionManagerOfEngagement(data2,5,QuesDynaObj, isReport, QuesMngrEngagementReportObject));
        }
        if(data.template===4){
          this.props.dispatch(dashboardActions.getQuestionManagerOfStress(data2,5,QuesDynaObj, isReport, QuesMngrStressReportObject));
        }
        if(data.template===5){
          this.props.dispatch(dashboardActions.getQuestionManagerOfManager(data2,5,QuesDynaObj, isReport, QuesMngrManagerReportObject));
        }

  }


  }


  handleManagerWiseDriver=(Mindextype, ManagerPID, filterName,filters,isReport)=>{
    let { CurrentSurveyId, ManagerDynaObj,

      DriverMngrHappinessReportObject, 
      DriverMngrEngagementReportObject, 
      DriverMngrStressReportObject,
      DriverMngrManagerReportObject
    
    } = this.state;
    
    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;


    if(EmpFilterData && EmpFilterData.length>0){
      console.log('-------------------------------> EmpFilterData',EmpFilterData)
      ManagerDynaObj["ManagerPID"] = []
      DriverMngrHappinessReportObject["ManagerPID"] = []
      DriverMngrEngagementReportObject["ManagerPID"] = []
      DriverMngrStressReportObject["ManagerPID"] = []
      DriverMngrManagerReportObject["ManagerPID"] = []

      EmpFilterData.forEach((emp)=>{
        ManagerDynaObj[emp.name] = []
        DriverMngrHappinessReportObject[emp.name] = []
        DriverMngrEngagementReportObject[emp.name] = []
        DriverMngrStressReportObject[emp.name] = []
        DriverMngrManagerReportObject[emp.name] = []
      });
    }



    this.setState({Mindextype:Mindextype})


    let tempdata ={
      "surveyId": CurrentSurveyId,
      "xAxis": filterName
  }
    if(Mindextype === 1){
      this.IndexDriverScore(Mindextype)
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(tempdata,5,ManagerDynaObj, isReport, DriverMngrHappinessReportObject,filters));
    }
    if(Mindextype === 2){
      this.IndexDriverScore(Mindextype)
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(tempdata,5,ManagerDynaObj, isReport, DriverMngrEngagementReportObject,filters));
    }
    if(Mindextype === 4){
      this.IndexDriverScore(Mindextype)
      this.props.dispatch(dashboardActions.getDemographicIndexStress(tempdata,5,ManagerDynaObj, isReport, DriverMngrStressReportObject,filters));
    }
    if(Mindextype === 5){
      this.IndexDriverScore(Mindextype)
      this.props.dispatch(dashboardActions.getDemographicIndexManager(tempdata,5,ManagerDynaObj, isReport, DriverMngrManagerReportObject,filters));
    }
    if(Mindextype === 6){
      // this.IndexDriverScore(Mindextype)
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(tempdata,5,ManagerDynaObj));
    }
  }

  handleMultiManagerWiseDriver=(OptionM,mngr)=>{
    this.setState({Mindextype:OptionM})

    let { CurrentSurveyId, ManagerListDriverObj } = this.state;
    let tempdata ={
      "surveyId": CurrentSurveyId,
      "xAxis": "ManagerPID"
  }

    if(OptionM === 1){
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(tempdata,0,ManagerListDriverObj));
      // //console.log('--------------------------------------------------------------------------------------------------------->----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------','>>>');
    }
    else if(OptionM === 2){
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(tempdata,0,ManagerListDriverObj));
    }
    else if(OptionM === 4){
      this.props.dispatch(dashboardActions.getDemographicIndexStress(tempdata,0,ManagerListDriverObj));
    }
    else if(OptionM === 5){
      this.props.dispatch(dashboardActions.getDemographicIndexManager(tempdata,0,ManagerListDriverObj));
    }
    else if(OptionM === 6){
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(tempdata,0,ManagerListDriverObj));
    }
  }


  handleQuadrantFilter=(val)=>{
    let { CurrentSurveyId } = this.state;
    this.onAdvanceComparision(CurrentSurveyId,val);
  }
  


  handleCorelation=(validParameterList)=>{
    let { CurrentSurveyId } = this.state;
    let { dashboard , survey } = this.props;
    let { EmpFilterData } = dashboard;
    let { getSurveyData } = survey;

    let filterNames = ''
    if(EmpFilterData && EmpFilterData.length>0){
        filterNames =  EmpFilterData[0].name;
    }




    let tempdata2 ={
      "surveyId": CurrentSurveyId,
      "filters":[],
      "targetDimension":'',
      "corrDimenionList":[]
    }



    //Organization parameter names

    let ParameterList = [];

    if(validParameterList && validParameterList.length>0){
      ParameterList = validParameterList;
    }

    if(getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length>0){
      getSurveyData.orgList.forEach((org)=>{
        ParameterList.push(org.name)
      });
        }

    if(ParameterList && ParameterList.length>1){
      let count = 1
      ParameterList.forEach((para,index)=>{
        if(index===0){
          tempdata2["targetDimension"] = para
        }
        else{
          if(ParameterList.length>2){
            if(count<5){
              tempdata2["corrDimenionList"].push(para);
              count++
            }
          }
          else{
              tempdata2["corrDimenionList"].push(para);
              count++
          }
        }




      });

      console.log('-----c-c-c-c-c-c-ParameterList',ParameterList)

      this.props.dispatch(dashboardActions.getCorrelationDimenionVsDimension(tempdata2));

    }





    // if(val === 1){
    //   this.props.dispatch(dashboardActions.getHappinessCorrelationOverAll(tempdata2));
    // }
    // else if(val === 2){
    //   let tempdataV2 ={
    //     "surveyId": CurrentSurveyId,
    //     "filters":[],
    //     "targetDimension":'Respect',
    //     "corrDimenionList":['Pride','Presence','Performance']
    //   }
    //   this.props.dispatch(dashboardActions.getEngagementCorrelationOverAll(tempdataV2));

    // }
    // else if(val === 4){
    //   this.props.dispatch(dashboardActions.getStressCorrelationOverAll(tempdata2));
    // }
    // else if(val === 5){


    //   let tempdataV2 ={
    //     "surveyId": CurrentSurveyId,
    //     "filters":[],
    //     "targetDimension":'Respect',
    //     "corrDimenionList":['Pride','Presence','Performance']
    //   }
    //   this.props.dispatch(dashboardActions.getEngagementCorrelationOverAll(tempdataV2));
    // }
    // else if(val === 6){
    //   this.props.dispatch(dashboardActions.getLeaderCorrelationOverAll(tempdata2));
    // }




  }


  handleCorrelationFilter = (val, demographic)=>{
    // console.log('handleCorrelationFilter: ',val, demographic)
    let { CurrentSurveyId } = this.state;
    let tempdata ={
      "surveyId": CurrentSurveyId,
      // "xAxis": demographic,
      "filters":[]
  }



  
        let tempdata2 ={
          "surveyId": CurrentSurveyId,
          "filters":[],
          "targetDimension":'Respect',
          "corrDimenionList":['Pride','Presence','Performance','Connect','ACT']
        }



      this.props.dispatch(dashboardActions.getCorrelationDimenionVsDimension(tempdata2));



    // if(val === 1){
    //   this.props.dispatch(dashboardActions.getHappinessCorrelationOverAll(tempdata));
    // }
    // else if(val === 2){
    //   this.props.dispatch(dashboardActions.getEngagementCorrelationOverAll(tempdata));
    // }
    // else if(val === 4){
    //   this.props.dispatch(dashboardActions.getStressCorrelationOverAll(tempdata));
    // }
    // else if(val === 5){
    //   this.props.dispatch(dashboardActions.getManagerCorrelationOverAll(tempdata));
    // }
    // else if(val === 6){
    //   this.props.dispatch(dashboardActions.getLeaderCorrelationOverAll(tempdata));
    // }



  }


  onRWAnalysis = (Filter,InnerIndexType) =>{
    let { CurrentSurveyId } = this.state;

    let { getIndexData }= this.props.dashboard;
    // console.log('getIndexData Input----->: ',getIndexData)

    let data2 ={
      "surveyId": CurrentSurveyId,
      "index": InnerIndexType,
      "filters": Filter,
      "addValueTemplate":[]
    }

    if(getIndexData && getIndexData["5"]){
      data2["addValueTemplate"].push(5)
    }
    
    if(getIndexData && getIndexData["6"]){
      data2["addValueTemplate"].push(6)
    }

    
    this.setState({rwaIndexType:InnerIndexType})



    this.props.dispatch(dashboardActions.getEngagementRWA(data2));


  }


  handleRWAFilter=(Filter,innerIndex, name, value)=>{
    let { CurrentSurveyId } = this.state;
    
    let data2 = {}
    if(name === 'none'){
      data2 ={
        "surveyId": CurrentSurveyId,
        "index": innerIndex,
        "filters":[]
      }
    }
    else{
      data2 ={
        "surveyId": CurrentSurveyId,
        "index": innerIndex,
        "filters":[
          {"level":name, "value":value}
        ]
      }
    }


    this.setState({rwaIndexType:innerIndex})

    // console.log('onRWAnalysis Input----->: ',data2)


    this.props.dispatch(dashboardActions.getEngagementRWA(data2));

  }

  onWorldCloud = () =>{
    let { CurrentSurveyId } = this.state;

    let data2 ={
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2,1));

  }



  onNetworkGraph = () =>{
    let { CurrentSurveyId } = this.state;

    let data2 ={
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2,2));

  }


  onAdvanceWorldCloud = () =>{
    let { CurrentSurveyId } = this.state;

    let data2 ={
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2,4));

  }


  handleNeighborGraph = (FilterList) =>{
    let { CurrentSurveyId } = this.state;

    let data2 ={
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2,3,FilterList));

  }


  
  // handleNetworkDemographicFilter=(Qid, Name, Value, graphType)=>{
  //   let { CurrentSurveyId } = this.state;
  //   let data2 = {};
  //   if(Name==='none'){
  //     data2 ={
  //       "surveyId": CurrentSurveyId,
  //       "questionId": Qid,
  //       "filters": []
  //     }
  //   }
  //   else{
  //     data2 ={
  //       "surveyId": CurrentSurveyId,
  //       "questionId": Qid,
  //       "filters": [
  //         {"level": Name,"value":Value}
  //       ]
  //     }
  //   }

  //   console.log('----asadata2',data2)

  //   if(graphType===1){
  //     this.props.dispatch(dashboardActions.cooccurmatrix(data2));
  //   }
  //   else if(graphType===2){
  //     this.props.dispatch(dashboardActions.neighborGraph(data2));
  //   }

  // }
  handleNetworkDemographicFilter=(Qid,FilterValues, graphType)=>{

    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }


    let { CurrentSurveyId } = this.state;
    let data2 = {};
      data2 ={
        "surveyId": CurrentSurveyId,
        "questionId": Qid,
        "filters":Filters
      }
    

    // console.log('----asadata2',data2)

    if(graphType===1){
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));
    }
    else if(graphType===2){
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }

  }



  onOrgQuestion=()=>{
    let { CurrentSurveyId, indexType } = this.state;
    let data2 ={
      "surveyId": CurrentSurveyId,
      "template": indexType
    }
    this.props.dispatch(dashboardActions.getQuestion(data2));
  }


  onOverviewFromHeader=()=>{
    let { CurrentSurveyId, showOverview } = this.state;
    if(showOverview){
      this.props.history.goBack()
    }
    else{
      this.onOptionType(CurrentSurveyId,0,1);
    }


  }



  ChooseSurvey=(val)=>{
    

    // this.setState({CurrentSurveyId:val})

          let data2 = {
            "surveyId": this.state.CurrentSurveyId
          }

          this.props.dispatch(dashboardActions.getRespondantDetails(data2));
          this.props.dispatch(dashboardActions.getIndex(data2));



  }


  handleMultifilterIndexScore=(FilterValues,isAllTwice,name)=>{
   
    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": (isAllTwice)?name:XAxis,
      "filters":Filters
    }

    console.log('data7:::',data7);

    this.props.dispatch(dashboardActions.getDemographicIndexMultiFilter(data7));
  
    if(this.state.indexType===1){
      this.props.dispatch(dashboardActions.getHappinessIndexV2Demographic(data7));
    }
    else if(this.state.indexType===2){
      this.props.dispatch(dashboardActions.getEngagementIndexV2Demographic(data7));
    }
    else if(this.state.indexType===4){
      this.props.dispatch(dashboardActions.getStressIndexV2Demographic(data7));
    }

  }


  handleMultifilterBehaviour=(FilterValues, isFilterTwice, name)=>{
    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice?name:XAxis,
      "filters":Filters
    }

    // console.log('data7:::',data7);
  
    if(this.state.indexType===1){
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(data7));
    }
    else if(this.state.indexType===2){
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(data7));
    }
    else if(this.state.indexType===4){
      this.props.dispatch(dashboardActions.getDemographicIndexStress(data7));
    }
    else if(this.state.indexType===5){
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data7));
    }
    else if(this.state.indexType===6){
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data7));
    }

  }


  handleMultiFilterGetQuestion=(FilterValues)=>{
    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.indexType,
      "filters":Filters
    }
    //console.log("getQuestion-->1",data7)

    // //console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getQuestion(data7));
  }
 

  handleMultiFilterQuantile=(FilterValues, clear)=>{
    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }



    let data7 = {}
    if(clear){
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "index": this.state.indexType,
        "filters":[]
      }
    }
    else{
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "index": this.state.indexType,
        "filters":Filters
      }
    }

    // //console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getIndexQuantile(data7));
  }



  handleFilterRWA=(FilterValues,IndexType)=>{
    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }


    let { getIndexData }= this.props.dashboard;



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "index": IndexType,
      "filters":Filters,
      "addValueTemplate":[]
    }

    if(getIndexData && getIndexData["5"]){
      data7["addValueTemplate"].push(5)
    }
    
    if(getIndexData && getIndexData["6"]){
      data7["addValueTemplate"].push(6)
    }
    // //console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getEngagementRWA(data7));
  }



  handleMultiFilterAdvanc4Quadrant=(FilterValues, isFilterTwice, name)=>{
    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice?name:XAxis,
      "filters":Filters
    }

    console.log('------------------------------------------->>>>.data7:::',name,isFilterTwice,data7);
    // //console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getDemographicIndex(data7));

  }


  handleMultiFilterAdvancScatter=(FilterValues,isFilterTwice, name)=>{
    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice?name:XAxis,
      "filters":Filters
    }

    //console.log('data7:::',data7);
    // //console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getDemographicIndexScatter(data7));
  }



  handleMultiFilterCorrelation=(FilterValues, selectedTargetValue, compareDimenList)=>{
    let XAxis = "";
    let Filters = [];
    let data7 ={};
    if(FilterValues && FilterValues.length>0){


    let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
    if(getIndex !== -1){
      FilterValues.forEach((item)=>{
        if(item.value !== "All"){
          Filters.push(item);
        }
      });
      if(Filters && Filters.length>0){
        XAxis = Filters[Filters.length-1].level;
      }
    }
    else{
      XAxis = FilterValues[FilterValues.length-1].level;
      Filters = FilterValues
    }


            
    data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": XAxis,
      "filters":Filters
    }



    }
    else{  
  
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "filters":[]
    }
  

    }


    //console.log('data7:::',data7);

    
          // let tempdata2 ={
          //   "surveyId": CurrentSurveyId,
          //   "filters":[],
          //   "targetDimension":'Respect',
          //   "corrDimenionList":['Pride','Presence','Performance','Connect','ACT']
          // }

        data7["targetDimension"]=selectedTargetValue;
        data7["corrDimenionList"]=compareDimenList;


        if(data7 && data7["filters"] && data7["filters"].length>0){
          this.props.dispatch(dashboardActions.getCorrelationDimensionVsDimensionFilter(data7));

        }else{
          this.props.dispatch(dashboardActions.getCorrelationDimenionVsDimension(data7));
        }



  
    // if(subIndexType===1){

    //   if(data7 && data7.filters && data7.filters.length>0){
    //     this.props.dispatch(dashboardActions.getHappinessCorrelation(data7));
    //   }
    //   else{
    //     let Newdata7 = {
    //       "surveyId": this.state.CurrentSurveyId,
    //       "filters":[]
    //     }
    //     this.props.dispatch(dashboardActions.getHappinessCorrelationOverAll(Newdata7));
    //   }


    // }
    // else if(subIndexType===2){

    //   if(data7 && data7.filters && data7.filters.length>0){

    //     data7["targetDimension"]='Respect';
    //     data7["corrDimenionList"]=['Pride','Presence','Performance'];

    //     this.props.dispatch(dashboardActions.getEngagementCorrelation(data7));
    //   }
    //   else{
    //     let Newdata7 = {
    //       "surveyId": this.state.CurrentSurveyId,
    //       "filters":[]
    //     }
    //     this.props.dispatch(dashboardActions.getEngagementCorrelationOverAll(Newdata7));
    //   }

    // }
    // else if(subIndexType===4){

    //   if(data7 && data7.filters && data7.filters.length>0){
    //     this.props.dispatch(dashboardActions.getStressCorrelation(data7));
    //   }
    //   else{
    //     let Newdata7 = {
    //       "surveyId": this.state.CurrentSurveyId,
    //       "filters":[]
    //     }
    //     this.props.dispatch(dashboardActions.getStressCorrelationOverAll(Newdata7));
    //   }


    // }
    // else if(subIndexType===5){

    //   if(data7 && data7.filters && data7.filters.length>0){
    //     this.props.dispatch(dashboardActions.getManagerCorrelation(data7));
    //   }
    //   else{
    //     let Newdata7 = {
    //       "surveyId": this.state.CurrentSurveyId,
    //       "filters":[]
    //     }
    //     this.props.dispatch(dashboardActions.getManagerCorrelationOverAll(Newdata7));
    //   }


    // }
    // else if(subIndexType===6){

    //   if(data7 && data7.filters && data7.filters.length>0){
    //     this.props.dispatch(dashboardActions.getLeaderCorrelation(data7));
    //   }
    //   else{
    //     let Newdata7 = {
    //       "surveyId": this.state.CurrentSurveyId,
    //       "filters":[]
    //     }
    //     this.props.dispatch(dashboardActions.getLeaderCorrelationOverAll(Newdata7));
    //   }

    // }
  }


  onAssignManagerActionPlan=()=>{
    let { CurrentSurveyId  } = this.state;
    this.props.dispatch(dashboardActions.getAllManagerActionPlan({surveyId:CurrentSurveyId}));
    this.props.dispatch(dashboardActions.AssignManagerActionPlan({surveyId:CurrentSurveyId}));
  }


  handleMultifilterOrgDriverSummary=(FilterValues,isFilterTwice,name)=>{
    
    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice?name:XAxis,
      "filters":Filters
    }

    if(data7.xAxis){
      this.props.dispatch(dashboardActions.getDemographicIndexOrg(data7));
    }

    //console.log('data7:::',data7);
    // //console.log('data7:::',data7);

  }
 


  onSwitchIndexScreen=(newFilterObj, IndexDimension, CountRestrict)=>{
    let { dashboard  } = this.props;
    let { survey  } = this.props;

    let { loading,


  

      //new ---------------------------------------
      EmpFilterData, getIndexFilterData ,getDemographicIndexData,
      getQuestionData, getQuestionFilterData,
      getIndexData,

    
      

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getHopeIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,
      getLeaderIndexV2Data,
      getSeniorleadershipIndexV2Data,
      getOrgIndexV2Data,

      getDemographicIndexHappinessData,
      getDemographicIndexEngagementData,
      getDemographicIndexStressData,
      getDemographicIndexManagerData,
      getDemographicIndexLeaderData,
      getDemographicIndexOrgData,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,
      getOrgIndexDriverData,



      getHappinessIndexV2DemographicData,
      getEngagementIndexV2DemographicData,
      getStressIndexV2DemographicData,
      getManagerIndexV2DemographicData,

      getAllBenchMarkData,
      getRespondantDetailsData,


      getIndexQuantileData,


      handleQuantileFilter,


      getRWAnalysisData,

      wordCloudData,


      getEngagementRWAData,

      cronbatchAlphaVarianceData,

      getHistoryIndexData,


      getCommentData,
      getDemographicIndexMultiFilterData,

      getAllParameterListData,
      getIndexQuantileCount

    } = dashboard;



    let {indexType, optionType, CurrentSurveyId, rwaIndexType } = this.state;

    let { getSurveyData } = survey;

    console.log('getSurveyData:----------->',getSurveyData)



    function GetParameterLabel(name){
      let AllParameterObj = {};
      if(getAllParameterListData && getAllParameterListData.length>0){
        getAllParameterListData.forEach((item)=>{
          AllParameterObj[item.name] = item&&item.label?item.label:item.name;
        });
      }
      return (AllParameterObj&&AllParameterObj[name]?AllParameterObj[name]:name);
    }

    // console.log('GetParameterLabel("CareerOpportunity"):',GetParameterLabel("CareerOpportunity"))


    switch(indexType){
      case 1: return <HappinesIndex     XAxis={IndexDimension.Happiness}     getIndexQuantileCount={getIndexQuantileCount} CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel}  name={"Happiness"} handleMultiFilterQuantile={this.handleMultiFilterQuantile} handleMultiFilterGetQuestion={this.handleMultiFilterGetQuestion}    handleMultifilterBehaviour={this.handleMultifilterBehaviour}    handleMultifilterIndexScore={this.handleMultifilterIndexScore} getDemographicIndexMultiFilterData={getDemographicIndexMultiFilterData}  loading={loading} getCommentData={getCommentData} CurrentSurveyId={CurrentSurveyId} onOptionType={this.onOptionType} getAllBenchMarkData={getAllBenchMarkData} getIndexScore={newFilterObj} getIndexData={getHappinessIndexV2Data}   getRespondantDetailsData={getRespondantDetailsData}  optionType={optionType}   indexType={indexType}          handleQuestionFilter={this.handleQuestionFilter}            EmpFilterData={EmpFilterData}  getIndexFilterData={getIndexFilterData} handleSummaryFilter={this.handleSummaryFilter}  handleSummaryCard={this.handleSummaryCard} getDemographicIndexData={getDemographicIndexData}    getIndexDistributionData={getHappinessIndexV2DemographicData}    getQuestionData={getQuestionData}  getQuestionFilterData={getQuestionFilterData}             getIndexDriverData={getHappinessIndexDriverData}  getDistributionIndexData={getDemographicIndexHappinessData} handleDistributionFilter={this.handleDistributionFilter}  getIndexQuantileData={getIndexQuantileData} handleQuantileFilter={this.handleQuantileFilter} cronbatchAlphaVarianceData={cronbatchAlphaVarianceData}/>
      case 2: return <EngagementIndex   XAxis={IndexDimension.Engagement}    getIndexQuantileCount={getIndexQuantileCount} CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel} name={"Engagement"} handleMultiFilterQuantile={this.handleMultiFilterQuantile} handleMultiFilterGetQuestion={this.handleMultiFilterGetQuestion}    handleMultifilterBehaviour={this.handleMultifilterBehaviour}    handleMultifilterIndexScore={this.handleMultifilterIndexScore} getDemographicIndexMultiFilterData={getDemographicIndexMultiFilterData}  loading={loading} getCommentData={getCommentData}  CurrentSurveyId={CurrentSurveyId} onOptionType={this.onOptionType} getAllBenchMarkData={getAllBenchMarkData} getIndexScore={newFilterObj} getIndexData={getEngagementIndexV2Data}  getRespondantDetailsData={getRespondantDetailsData}  optionType={optionType}   indexType={indexType}          handleQuestionFilter={this.handleQuestionFilter}            EmpFilterData={EmpFilterData}  getIndexFilterData={getIndexFilterData} handleSummaryFilter={this.handleSummaryFilter}  handleSummaryCard={this.handleSummaryCard} getDemographicIndexData={getDemographicIndexData}    getIndexDistributionData={getEngagementIndexV2DemographicData}   getQuestionData={getQuestionData}  getQuestionFilterData={getQuestionFilterData}            getIndexDriverData={getEngagementIndexDriverData} getDistributionIndexData={getDemographicIndexEngagementData} handleDistributionFilter={this.handleDistributionFilter}getIndexQuantileData={getIndexQuantileData} handleQuantileFilter={this.handleQuantileFilter} cronbatchAlphaVarianceData={cronbatchAlphaVarianceData} getHistoryIndexData={getHistoryIndexData}/>
      case 4: return <StressIndex       XAxis={IndexDimension.Stress}        getIndexQuantileCount={getIndexQuantileCount} CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel} name={"Stress"} handleMultiFilterQuantile={this.handleMultiFilterQuantile} handleMultiFilterGetQuestion={this.handleMultiFilterGetQuestion}    handleMultifilterBehaviour={this.handleMultifilterBehaviour}    handleMultifilterIndexScore={this.handleMultifilterIndexScore} getDemographicIndexMultiFilterData={getDemographicIndexMultiFilterData}  loading={loading} getCommentData={getCommentData}  CurrentSurveyId={CurrentSurveyId} onOptionType={this.onOptionType} getAllBenchMarkData={getAllBenchMarkData} getIndexScore={newFilterObj} getIndexData={getStressIndexV2Data}      getRespondantDetailsData={getRespondantDetailsData}  optionType={optionType}   indexType={indexType}          handleQuestionFilter={this.handleQuestionFilter}            EmpFilterData={EmpFilterData}  getIndexFilterData={getIndexFilterData} handleSummaryFilter={this.handleSummaryFilter}  handleSummaryCard={this.handleSummaryCard} getDemographicIndexData={getDemographicIndexData}    getIndexDistributionData={getStressIndexV2DemographicData}       getQuestionData={getQuestionData}  getQuestionFilterData={getQuestionFilterData}            getIndexDriverData={getStressIndexDriverData}     getDistributionIndexData={getDemographicIndexStressData} handleDistributionFilter={this.handleDistributionFilter}getIndexQuantileData={getIndexQuantileData} handleQuantileFilter={this.handleQuantileFilter} cronbatchAlphaVarianceData={cronbatchAlphaVarianceData}/>
      case 5: return <ManagerIndex      XAxis={IndexDimension.Manager}       getIndexQuantileCount={getIndexQuantileCount} CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel} name={"Manager"} handleMultiFilterQuantile={this.handleMultiFilterQuantile} handleMultiFilterGetQuestion={this.handleMultiFilterGetQuestion}    handleMultifilterBehaviour={this.handleMultifilterBehaviour}    handleMultifilterIndexScore={this.handleMultifilterIndexScore} getDemographicIndexMultiFilterData={getDemographicIndexMultiFilterData}  loading={loading} getCommentData={getCommentData}  CurrentSurveyId={CurrentSurveyId} onOptionType={this.onOptionType} getAllBenchMarkData={getAllBenchMarkData} getIndexScore={newFilterObj} getIndexData={getManagerIndexV2Data}     getRespondantDetailsData={getRespondantDetailsData}  optionType={optionType}   indexType={indexType}          handleQuestionFilter={this.handleQuestionFilter}            EmpFilterData={EmpFilterData}  getIndexFilterData={getIndexFilterData} handleSummaryFilter={this.handleSummaryFilter}  handleSummaryCard={this.handleSummaryCard} getDemographicIndexData={getDemographicIndexData}    getIndexDistributionData={getManagerIndexV2DemographicData}       getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData}            getIndexDriverData={getManagerIndexDriverData}  getDistributionIndexData={getDemographicIndexManagerData} handleDistributionFilter={this.handleDistributionFilter}getIndexQuantileData={getIndexQuantileData} handleQuantileFilter={this.handleQuantileFilter} cronbatchAlphaVarianceData={cronbatchAlphaVarianceData}/>
      case 6: return <LeadershipIndex   XAxis={IndexDimension.Leader}        getIndexQuantileCount={getIndexQuantileCount} CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel} name={"Leader"} handleMultiFilterQuantile={this.handleMultiFilterQuantile} handleMultiFilterGetQuestion={this.handleMultiFilterGetQuestion}    handleMultifilterBehaviour={this.handleMultifilterBehaviour}    handleMultifilterIndexScore={this.handleMultifilterIndexScore} getDemographicIndexMultiFilterData={getDemographicIndexMultiFilterData}  loading={loading} getCommentData={getCommentData}  CurrentSurveyId={CurrentSurveyId} onOptionType={this.onOptionType} getAllBenchMarkData={getAllBenchMarkData} getIndexScore={newFilterObj} getIndexData={getManagerIndexV2Data}     getRespondantDetailsData={getRespondantDetailsData}  optionType={optionType}   indexType={indexType}          handleQuestionFilter={this.handleQuestionFilter}            EmpFilterData={EmpFilterData}  getIndexFilterData={getIndexFilterData} handleSummaryFilter={this.handleSummaryFilter}  handleSummaryCard={this.handleSummaryCard} getDemographicIndexData={getDemographicIndexData}    getIndexDistributionData={getManagerIndexV2DemographicData}       getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData}            getIndexDriverData={getLeaderIndexDriverData}  getDistributionIndexData={getDemographicIndexLeaderData} handleDistributionFilter={this.handleDistributionFilter}getIndexQuantileData={getIndexQuantileData} handleQuantileFilter={this.handleQuantileFilter} cronbatchAlphaVarianceData={cronbatchAlphaVarianceData}/>
      case 8: return <OrgCore                                                CountRestrict={CountRestrict} handleMultifilterOrgDriverSummary={this.handleMultifilterOrgDriverSummary} GetParameterLabel={GetParameterLabel} validateIndex={getIndexData} getSurveyData={getSurveyData}    handleFilterRWA={this.handleFilterRWA} handleMultiFilterQuantile={this.handleMultiFilterQuantile} handleMultiFilterGetQuestion={this.handleMultiFilterGetQuestion}    handleMultifilterIndexScore={this.handleMultifilterIndexScore} getDemographicIndexMultiFilterData={getDemographicIndexMultiFilterData}  loading={loading} getCommentData={getCommentData}  CurrentSurveyId={CurrentSurveyId} onOptionType={this.onOptionType} getAllBenchMarkData={getAllBenchMarkData} getIndexScore={newFilterObj} getIndexData={getOrgIndexV2Data}         getRespondantDetailsData={getRespondantDetailsData}  optionType={optionType}   indexType={indexType}          handleQuestionFilter={this.handleQuestionFilter}            EmpFilterData={EmpFilterData}  getIndexFilterData={getIndexFilterData} handleSummaryFilter={this.handleSummaryFilter}  handleSummaryCard={this.handleSummaryCard} getDemographicIndexData={getDemographicIndexData}    getIndexDistributionData={getHappinessIndexV2DemographicData}    getQuestionData={getQuestionData}  getQuestionFilterData={getQuestionFilterData}            getIndexDriverData={getOrgIndexDriverData}  getDistributionIndexData={getDemographicIndexOrgData} handleDistributionFilter={this.handleDistributionFilter} getIndexQuantileData={getIndexQuantileData} handleQuantileFilter={this.handleQuantileFilter} onRWAnalysis={this.onRWAnalysis}  getEngagementRWAData={getEngagementRWAData} rwaIndexType={rwaIndexType} handleRWAFilter={this.handleRWAFilter} onOrgQuestion={this.onOrgQuestion}/>

      default:<h2 style={{textAlign:'center',marginTop:'5%',color:'#757575'}}>Select Index</h2>
    }
  }
  
  firstWord =(ClientProfileData)=>{
    if(ClientProfileData && ClientProfileData.name){
      var firstWords = [];
      var firstWords2 = [];
      var codelines = ClientProfileData.name;
      for (var i=0;i<codelines.length;i++)
      {
        var words = codelines[i].split(" ");

        firstWords.push(words[0]);
      }
      if(firstWords && firstWords.length>0){
        firstWords.every((name,index)=>{
          if(name ===""){
            return false;
          }
          else{
            firstWords2.push(name);
            return true;
          }
        }
        
        )
      }
      var Username = firstWords2.join("");

      return Username

      }
      return 'User'
    }


    onOverview=(surveyId)=>{
      this.setState({
        showOverview:true,
        showIndex:false,
        showManagerList:false,
        showOrgCore:false, 
        showAdvanceComparision:false,
        showTextAnalysis:false,
        showStrengthAndWeaknesses:false,
        showManagerActionPlan:false


      })
      // this.setState({CurrentSurveyId:surveyId})
  
    }


  handleManagerDashboardCall=(mngr)=>{
      let {ManagerListDasboardDataObj} = this.state;
      let data = {
        "surveyId":this.state.CurrentSurveyId,
        "filters":[{
             "level":"ManagerPID",
             "value":mngr.id
         }]
    }
    for(let i=0;i<2;i++){
          if(ManagerListDasboardDataObj.findIndex(prev=>prev === mngr.id)===-1){
            this.props.dispatch(dashboardActions.getManagerListDashboardManagerIndex(data,mngr,ManagerListDasboardDataObj));
            let asdas= ManagerListDasboardDataObj;

      }
    }
    setTimeout(()=>{
      this.setState({ManagerListDasboardDataObj});
    },3000);

  }






  handleCallTemp=()=>{
    let data={
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": 80000011
    }
    this.props.dispatch(dashboardActions.getDemographicIndex(data));
  }


  handleManagerActionTaskComplete=(task)=>{
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "taskId":task.id,
      "remark":task.remark
    }
    this.props.dispatch(dashboardActions.updateEmployeeTask(data));
  }

  
  handleManagerActionResourceComplete=(resource)=>{
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "resourceId":resource.id,
      "remark":resource.remark
    }
    this.props.dispatch(dashboardActions.updateEmployeeResource(data));
  }



  onCrossTab=()=>{

    let data ={
      surveyId:this.state.CurrentSurveyId
    }

    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));


  }

  handleCrossTabApi=(QuesID1,QuesID2, FilterValues)=>{
    
      let data = {
        // "xAxis": "Department",
        "surveyId":this.state.CurrentSurveyId,
        "questionId1": QuesID1.id,
        "questionId2": QuesID2.id,
        "filters": FilterValues
      }
      // this.props.dispatch(dashboardActions.crossTab(data));

      this.props.dispatch(dashboardActions.crossTabAnalysis(data));


  }


  IndexDriverScore=(template)=>{
    let data = {
      "surveyId":this.state.CurrentSurveyId
    }
    if(template===1){
      this.props.dispatch(dashboardActions.getHappinessIndexDriver(data));
    }
    else if(template===2){
      this.props.dispatch(dashboardActions.getEngagementIndexDriver(data));
    }
    else if(template===4){
      this.props.dispatch(dashboardActions.getStressIndexDriver(data));
    }
    else if(template===5){
      this.props.dispatch(dashboardActions.getManagerIndexDriver(data));
    }
    else if(template===26){
      this.props.dispatch(dashboardActions.getLeaderIndexDriver(data));
    }
    
  }


  AssignManagerActionPlan=(data)=>{
    let { CurrentSurveyId } = this.state;
    let temp = {
      "surveyId":CurrentSurveyId,
      "managerList":data
    }
    console.log('temp:--------------> ',temp)
    this.props.dispatch(dashboardActions.AssignManagerActionPlan(temp));
  }



  // onLoadManagerReport=(managerDetails)=>{
  //   let { dashboard }= this.props;
  //   let { getIndexData } = dashboard;

  //   let IndexList = getIndexData? Object.keys(getIndexData) :[]

  //   let mngrId = managerDetails && managerDetails["emp_id"]?managerDetails["emp_id"]:"";
  //   let mngrDMGValue = managerDetails && managerDetails["Department"]?managerDetails["Department"]:"";

  //   if(mngrId){

  //     let data1 = {
  //       "surveyId":this.state.CurrentSurveyId
  //     }

  //     this.props.dispatch(surveyActions.getSurveyById(data1));


  //     let managerIDNumber = mngrId;


      
  //     if(IndexList.some(prev=>(prev.toString())==="1") && getIndexData && getIndexData["1"]){
  //         //Happiness Manager
  //         this.props.dispatch(dashboardActions.getHappinessQuestion({
  //           ...data1,
  //           "template":1,
  //           "filters":[]
  //         }));
  //         this.props.dispatch(dashboardActions.getHappinessManagerQuestion({
  //           ...data1,
  //           "template":1,
  //           "filters":[
  //             {
  //               "level":"ManagerPID",
  //               "value":managerIDNumber
  //             }
  //           ]
  //         }));
  //         if(mngrDMGValue){
  //           this.props.dispatch(dashboardActions.getHappinessDMGQuestion({
  //             ...data1,
  //             "template":1,
  //             "filters":[
  //               {
  //                 "level":"Department",
  //                 "value":mngrDMGValue.toString()
  //               }
  //             ]
  //           }));
  //         }
  //     }






  //     if(IndexList.some(prev=>(prev.toString())==="2") && getIndexData && getIndexData["2"]){
  //     //Engagement Manager
  //     this.props.dispatch(dashboardActions.getEngagementQuestion({
  //       ...data1,
  //       "template":2,
  //       "filters":[]
  //     }));
  //     this.props.dispatch(dashboardActions.getEngagementManagerQuestion({
  //       ...data1,
  //       "template":2,
  //       "filters":[
  //         {
  //           "level":"ManagerPID",
  //           "value":managerIDNumber
  //         }
  //       ]
  //     }));
  //     if(mngrDMGValue){
  //       this.props.dispatch(dashboardActions.getEngagementDMGQuestion({
  //         ...data1,
  //         "template":2,
  //         "filters":[
  //           {
  //             "level":"Department",
  //             "value":mngrDMGValue.toString()
  //           }
  //         ]
  //       }));
  //     }
  //   }


  //   if(IndexList.some(prev=>(prev.toString())==="4") && getIndexData && getIndexData["4"]){
  //     //Stress Manager
  //     this.props.dispatch(dashboardActions.getStressQuestion({
  //       ...data1,
  //       "template":4,
  //       "filters":[]
  //     }));
  //     this.props.dispatch(dashboardActions.getStressManagerQuestion({
  //       ...data1,
  //       "template":4,
  //       "filters":[
  //         {
  //           "level":"ManagerPID",
  //           "value":managerIDNumber
  //         }
  //       ]
  //     }));
  //     if(mngrDMGValue){
  //       this.props.dispatch(dashboardActions.getStressDMGQuestion({
  //         ...data1,
  //         "template":4,
  //         "filters":[
  //           {
  //             "level":"Department",
  //             "value":mngrDMGValue.toString()
  //           }
  //         ]
  //       }));
  //     }
  //   }





  //   if(IndexList.some(prev=>(prev.toString())==="5") && getIndexData && getIndexData["5"]){

  //       this.props.dispatch(dashboardActions.getDemographicIndexManager({
  //         ...data1,"xAxis":"ManagerPID"
  //       }));

  //       this.props.dispatch(dashboardActions.getManagerIndexByFilter({
  //         ...data1,
  //         "filters":[
  //           {
  //             "level":"ManagerPID",
  //             "value":managerIDNumber
  //           }
  //         ]
  //       }));

        


  //       //Manager Manager
  //       this.props.dispatch(dashboardActions.getManagerQuestion({
  //         ...data1,
  //         "template":5,
  //         "filters":[]
  //       }));
  //       this.props.dispatch(dashboardActions.getManagerManagerQuestion({
  //         ...data1,
  //         "template":5,
  //         "filters":[
  //           {
  //             "level":"ManagerPID",
  //             "value":managerIDNumber
  //           }
  //         ]
  //       }));
  //       if(mngrDMGValue){
  //         this.props.dispatch(dashboardActions.getManagerDMGQuestion({
  //           ...data1,
  //           "template":5,
  //           "filters":[
  //             {
  //               "level":"Department",
  //               "value":mngrDMGValue.toString()
  //             }
  //           ]
  //         }));
  //       }


  //   }

  //   if(IndexList.some(prev=>(prev.toString())==="6") && getIndexData && getIndexData["6"]){

  //     this.props.dispatch(dashboardActions.getDemographicIndexLeader({
  //       ...data1,"xAxis":"ManagerPID"
  //     }));
  //     this.props.dispatch(dashboardActions.getLeaderIndexByFilter({
  //       ...data1,
  //       "filters":[
  //         {
  //           "level":"ManagerPID",
  //           "value":managerIDNumber
  //         }
  //       ]
  //     }));


  //     //Leader Manager
  //     this.props.dispatch(dashboardActions.getLeaderQuestion({
  //       ...data1,
  //       "template":6,
  //       "filters":[]
  //     }));
  //     this.props.dispatch(dashboardActions.getLeaderManagerQuestion({
  //       ...data1,
  //       "template":6,
  //       "filters":[
  //         {
  //           "level":"ManagerPID",
  //           "value":managerIDNumber
  //         }
  //       ]
  //     }));
  //     if(mngrDMGValue){
  //       this.props.dispatch(dashboardActions.getLeaderDMGQuestion({
  //         ...data1,
  //         "template":6,
  //         "filters":[
  //           {
  //             "level":"Department",
  //             "value":mngrDMGValue.toString()
  //           }
  //         ]
  //       }));
  //     }



  //   }

  
  //   }

    
  // }


  // onLoadManagerReport=(managerDetails)=>{
  //   let { dashboard }= this.props;
  //   let { getIndexData } = dashboard;

  //   let IndexList = getIndexData? Object.keys(getIndexData) :[]

  //   let mngrId = managerDetails && managerDetails["emp_id"]?managerDetails["emp_id"]:"";
  //   let mngrDMGValue = managerDetails && managerDetails["Department"]?managerDetails["Department"]:"";

  //   if(mngrId){

  //     let data1 = {
  //       "surveyId":this.state.CurrentSurveyId
  //     }

  //     this.props.dispatch(surveyActions.getSurveyById(data1));


  //     let managerIDNumber = mngrId;


      
  //     if(IndexList.some(prev=>(prev.toString())==="1") && getIndexData && getIndexData["1"]){

  //       this.props.dispatch(dashboardActions.getDemographicIndexHappiness({
  //         ...data1,"xAxis":"ManagerPID"
  //       }));

  //       this.props.dispatch(dashboardActions.getHappinessIndexByFilter({
  //         ...data1,
  //         "filters":[
  //           {
  //             "level":"ManagerPID",
  //             "value":managerIDNumber
  //           }
  //         ]
  //       }));



  //         //Happiness Manager
  //         this.props.dispatch(dashboardActions.getHappinessQuestion({
  //           ...data1,
  //           "template":1,
  //           "filters":[]
  //         }));
  //         this.props.dispatch(dashboardActions.getHappinessManagerQuestion({
  //           ...data1,
  //           "template":1,
  //           "filters":[
  //             {
  //               "level":"ManagerPID",
  //               "value":managerIDNumber
  //             }
  //           ]
  //         }));
  //         if(mngrDMGValue){
  //           this.props.dispatch(dashboardActions.getHappinessDMGQuestion({
  //             ...data1,
  //             "template":1,
  //             "filters":[
  //               {
  //                 "level":"Department",
  //                 "value":mngrDMGValue.toString()
  //               }
  //             ]
  //           }));
  //         }
  //     }






  //     if(IndexList.some(prev=>(prev.toString())==="2") && getIndexData && getIndexData["2"]){

  //       this.props.dispatch(dashboardActions.getDemographicIndexEngagement({
  //         ...data1,"xAxis":"ManagerPID"
  //       }));

  //       this.props.dispatch(dashboardActions.getEngagementIndexByFilter({
  //         ...data1,
  //         "filters":[
  //           {
  //             "level":"ManagerPID",
  //             "value":managerIDNumber
  //           }
  //         ]
  //       }));




  //     //Engagement Manager
  //     this.props.dispatch(dashboardActions.getEngagementQuestion({
  //       ...data1,
  //       "template":2,
  //       "filters":[]
  //     }));
  //     this.props.dispatch(dashboardActions.getEngagementManagerQuestion({
  //       ...data1,
  //       "template":2,
  //       "filters":[
  //         {
  //           "level":"ManagerPID",
  //           "value":managerIDNumber
  //         }
  //       ]
  //     }));
  //     if(mngrDMGValue){
  //       this.props.dispatch(dashboardActions.getEngagementDMGQuestion({
  //         ...data1,
  //         "template":2,
  //         "filters":[
  //           {
  //             "level":"Department",
  //             "value":mngrDMGValue.toString()
  //           }
  //         ]
  //       }));
  //     }
  //   }


  //   if(IndexList.some(prev=>(prev.toString())==="4") && getIndexData && getIndexData["4"]){

  //     this.props.dispatch(dashboardActions.getDemographicIndexStress({
  //       ...data1,"xAxis":"ManagerPID"
  //     }));

  //     this.props.dispatch(dashboardActions.getStressIndexByFilter({
  //       ...data1,
  //       "filters":[
  //         {
  //           "level":"ManagerPID",
  //           "value":managerIDNumber
  //         }
  //       ]
  //     }));




  //     //Stress Manager
  //     this.props.dispatch(dashboardActions.getStressQuestion({
  //       ...data1,
  //       "template":4,
  //       "filters":[]
  //     }));
  //     this.props.dispatch(dashboardActions.getStressManagerQuestion({
  //       ...data1,
  //       "template":4,
  //       "filters":[
  //         {
  //           "level":"ManagerPID",
  //           "value":managerIDNumber
  //         }
  //       ]
  //     }));
  //     if(mngrDMGValue){
  //       this.props.dispatch(dashboardActions.getStressDMGQuestion({
  //         ...data1,
  //         "template":4,
  //         "filters":[
  //           {
  //             "level":"Department",
  //             "value":mngrDMGValue.toString()
  //           }
  //         ]
  //       }));
  //     }
  //   }





  //   if(IndexList.some(prev=>(prev.toString())==="5") && getIndexData && getIndexData["5"]){

  //       this.props.dispatch(dashboardActions.getDemographicIndexManager({
  //         ...data1,"xAxis":"ManagerPID"
  //       }));

  //       this.props.dispatch(dashboardActions.getManagerIndexByFilter({
  //         ...data1,
  //         "filters":[
  //           {
  //             "level":"ManagerPID",
  //             "value":managerIDNumber
  //           }
  //         ]
  //       }));

        


  //       //Manager Manager
  //       this.props.dispatch(dashboardActions.getManagerQuestion({
  //         ...data1,
  //         "template":5,
  //         "filters":[]
  //       }));
  //       this.props.dispatch(dashboardActions.getManagerManagerQuestion({
  //         ...data1,
  //         "template":5,
  //         "filters":[
  //           {
  //             "level":"ManagerPID",
  //             "value":managerIDNumber
  //           }
  //         ]
  //       }));
  //       if(mngrDMGValue){
  //         this.props.dispatch(dashboardActions.getManagerDMGQuestion({
  //           ...data1,
  //           "template":5,
  //           "filters":[
  //             {
  //               "level":"Department",
  //               "value":mngrDMGValue.toString()
  //             }
  //           ]
  //         }));
  //       }


  //   }

  //   if(IndexList.some(prev=>(prev.toString())==="6") && getIndexData && getIndexData["6"]){

  //     this.props.dispatch(dashboardActions.getDemographicIndexLeader({
  //       ...data1,"xAxis":"ManagerPID"
  //     }));
  //     this.props.dispatch(dashboardActions.getLeaderIndexByFilter({
  //       ...data1,
  //       "filters":[
  //         {
  //           "level":"ManagerPID",
  //           "value":managerIDNumber
  //         }
  //       ]
  //     }));


  //     //Leader Manager
  //     this.props.dispatch(dashboardActions.getLeaderQuestion({
  //       ...data1,
  //       "template":6,
  //       "filters":[]
  //     }));
  //     this.props.dispatch(dashboardActions.getLeaderManagerQuestion({
  //       ...data1,
  //       "template":6,
  //       "filters":[
  //         {
  //           "level":"ManagerPID",
  //           "value":managerIDNumber
  //         }
  //       ]
  //     }));
  //     if(mngrDMGValue){
  //       this.props.dispatch(dashboardActions.getLeaderDMGQuestion({
  //         ...data1,
  //         "template":6,
  //         "filters":[
  //           {
  //             "level":"Department",
  //             "value":mngrDMGValue.toString()
  //           }
  //         ]
  //       }));
  //     }



  //   }

  
  //   }

    
  // }


  onLoadManagerReport=(managerDetails)=>{
    let { dashboard }= this.props;
    let { getIndexData } = dashboard;
    let { ManagerReportFConfig } = this.state;

    let IndexList = getIndexData? Object.keys(getIndexData) :[]

    let mngrId = managerDetails && managerDetails["emp_id"]?managerDetails["emp_id"]:"";
    let mngrDMGValue = managerDetails && managerDetails["Department"]?managerDetails["Department"]:"";

    if(mngrId){

      let data1 = {
        "surveyId":this.state.CurrentSurveyId
      }

      this.props.dispatch(surveyActions.getSurveyById(data1));


      let managerIDNumber = mngrId;

      if(ManagerReportFConfig && ManagerReportFConfig.managerIdType){
        if(ManagerReportFConfig.managerIdType==="Int"){
          managerIDNumber = parseInt(mngrId)
        }
        else if(ManagerReportFConfig.managerIdType==="String"){
          managerIDNumber = (mngrId).toString()
        }
      }


      
      if(IndexList.some(prev=>(prev.toString())==="1") && getIndexData && getIndexData["1"]){

        this.props.dispatch(dashboardActions.getDemographicIndexHappiness({
          ...data1,"xAxis":"ManagerPID"
        }));

        this.props.dispatch(dashboardActions.getHappinessIndexByFilter({
          ...data1,
          "filters":[
            {
              "level":"ManagerPID",
              "value":managerIDNumber
            }
          ]
        }));



          //Happiness Manager
          this.props.dispatch(dashboardActions.getHappinessQuestion({
            ...data1,
            "template":1,
            "filters":[]
          }));
          this.props.dispatch(dashboardActions.getHappinessManagerQuestion({
            ...data1,
            "template":1,
            "filters":[
              {
                "level":"ManagerPID",
                "value":managerIDNumber
              }
            ]
          }));
          if(mngrDMGValue){
            this.props.dispatch(dashboardActions.getHappinessDMGQuestion({
              ...data1,
              "template":1,
              "filters":[
                {
                  "level":"Department",
                  "value":mngrDMGValue.toString()
                }
              ]
            }));
          }
      }






      if(IndexList.some(prev=>(prev.toString())==="2") && getIndexData && getIndexData["2"]){

        this.props.dispatch(dashboardActions.getDemographicIndexEngagement({
          ...data1,"xAxis":"ManagerPID"
        }));

        this.props.dispatch(dashboardActions.getEngagementIndexByFilter({
          ...data1,
          "filters":[
            {
              "level":"ManagerPID",
              "value":managerIDNumber
            }
          ]
        }));




      //Engagement Manager
      this.props.dispatch(dashboardActions.getEngagementQuestion({
        ...data1,
        "template":2,
        "filters":[]
      }));
      this.props.dispatch(dashboardActions.getEngagementManagerQuestion({
        ...data1,
        "template":2,
        "filters":[
          {
            "level":"ManagerPID",
            "value":managerIDNumber
          }
        ]
      }));
      if(mngrDMGValue){
        this.props.dispatch(dashboardActions.getEngagementDMGQuestion({
          ...data1,
          "template":2,
          "filters":[
            {
              "level":"Department",
              "value":mngrDMGValue.toString()
            }
          ]
        }));
      }
    }


    if(IndexList.some(prev=>(prev.toString())==="4") && getIndexData && getIndexData["4"]){

      this.props.dispatch(dashboardActions.getDemographicIndexStress({
        ...data1,"xAxis":"ManagerPID",
        "filters":[
          {
            "level":"ManagerPID",
            "value":managerIDNumber.toString()
          }
        ]
      }));

      this.props.dispatch(dashboardActions.getStressIndexByFilter({
        ...data1,
        "filters":[
          {
            "level":"ManagerPID",
            "value":managerIDNumber
          }
        ]
      }));




      //Stress Manager
      this.props.dispatch(dashboardActions.getStressQuestion({
        ...data1,
        "template":4,
        "filters":[]
      }));
      this.props.dispatch(dashboardActions.getStressManagerQuestion({
        ...data1,
        "template":4,
        "filters":[
          {
            "level":"ManagerPID",
            "value":managerIDNumber
          }
        ]
      }));
      if(mngrDMGValue){
        this.props.dispatch(dashboardActions.getStressDMGQuestion({
          ...data1,
          "template":4,
          "filters":[
            {
              "level":"Department",
              "value":mngrDMGValue.toString()
            }
          ]
        }));
      }
    }





    if(IndexList.some(prev=>(prev.toString())==="5") && getIndexData && getIndexData["5"]){

        this.props.dispatch(dashboardActions.getDemographicIndexManager({
          ...data1,"xAxis":"ManagerPID"
        }));

        this.props.dispatch(dashboardActions.getManagerIndexByFilter({
          ...data1,
          "filters":[
            {
              "level":"ManagerPID",
              "value":managerIDNumber
            }
          ]
        }));

        


        //Manager Manager
        this.props.dispatch(dashboardActions.getManagerQuestion({
          ...data1,
          "template":5,
          "filters":[]
        }));
        this.props.dispatch(dashboardActions.getManagerManagerQuestion({
          ...data1,
          "template":5,
          "filters":[
            {
              "level":"ManagerPID",
              "value":managerIDNumber
            }
          ]
        }));
        if(mngrDMGValue){
          this.props.dispatch(dashboardActions.getManagerDMGQuestion({
            ...data1,
            "template":5,
            "filters":[
              {
                "level":"Department",
                "value":mngrDMGValue.toString()
              }
            ]
          }));
        }


    }

    if(IndexList.some(prev=>(prev.toString())==="6") && getIndexData && getIndexData["6"]){

      this.props.dispatch(dashboardActions.getDemographicIndexLeader({
        ...data1,"xAxis":"ManagerPID"
      }));
      this.props.dispatch(dashboardActions.getLeaderIndexByFilter({
        ...data1,
        "filters":[
          {
            "level":"ManagerPID",
            "value":managerIDNumber
          }
        ]
      }));


      //Leader Manager
      this.props.dispatch(dashboardActions.getLeaderQuestion({
        ...data1,
        "template":6,
        "filters":[]
      }));
      this.props.dispatch(dashboardActions.getLeaderManagerQuestion({
        ...data1,
        "template":6,
        "filters":[
          {
            "level":"ManagerPID",
            "value":managerIDNumber
          }
        ]
      }));
      if(mngrDMGValue){
        this.props.dispatch(dashboardActions.getLeaderDMGQuestion({
          ...data1,
          "template":6,
          "filters":[
            {
              "level":"Department",
              "value":mngrDMGValue.toString()
            }
          ]
        }));
      }



    }

  
    }

    
  }

  render() {
    
    let { dashboard, questions, survey } = this.props;
    let { questionsList } = questions;
    //console.log('questionsList:',questionsList)

    let { 
        ClientProfileData, SurveyList, HappinessIndexMNGRData, EngagementIndexMNGRData, HopeIndexMNGRData, StressIndexMNGRData,


        EmpFilterData,

        ManagerListData, getDemographicIndexData,

        wordCloudMultiTextData,
        wordMultiTextKeyWord,

        loading, 

        crossTabAnalysisData,

        getManagerListDashboardManagerIndexData,


        HappinessIndexNewData,
        EngagementIndexNewData,
        HopeIndexNewData,
        StressIndexNewData,
        ManagerIndexNewData,


        getHappinessIndexDriverData,
        getEngagementIndexDriverData,
        getStressIndexDriverData,
        getManagerIndexDriverData,
        getLeaderIndexDriverData,


        OrgCoreIndexData,

        getIndexFilterData,
        getManagerListDomainFilterData,

        getQuestionData,

      getDemographicIndexHappinessData,
      getDemographicIndexEngagementData,
      getDemographicIndexStressData,
      getDemographicIndexManagerData,

        getQuestionManagerOfHappinessData,
        getQuestionManagerOfEngagementData,
        getQuestionManagerOfStressData,
        getQuestionManagerOfManagerData,


        getHappinessIndexV2Data,
        getEngagementIndexV2Data,
        getStressIndexV2Data,
        getManagerIndexV2Data,

        getDemographicIndexScatterData,


        getHappinessCorrelationData,
        getEngagementCorrelationData,
        getStressCorrelationData,
        getManagerCorrelationData,
        getLeaderCorrelationData,


        getRWAnalysisData,


        getTextQuestionData,

        wordCloudData,
        AdvanceWordCloudData,

        wordTextKeyWord,

        cooccurmatrixData,


        getQuestionFavorabilityData,



        getCommentData,

        crossTabData,
        crossTabDMGData,

        getQuestionOverallData,

        getCorrelationDimenionVsDimensionData,
        getCorrelationDimensionVsDimensionFilterData,
        getOrgIndexV2Data,




        getHappinessQuestionData,
        getHappinessManagerQuestionData,
        getHappinessDMGQuestionData,

        getEngagementQuestionData,
        getEngagementManagerQuestionData,
        getEngagementDMGQuestionData,

        getStressQuestionData,
        getStressManagerQuestionData,
        getStressDMGQuestionData,


        getManagerQuestionData,
        getManagerManagerQuestionData,
        getManagerDMGQuestionData,


        getLeaderQuestionData,
        getLeaderManagerQuestionData,
        getLeaderDMGQuestionData,


        getDemographicIndexLeaderData,


        getHappinessIndexByFilterData,
        getEngagementIndexByFilterData,
        getStressIndexByFilterData,
        getManagerIndexByFilterData,
        getLeaderIndexByFilterData,





    } = dashboard;

    let { Mindextype, MQuesIndextype , ManagerListDasboardDataObj, ManagerListQuestionDataObj, SelectedAPDimension, SelectedAPAction, SelectedAPTask} = this.state;


//ROLE MANAGEMENT ----------------------------------------------
let moduleAccess = {
  "isAdmin":false,
  "access":{}
};
if(accessModule()){
  moduleAccess["access"] = accessModule();
}



console.log('wordMultiTextKeyWord: ----------------------------------------------',wordMultiTextKeyWord)



let SHOW_ELEMENT = false;
let clientData = this.props.dashboard.ClientProfileData;
if(clientData && clientData.role){

    if(clientData.role === "ADMIN"){
      SHOW_ELEMENT = true;
      moduleAccess["isAdmin"] = true;
    }
    else if(clientData.role === "MNGR"){
      SHOW_ELEMENT = true;
      moduleAccess["isAdmin"] = false;
    }
    else if(clientData.role === "CROLE"){
      SHOW_ELEMENT = true;
      moduleAccess["isAdmin"] = false;
    }
    else if(clientData.role === "EMP"){
      SHOW_ELEMENT = false;
      moduleAccess["isAdmin"] = false;
    }
}
function accessAllow (item){
  return (moduleAccess.isAdmin?true:(moduleAccess && moduleAccess.access && moduleAccess.access[item]?true:false))
}

//END -------------------------------------------------------




    let ManagerIndexQuestionData = '';

    if(MQuesIndextype === 1){
      ManagerIndexQuestionData=getQuestionManagerOfHappinessData;
    }
    else if(MQuesIndextype === 2){
      ManagerIndexQuestionData=getQuestionManagerOfEngagementData;
    }
    else if(MQuesIndextype === 4){
      ManagerIndexQuestionData=getQuestionManagerOfStressData;
    }
    else if(MQuesIndextype === 5){
      ManagerIndexQuestionData=getQuestionManagerOfManagerData;
    }




    let ManagerIndexDriverData = [];

    if(Mindextype === 1){
      ManagerIndexDriverData=getDemographicIndexHappinessData;
    }
    else if(Mindextype === 2){
      ManagerIndexDriverData=getDemographicIndexEngagementData;
    }
    else if(Mindextype === 4){
      ManagerIndexDriverData=getDemographicIndexStressData;
    }
    else if(Mindextype === 5){
      ManagerIndexDriverData=getDemographicIndexManagerData;
    }
      



      let { showSidebar,CurrentSurveyId, indexType, showOverview,showIndex,showManagerList ,showAdvanceComparision, showTextAnalysis,showStrengthAndWeaknesses,showManagerActionPlan,  DynaObj, ManagerDynaObj, QuesDynaObj  } = this.state;
      let { getAllParameterListData, getAllManagerActionPlanData, getManagerActionPlanData ,getIndexData, getDemographicIndexForManagerData, wordCloudTextData, neighborGraphData,getRespondantDetailsData } = dashboard;
      
      // //console.log('showOverview: ',showOverview)
      // //console.log('showIndex: ',showIndex)
      // //console.log('showManagerList: ',showManagerList)
      // //console.log('sshowAdvanceComparision: ',showAdvanceComparision)
      let ManagerNameIdList =  getDemographicIndexForManagerData;

      if(ClientProfileData && ClientProfileData.role && ClientProfileData.role==="MNGR"){
        this.props.history.push(`/app/manageractionplan`)
      }


      //console.log('--------------------->getDemographicIndexHappinessData',getDemographicIndexHappinessData)

      function checkIndex(key){
        let check = false
          if      (key===1){ check=true }
          else if(key===2){ check=true }
          else if(key===3){ check=true }
          else if(key===4){ check=true }
          else if(key===5){ check=true }
          else if(key===6){ check=true }
          else if(key===7){ check=true }
          else if(key===9){ check=true }
          else { check=false }
  
        return check;
      }

      let IndexList = []
      let newFilterObj ={}
      if(getIndexData){
  
  
  
      IndexList = Object.keys(getIndexData).map((key) => [ Number(key), getIndexData[key] ]);
  
      if(getIndexFilterData){
        if(IndexList && IndexList.length>0){
          IndexList.forEach((data)=>{
            if(checkIndex(data[0])){
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        };
      }
    }




    let IndexDimension = {
      "Happiness":['Time','Being','Relationship','WorkLife','Achivement','Meeningfull'],
      "Engagement":['Pride','Presence','Performance'],
      "Stress":['Recognition','WorkSatisfaction','Inclusion','Collaboration','Empowerment'],
      "Manager":['Connect','ACT','Respect','Empower'],
      "Leader":['Vision','Impact','Execute','Wisdom']
    }


    function CountRestrict(count, isOverall, section){
      let isCountLessThanFive = false;

      if(count!=="NaN"){
              if(isOverall){
                isCountLessThanFive = false;
              }
              else{
                if(section === "rwa"){
                  if(parseInt(count)<30){
                    isCountLessThanFive = true;
                  }
                }
                else{
                  if(parseInt(count)<5){
                    isCountLessThanFive = true;
                  }
                }

              }
      }

      return isCountLessThanFive
    }


    function GetParameterLabel(name){
      let AllParameterObj = {};
      if(getAllParameterListData && getAllParameterListData.length>0){
        getAllParameterListData.forEach((item)=>{
          AllParameterObj[item.name] = item&&item.label?item.label:item.name;
        });
      }
      return (AllParameterObj&&AllParameterObj[name]?AllParameterObj[name]:name);
    }



    let { getSurveyData } = survey;



    let surveyLogo = "";

    let surveyId =  getSurveyData && getSurveyData.id?getSurveyData.id:"";

    if(surveyId === "60b9df102f68b86828ce1539"){
      surveyLogo = 'img/logo/motherdairylogo.jpg';
    }
    else if(surveyId === "613afdbb536b197e580fb6db"){
      surveyLogo = 'img/logo/gennovalogo.jpeg';
    }





    return (
      <>
    <LoadingOverlay
      active={loading}
      // active={false}

      spinner
      text='Loading Data...'
      className="loader"
      >

    <div>

    
      <div className="w-full fixed" >
        <Header
          ClientProfileData={ClientProfileData}
          handleLogout={this.handleLogout}
          SurveyList={SurveyList}
          ChooseSurvey={this.ChooseSurvey}
          CurrentSurveyId={CurrentSurveyId}
          props={this.props}
          onOverviewFromHeader={this.onOverviewFromHeader}
          getSurveyData={getSurveyData}
        />
      </div>

      <div className="flex bg-gray-200 w-full h-screen pt-16" >
      {accessAllow("dashboard")?
      <>
      <Sidebar
        SurveyList={SurveyList}
        onOverview={this.onOverview}
        onOptionType={this.onOptionType}
        onManagerList={this.onManagerList}
        onOrganizationCore={this.onOrganizationCore}
        indexType={indexType}
        getIndexData={newFilterObj}
        CurrentSurveyId={CurrentSurveyId}
        showSidebar={showSidebar}
        handleSidebar={this.handleSidebar}
      />

      
      {showOverview?
        <Overview
          getIndexData = {newFilterObj}
          SurveyList={SurveyList}
          CurrentSurveyId={CurrentSurveyId}
          onOptionType={this.onOptionType}
          
          getHappinessIndexV2Data={getHappinessIndexV2Data}
          getEngagementIndexV2Data={getEngagementIndexV2Data}
          getStressIndexV2Data={getStressIndexV2Data}
          getManagerIndexV2Data={getManagerIndexV2Data}
          getRespondantDetailsData={getRespondantDetailsData}

          EmpFilterData={EmpFilterData}
          getIndexFilterData = {getIndexFilterData}
          getCommentData={getCommentData}
          
        />
      :null}



      {showIndex?
        <>
        {this.onSwitchIndexScreen(newFilterObj, IndexDimension, CountRestrict)}
        </>
      :null}


      {/* {showManagerList?
                      <ManagerList 
                        indexType={indexType}

                        validateIndex={getIndexData}

                        ManagerListData={ManagerListData} 
                        CurrentSurveyId={CurrentSurveyId}

                        getDemographicIndexData={getDemographicIndexData}
                        getDemographicIndexForManagerData={ManagerNameIdList}
                        getIndexData={newFilterObj}

                        getManagerListDomainFilterData={getManagerListDomainFilterData}

                        handleManagerHeatmap={this.handleManagerHeatmap}

                        EmpFilterData={EmpFilterData}

                        DynaObj={DynaObj}

                        handleManagerQuestion={this.handleManagerQuestion}

                        getQuestionData={getQuestionData}

                        handleManagerWiseDriver={this.handleManagerWiseDriver}

                        ManagerIndexDriverData={ManagerIndexDriverData}

                        ManagerDynaObj={ManagerDynaObj}

                        QuesDynaObj={QuesDynaObj}


                        ManagerIndexQuestionData={ManagerIndexQuestionData}

                        getCommentData={getCommentData}

                        loading={loading}

                        //-----------------------------------------------------------------------

                        handleManagerDashboardCall={this.handleManagerDashboardCall}

                        ManagerListDasboardDataObj={ManagerListDasboardDataObj}

                        handleManagerListIndexQuestionCall={this.handleManagerListIndexQuestionCall}

                        ManagerListQuestionDataObj={ManagerListQuestionDataObj}

                        handleMultiManagerWiseDriver={this.handleMultiManagerWiseDriver}



                        QuesMngrHappinessReportObject={this.state.QuesMngrHappinessReportObject}
                        QuesMngrEngagementReportObject={this.state.QuesMngrEngagementReportObject}
                        QuesMngrStressReportObject={this.state.QuesMngrStressReportObject}
                        QuesMngrManagerReportObject={this.state.QuesMngrManagerReportObject}

                        DriverMngrHappinessReportObject={this.state.DriverMngrHappinessReportObject}
                        DriverMngrEngagementReportObject={this.state.DriverMngrEngagementReportObject}
                        DriverMngrStressReportObject={this.state.DriverMngrStressReportObject}
                        DriverMngrManagerReportObject={this.state.DriverMngrManagerReportObject}

                        getQuestionOverallData={getQuestionOverallData}

                        IndexDimension={IndexDimension}


                        GetParameterLabel={GetParameterLabel}

                        IndexDriverScore={this.IndexDriverScore}


                        getHappinessIndexDriverData={getHappinessIndexDriverData}
                        getEngagementIndexDriverData={getEngagementIndexDriverData}
                        getStressIndexDriverData={getStressIndexDriverData}
                        getManagerIndexDriverData={getManagerIndexDriverData}
                        getLeaderIndexDriverData={getLeaderIndexDriverData}



                      /> 
                    :null} */}




{showManagerList?
                      <ManagerList 
                        indexType={indexType}
                        getSurveyData={getSurveyData}
                        validateIndex={getIndexData}

                        ManagerListData={ManagerListData} 
                        CurrentSurveyId={CurrentSurveyId}

                        getDemographicIndexData={getDemographicIndexData}
                        getDemographicIndexForManagerData={ManagerNameIdList}
                        getIndexData={newFilterObj}

                        getManagerListDomainFilterData={getManagerListDomainFilterData}

                        handleManagerHeatmap={this.handleManagerHeatmap}

                        EmpFilterData={EmpFilterData}

                        DynaObj={DynaObj}

                        handleManagerQuestion={this.handleManagerQuestion}

                        getQuestionData={getQuestionData}

                        handleManagerWiseDriver={this.handleManagerWiseDriver}

                        ManagerIndexDriverData={ManagerIndexDriverData}

                        ManagerDynaObj={ManagerDynaObj}

                        QuesDynaObj={QuesDynaObj}


                        ManagerIndexQuestionData={ManagerIndexQuestionData}

                        getCommentData={getCommentData}

                        loading={loading}

                        //-----------------------------------------------------------------------

                        handleManagerDashboardCall={this.handleManagerDashboardCall}

                        ManagerListDasboardDataObj={ManagerListDasboardDataObj}

                        handleManagerListIndexQuestionCall={this.handleManagerListIndexQuestionCall}

                        ManagerListQuestionDataObj={ManagerListQuestionDataObj}

                        handleMultiManagerWiseDriver={this.handleMultiManagerWiseDriver}



                        QuesMngrHappinessReportObject={this.state.QuesMngrHappinessReportObject}
                        QuesMngrEngagementReportObject={this.state.QuesMngrEngagementReportObject}
                        QuesMngrStressReportObject={this.state.QuesMngrStressReportObject}
                        QuesMngrManagerReportObject={this.state.QuesMngrManagerReportObject}

                        DriverMngrHappinessReportObject={this.state.DriverMngrHappinessReportObject}
                        DriverMngrEngagementReportObject={this.state.DriverMngrEngagementReportObject}
                        DriverMngrStressReportObject={this.state.DriverMngrStressReportObject}
                        DriverMngrManagerReportObject={this.state.DriverMngrManagerReportObject}

                        getQuestionOverallData={getQuestionOverallData}

                        IndexDimension={IndexDimension}


                        GetParameterLabel={GetParameterLabel}

                        IndexDriverScore={this.IndexDriverScore}


                        getHappinessIndexDriverData={getHappinessIndexDriverData}
                        getEngagementIndexDriverData={getEngagementIndexDriverData}
                        getStressIndexDriverData={getStressIndexDriverData}
                        getManagerIndexDriverData={getManagerIndexDriverData}
                        getLeaderIndexDriverData={getLeaderIndexDriverData}


                        getDemographicIndexManagerData={getDemographicIndexManagerData}
                        getDemographicIndexLeaderData={getDemographicIndexLeaderData}



                        getHappinessQuestionData={getHappinessQuestionData}
                        getHappinessManagerQuestionData={getHappinessManagerQuestionData}
                        getHappinessDMGQuestionData={getHappinessDMGQuestionData}
                        getDemographicIndexHappinessData={getDemographicIndexHappinessData}

                        
                        getEngagementQuestionData={getEngagementQuestionData}
                        getEngagementManagerQuestionData={getEngagementManagerQuestionData}
                        getEngagementDMGQuestionData={getEngagementDMGQuestionData}
                        getDemographicIndexEngagementData={getDemographicIndexEngagementData}


                        getStressQuestionData={getStressQuestionData}
                        getStressManagerQuestionData={getStressManagerQuestionData}
                        getStressDMGQuestionData={getStressDMGQuestionData}
                        getDemographicIndexStressData={getDemographicIndexStressData}


                        getManagerQuestionData={getManagerQuestionData}
                        getManagerManagerQuestionData={getManagerManagerQuestionData}
                        getManagerDMGQuestionData={getManagerDMGQuestionData}
                        getDemographicIndexManagerData={getDemographicIndexManagerData}


                        getLeaderQuestionData={getLeaderQuestionData}
                        getLeaderManagerQuestionData={getLeaderManagerQuestionData}
                        getLeaderDMGQuestionData={getLeaderDMGQuestionData}
                        getDemographicIndexLeaderData={getDemographicIndexLeaderData}


                        onLoadManagerReport={this.onLoadManagerReport}

                        getHappinessIndexByFilterData={getHappinessIndexByFilterData}
                        getEngagementIndexByFilterData={getEngagementIndexByFilterData}
                        getStressIndexByFilterData={getStressIndexByFilterData}
                        getManagerIndexByFilterData={getManagerIndexByFilterData}
                        getLeaderIndexByFilterData={getLeaderIndexByFilterData}

                        surveyLogo={surveyLogo}


                      /> 
                    :null}




        {showAdvanceComparision?
            <AdvanceComparision

              validateIndex={getIndexData}
            
              indexType={indexType}
              getIndexData = {newFilterObj}
              SurveyList={SurveyList}
              CurrentSurveyId={CurrentSurveyId}
              onOptionType={this.onOptionType}

              getDemographicIndexData = {getDemographicIndexData}

              EmpFilterData={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              handleQuadrantFilter={this.handleQuadrantFilter}

              handleCorelation={this.handleCorelation}


              getDemographicIndexScatterData={getDemographicIndexScatterData}


              getHappinessCorrelationData={getHappinessCorrelationData}
              getEngagementCorrelationData={getEngagementCorrelationData}
              getStressCorrelationData={getStressCorrelationData}
              getManagerCorrelationData={getManagerCorrelationData}
              getLeaderCorrelationData={getLeaderCorrelationData}


              handleCorrelationFilter={this.handleCorrelationFilter}


              getRWAnalysisData={getRWAnalysisData}

              getCommentData={getCommentData}

              loading={loading}


              handleMultiFilterAdvanc4Quadrant={this.handleMultiFilterAdvanc4Quadrant}
              handleMultiFilterAdvancScatter={this.handleMultiFilterAdvancScatter}
              handleMultiFilterCorrelation={this.handleMultiFilterCorrelation}

              onCrossTab={this.onCrossTab}
              questionsList={questionsList}
              handleCrossTabApi={this.handleCrossTabApi}
              crossTabData={crossTabData}

              crossTabAnalysisData={crossTabAnalysisData}

              IndexDimension={IndexDimension}

              GetParameterLabel={GetParameterLabel}

              getCorrelationData={getCorrelationDimenionVsDimensionData}
              getCorrelationFilterData={getCorrelationDimensionVsDimensionFilterData}


               getSurveyData={getSurveyData}

               CountRestrict={CountRestrict}

            />
          :null}


          {showTextAnalysis?
            <TextAnalysis
              indexType={indexType}
              getTextQuestionData={getTextQuestionData}

              handleChangeQuestion={this.handleChangeQuestion}
              
              wordCloudData={wordCloudData}
              AdvanceWordCloudData={AdvanceWordCloudData}

              EmpFilterData={EmpFilterData}
              getIndexFilterData = {getIndexFilterData}

              handleWordCloudDemographicFilter={this.handleWordCloudDemographicFilter}

              callWordCloudText={this.callWordCloudText}

              wordCloudTextData={wordCloudTextData}

              wordTextKeyWord={wordTextKeyWord}

              callQuestionWordCloud={this.callQuestionWordCloud}

              cooccurmatrixData={cooccurmatrixData}

              onWorldCloud={this.onWorldCloud}
              onNetworkGraph={this.onNetworkGraph}
              onAdvanceWorldCloud={this.onAdvanceWorldCloud}

              callQuestionNetworkCloud={this.callQuestionNetworkCloud}
              handleNetworkDemographicFilter={this.handleNetworkDemographicFilter}


              neighborGraphData={neighborGraphData}

              handleNeighborGraph={this.handleNeighborGraph}

              getCommentData={getCommentData}

              loading={loading}

              GetParameterLabel={GetParameterLabel}

              CountRestrict={CountRestrict}

              wordCloudMultiTextData={wordCloudMultiTextData}
              wordMultiTextKeyWord={wordMultiTextKeyWord}

            />
          :null}



          {showStrengthAndWeaknesses?

            <StrengthAndWeaknesses
            indexType={indexType}
            EmpFilterData={EmpFilterData}
            getIndexFilterData={getIndexFilterData}
            getQuestionFavorabilityData={getQuestionFavorabilityData}
            handleStrengthQuestionFilter={this.handleStrengthQuestionFilter}
            handleWeaknessesQuestionFilter={this.handleWeaknessesQuestionFilter}
            onWeaknessQuestionCall={this.onWeaknessQuestionCall}
            onStrengthQuestionCall={this.onStrengthQuestionCall}

            getCommentData={getCommentData}

            loading={loading}

            getRespondantDetailsData={getRespondantDetailsData}
            GetParameterLabel={GetParameterLabel}


            />
          
          :null}



          {/* {showManagerActionPlan?
            <ManagerActionPlan
              ClientProfileData={ClientProfileData}
              onAssignManagerActionPlan={this.onAssignManagerActionPlan}
              getManagerActionPlanData={getManagerActionPlanData}
              handleManagerActionTaskComplete={this.handleManagerActionTaskComplete} 
              handleManagerActionResourceComplete={this.handleManagerActionResourceComplete}
              getAllManagerActionPlanData={getAllManagerActionPlanData}
              getDemographicIndexForManagerData={ManagerNameIdList}
            />
          :null} */}



          {showManagerActionPlan?
            <ManagerActionPlanSelect
            indexType={indexType}
            ManagerListData={ManagerListData} 
            CurrentSurveyId={CurrentSurveyId}
            validateIndex={getIndexData}

            getDemographicIndexData={getDemographicIndexData}
            getDemographicIndexForManagerData={ManagerNameIdList}
            getIndexData={newFilterObj}

            getManagerListDomainFilterData={getManagerListDomainFilterData}

            handleManagerHeatmap={this.handleManagerHeatmap}

            EmpFilterData={EmpFilterData}

            getIndexFilterData={getIndexFilterData}

            DynaObj={DynaObj}

            handleManagerQuestion={this.handleManagerQuestion}

            getQuestionData={getQuestionData}

            handleManagerWiseDriver={this.handleManagerWiseDriver}

            ManagerIndexDriverData={ManagerIndexDriverData}

            ManagerDynaObj={ManagerDynaObj}

            QuesDynaObj={QuesDynaObj}


            ManagerIndexQuestionData={ManagerIndexQuestionData}

            getCommentData={getCommentData}

            loading={loading}

            //-----------------------------------------------------------------------

            handleManagerDashboardCall={this.handleManagerDashboardCall}

            ManagerListDasboardDataObj={ManagerListDasboardDataObj}

            handleManagerListIndexQuestionCall={this.handleManagerListIndexQuestionCall}

            ManagerListQuestionDataObj={ManagerListQuestionDataObj}

            handleMultiManagerWiseDriver={this.handleMultiManagerWiseDriver}



            QuesMngrHappinessReportObject={this.state.QuesMngrHappinessReportObject}
            QuesMngrEngagementReportObject={this.state.QuesMngrEngagementReportObject}
            QuesMngrStressReportObject={this.state.QuesMngrStressReportObject}
            QuesMngrManagerReportObject={this.state.QuesMngrManagerReportObject}


            getQuestionOverallData={getQuestionOverallData}

            AssignManagerActionPlan={this.AssignManagerActionPlan}


            getAllManagerActionPlanData={getAllManagerActionPlanData}

            GetParameterLabel={GetParameterLabel}

            IndexDimension={IndexDimension}

            />
          :null}
 

 </>
:
<div className="container py-12 mx-auto px-4 h-full overflow-hidden overflow-y-auto" style={{height: 'calc(100% - 4rem)'}}>
<div className="w-full h-full flex justify-center items-center">
  {/*  text start*/}
  <div className="text-center">
    <img src="img/empty_survey.png" className="mb-4" />
    <h1 className="text-gray-500 text-xl  font-semibold cursor-default">You have no access <br/> of dashboard!</h1>
  </div>
</div>
</div>
}


      </div>
      
      <div id="sidebar-btn" className="bg-white hover:bg-blue-500 hover:text-white text-gray-900 w-10 h-10 rounded-full shadow-md bottom-8 right-8  flex justify-center items-center fixed z-30 lg:hidden">
        <span className="material-icons">view_quilt</span>
      </div>



    </div>



    </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, questions, survey } = state;
  return {
    loggingIn,
    users,
    dashboard,
    questions,
    survey
  };
}
export default connect(mapStateToProps)(Dashboard);
