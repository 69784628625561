import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {
    
    let { item, Benchmark } = props;





    return (
      <>	
      <div className="pt-1 justify-center items-center" style={{height:Benchmark && Benchmark.score?null:"140px"}}>
                              <ReactSpeedometer 
                                value={item.score}
                                minValue={0}
                                maxValue={72}
                                needleColor="#4db8ff"
                                segments={4}
                                segmentColors={[
                                "#e53935",
                                "#ffc107",
                                "#00cd7e",
                                "#ff4081",
                                ]}
                                ringWidth={35}
                                customSegmentStops={[0, 48, 60, 66, 72]}
                                // fluidWidth={true}
                                // needleTransitionDuration={3333}
                                // needleTransition="easeElastic"
                              />   
          {Benchmark && Benchmark.score || true?
          <div className="gauge-outer">
              <div className="gauge-inner" style={{transform: 'rotate(calc(1deg * (-45 + '+0+' * 2.50)))'}}></div>
            </div>
:null}
          </div>

      </>
    );
  }
