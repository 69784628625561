import React, { Component } from 'react';
import { connect } from 'react-redux';
import { campaignActions, dashboardActions, surveyActions } from '../../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import { accessModule } from '../../../_helpers';

import CreateCampaign from './Modal/CreateCampaign';
import CampaignList from './Components/CampaignList';
import Header from '../Header/Header';
// import { Checkboard } from 'react-color';
import ReminderCampaign from './Components/ReminderCampaign/ReminderCampaign';
import ConfirmSubmit from './Components/ReminderCampaign/ConfirmSubmit';
import ConfirmCreateCampaign from './Modal/ConfirmCreateCampaign';
import LoadingOverlay from 'react-loading-overlay';
import Sidebar from '../Survey/Components/Sidebar/Sidebar';

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
        showCreateCampaign:false,
        showReminderModal:false,
        CurrCampaignID:'',
        showReminderEmployeeFilter:false,
        empSelectRemindFilter:'',
        reminderSubmitConfirm:false,
        confirmCreateCampaignAlert:false,

        showCampaignOptions:false,

        campaignFormData:{
            "title": "",
            "subject": "",
            "type": "EMAIL",
            "message": "",
            "emailName": "",
            "campaign_type":"",
            "teamname": "",
            "surveyId": this.props.match.params.id,
            "employees": [],
            "isTest":false
          },
          reminderformData:{
            "action":'Reminder to Survey Incomplete',
            "actionId":2,
            "title":'',
            "subject":'',
            "emailName": "",
            "message":'',
            "teamname": "",
            "createdAt":""  
          },

          formBatchRange:{
            "label":"1-200",
            "rangeNo":1,
            "size":200
          },

          storeBatchData:{
            "label":"1-200",
            "rangeNo":1,
            "size":200
          },

          showBatchRange:false,

          selectAll:false,
          employees:[],

          EmpIndexA:0,
          EmpIndexB:6,

          currentEmpPage:1,

          selectAllEmp:false,

          showEmployeeFilter:false,

          empSelectFilter:{
            name:'Show All',
            template:1
          },

          showSidebar:false,
          CrrRoute:"survey",
          crrSurvey:"",

          CurrentLanguage:'en',
          CurrentLanguageName:'English',
          showLanguageModal:false,

          currFilterLevel:"Select Filter",showLevelFilter:false,
          currFilterValue:"Select Value", showValueFilter:false,

          employeeValueSearch:""

    }
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getClientProfile());

    let data = {
      "surveyId": this.props.match.params.id
    }
    this.props.dispatch(surveyActions.getSurveyById(data));

    let data1 ={
        "surveyId": this.props.match.params.id,
      }
    this.props.dispatch(campaignActions.getCampaignListBySurveyID(data1));

    let data2 = {
        "keyWord": "",
        "pageNo": 1
      }
    // this.props.dispatch(campaignActions.getAllEmployees(data2));


  let data5 = {
    "rangeNo": 1,
    "size":this.state.formBatchRange.size
  }
  this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));

  let data52 = {
    "rangeNo": 1,
    "size":this.state.formBatchRange.size
  }
  this.props.dispatch(surveyActions.getCustomerBatchRange(data52));


    let data3 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data3));
    this.props.dispatch(surveyActions.getLanguage(data3));



    this.props.dispatch(surveyActions.getIndexByFilterDMG({
      filters:[]
    }));

    
    
    
  }

  handleEmpDropdown=(check)=>{
    this.setState({showEmployeeFilter:!check})
  }

  handleEmpFilterSelect=(data)=>{
    this.setState({
      empSelectFilter:data,
      showEmployeeFilter:false
    })
  }

  prevEmpPage=()=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(EmpIndexA>0){
        this.setState({EmpIndexA:EmpIndexA-1, EmpIndexB:EmpIndexB-1});
    }
  }
  nextEmpPage=(IndexListIndex)=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(IndexListIndex && IndexListIndex.length && (EmpIndexB<IndexListIndex.length)){
      this.setState({EmpIndexA:EmpIndexA+1, EmpIndexB:EmpIndexB+1});
    }
  }

  SelectAllEmployees=(check, EmployeesList)=>{
    this.setState({selectAllEmp:!check})

    let { employees, campaignFormData } = this.state;

    this.setState({employees},()=>{
      if(EmployeesList && EmployeesList.length>0){
        if(!check){
          EmployeesList.forEach((emp)=>{
            if(employees.findIndex(prev=>prev.id === emp.id)===-1){
              employees.push({
                id:emp.id
              })
              this.setState({employees},()=>{
                campaignFormData['employees'] = employees;
                this.setState({ campaignFormData})
              })
            }
          })
        }
        else{
          employees=[]
          campaignFormData['employees'] = []
          this.setState({employees, campaignFormData})
        }
      }
    })

  }

  SelectEmpPage=(val)=>{
    this.setState({currentEmpPage:val})
  }


  onCreateCampaign=(type)=>{
    if(type===1){
      this.setState({
        showCreateCampaign:true,
        showReminderModal:false
      })
    }
    else if(2){
      this.setState({
        showCreateCampaign:false,
        showReminderModal:false
      }) 
    }

  }

  handleCampaignInput=(e)=>{
    let { campaignFormData } = this.state;
    campaignFormData[e.target.name]=e.target.value;
    this.setState({campaignFormData})
  }

  handleMessageInput=(value)=>{
    let { campaignFormData } = this.state;
    campaignFormData['message'] = value;
    this.setState({ campaignFormData });
  }

  handleTestUserCheck=(value)=>{
    let { campaignFormData } = this.state;
    campaignFormData['isTest'] = value;
    this.setState({ campaignFormData });
  }

  handleSendTypeCheck=(value)=>{
    const { campaignFormData } = this.state;
    campaignFormData["type"] = value;
    this.setState({ campaignFormData });
  }

  handleCampaignEmployees = (emp) => {
    const { campaignFormData, employees, selectAll } = this.state;


    if (!selectAll) {



      if (employees.length > 0) {
        var matchFlag = null;
        employees.forEach((selectedEmp, empIndex) => {

          if (emp.id === selectedEmp.id) {
            matchFlag = 1
            var data = employees;
            data.splice(empIndex, 1);

            campaignFormData['employees'] = data;
            this.setState({ campaignFormData });

          }

        });

        if (matchFlag === 1) {
          console.log("matchFlag = 1:---->", employees)
        }
        else {
          var data = employees;
          data.push({ id: emp.id })


          campaignFormData['employees'] = data;
          this.setState({ campaignFormData });

          console.log("matchFlag = 0:---->", employees)
        }



      }
      else {

        var datax = employees;

        datax.push({ id: emp.id })

        console.log("setZero:---->", employees)
        campaignFormData['employees'] = datax;
        this.setState({ campaignFormData });
      }


    }


  }

  handleCampaignSubmit=()=>{
    this.setState({confirmCreateCampaignAlert:true});
    console.log('confirmCreateCampaignAlert: ',this.state.confirmCreateCampaignAlert)
  }

  handleConfirmCreateCampaignMailClose=()=>{
    this.setState({confirmCreateCampaignAlert:false});
    let data = {
      "title": "",
      "subject": "",
      "message": "",
      "type":"EMAIL",
      "teamname": "",
      "emailName":"",
      "campaign_type":"",
      "surveyId": this.props.match.params.id,
      "employees": []
    }
    this.setState({campaignFormData:data})
  }


  onCampaignSubmit=()=>{
    let { campaignFormData } = this.state;
    let data = {
      "title": campaignFormData.title,
      "subject": campaignFormData.subject,
      "message": campaignFormData.message,
      "type": campaignFormData.type,
      "emailName":campaignFormData.emailName,
      "campaign_type":campaignFormData && campaignFormData.campaign_type?campaignFormData.campaign_type:null,
      "teamname": campaignFormData.teamname,
      "surveyId": campaignFormData.surveyId,
      "employees": campaignFormData.employees,
      "isTest": (campaignFormData && campaignFormData.isTest)?true:false

    }
    this.props.dispatch(campaignActions.createNewCampaign(data))




    this.setState({confirmCreateCampaignAlert:false,showCreateCampaign:false,showReminderModal:false})
    let data2 = {
      "title": "",
      "subject": "",
      "message": "",
      "type":"EMAIL",
      "teamname": "",
      "emailName":"",
      "campaign_type":"",
      "surveyId": this.props.match.params.id,
      "employees": []
    }
    this.setState({campaignFormData:data2})

  }



  onReportCampaign=(data)=>{
      let surveyId = this.props.match.params.id;
      console.log("onReportCampaign-------------->",data)
      if(data && data.campaign_type && data.campaign_type === "KIOSK"){
        this.props.history.push(`/app/campaignkioskreport/${data.id}/${surveyId}`);
      }
      else{
        this.props.history.push(`/app/campaignreport/${data.id}/${surveyId}`);
      }
  }
 

  onReminderCampaign=(data)=>{
    let { reminderformData } = this.state;
    this.setState({showReminderModal:true})
    reminderformData['title'] = data.title;
    reminderformData['subject'] = "Re: "+data.subject;
    reminderformData['emailName'] = data.emailName;
    reminderformData['message'] = data.message;
    reminderformData['teamname'] = data.teamname;
    reminderformData['createdAt'] = data.createdAt;

    this.setState({CurrCampaignID:data.id, reminderformData})
    
    let campaignObj = {
      "surveyId": this.props.match.params.id,
      "campaignId": data.id
    }
    this.props.dispatch(campaignActions.getCampaignEmployeesByID(campaignObj));

    
  }



  handleReminderInput=(e)=>{
    let { reminderformData } = this.state;
    reminderformData[e.target.name] = e.target.value;
    this.setState({reminderformData})
  }


  handleReminderMessageInput=(value)=>{
    let { reminderformData } = this.state;
    reminderformData["message"] = value;
    this.setState({reminderformData})
  }

  handleEmpReminderDropdown=(check)=>{
    this.setState({showReminderEmployeeFilter:!check})
  }

  handleEmpRemindFilterSelect=(value)=>{
    console.log('handleEmpRemindFilterSelect',value)
    let { reminderformData } = this.state;
    reminderformData['action'] = value.type;
    reminderformData['actionId'] = value.id;
    this.setState({reminderformData, showReminderEmployeeFilter:false})  
  }

  handleReminderSubmit=()=>{
    this.setState({reminderSubmitConfirm:true})

  }

  handleConfirmReminderMailClose=()=>{
    this.setState({reminderSubmitConfirm:false})
  }


  onReminderSubmit=()=>{
    let { reminderformData, CurrCampaignID } = this.state;
    let data = {
        "campaignId": CurrCampaignID,
        // "companyId": "5f9aaf951be7e064d18f6e6a",
        "isMailDeliveryFailed": (reminderformData.actionId===2)?false:true,
        "subject":reminderformData.subject,
        "emailName":reminderformData.emailName,
        "message":reminderformData.message
      }

      console.log('onReminderSubmit: ',data)
      this.props.dispatch(campaignActions.resendMailInBulk(data));
      this.setState({reminderSubmitConfirm:false,showReminderModal:false,showCreateCampaign:false})

  }



  handleLanguage=(item)=>{
    this.setState({CurrentLanguage:item.shortName, CurrentLanguageName:item.name, showLanguageModal:false});
  }




  sidebarToggle = () =>{
    this.setState({showSidebar:!this.state.showSidebar});
  }
  routeLink=(link)=>{
    this.props.history.push('/app/'+link);
    this.setState({CrrRoute:link});
  }
  openLanguageModal=(check)=>{
    this.setState({showLanguageModal:!check});
    setTimeout(()=>{
      this.setState({showLanguageModal:false});
    },7000);
  }

  handleCampaignOptions=(value)=>{
    if(value === this.state.showCampaignOptions){
      this.setState({showCampaignOptions:''});
    }else{
      this.setState({showCampaignOptions:value});
    }
  }

  chooseCampaignOption=(item, optionType)=>{
    if(optionType===1){
      this.onReportCampaign(item);
    }
    else if(optionType===2){
      this.onReminderCampaign(item);
    }
    this.setState({showCampaignOptions:''});
  }



  handleEmpLevelDropdown=(check)=>{
    this.setState({showLevelFilter:!check, showValueFilter:false});
  }

  handleEmpValueDropdown=(check)=>{
    this.setState({showValueFilter:!check, showLevelFilter:false});
  }


  handleLevelSelect=(level)=>{

    if(level === "All"){
      this.setState({currFilterLevel:"Select Filter",currFilterValue:"Select Value", showValueFilter:false, showLevelFilter:false});
    }
    else{
      this.setState({currFilterLevel:level,currFilterValue:"Select Value", showLevelFilter:false});
    }
    
  }


  handleValueSelect=(value)=>{
    this.setState({currFilterValue:value, showValueFilter:false});
  }

  selectBatchSize=(item)=>{
    let { formBatchRange } = this.state;
    formBatchRange["size"] = item;
    formBatchRange["label"] = "";
    formBatchRange["rangeNo"] = 1;
    this.setState({formBatchRange});

    // let data5 = {
    //   "rangeNo": 1,
    //   "size":item
    // }
    // this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));

  }

  selectBatchRange=(item)=>{
    let { campaignFormData } = this.state;
    
    this.setState({storeBatchData:item})

    campaignFormData["employees"]=[];
    this.setState({selectAllEmp:false,campaignFormData,employees:[]});

    console.log('item; ',item.rangeNo);
    let { formBatchRange } = this.state;
    formBatchRange["label"] = item.label;
    formBatchRange["rangeNo"] = item.rangeNo;
    this.setState({formBatchRange,showBatchRange:false});



    // campaignFormData["employees"]=[];
    // this.setState({selectAllEmp:false,campaignFormData,employees:[]});

    // console.log('item; ',item.rangeNo);
    // let { formBatchRange } = this.state;
    // formBatchRange["label"] = item.label;
    // formBatchRange["rangeNo"] = item.rangeNo;
    // this.setState({formBatchRange,showBatchRange:false});
    // let data5 = {
    //   "rangeNo": item.rangeNo,
    //   "size":formBatchRange.size
    // }
    // this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));

  }

  handleBatchRangeDropdown=(check)=>{
    this.setState({showBatchRange:check});
  }


  handleCampaignType = (value) =>{
    let { campaignFormData } = this.state;
    campaignFormData['campaign_type'] = value;
    this.setState({campaignFormData});
  }


  handleEmployeeValueSearch=(e)=>{
    this.setState({employeeValueSearch:e.target.value},()=>{


      let data5={
        keyWord: this.state.employeeValueSearch,
        pageNo: 1,
        size: 10,
      }
      this.props.dispatch(surveyActions.getEmployeeSearch(data5));



    });

  }



  handleGetEmployees=(item)=>{
    let { formBatchRange, currFilterLevel, currFilterValue} = this.state;
      
      
          let data5 = {
            "rangeNo": formBatchRange.rangeNo,
            "size":formBatchRange.size,

          }
          if(currFilterLevel!=="Select Filter" && currFilterValue!=="Select Value"){
            data5["filterName"] = currFilterLevel;
            data5["filterValue"] = currFilterValue;
          }

          this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));

  }


  

render() {

let {formBatchRange,showBatchRange, employeeValueSearch} = this.state;


//ROLE MANAGEMENT ----------------------------------------------
let moduleAccess = {
  "isAdmin":false,
  "access":{}
};
if(accessModule()){
  moduleAccess["access"] = accessModule();
}
console.log('moduleAccess: ',moduleAccess)



let SHOW_ELEMENT = false;
let clientData = this.props.dashboard.ClientProfileData;
if(clientData && clientData.role){

    if(clientData.role === "ADMIN"){
      SHOW_ELEMENT = true;
      moduleAccess["isAdmin"] = true;
    }
    else if(clientData.role === "MNGR"){
      SHOW_ELEMENT = true;
      moduleAccess["isAdmin"] = false;
    }
    else if(clientData.role === "CROLE"){
      SHOW_ELEMENT = true;
      moduleAccess["isAdmin"] = false;
    }
    else if(clientData.role === "EMP"){
      SHOW_ELEMENT = false;
      moduleAccess["isAdmin"] = false;
    }
}
function accessAllow (item){
  return (moduleAccess.isAdmin?true:(moduleAccess && moduleAccess.access && moduleAccess.access[item]?true:false))
}

//END -------------------------------------------------------


        



    let { campaign, survey } = this.props;
    let {  campaignList, getCampaignEmployeesByIDData } = campaign;
    let { getIndexByFilterDMGData, getLanguageLabelData, getLanguageData, getSurveyData, getEmployeeSearchData, getEmployeeBatchRangeData, getCustomerBatchRangeData } = survey;
    let { showCampaignOptions,showLanguageModal,CurrentLanguageName, CurrentLanguage, crrSurvey, CrrRoute,showSidebar,reminderSubmitConfirm,confirmCreateCampaignAlert, empSelectRemindFilter, showReminderEmployeeFilter, reminderformData, showReminderModal, showCreateCampaign, campaignFormData, employees, EmpIndexA, EmpIndexB, currentEmpPage, selectAllEmp, showEmployeeFilter, empSelectFilter } = this.state;
    // console.log('getCampaignEmployeesByIDData: ',getCampaignEmployeesByIDData)


    let allEmployeesList = [];
    let AllEmpTotal = 0;

    if(employeeValueSearch){
      allEmployeesList = getEmployeeSearchData && getEmployeeSearchData.list && getEmployeeSearchData.list.length>0?getEmployeeSearchData.list:[];
      AllEmpTotal = getEmployeeSearchData && getEmployeeSearchData.total?getEmployeeSearchData.total:0;
    }
    else{
      allEmployeesList = getEmployeeBatchRangeData && getEmployeeBatchRangeData.list && getEmployeeBatchRangeData.list.length>0?getEmployeeBatchRangeData.list:[];
      AllEmpTotal = getEmployeeBatchRangeData && getEmployeeBatchRangeData.total?getEmployeeBatchRangeData.total:0;
    }


    let allCustomerList = getCustomerBatchRangeData && getCustomerBatchRangeData.list && getCustomerBatchRangeData.list.length>0?getCustomerBatchRangeData.list:[];
    let AllCustTotal = getCustomerBatchRangeData && getCustomerBatchRangeData.total?getCustomerBatchRangeData.total:0;

    console.log('campaignFormData: ',campaignFormData)
    console.log('employees: ',employees)
    console.log('xxxxxxx---------->employeeValueSearch: ',employeeValueSearch);
    console.log('-jjjjjj--------------------------------->getIndexByFilterDMGData: ',getIndexByFilterDMGData);
    // console.log('getLanguageData: ',getLanguageData)

    let loading = survey.loading || campaign.loading;

    let SurveyName = '';
    if(getSurveyData){
      SurveyName = getSurveyData.name;
    }

    let EmployeeList = []
    if(allEmployeesList && allEmployeesList.length>0){
      
      if(empSelectFilter.template===2){
        allEmployeesList.forEach((item,index)=>{
          if(!item.isDisable){
            EmployeeList.push(item)
          }
        })
      }
      else if(empSelectFilter.template===3){
        allEmployeesList.forEach((item,index)=>{
          if(item.isDisable){
            EmployeeList.push(item)
          }
        })
      }
      else{
        EmployeeList = allEmployeesList;
      }



    }
    

    let LanguageObj = {};
    if(getLanguageLabelData && getLanguageLabelData[CurrentLanguage]){
      LanguageObj =  getLanguageLabelData[CurrentLanguage];
    }
    let EmployeFilterList=[
      {
        name:LanguageObj && LanguageObj["show_all"+"_"+CurrentLanguage]? LanguageObj["show_all"+"_"+CurrentLanguage]:"Show All",
        template:1
      },
      {
        name:LanguageObj && LanguageObj["active"+"_"+CurrentLanguage]? LanguageObj["active"+"_"+CurrentLanguage]:"Active",
        template:2
      },
      {
        name:LanguageObj && LanguageObj["inactive"+"_"+CurrentLanguage]? LanguageObj["inactive"+"_"+CurrentLanguage]:"InActive",
        template:3
      },
    ]

    let actionList = [
      {
        id:2,
        type:LanguageObj && LanguageObj["reminder_to_incomplete"+"_"+CurrentLanguage]? LanguageObj["reminder_to_incomplete"+"_"+CurrentLanguage]:
        'Reminder to Survey Incomplete',
      },
      {
        id:3,
        type:LanguageObj && LanguageObj["reminder_to_not_delivered"+"_"+CurrentLanguage]? LanguageObj["reminder_to_not_delivered"+"_"+CurrentLanguage]:
        'Reminder to SurveyY Not Delivered',
      }
    ]
  
    let { ClientProfileData } = this.props.dashboard;
    if(ClientProfileData && ClientProfileData.role && ClientProfileData.role==="MNGR"){
      this.props.history.push(`/app/manageractionplan`)
    }



    let { 
      currFilterLevel,showLevelFilter,
      currFilterValue, showValueFilter
    } = this.state;


    // console.log('currFilterLevel: ',currFilterLevel);
    // console.log('confirmCreateCampaignAlert: ',confirmCreateCampaignAlert);

    console.log('formBatchRange: ',formBatchRange);



    let campaignTypeList = ['RECURSIVE', 'NORMAL', 'EVENT', 'SEGMENT', 'CUSTOMER','KIOSK'];



    return (
      <>
<div>


      <Sidebar sidebarToggle={this.sidebarToggle}  accessAllow={accessAllow} showSidebar={showSidebar} routeLink={this.routeLink} CrrRoute={CrrRoute}
       LanguageLabel={this.LanguageLabel}

       LanguageObj={LanguageObj}
       CurrentLanguage={CurrentLanguage}
      />


  <main className="lg:ml-16 h-screen">


  
  <Header  onLogout={this.onLogout} 
    CurrentLanguageName={CurrentLanguageName} 
    getLanguageData={getLanguageData} 
    handleLanguage={this.handleLanguage}
    openLanguageModal={this.openLanguageModal}
    showLanguageModal={showLanguageModal} 

    />



 
    <div className="mx-auto  h-full overflow-hidden overflow-y-auto bg-gray-100" style={{height: 'calc(100% - 4rem)'}}>
    {accessAllow("publishSurvey")?
    <>
      {/*sub nav bar 1 */}
      <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-1">
        <div className="inline-flex text-lg font-medium text-gray-500">
          <span onClick={()=>this.props.history.goBack()} className="cursor-pointer material-icons mr-4">keyboard_backspace</span>
          <div className="-mt-1 font-bold">{SurveyName} </div>
        </div>
        <div className="inline-flex items-center">
          {/* <p className="text-sm mr-1 text-gray-700">Expired on:</p> 
          <p className="text-sm font-semibold pl-1 text-gray-700">07 Nov, 2020</p> */}
          <div style={{height:'50px'}}>
            {showReminderModal || showCreateCampaign?
            <button onClick={()=>this.onCreateCampaign(2)} className="border border-blue-500 py-2 px-4 text-xs text-blue-500 rounded ml-2 ">
              {LanguageObj && LanguageObj["view_campaign_list"+"_"+CurrentLanguage]? LanguageObj["view_campaign_list"+"_"+CurrentLanguage]:"VIEW CAMPAIGN"}</button>
            :null}
          </div>

        </div>
      </div>
      {/* end */} 
      <div className="md:flex justify-between py-4 px-4">
        {/* left side */}


        
        <div className="flex items-center whitespace-nowrap overflow-x-auto">
          <h1 className="text-lg font-medium text-gray-800 ">{
          showReminderModal?
          (LanguageObj && LanguageObj["reminder_campaign"+"_"+CurrentLanguage]? LanguageObj["reminder_campaign"+"_"+CurrentLanguage]:"Reminder Campaign"):
            showCreateCampaign?
            (LanguageObj && LanguageObj["create_campaign"+"_"+CurrentLanguage]? LanguageObj["create_campaign"+"_"+CurrentLanguage]:"Create Campaign"): 
            ((showReminderModal?true:showCreateCampaign?true:(campaignList && campaignList.length>0)?true:!loading?false:true)?
              (LanguageObj && LanguageObj["campaign_label"+"_"+CurrentLanguage]? LanguageObj["campaign_label"+"_"+CurrentLanguage]:"Campaign")
            :null)
          }</h1>
          <div className="flex text-gray-500 text-xs items-center ml-2 border-l border-blue-500  pl-2 ">
          </div>
        </div>




        <div>
          {/* serch and button */}
          <div className="flex justify-between ">

            {/* <div className="bg-white mx-1.5 w-40 border text-black flex justify-between px-4 items-center relative py-1.5">
              <span className=" text-black text-xs font-semibold pr-4">All</span> 
              <span className="material-icons text-gray-500">arrow_drop_down</span>
              <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500 overflow-hidden hidden">
                <span className="inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"> 
                  Multiple Choice</span>
                <span className="inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100">
                  Check Box</span>
                <span className="inline-flex items-center py-3 px-4 text-xs w-full hover:bg-gray-100">
                  Text Box</span>
              </div>
            </div> */}

            {!showReminderModal?
            (!showCreateCampaign && campaignList && campaignList.length>0)?
              <button onClick={()=>this.onCreateCampaign(1)} className="bg-blue-500 text-white px-4 py-2 text-xs rounded">
               {LanguageObj && LanguageObj["create_campaign"+"_"+CurrentLanguage]? LanguageObj["create_campaign"+"_"+CurrentLanguage]:"CREATE CAMPAIGN"} 
              </button>
            :null:null}
            
          </div>
        </div>
      </div>



      {showReminderModal?
      <ReminderCampaign 
      reminderformData = {reminderformData}
      handleReminderInput = {this.handleReminderInput}
      handleReminderMessageInput = {this.handleReminderMessageInput}
      campaignList={campaignList}
      getCampaignEmployeesByIDData={getCampaignEmployeesByIDData}
      actionList={actionList}
      showReminderEmployeeFilter={showReminderEmployeeFilter}
      handleEmpReminderDropdown={this.handleEmpReminderDropdown}
      empSelectRemindFilter={empSelectRemindFilter}
      handleEmpRemindFilterSelect={this.handleEmpRemindFilterSelect}
      handleReminderSubmit={this.handleReminderSubmit}
      LanguageObj = {LanguageObj}
      CurrentLanguage = {CurrentLanguage}
      />
      :
      <>
      {showCreateCampaign?
        <CreateCampaign
            campaignFormData={campaignFormData}
            handleCampaignInput={this.handleCampaignInput}
            handleMessageInput={this.handleMessageInput}
            handleCampaignSubmit={this.handleCampaignSubmit}
            allEmployeesList={EmployeeList}
            allCustomerList={allCustomerList}

            handleCampaignEmployees={this.handleCampaignEmployees}

            employees={employees}

            EmpIndexA={EmpIndexA}
            EmpIndexB={EmpIndexB}

            prevEmpPage={this.prevEmpPage}
            nextEmpPage={this.nextEmpPage}

            SelectEmpPage={this.SelectEmpPage}
            currentEmpPage={currentEmpPage}
            SelectAllEmployees={this.SelectAllEmployees}
            selectAllEmp={selectAllEmp}


            showEmployeeFilter={showEmployeeFilter}

            EmployeFilterList={EmployeFilterList}

            empSelectFilter={empSelectFilter}

            handleEmpFilterSelect={this.handleEmpFilterSelect}

            handleEmpDropdown={this.handleEmpDropdown}

            handleTestUserCheck={this.handleTestUserCheck}
            LanguageObj = {LanguageObj}
            CurrentLanguage = {CurrentLanguage}


                  
          currFilterLevel={currFilterLevel}
          currFilterValue={currFilterValue}

          showLevelFilter={showLevelFilter}
          showValueFilter={showValueFilter}

          handleEmpLevelDropdown={this.handleEmpLevelDropdown}
          handleEmpValueDropdown={this.handleEmpValueDropdown}

          handleLevelSelect={this.handleLevelSelect}
          handleValueSelect={this.handleValueSelect}


          handleSendTypeCheck={this.handleSendTypeCheck}

          AllEmpTotal={AllEmpTotal}

          formBatchRange={formBatchRange}

          selectBatchRange={this.selectBatchRange}
          selectBatchSize={this.selectBatchSize}

          showBatchRange={showBatchRange}
          handleBatchRangeDropdown={this.handleBatchRangeDropdown}
          campaignTypeList={campaignTypeList}
          handleCampaignType={this.handleCampaignType}

          employeeValueSearch={employeeValueSearch}
          handleEmployeeValueSearch={this.handleEmployeeValueSearch}
          getIndexByFilterDMGData={getIndexByFilterDMGData}
          handleGetEmployees={this.handleGetEmployees}
        />
        :
        <>
        {campaignList && campaignList.length>0?
      <CampaignList 
        campaignList={campaignList}
        onReportCampaign={this.onReportCampaign}
        onReminderCampaign={this.onReminderCampaign}
        LanguageObj = {LanguageObj}
        CurrentLanguage = {CurrentLanguage}
        handleCampaignOptions={this.handleCampaignOptions}
        showCampaignOptions={showCampaignOptions}
        chooseCampaignOption={this.chooseCampaignOption}
      />
      :
      <>
      {!loading?
            <div className="w-full  flex justify-center items-center mt-4">
              {/*  text start*/}
              <div className="text-center">
                <img src="img/empty_survey.png" className="mb-4" />
                <h1 className="text-gray-800 text-lg  font-semibold cursor-default">No Campaign has been created yet.</h1>
                <p className="text-xs text-gray-500 cursor-default">Let's get started &amp; create your first campaign!</p>   
                <div className="flex justify-center"><button onClick={()=>this.onCreateCampaign(1)} className="my-6 bg-blue-500 px-6 py-3 text-white flex items-center focus:outline-none  rounded-md text-sm"><span className="material-icons mr-2">add</span> CREATE CAMPAIGN</button>    </div>
              </div>
              {/* tex end */}
            </div>
      :null}
      </>
      }
      </> 
      }
      </>
      }

    {reminderSubmitConfirm?

    <ConfirmSubmit 
    onReminderSubmit={this.onReminderSubmit}
    handleConfirmReminderMailClose={this.handleConfirmReminderMailClose}
    />

    :null}


    {confirmCreateCampaignAlert?

    <ConfirmCreateCampaign
    handleSendSingleMail={this.onCampaignSubmit}
    handleConfirmMailClose={this.handleConfirmCreateCampaignMailClose}
    />



:null}


</>


    :null}

         
    </div>





  </main></div>

      </>
    );
  }
}
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { campaign, survey, dashboard } = state;
  return {
    loggingIn,
    campaign,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(Campaign);
