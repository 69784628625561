import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { value } = this.props;
    
    let names = []
    let weightage1 = [];
    let weightage2 = [];

    names.push({
        value:"Overall",
        textStyle:{fontWeight:'bold'}
    })
    weightage1.push({
        value:value,
        itemStyle: {color: '#6536d1'}
    });
    weightage2.push({
        value:1-value,
        itemStyle: {color: '#ffffff'}

    });

                      
    return (
      <>
        <div style={{zIndex:'10'}}>

{true?
              <ReactEcharts
              style={{height:'95px',marginTop:"-55px",width:'100%'}}
              option={{
                            // tooltip: {
                            //     trigger: 'axis',
                            //     axisPointer: {            
                            //         type: 'shadow'        
                            //     },
                            //     formatter:function(a,b) {
                            //         console.log('a,b--->',a,b)
                            //     }
                            // },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'value',
                                axisLine:'left',
                                splitLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                },
                                axisLabel:{
                                    show:false
                                }

                            },
                            yAxis: {
                                type: 'category',
                                data: names,
                                position:'left',
                                inverse:true,
                                axisLine: {
                                    lineStyle: {
                                      color:'#fff'
                                    }
                                  },
                                axisLabel:{
                                    show:false
                                }
                            },
                            axisLabel: {
                                color: "#000",
                                interval: 0,
                                formatter: function(value) {
                                  if (value.length > 20) {
                                    return value.substring(0, 20) + ".. :";
                                  } else {
                                    return value + ":";
                                  }
                                }
                              },
                            series: [
                                {
                                    // name: 'Strongly Disagree',
                                    type: 'bar',
                                    stack: '总量',
                                    barWidth:'30px',
                                    label: {
                                        show: true,
                                        position: 'insideRight',
                                        formatter: function(data) {
                                          let score = data && data.value?data.value.toFixed(2):0;
                                          return score;
                                        }
                                    },
                                    data: weightage1,
                                    inverse:true
                                },
                                {
                                    // name: 'Disagree',
                                    type: 'bar',
                                    stack: '总量',
                                    barWidth:'30px',
                                    label: {
                                        show: false,
                                        position: 'insideRight',
                                        formatter: function(d) {
                                            if(parseInt(d.value) <7){
                                                return '';
                                            }
                                            else{
                                                return d.value;
                                            }
                                          }
                                    },
                                    data: weightage2,
                                    inverse:true
                                },
                               
                            ]
                        }}
                          />
                          : <div className="text-purple-500 font-semibold text-left p-2 px-4">NaN</div>}

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
//   console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
