import { generalConstants } from '../_constants';
import { generalService } from '../_services';

export const generalActions = {
    getSurvey,
    getDashboardCard,
    getQuestionDist,
    getQuestionDistOverall,
    getMeanScore,
    getMeanScoreQwise,
    getQuestionMatrixResult,
    getQuestionOthersResult
};

function getSurvey(data) {
    return dispatch => {
        dispatch(request());
        generalService.getSurvey(data)
            .then(
                general =>{ 
                    dispatch(success(general));
                    if(general && general.SurveyData && general.SurveyData.list && general.SurveyData.list.length>0){
                        let SurveyList = []
                        general.SurveyData.list.forEach((survey)=>{
                            if(survey && survey.templateId && survey.templateId === 3){
                                SurveyList.push(survey);
                            }

                        });
                        if(SurveyList && SurveyList.length>0){
                            let data2 = {
                                "surveyId": false? SurveyList[0].id:"5fa04d316ede0f0336066cce"
                            }
                            console.log('surveyId', data2.surveyId)

                            if(data2 && data2.surveyId){
                                dispatch(this.getDashboardCard(data2));
                            }
                        }

                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: generalConstants.GET_SURVEY_LIST_REQUEST } }
    function success(general) { return { type: generalConstants.GET_SURVEY_LIST_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_SURVEY_LIST_FAILURE, error } }
}


function getDashboardCard(data) {
    return dispatch => {
        dispatch(request());
        generalService.getDashboardCard(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: generalConstants.GET_DASHBOARD_CARD_REQUEST } }
    function success(general) { return { type: generalConstants.GET_DASHBOARD_CARD_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_DASHBOARD_CARD_FAILURE, error } }
}



function getQuestionDist(data) {
    return dispatch => {
        dispatch(request());
        generalService.getQuestionDist(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUESTION_DIST_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUESTION_DIST_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUESTION_DIST_FAILURE, error } }
}


function getQuestionDistOverall(data) {
    return dispatch => {
        dispatch(request());
        generalService.getQuestionDistOverall(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUESTION_DIST_OVERALL_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUESTION_DIST_OVERALL_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUESTION_DIST_OVERALL_FAILURE, error } }
}

function getMeanScore(data) {
    return dispatch => {
        dispatch(request());
        generalService.getMeanScore(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_MEAN_SCORE_REQUEST } }
    function success(general) { return { type: generalConstants.GET_MEAN_SCORE_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_MEAN_SCORE_FAILURE, error } }
}



function getMeanScoreQwise(data) {
    return dispatch => {
        dispatch(request());
        generalService.getMeanScoreQwise(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_MEAN_SCORE_QWISE_REQUEST } }
    function success(general) { return { type: generalConstants.GET_MEAN_SCORE_QWISE_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_MEAN_SCORE_QWISE_FAILURE, error } }
}


function getQuestionMatrixResult(data) {
    return dispatch => {
        dispatch(request());
        generalService.getQuestionMatrixResult(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_NPS_RESULT_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_NPS_RESULT_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_NPS_RESULT_FAILURE, error } }
}

function getQuestionOthersResult(data) {
    return dispatch => {
        dispatch(request());
        generalService.getQuestionOthersResult(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_OTHERS_RESULT_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_OTHERS_RESULT_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_OTHERS_RESULT_FAILURE, error } }
}