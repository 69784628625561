import React, { Component } from 'react';
import { connect } from 'react-redux';

//api call
import { dashboardActions, userActions } from '../../_actions';

import LoadingOverlay from 'react-loading-overlay';

// import ManagerActionPlan from './ManagerActionPlan/ManagerActionPlan';

import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import Survey from '../SurveyMgmt/Survey/Survey';
import DimensionListComponent from './DimensionList/DimensionList';
import ActionListComponent from './ActionList/ActionList';
import TaskListComponent from './TaskList/TaskList';
import ResourceListComponent from './ResourceList/ResourceList';
import TaskDetailsComponent from './TaskList/TaskDetails';
import ResourceDetailsComponent from './ResourceList/ResourceDetails';
import CompleteModal from './CompleteModal';
import EmptyDimensions from './EmptyDimensions';
import StartModal from './StartModal';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        CurrentSurveyId:"",
        CurrentSurveyName:"",
        showDimensionCards:false,
        CurrentDimensionId:"",
        CurrentDimensionData:"",
        CurrentActionId:"",
        ShowComponent:1,
        CurrentActionData:"",
        CurrentTaskId:"",
        CurrentTaskData:"",
        CurrentResourceId:"",
        CurrentResourceData:"",
        showCompleteModal:false,
        showStartModal:false,
        remarkTask:"",
        remarkResource:""
    }
  }
  componentDidMount() {

    let data = {
        "keyWord": "",
        "pageNo": 1,
        "size": 100
    }
    
    this.props.dispatch(dashboardActions.emptyManagerActionPlan());
    this.props.dispatch(dashboardActions.getClientProfile());
    // this.props.dispatch(dashboardActions.getSurvey(data,"ManageActionPlan"));

    this.props.dispatch(dashboardActions.getManagerActionPlan({surveyId:data.id}));


  }

  handleLogout=()=>{
    this.props.dispatch(userActions.logout());
  }

  


  // ChooseSurvey=(data,check)=>{
  //   this.setState({CurrentSurveyId:data.id,CurrentSurveyName:data.name, showDimensionCards:check?true:false});
  //   this.props.dispatch(dashboardActions.getManagerActionPlan({surveyId:data.id}));
  // }

  onSelectDimension=(parameter)=>{
    this.setState({CurrentDimensionId:parameter.id,CurrentDimensionData:parameter});
    this.setState({CurrentActionId:'',ShowComponent:1, CurrentResourceId:'',CurrentResourceData:"",CurrentTaskId:"",CurrentTaskData:""});

  }

  onSelectAction=(action)=>{
    this.setState({CurrentActionId:action.id,CurrentActionData:action, ShowComponent:1});
  }

  navigate=(page)=>{
    if(page==="tasklist"){
      this.setState({ShowComponent:1});
    }
    else if(page==="resourcelist"){
      this.setState({ShowComponent:2});
    }
    else if(page==="actionlist"){
      this.setState({CurrentActionId:"",CurrentActionData:"",ShowComponent:1});
    }
  }

  onSelectTask=(task,number)=>{
    this.setState({CurrentTaskId:task.id,CurrentTaskData:{...task,"number":number}, ShowComponent:3});
  }
  onSelectResource=(resource,number)=>{
    this.setState({CurrentResourceId:resource.id,CurrentResourceData:{...resource,"number":number}, ShowComponent:4});
  }


  handleManagerActionTaskComplete=(task, isStart)=>{
    let { dashboard } = this.props;
    let { SurveyList } = dashboard;
    let { CurrentSurveyId } = this.state;

    // let surveyId="";
    // if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    // }
    // else{
    //     surveyId=(SurveyList && SurveyList.length>0)?SurveyList[0].id:""
    // }

    let data = {
      "actionId":task.actionId,
      "taskId":task.taskId,
      "remark":task.remark,
      "emp_id":task.emp_id,
      "isStart":isStart
    }
    this.props.dispatch(dashboardActions.updateEmployeeTask(data));
    this.setState({showCompleteModal:false, showStartModal:false});

  }

  
  
  handleManagerActionResourceComplete=(resource)=>{
    let { dashboard } = this.props;
    let { SurveyList } = dashboard;
    let { CurrentSurveyId } = this.state;

    // let surveyId="";
    // if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    // }
    // else{
    //     surveyId=(SurveyList && SurveyList.length>0)?SurveyList[0].id:""
    // }

    let data = {
      // "surveyId": surveyId,
      "actionId":resource.actionId,
      "resourceId":resource.resourceId,
      "remark":resource.remark,
      "emp_id":resource.emp_id
    }
    console.log('emp_id: ',data)
    this.props.dispatch(dashboardActions.updateEmployeeResource(data));
    this.setState({showCompleteModal:false});

  }


  onComplete=()=>{
    this.setState({showCompleteModal:true});
  }

  onStartTask=()=>{
    this.setState({showStartModal:true});
  }


  closeCompleteModal=()=>{
    this.setState({showCompleteModal:false});
  }

  closeStartModal=()=>{
    this.setState({showStartModal:false});
  }


  handleRemark=(e)=>{
    console.log('e------------------------------->',e.target.value)
    let{ShowComponent}=this.state;
    if(ShowComponent===3){
      this.setState({remarkTask:e.target.value});
    }
    else if(ShowComponent===4){  
      this.setState({remarkResource:e.target.value});
    }
  }
  onRemarkCompleteSubmit=(data)=>{
    let{CurrentTaskId, CurrentResourceId, ShowComponent, CurrentActionId, remarkTask, remarkResource}=this.state;
    let { ClientProfileData } = this.props.dashboard;
    let emp_id = '';
    if(ClientProfileData && ClientProfileData.ManagerPID){
      emp_id = ClientProfileData.emp_id;
    }

    if(ShowComponent===3){
      let data1 = {
        "taskId":CurrentTaskId,
        "remark":remarkTask,
        "actionId":CurrentActionId,
        "emp_id":emp_id
      }
      let isStart =  false;
      this.handleManagerActionTaskComplete(data1, isStart)
      console.log("data1:",data1)
      this.setState({ShowComponent:1});

    }
    else if(ShowComponent===4){

      let data2 = {
        "resourceId":CurrentResourceId,
        "remark":remarkResource,
        "actionId":CurrentActionId,
        "emp_id":emp_id
      }
      this.handleManagerActionResourceComplete(data2)
      console.log("data2:",data2)

      this.setState({ShowComponent:2});
    }


  }


  onStartTaskEntry=(data)=>{
    let{CurrentTaskId, CurrentResourceId, ShowComponent, CurrentActionId, remarkTask, remarkResource}=this.state;
    let { ClientProfileData } = this.props.dashboard;
    let emp_id = '';
    if(ClientProfileData && ClientProfileData.ManagerPID){
      emp_id = ClientProfileData.emp_id;
    }

    if(ShowComponent===3){
      let data1 = {
        "taskId":CurrentTaskId,
        "actionId":CurrentActionId,
        "remark":"",
        "emp_id":emp_id
      }
      let isStart = true;
      this.handleManagerActionTaskComplete(data1,isStart)
      console.log("data1:",data1)
      this.setState({ShowComponent:1});

    }
    // else if(ShowComponent===4){

    //   let data2 = {
    //     "resourceId":CurrentResourceId,
    //     "actionId":CurrentActionId,
    //     "emp_id":emp_id
    //   }
    //   this.handleManagerActionResourceComplete(data2)
    //   console.log("data2:",data2)

    //   this.setState({ShowComponent:2});
    // }


  }

  onAssignManagerActionPlan=()=>{
    let { CurrentSurveyId  } = this.state;
    this.props.dispatch(dashboardActions.AssignManagerActionPlan({surveyId:CurrentSurveyId}));

  }


  render() {
    
    let { dashboard } = this.props;
    let { 
    ClientProfileData,loading,
    getManagerActionPlanData,
    SurveyList
    } = dashboard;

    let {showStartModal, remarkTask, remarkResource, showCompleteModal,showDimensionCards, CurrentSurveyId, CurrentSurveyName, CurrentDimensionId,CurrentDimensionData,CurrentActionId, ShowComponent,
      CurrentActionData,CurrentTaskId,CurrentTaskData,  CurrentResourceId,CurrentResourceData }= this.state;

    let isAlreadyAssigned = false;
    if(ClientProfileData && ClientProfileData.actionPlanStage && ClientProfileData.actionPlanStage===1){
        isAlreadyAssigned = true;
    }


    let optionType = 2;
    // if(!loading){
    //   if(isAlreadyAssigned){
    //     optionType = 2;
    //   }
    //   else{
    //     optionType = 1;
    //   }
    // }



    if(ClientProfileData && ClientProfileData.role && (ClientProfileData.role==="ADMIN")){
        this.props.history.push(`/app/survey`)
    }

    console.log('SurveyList: ',SurveyList)


    let ParameterList = []
    if(getManagerActionPlanData && getManagerActionPlanData.length>0){
        getManagerActionPlanData.forEach((action)=>{
            if(ParameterList.findIndex(prev=>prev.id===action.parameterId.id)===-1){
                let temp = {
                  ...action.parameterId,
                  score:action.parameterScore
                }
                ParameterList.push(temp);
            }
        })
    }


    let DimensionId = '';
    let DimensionData = '';
    if(CurrentDimensionId){
      DimensionId = CurrentDimensionId;
      DimensionData = CurrentDimensionData;
    }
    else{
      if(ParameterList && ParameterList.length>0){
        DimensionId = ParameterList[0].id;
        DimensionData = ParameterList[0];
      }
    }








    return (
      <>
    
    <LoadingOverlay
    active={loading}
    // active={false}

    spinner
    text='Loading Data...'
    className="loader"
    >
      <Sidebar/>



<div>


  {/* side bar end */} 
  <main class="lg:ml-16 h-screen bg-gray-100">

  <Header
        ClientProfileData={ClientProfileData}
        handleLogout={this.handleLogout}
      />

     
    <div className="mx-auto  h-full overflow-hidden overflow-y-auto" style={{height: "calc(100% - 4rem)"}}>
      <div className="md:flex justify-between items-center py-4 px-4">
        {/* left side */}
        <div className="flex items-center">
          <h1 className="text-2xl font-semibold text-gray-500 px-2">{ClientProfileData && ClientProfileData.name?ClientProfileData.name+" (Action Plan)":"Manager Action Plan"}</h1>
        </div>
        {/* right */}

        {/* <SurveyListComponent
        SurveyList={SurveyList}
        ChooseSurvey={this.ChooseSurvey}
        CurrentSurveyId={CurrentSurveyId}
        CurrentSurveyName={CurrentSurveyName}

        /> */}
      
      
      </div>
      {/* end */}
      {/*two box  */}
      <div className="lg:flex p-4 block h-full">

      {ParameterList && ParameterList.length>0?
      <>
      <DimensionListComponent
        ParameterList={ParameterList}
        onSelectDimension={this.onSelectDimension}
        CurrentDimensionId={DimensionId}
      />

      {CurrentActionId?
      <>
        
        {ShowComponent===1?
          <TaskListComponent
          getManagerActionPlanData={getManagerActionPlanData}
          CurrentDimensionData={DimensionData}
          CurrentActionId={CurrentActionId}
          CurrentActionData={CurrentActionData}
          navigate={this.navigate}
          onSelectTask={this.onSelectTask}
          
          />
        :null}

        {ShowComponent===2?
          <ResourceListComponent
          getManagerActionPlanData={getManagerActionPlanData}
          CurrentDimensionData={DimensionData}
          CurrentActionId={CurrentActionId}
          CurrentActionData={CurrentActionData}
          navigate={this.navigate}
          onSelectResource={this.onSelectResource}
          />
        :null}

        {ShowComponent===3?
          <TaskDetailsComponent
          getManagerActionPlanData={getManagerActionPlanData}
          CurrentDimensionData={DimensionData}
          CurrentActionId={CurrentActionId}
          CurrentActionData={CurrentActionData}
          navigate={this.navigate}
          onSelectTask={this.onSelectTask}
          CurrentTaskId={CurrentTaskId}
          CurrentTaskData={CurrentTaskData}
          onComplete={this.onComplete}
          onStartTask={this.onStartTask}
          />
        :null}

        {ShowComponent===4?
          <ResourceDetailsComponent
          getManagerActionPlanData={getManagerActionPlanData}
          CurrentDimensionData={DimensionData}
          CurrentActionId={CurrentActionId}
          CurrentActionData={CurrentActionData}
          navigate={this.navigate}
          onSelectResource={this.onSelectResource}
          CurrentResourceId={CurrentResourceId}
          CurrentResourceData={CurrentResourceData}
          onComplete={this.onComplete}
          />
        :null}

      </>
      :
      <ActionListComponent 
        getManagerActionPlanData={getManagerActionPlanData}
        CurrentDimensionId={DimensionId}
        onSelectAction={this.onSelectAction}
      />
      }
      </>
      :
      <>
      {!loading?
      <EmptyDimensions/>
      :null}
      </>
      }

      {showCompleteModal?
        <CompleteModal closeCompleteModal={this.closeCompleteModal} 

        tempData={ShowComponent===3?CurrentTaskData:CurrentResourceData} 
        remarkContent={ShowComponent===3?remarkTask:remarkResource} 

        handleRemark={this.handleRemark} 
        onRemarkCompleteSubmit={this.onRemarkCompleteSubmit}/>
      :null}


      {showStartModal?
        <StartModal closeStartModal={this.closeStartModal} 
        onStartTaskEntry={this.onStartTaskEntry}/>
      :null}




      </div>
    </div>



  
  
  
  </main>
</div>

    </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dashboard);
