import React from 'react';
import ReactEcharts from "echarts-for-react";


export default function BarCorelation(props) {
    
  let { GetParameterLabel, getCorrelationData , CountRestrict} = props;
  
  let itemListX ={}

  let count = getCorrelationData && getCorrelationData[1] && getCorrelationData[1].count ? getCorrelationData[1].count: "NaN";


  if(getCorrelationData && getCorrelationData.length>0){
    itemListX = Object.keys(getCorrelationData[0]);
  }
  
  let itemList = CountRestrict(count)?[]:itemListX;


  let CorrelationOverall = [];
  if(itemList && itemList.length>0){
    itemList.forEach((element)=>{
      if(element!=="name"){
        CorrelationOverall.push({
          "label":GetParameterLabel(element.slice(0,-3)),
          "score":getCorrelationData && getCorrelationData[0] && getCorrelationData[0][element]?getCorrelationData[0][element]:0
        });
      }


    });
  }

  CorrelationOverall.sort((a,b)=>b.score-a.score)




  let XAxis =[]
  let List = []


  if(CorrelationOverall && CorrelationOverall.length>0){
    CorrelationOverall.forEach((item)=>{
      XAxis.push(GetParameterLabel(item.label));
      List.push(item.score);
    })
  }

  console.log('CorrelationOverall:-------->>> ',CorrelationOverall)


  return (
    <>	
      {itemList && itemList.length>0?
      <div className="pt-1 -mt-6">
        <ReactEcharts
          option={{
            tooltip:{
              trigger: 'item',
              formatter:function (a) {
                console.log('params: ', a)
                return '<b>'+XAxis[a.dataIndex]+'</b></br>'+'Value: '+((a.value)?a.value.toFixed(2):0).toString()
              }
            },
            xAxis: {
                type: 'category',
                data: XAxis
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: List,
                type: 'bar',
                showBackground: true,
                barWidth:'20px',
                color:'#6536d1'
            }]
        }}
                          
        />
    
    <div className="text-center"><b>{"X-Axis:"}</b>{" Drivers,"} <b>{"Y-Axis:"}</b>{" Correlation"}</div>
    </div>
    :null}

    <div className="py-4 px-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>
         
    </>
  );
}
