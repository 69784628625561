import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Header/Header';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }
 

  render() {


    return (
      <>
    <div>
    <Header/>
    <main className="px-6 bg-gray-100 h-screen" style={{height:'90vh',overflow:'hidden'}}>
        <div className="container py-12 mx-auto">
            <h1 className="text-xl  pb-8">Complete you survey in just 3 steps.</h1>
            {/* boxes */}
            {/* here is boxes */}
            <div className="text-gray-600 justify-center grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1  gap-6 ">
            {/*box 1  */}
            <div className="p-4 max-w-md bg-white shadow-lg rounded py-8">
                <div className="flex justify-center">
                <span className="mt-2 bg-gray-100 h-24 w-24 rounded-full flex justify-center items-center 
        text-gray-800 font-bold text-3xl "> 1</span> 
                </div>
                <div className="flex justify-center">
                <span className="mt-2 text-gray-800 font-bold text-lg "> Create</span> 
                </div>
                <p className="text-center px-4 py-4 md:text-xs font-normal text-gray-800">From its medieval origins to the digital era,
                learn everything there is to know about
                the ubiquitous lorem ipsum passage.</p>   
            </div>
            {/* box 2 */}
            <div className="p-4 max-w-md bg-white shadow-lg rounded py-8">
                <div className="flex justify-center">
                <span className="mt-2 bg-gray-100 h-24 w-24 rounded-full flex justify-center items-center 
        text-gray-800 font-bold text-3xl "> 2</span> 
                </div>
                <div className="flex justify-center">
                <span className="mt-2 text-gray-800 font-bold text-lg "> Publish</span> 
                </div>
                <p className="text-center px-4 py-4 md:text-xs font-normal text-gray-800">From its medieval origins to the digital era,
                learn everything there is to know about
                the ubiquitous lorem ipsum passage.</p>   
            </div>
            {/* box 3 */}
            <div className="p-4 max-w-md bg-white shadow-lg rounded py-8">
                <div className="flex justify-center">
                <span className="mt-2 bg-gray-100 h-24 w-24 rounded-full flex justify-center items-center 
        text-gray-800 font-bold text-3xl ">3</span> 
                </div>
                <div className="flex justify-center">
                <span className="mt-2 text-gray-800 font-bold text-lg "> Analyze</span> 
                </div>
                <p className="text-center px-4 py-4 md:text-xs font-normal text-gray-800">From its medieval origins to the digital era,
                learn everything there is to know about
                the ubiquitous lorem ipsum passage.</p>   
            </div>
            {/* box 4 */}
            <div className="p-4 max-w-md bg-blue-500 shadow-lg rounded py-8">
                <div className="flex justify-center items-center w-full h-full">
                <div className="text-center">
                    <p className="text-center px-4 py-4 text-base font-normal text-white">Let's get started &amp;<br />
                    create your first survey</p>   
                    <button className="bg-white rounded text-sm py-3 px-8 text-blue-500 hover:shadow-lg font-semibold">CREATE SURVEY</button>
                </div>
                </div>
            </div>
            </div>
            {/* end */}
        </div>
        {/* container end */}
        </main>

    </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(LandingPage);
