import React from 'react';

 
  export default function SingleChoice(props){
    let { showBucketDropdown, parameterList, handleParameterBucket, handleParameterQuestion,
        getParameterListbyParentData,paraBucketName,question,showDimensionDropdown, 
        paraDimensionName, handleParameterDimension , GetParameterLabel } = props;
    return (
      <>
                       
                                        <>
                                        <div className="lg:flex justify-center ml-2">
                    
                                              <div className="flex items-center justify-center lg:float-left ">
                                                  <div className="pl-2 w-full">
                                                    <div className="bg-white mx-1.5 lg:w-60 sm:w-full md:w-full rounded-lg border text-black flex justify-between px-4 items-center relative py-1">
                                                      <span className="text-black text-xs font-semibold pr-4 cursor-pointer w-full" onClick={()=>handleParameterBucket(showBucketDropdown)}>{(paraBucketName)?paraBucketName:(question && question.parameterBucketId && question.parameterBucketId.name)?question.parameterBucketId.name:'Select Bucket'}</span> 
                                                      <span className="material-icons text-gray-500 cursor-pointer" onClick={()=>handleParameterBucket(showBucketDropdown)}>expand_more</span>
                                                      {showBucketDropdown?
                                                      <>
                                                      <div className="z-10 absolute top-10 left-0 bg-white  shadow-md rounded-md w-full text-gray-500 overflow-y-hidden">
                                                      <span onClick={()=>handleParameterQuestion({id:"",name:""}, question.id, 1)}
                                                      className="cursor-pointer inline-flex items-center py-3 px-4 
                                                      text-xs w-full border-b hover:bg-gray-100">{'None'}</span>
                                                      {parameterList && parameterList.length>0?
                                                        parameterList.map((para,index)=>
                                                        (para.parameterTypeLevel === 1)?
                                                          <span key={index}  onClick={()=>handleParameterQuestion(para, question.id)} className="cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"> 
                                                            {GetParameterLabel(para.name)}</span>
                                                          :null
                                                        )
                                                      :null}
                                                      </div>
                                                      </>
                                                      :null}
                    
                                                    </div>
                                                  </div>
                                                </div>
                    
                                            {(getParameterListbyParentData && getParameterListbyParentData.length>0)?
                                                (paraBucketName) || (question && question.parameterBucketId && question.parameterBucketId.name)?
                                                <>
                                                <div className="flex items-center justify-center">
                                                  <div className="pl-2 w-full">
                                                    <div className="bg-white mx-1.5 lg:w-60 sm:w-full rounded-lg md:w-full border text-black flex justify-between px-4 items-center relative py-1">
                                                      <span className=" text-black text-xs font-semibold pr-4 cursor-pointer w-full" onClick={()=>handleParameterDimension(showDimensionDropdown)}>{(paraDimensionName)?GetParameterLabel(paraDimensionName):(question && question.parameterDimensionId && question.parameterDimensionId.name)?GetParameterLabel(question.parameterDimensionId.name):'Select Dimension'}</span> 
                                                      <span className="material-icons text-gray-500 cursor-pointer" onClick={()=>handleParameterDimension(showDimensionDropdown)} >expand_more</span>
                                                      {showDimensionDropdown?
                                                      <>
                                                      <div className="z-10 absolute top-10 left-0 bg-white  shadow-md rounded-md w-full text-gray-500 overflow-hidden">
                                                      <span onClick={()=>handleParameterQuestion({id:"",name:""}, question.id, 2)} 
                                                      className="cursor-pointer inline-flex items-center py-3 px-4 
                                                      text-xs w-full border-b hover:bg-gray-100">{'None'}</span>
                                                      {getParameterListbyParentData && getParameterListbyParentData.length>0?
                                                        getParameterListbyParentData.map((para,index)=>
                                                          <span key={index}  onClick={()=>handleParameterQuestion(para, question.id)} className="cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"> 
                                                            {GetParameterLabel(para.name)}</span>
                                                        )
                                                      :null}
                                                      </div>
                                                      </>
                                                      :null}
                    
                                                    </div>
                                                  </div>
                                                </div>
                                                </>
                                                :null:null}
                    
                                          </div>
                                          </>
      </>
    );
  }