import React from "react";
import moment from 'moment';

export default function AddUserEmployeeList(props) {
  let { 
    getEmployeeListData,
    LanguageObj,
    CurrentLanguage,
    openAccessTab


  } = props;







  return (
   <>
          <div className="align-middle inline-block min-w-full overflow-hidden  ">
        <table className="min-w-full">
          <thead>
            <tr className="text-sm text-gray-800">
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left  font-medium  tracking-wider"> {"Emp Name"}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left  font-medium  tracking-wider">{LanguageObj && LanguageObj["emp_id_label"+"_"+CurrentLanguage]? LanguageObj["emp_id_label"+"_"+CurrentLanguage]:"Emp ID"}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left  font-medium  tracking-wider"> {LanguageObj && LanguageObj["email_address_label"+"_"+CurrentLanguage]? LanguageObj["email_address_label"+"_"+CurrentLanguage]:"Email Address"}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left  font-medium  tracking-wider"> {"Role"} </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center  font-medium  tracking-wider"> {LanguageObj && LanguageObj["status_label"+"_"+CurrentLanguage]? LanguageObj["status_label"+"_"+CurrentLanguage]:"Status"} </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center  font-medium  tracking-wider"> {LanguageObj && LanguageObj["action_label"+"_"+CurrentLanguage]? LanguageObj["action_label"+"_"+CurrentLanguage]:"Access"} </th>
            </tr>
          </thead>
          <tbody className="bg-white">
          {getEmployeeListData && getEmployeeListData.length>0?
            getEmployeeListData.map((emp, index)=>
            (!(emp && emp.role==="ADMIN") || true)?
                <tr className="text-sm">
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div className="leading-5  ">{emp && emp.name?emp.name:"-----"}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div className="leading-5  ">{emp && emp.emp_id?emp.emp_id:"-----"}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200   font-normal ">  {emp && emp.email?emp.email:"-----"} </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200   leading-5 "> {emp && emp.role?emp.role:"-----"}</td>

                  {emp.isDisable?
                <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200  leading-5 font-medium text-center"> 
                  <span className="px-2 inline-flex   leading-5 font-medium rounded-full bg-green-100 text-green-800">Inactive</span> 
                </td>
                :
                <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200  leading-5 font-medium text-center"> 
                <span className="px-2 inline-flex   leading-5 font-medium rounded-full bg-red-100 text-red-800">Active</span> 
                </td>}

                  <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200   leading-5 font-medium text-center"> 
                    <span onClick={()=>openAccessTab(emp)} className="cursor-pointer material-icons-outlined hover:bg-blue-100 p-2 rounded-md hover:text-blue-500 " style={{fontSize: '1.2rem'}}>privacy_tip</span> 
                  </td>
                </tr>
              :null
              ):null
            }
          </tbody>
        </table>
      </div>
   </>
  );
}
