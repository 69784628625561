import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

// class QuadrantChart extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//     }
//   }
//   componentDidMount() {

//   }

  export default function QuadrantChart(props) {
    let {  getDemographicIndexData, indexType, selectedItem1, selectedItem2, CountRestrict  } = props;
 
    let XAxis= ['Time','Being','Relationship','Worklife','Achievement','Meaning']
    let XAxisName= ['TimeAvg','BeingAvg','RelationshipAvg','WorkLifeAvg','AchivementAvg','MeeningfullAvg']


    // console.log('/===================/getDemographicIndexData/===================',getDemographicIndexData)
    console.log('/===================/selectedItem1/===================',selectedItem1)
    console.log('/===================/selectedItem2/===================',selectedItem2)
    
    let happinessIndex = selectedItem1.index;
    let engagementIndex = selectedItem2.index;

    let happinessName = selectedItem1.name;
    let engagementName = selectedItem2.name;

    let happinessCutoff = selectedItem1.cutOff;
    let engagementCutoff = selectedItem2.cutOff;

    let happinessRange = selectedItem1.range;
    let engagementRange = selectedItem2.range;

    let LL = []
    let HL = []
    let LH = []
    let HH = []


    let list = []
    let DepartmentList = []

    if(getDemographicIndexData && getDemographicIndexData.length>0){
      getDemographicIndexData.forEach((item)=>{
        console.log("item=====",item)
        if(!CountRestrict(item.count,false,"other")){

    
        list.push([
          item[happinessIndex],item[engagementIndex]
        ])

        DepartmentList.push({
          name:item.name,
          x:(item[happinessIndex])?(item[happinessIndex]).toFixed(2):item[happinessIndex],
          y:(item[engagementIndex])?(item[engagementIndex]).toFixed(2):item[engagementIndex],
        })




        if(item[happinessIndex]<happinessCutoff && item[engagementIndex]<engagementCutoff){
          LL.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        }
        else if(item[happinessIndex]>=happinessCutoff && item[engagementIndex]<engagementCutoff){
          HL.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        }
        else if(item[happinessIndex]<happinessCutoff && item[engagementIndex]>=engagementCutoff){
          LH.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        }
        else if(item[happinessIndex]>=happinessCutoff && item[engagementIndex]>=engagementCutoff){
          HH.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        } 





      }

      })
    }


    console.log('LL--->',LL);
    console.log('HL--->',HL);
    console.log('LH--->',LH);
    console.log('HH--->',HH);


    console.log('/===================/list/===================',DepartmentList)








    return (
      <>
        {/* <div className="px-2 font-semibold text-xl py-4">{"Happiness VS Engagement"}</div>
        <div className="px-2">{"Happiness (x-axis), Engagement(y-axis)"}</div> */}
        <div className="pt-2">


                <ReactEcharts
                    option={{
                      // title: {
                      //     text: 'Happiness VS Engagement',
                      //     subtext: 'Happiness (x-axis), Engagement(y-axis)'
                      // },
                      grid: {
                          left: '3%',
                          right: '7%',
                          bottom: '7%',
                          containLabel: true
                      },
                      tooltip: {
                          // trigger: 'axis',
                          showDelay: 0,
                          formatter: function (a) {
                            // console.log(a);
                            let HappinessScore = (DepartmentList[a.dataIndex])?DepartmentList[a.dataIndex].x:0;
                            let EngagementScore = (DepartmentList[a.dataIndex])?DepartmentList[a.dataIndex].y:0;

                            console.log("a----",a)
                            console.log("DepartmentList----",DepartmentList)

                            let getIndex1 = -1
                            if(DepartmentList && DepartmentList.length>0){
                              let a0 =  a && a.data && a.data[0] && a.data[0].toFixed(2)?a.data[0].toFixed(2):"";
                              let a1 =  a && a.data && a.data[1] && a.data[1].toFixed(2)?a.data[1].toFixed(2):"";
                              getIndex1 = DepartmentList.findIndex(prev=>prev.x===a0 && prev.y===a1)
                            }
                            console.log("getIndex1----",getIndex1)

                            let name1 = getIndex1!==-1 && DepartmentList && DepartmentList[getIndex1] && DepartmentList[getIndex1].name?DepartmentList[getIndex1].name:""
                            if(HappinessScore<happinessCutoff && EngagementScore<engagementCutoff){
                              return (
                                '<b>'+name1+'</b><br/><br/>'+
                                '<p style="color:red">'+happinessName+': '+HappinessScore+'</p>'+
                                '<p style="color:red">'+engagementName+': '+EngagementScore+'</p><br/>'
  
                              )
                            }
                            else if(HappinessScore>=happinessCutoff && EngagementScore<engagementCutoff){
                              return (
                                '<b>'+name1+'</b><br/><br/>'+
                                '<p style="color:green">'+happinessName+': '+HappinessScore+'</p>'+
                                '<p style="color:red">'+engagementName+': '+EngagementScore+'</p><br/>'
  
                              )
                            }
                            else if(HappinessScore<happinessCutoff && EngagementScore>=engagementCutoff){
                              return (
                                '<b>'+name1+'</b><br/><br/>'+
                                '<p style="color:red">'+happinessName+': '+HappinessScore+'</p>'+
                                '<p style="color:green">'+engagementName+': '+EngagementScore+'</p><br/>'
  
                              )
                            }
                            else if(HappinessScore>=happinessCutoff && EngagementScore>=engagementCutoff){
                              return (
                                '<b>'+name1+'</b><br/><br/>'+
                                '<p style="color:green">'+happinessName+': '+HappinessScore+'</p>'+
                                '<p style="color:green">'+engagementName+': '+EngagementScore+'</p><br/>'
  
                              )
                            } 

                            
                          },
                          axisPointer: {
                              show: true,
                              type: 'cross',
                              lineStyle: {
                                  type: 'dashed',
                                  width: 1
                              }
                          }
                      },
                      // toolbox: {
                      //     feature: {
                      //         dataZoom: {},
                      //         brush: {
                      //             type: ['rect', 'polygon', 'clear']
                      //         }
                      //     }
                      // },
                      // legend: {
                      //     data: ['Female', 'Male'],
                      //     left: 'center',
                      //     bottom: 10
                      // },
                      xAxis: [
                          {
                              type: 'value',
                              scale: true,
                              min:0,
                              max:happinessRange,
                              axisLabel: {
                                  formatter: '{value}'
                              },
                              splitLine: {
                                  show: false
                              }
                          }
                      ],
                      yAxis: [
                          {
                              type: 'value',
                              scale: true,
                              min:0,
                              max:engagementRange,
                              axisLabel: {
                                  formatter: '{value}'
                              },
                              splitLine: {
                                  show: false
                              }
                          }
                      ],
                      series: [
                          {
                              name: 'LL',
                              type: 'scatter',
                              emphasis: {
                                  focus: 'series'
                              },
                              data: list,
                              symbolSize:15,
                              markArea: {
                                  silent: true,
                                  itemStyle: {
                                      color: 'transparent',
                                      borderWidth: 1,
                                      borderType: 'dashed'
                                  },
                                  
                              },
                              itemStyle:{

                                color:function (a) {
                                    // console.log(a);
                                    let HappinessScore = DepartmentList[a.dataIndex].x;
                                    let EngagementScore = DepartmentList[a.dataIndex].y;
        
                                    if(HappinessScore<happinessCutoff && EngagementScore<engagementCutoff){
                                      return ('#e35454')
                                    }
                                    else if(HappinessScore>=happinessCutoff && EngagementScore<engagementCutoff){
                                      return ('#548be3')

                                    }
                                    else if(HappinessScore<happinessCutoff && EngagementScore>=engagementCutoff){
                                      return ('#a76af7')

                                    }
                                    else if(HappinessScore>=happinessCutoff && EngagementScore>=engagementCutoff){
                                      return ('#54e371')

                                    } 
                                }
                              },
                              markLine: {
                                  lineStyle: {
                                      type: 'solid'
                                  },
                                  data: [
                                      {xAxis:happinessCutoff},{yAxis:engagementCutoff}
                                     

                                  ]
                              }
                          },
                          
                      ]
                  }}
                  />
        </div>
      </>
    );
  }
// }
// function mapStateToProps(state) {
// //   console.log("state  ", state);
//   const { loggingIn } = state.authentication;
//   const { users, dashboard } = state;
//   return {
//     loggingIn,
//     users,
//     dashboard
//   };
// }
// export default connect(mapStateToProps)(QuadrantChart);
