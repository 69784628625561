import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Summary from './Components/Summary/Summary';
import NPSQuestion from './NPSQuestion/QuestionDetails';
import YesNoQuestion from './YesNoQuestion/QuestionDetails';
import RatingScaleQuestion from './RatingScaleQuestion/QuestionDetails';
import SingleChoice from './SingleChoice/QuestionDetails';
import MatrxiQuestion from './MatrixQuestion/QuestionDetails';

class HappinessIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
        optionType:1
    }
  }
  componentDidMount() {

  }

  handleOthersFilterCall=(item1, item2)=>{
    let { handleOthersFilterCall, currentQuestionTemplate } = this.props;
    handleOthersFilterCall(item1, item2, currentQuestionTemplate)
  }

  render() {
    let { getQuestionOthersResultData,handleNPSFilterCall, currentQuestionId, currentQuestionTemplate,
      EmpFilterData,getIndexFilterData } = this.props;


      let { getQuestionMatrixResultData, questionsList} = this.props;

    return (
      <> 

          {currentQuestionTemplate===6?
            <NPSQuestion 
              getQuestionOthersResultData={getQuestionOthersResultData}
              handleOthersFilterCall={this.handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}  
              getIndexFilterData={getIndexFilterData} 
            />
          :null}

          {currentQuestionTemplate===5?
            <RatingScaleQuestion 
              getQuestionOthersResultData={getQuestionOthersResultData}
              handleOthersFilterCall={this.handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}  
              getIndexFilterData={getIndexFilterData} 
            />
          :null}

          {currentQuestionTemplate===11?
            <YesNoQuestion 
              getQuestionOthersResultData={getQuestionOthersResultData}
              handleOthersFilterCall={this.handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}  
              getIndexFilterData={getIndexFilterData} 
            />
          :null}

          {currentQuestionTemplate===1?
            <SingleChoice 
              getQuestionOthersResultData={getQuestionOthersResultData}
              handleOthersFilterCall={this.handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}  
              getIndexFilterData={getIndexFilterData} 
            />
          :null}

        {currentQuestionTemplate===13?
            <MatrxiQuestion 
              getQuestionMatrixResultData={getQuestionMatrixResultData}
              questionsList={questionsList}
              handleOthersFilterCall={this.handleOthersFilterCall} 
              currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}  
              getIndexFilterData={getIndexFilterData} 
            />
        :null}


      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HappinessIndex);
