import React from 'react';
import Parameter from '../Parameter/Parameter';
import ParameterView from '../Parameter/ParameterView';

  export default function SingleChoice(props){
    let { SurveyTemplateId,question,quesindex, currQuesEditId,showTranslatePage,translShortName,
      translQuestion, handleTranslateQuestion, handleTranslateOption, onTranslateQuestionSave,

      handleQuestionInput, handleOptionInput, handleWeightageInput, onDeleteOption, onAddNewOption, onQuesMandatory, onSaveQues,
      handleParameterBucket, parameterList, handleParameterQuestion,paraBucketName,
      showBucketDropdown,showDimensionDropdown, handleParameterDimension, getParameterListbyParentData, paraDimensionName, showParameterModal, onParamaterModal,
      innerEdit,handleQuesCancel ,GetParameterLabel, handleLocationLable
    } = props;

      let [showDropdownQues,openDropdownQuestion]= React.useState(false)
      let [dropdownInput,handleDropdownSelect]= React.useState("")

      let NPSLabels = [
        {
          id:"country",
          name:"Country"
        },
        {
          id:"state",
          name:"State"
        },
        {
          id:"city",
          name:"City"
        },
      ]

      let TranslateLabels = [
        {
          id:"state",
          name:"Select State",
        },
        {
          id:"city",
          name:"Select District"
        }
      ]

    return (
      <>
{currQuesEditId === question.id?
                  <>
                  {showTranslatePage?
                  <>
                    <div className="flex justify-between items-center">
                      <div className="text-sm font-semibold text-gray-400">Question</div>
                      <div className="flex px-2">
                      <div onClick={()=>onTranslateQuestionSave(true, translShortName)} className="py-1 px-3 rounded-sm border border-1 border-green-500 cursor-pointer text-sm text-green-500 mr-2">Save</div>
                      <div onClick={()=>onTranslateQuestionSave(false, translShortName)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500">Cancel</div>
                    </div>                    </div>
                    <input 
                      type="text" 
                      value={question.name}
                      onChange={(event)=>handleQuestionInput(event.target.value,question.id)}
                      placeholder={"Q."+ (quesindex + 1) + " " + question.name}
                      className="py-2 px-4 border-2 outline-none w-full mt-2" 
                    />
                    <input 
                      type="text" 
                      value={translQuestion["transl"][translShortName].name}
                      onChange={(event)=>handleTranslateQuestion(event.target.value,question.id,translShortName)}
                      placeholder="Translate here..."
                      className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                    />
                    <hr/>
                    <ul className="pb-4">

                    <div className="flex ml-1 pt-4 mt-10">
                      <div className="text-sm font-semibold w-8/12 mr-16 text-gray-400">Options</div>
                    </div>
                    {TranslateLabels && TranslateLabels.length>0?
                          TranslateLabels.map((option, optionindex) => 
                          (translQuestion && translQuestion["transl"] && translQuestion["transl"]["customLocation"] && translQuestion["transl"]["customLocation"]["type"]?translQuestion["transl"]["customLocation"]["type"]:"")===option.id?
                            <div className="flex items-center justify-between py-2">
                              <div className=" w-full">
                                <input 
                                  type="text"
                                  value={option.name}
                                  onChange={(event)=>handleOptionInput(event.target.value,question.id,option.id)}
                                  placeholder={option.name}
                                  disabled={true}
                                  className="border border-gray-500 outline-none  px-4 text-sm py-1.5 w-9/12 mr-4" 
                                />
                              </div>
                              <div className=" w-full">
                                  <input 
                                  type="text"
                                  value={translQuestion && translQuestion["transl"] && translQuestion["transl"][translShortName] && translQuestion["transl"][translShortName].optionsList && translQuestion["transl"][translShortName].optionsList[option.id]?translQuestion["transl"][translShortName].optionsList[option.id]:""}
                                  onChange={(event)=>handleTranslateOption(event.target.value,question.id,option.id,translShortName)}
                                  className="border border-purple-500 outline-none  px-4 text-sm py-1.5 w-9/12 mr-4" 
                                  placeholder="Translate here..."
                                />
                              </div>
                            </div>
                          :null)
                        :null} 
                    </ul>
                  

                  </>
                  :
                  <>
                    <div className="flex justify-between items-center">
                      <div className="text-sm font-semibold text-gray-400">Question</div>
                      <div onClick={()=>handleQuesCancel(false)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500 mr-2">Cancel</div>
                    </div>
                    <input 
                      type="text" 
                      value={question.name}
                      onChange={(event)=>handleQuestionInput(event.target.value,question.id)}
                      placeholder={"Q."+ (quesindex + 1) + " " + question.name}
                      className="py-2 px-4 border-2 outline-none w-full mt-2" 
                    />
                    <hr/>
                    <ul className="pb-4">
                      {/* <p className="text-xs text-gray-500 pb-2">Answer</p> */}
                      <div className="mb-1.5 mt-2" style={{height:"30px"}}>
                        <div className="py-1" >
                          <input
                          id="mandatory-check"
                          value={question.isMandatory}
                          checked={question.isMandatory}
                          type="checkbox"
                          onChange={()=>{}}
                          className="cursor-pointer ml-1"
                          onClick={()=>onQuesMandatory(question.isMandatory,question.id)}
                          />
                          <label htmlFor="mandatory-check" className="text-sm text-gray-500 pb-8 pl-2">Mandatory</label>
                        </div>
                         {/* <div className="ml-6 mt-1 cursor-pointer text-sm text-purple-500 uppercase font-semibold" onClick={()=>onParamaterModal(!showParameterModal)} ><i className={showParameterModal?"fa fa-times":"pr-2 fa fa-plus"} />{showParameterModal?"":"Add Paramaters"}</div> */}
                      
                      
                      
                        
                        {/* <Parameter
                        showBucketDropdown={showBucketDropdown} 
                        parameterList={parameterList}
                        handleParameterBucket={handleParameterBucket}
                        handleParameterQuestion={handleParameterQuestion}
                        getParameterListbyParentData={getParameterListbyParentData}
                        paraBucketName={paraBucketName}
                        question={question}
                        showDimensionDropdown={showDimensionDropdown}
                        paraDimensionName={paraDimensionName}
                        handleParameterDimension={handleParameterDimension}
                        GetParameterLabel={GetParameterLabel}
                        /> */}
                      
  
                              </div>







                          <div className="flex ml-1 pt-4 mt-10">
                            <div className="text-sm font-semibold w-6/12 text-gray-400">Labels</div>
                            <div className="text-sm font-semibold w-2/12 text-gray-400">Names</div>
                          </div>
                            {NPSLabels && NPSLabels.length>0?
                              NPSLabels.map((option, optionindex) => 
                                <div className="flex items-center justify-between py-2">
                                  <div className=" w-full">
                                    <input 
                                      type="text"
                                      value={option.name}
                                      onChange={(event)=>handleOptionInput(event.target.value,question.id,option.id)}
                                      placeholder={option.name}
                                      disabled={true}
                                      className="border border-gray-500 rounded-lg outline-none  px-4 text-sm py-1.5 w-9/12 mr-4" 
                                    />
                                  </div>
                                  <div className=" w-full">
                                      <input 
                                      type="checkbox"
                                      checked={translQuestion && translQuestion["transl"] && translQuestion["transl"]["customLocation"] && translQuestion["transl"]["customLocation"]["type"] === option.id}
                                      value={translQuestion && translQuestion["transl"] && translQuestion["transl"]["customLocation"] && translQuestion["transl"]["customLocation"]["type"]?translQuestion["transl"]["customLocation"]["type"]:""}
                                      onClick={()=>handleLocationLable(option.id,question.id,"type","customLocation")}
                                      onChange={()=>{}}
                                      className="border border-blue-500 outline-none rounded-lg  px-4 text-sm py-1.5 ml-4 mr-4" 
                                      placeholder="Write here..."
                                    />
                                  </div>
                                </div>
                              )
                            :null} 





                    </ul>

                    <div className="flex justify-center">
                      <span onClick={()=>onAddNewOption(question)} className="cursor-pointer text-sm text-blue-500 uppercase font-semibold"><i className="fa fa-plus" /> Add more options</span>
                    </div>
                    
                  
                    <div className="flex justify-center pb-2 pt-8">
                      <span className="cursor-pointer text-sm rounded uppercase   bg-blue-500 py-2 px-4 text-white w-28  mx-1 text-center" onClick={()=>onSaveQues(question)}> save</span>
                    </div>


                    </>}
                  </>
              :
                  <>
                  <div title="Double Click to Edit!" onDoubleClick={()=>innerEdit(question,SurveyTemplateId)}>
                  <div  className="text-black font-semibold text-sm inline-flex">{question.parentId?<p className="pr-2">{" - "}</p>:<p className="pr-2">Q{quesindex+1}.</p>}  <h1>{question.name}{(question.isMandatory)?'*':null}</h1> </div>
                      <ParameterView question={question} GetParameterLabel={GetParameterLabel}/>

                      <div className="text-gray-500 font-semibold px-8">Location Type: {question && question["transl"] && question["transl"]["customLocation"] && question["transl"]["customLocation"]["type"]}</div>


                    </div>
                  </>
              }
      </>
    );
  }