import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';
import { generalActions } from '../_actions';

export const dashboardActions = {
    getClientProfile,
    getSurvey,
   

    // assignRole,

    newStackList,



    getIndex,
    getEmpFilter,
    getIndexFilter,
    getDemographicIndex,

    // summary score
    getHappinessIndexV2,
    getEngagementIndexV2,
    getHopeIndexV2,
    getStressIndexV2,
    getManagerIndexV2,
    getLeaderIndexV2,
    getSeniorleadershipIndexV2,


    // driver
    getDemographicIndexHappiness,
    getDemographicIndexEngagement,
    getDemographicIndexStress,
    getDemographicIndexManager,
    getDemographicIndexLeader,
    getDemographicIndexOrg,


    getHappinessIndexDriver,
    getEngagementIndexDriver,
    getStressIndexDriver,
    getManagerIndexDriver,
    getLeaderIndexDriver,
    getOrgIndexDriver,


    // question analysis
    getQuestion,
    getQuestionOverall,
    getQuestionReport,
    getMultiManagerQuestionList,
    getQuestionFilter,



    // score distribution
    getHappinessIndexV2Demographic,
    getEngagementIndexV2Demographic,
    getStressIndexV2Demographic,


    getManagerList,
    getDemographicIndexForManager,

    getManagerListDashboardManagerIndex,

    getAllBenchMark,
    getOrgIndexV2,

    getRespondantDetails,

    getManagerListDomainFilter,



    getQuestionManagerOfHappiness,
    getQuestionManagerOfEngagement,
    getQuestionManagerOfStress,
    getQuestionManagerOfManager,



    getIndexQuantile,

    getDemographicIndexScatter,


    getHappinessCorrelation,
    getHappinessCorrelationOverAll,

    getEngagementCorrelation,
    getEngagementCorrelationOverAll,


    getStressCorrelation,
    getStressCorrelationOverAll,

    getManagerCorrelation,
    getManagerCorrelationOverAll,


    getLeaderCorrelation,
    getLeaderCorrelationOverAll,


    getRWAnalysis,


    getTextQuestion,

    wordCloud,
    wordCloudText,
    wordCloudMultiText,
    AdvanceWordCloud,

    cooccurmatrix,

    neighborGraph,

    getEngagementRWA,

    cronbatchAlphaVariance,

    getQuestionFavorability,


    getHistoryIndex,


    getResponseAnalytics,

    getComment,




    getDemographicIndexMultiFilter,

    AssignManagerActionPlan,

    getManagerActionPlan,


    updateEmployeeTask,
    updateEmployeeResource,
    emptyClientProfile,

    getAllManagerActionPlan,
    crossTab,
    crossTabDMG,
    emptyManagerActionPlan,

    crossTabAnalysis,
    getCorrelationQuestionType2,

    getAllParameterList,

    getCorrelationDimenionVsDimension,
    getCorrelationDimensionVsDimensionFilter,




    getHappinessQuestion,
    getHappinessManagerQuestion,
    getHappinessDMGQuestion,

    getEngagementQuestion,
    getEngagementManagerQuestion,
    getEngagementDMGQuestion,

    getHopeQuestion,

    getStressQuestion,
    getStressManagerQuestion,
    getStressDMGQuestion,

    getManagerQuestion,
    getManagerManagerQuestion,
    getManagerDMGQuestion,

    getLeaderQuestion,
    getLeaderManagerQuestion,
    getLeaderDMGQuestion,

    getHappinessIndexByFilter,
    getEngagementIndexByFilter,
    getStressIndexByFilter,
    getManagerIndexByFilter,
    getLeaderIndexByFilter,


    
};

function emptyClientProfile() {
    let obj={
        ClientProfileData:{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getClientProfile()
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_FAILURE, error } }
}


function getClientProfile() {
    return dispatch => {
        dispatch(request());
        dashboardService.getClientProfile()
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CLIENT_PROFILE_DATA_FAILURE, error } }
}

function getSurvey(data, type) {
    return dispatch => {
        dispatch(request());
        dashboardService.getSurvey(data)
            .then(
                dashboard =>{ 
                    dispatch(success(dashboard));
                    dispatch(this.getAllManagerActionPlan({surveyId:data.surveyId}));
                    // if(type==="ManagerActionPlan"){
                    //     if(dashboard && dashboard.SurveyData && dashboard.SurveyData.list && dashboard.SurveyData.list.length>0 && dashboard.SurveyData.list[0] && dashboard.SurveyData.list[0].id){
                    //         const firstSurveyId = dashboard.SurveyData.list[0].id;
                    //         dispatch(this.AssignManagerActionPlan({surveyId:firstSurveyId}));
                    //     }
                    // }
                    // if(dashboard && dashboard.SurveyData && dashboard.SurveyData.list && dashboard.SurveyData.list.length>0 && dashboard.SurveyData.list[0] && dashboard.SurveyData.list[0].id){
                    //     let data2 = {
                    //         "surveyId": dashboard.SurveyData.list[0].id
                    //     }
                    //     let data3 = {
                    //         "surveyId": dashboard.SurveyData.list[0].id,
                    //         "filters":null
                    //     }
                    //     if(data2.surveyId){
                    //         dispatch(this.getRespondantDetails(data2));

                    //         dispatch(this.getIndex(data2));

                    //     }

                    // }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_SURVEY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_SURVEY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_SURVEY_FAILURE, error } }
}



function getEmpFilter(apiName,surveyId) {
    return dispatch => {
        dispatch(request());
        dashboardService.getEmpFilter()
            .then(
                dashboard =>{
                    dispatch(success(dashboard));
                    if(dashboard && dashboard.EmpFilterData && dashboard.EmpFilterData.length>0){
                        let Level = dashboard.EmpFilterData[0].name;
                        if(apiName==="getMeanScoreQwise"){
                            let data4 = {
                                "surveyId": surveyId,
                                "xAxis": Level
                              }
                              dispatch(generalActions.getMeanScoreQwise(data4)); 
                        }
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_EMP_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_EMP_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_EMP_FILTER_FAILURE, error } }
}



// function assignRole(data) {
//     return dispatch => {
//         dispatch(request());
//         dashboardService.assignRole(data)
//             .then(
//                 dashboard => dispatch(success(dashboard)),
//                 error => {
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: dashboardConstants.GET_ASSIGN_ROLE_REQUEST } }
//     function success(dashboard) { return { type: dashboardConstants.GET_ASSIGN_ROLE_SUCCESS, dashboard } }
//     function failure(error) { return { type: dashboardConstants.GET_ASSIGN_ROLE_FAILURE, error } }
// }


function newStackList() {
    let obj={
        StackListData:[]
    }
    return dispatch => {
        dispatch(success2(obj));
    };
    function success2(dashboard) { return { type: dashboardConstants.GET_STACK_LIST_SUCCESS, dashboard } }
}


// new api s------------------------------------>

function getIndex(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getIndex(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_FAILURE, error } }
}


function getIndexFilter(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_FILTER_FAILURE, error } }
}



function getDemographicIndex(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getDemographicIndex(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_FAILURE, error } }
}


function getQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_FAILURE, error } }
}


function getQuestionOverall(data) {
    let Obj ={
        getQuestionOverallData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionOverall(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_OVERALL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_OVERALL_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_OVERALL_FAILURE, error } }
}

function getQuestionReport(data, template) {
    return dispatch => {
        dispatch(request());
        dashboardService.getQuestionReport(data, template)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_REPORT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_REPORT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_REPORT_FAILURE, error } }
}



function getQuestionFilter(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getQuestionFilter(data)
            .then(
                dashboard => 
                {
                    dispatch(success(dashboard))
                    console.log('dashboard: ',dashboard)
                },

                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_FILTER_FAILURE, error } }
}


function getHappinessIndexV2(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getHappinessIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_FAILURE, error } }
}


function getEngagementIndexV2(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getEngagementIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_FAILURE, error } }
}


function getHopeIndexV2(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getHopeIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HOPE_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HOPE_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HOPE_INDEX_V2_FAILURE, error } }
}


function getStressIndexV2(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getStressIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_FAILURE, error } }
}


function getManagerIndexV2(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getManagerIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_INDEX_V2_FAILURE, error } }
}


function getLeaderIndexV2(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getLeaderIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_INDEX_V2_FAILURE, error } }
}


function getSeniorleadershipIndexV2(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getSeniorleadershipIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_FAILURE, error } }
}

function getOrgIndexV2(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getOrgIndexV2(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ORG_INDEX_V2_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_INDEX_V2_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ORG_INDEX_V2_FAILURE, error } }
}



function getDemographicIndexHappiness(data, type, ManagerDynaObj, isReport, DriverMngrHappinessReportObject,filters) {
    let obj = {
        getDemographicIndexHappinessData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexHappiness(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if(isReport){
                        if(dashboard && dashboard.getDemographicIndexHappinessData){
                            dashboard.getDemographicIndexHappinessData.forEach((item)=>{
                                if(filters.value===item.name){
                                            if(DriverMngrHappinessReportObject&&DriverMngrHappinessReportObject[filters.level]&&DriverMngrHappinessReportObject[filters.level].findIndex(prev=>prev.name === item.name)===-1){
                                                DriverMngrHappinessReportObject[filters.level].push(item);
                                            }
                                            else{
                                                if(DriverMngrHappinessReportObject&&DriverMngrHappinessReportObject[filters.level]&&DriverMngrHappinessReportObject[filters.level].findIndex(prev=>prev.name === item.name)){
                                                    let getIndex = DriverMngrHappinessReportObject[filters.level].findIndex(prev=>prev.name === item.name);
                                                    DriverMngrHappinessReportObject[filters.level][getIndex] = item;
                                                }
                                            }
                                }
                            });
                        }
                    }
                    if(type===5){
                        if(dashboard && dashboard.getDemographicIndexHappinessData){
                            dashboard.getDemographicIndexHappinessData.forEach((item)=>{
                                if(ManagerDynaObj.findIndex(prev=>prev.name === item.name)===-1){
                                    ManagerDynaObj.push(item);
                                }
                                else{
                                    let getIndex = ManagerDynaObj.findIndex(prev=>prev.name === item.name);
                                    ManagerDynaObj[getIndex]=item
                                }
                            })
                        }
                    }
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE, error } }
}


function getDemographicIndexEngagement(data, type, ManagerDynaObj,isReport, DriverMngrEngagementReportObject,filters) {
    let obj = {
        getDemographicIndexEngagementData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexEngagement(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if(isReport){
                        if(dashboard && dashboard.getDemographicIndexEngagementData){
                            dashboard.getDemographicIndexEngagementData.forEach((item)=>{
                                if(filters.value===item.name){
                                            if(DriverMngrEngagementReportObject&&DriverMngrEngagementReportObject[filters.level]&&DriverMngrEngagementReportObject[filters.level].findIndex(prev=>prev.name === item.name)===-1){
                                                DriverMngrEngagementReportObject[filters.level].push(item);
                                            }
                                            else{
                                                if(DriverMngrEngagementReportObject&&DriverMngrEngagementReportObject[filters.level]&&DriverMngrEngagementReportObject[filters.level].findIndex(prev=>prev.name === item.name)){
                                                    let getIndex = DriverMngrEngagementReportObject[filters.level].findIndex(prev=>prev.name === item.name);
                                                    DriverMngrEngagementReportObject[filters.level][getIndex] = item;
                                                }
                                            }
                                }
                            });
                        }
                    }
                    if(type===5){
                        if(dashboard && dashboard.getDemographicIndexEngagementData){
                            // ManagerDynaObj.concat(dashboard.getDemographicIndexEngagementData)
                            dashboard.getDemographicIndexEngagementData.forEach((item)=>{
                                if(ManagerDynaObj.findIndex(prev=>prev.name === item.name)===-1){
                                    ManagerDynaObj.push(item);
                                }
                                else{
                                    let getIndex = ManagerDynaObj.findIndex(prev=>prev.name === item.name);
                                    ManagerDynaObj[getIndex]=item
                                }
                            })
                        }
                    }
                },                
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE, error } }
}

function getDemographicIndexStress(data, type, ManagerDynaObj,isReport,DriverMngrStressReportObject,filters) {
    let obj = {
        getDemographicIndexStressData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexStress(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if(isReport){
                        if(dashboard && dashboard.getDemographicIndexStressData){
                            dashboard.getDemographicIndexStressData.forEach((item)=>{
                                if(filters.value===item.name){
                                            if(DriverMngrStressReportObject&&DriverMngrStressReportObject[filters.level]&&DriverMngrStressReportObject[filters.level].findIndex(prev=>prev.name === item.name)===-1){
                                                DriverMngrStressReportObject[filters.level].push(item);
                                            }
                                            else{
                                                if(DriverMngrStressReportObject&&DriverMngrStressReportObject[filters.level]&&DriverMngrStressReportObject[filters.level].findIndex(prev=>prev.name === item.name)){
                                                    let getIndex = DriverMngrStressReportObject[filters.level].findIndex(prev=>prev.name === item.name);
                                                    DriverMngrStressReportObject[filters.level][getIndex] = item;
                                                }
                                            }
                                }
                            });
                        }
                    }
                    if(type===5){
                        if(dashboard && dashboard.getDemographicIndexStressData){
                            // ManagerDynaObj.concat(dashboard.getDemographicIndexStressData)
                            dashboard.getDemographicIndexStressData.forEach((item)=>{
                                if(ManagerDynaObj.findIndex(prev=>prev.name === item.name)===-1){
                                    ManagerDynaObj.push(item);
                                }
                                else{
                                    let getIndex = ManagerDynaObj.findIndex(prev=>prev.name === item.name);
                                    ManagerDynaObj[getIndex]=item
                                }
                            })
                        }
                    }
                },                   
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE, error } }
}


function getDemographicIndexManager(data, type, ManagerDynaObj,isReport,DriverMngrManagerReportObject,filters) {
    let obj = {
        getDemographicIndexManagerData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexManager(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if(isReport){
                        if(dashboard && dashboard.getDemographicIndexManagerData){
                            dashboard.getDemographicIndexManagerData.forEach((item)=>{
                                if(filters.value===item.name){
                                            if(DriverMngrManagerReportObject&&DriverMngrManagerReportObject[filters.level]&&DriverMngrManagerReportObject[filters.level].findIndex(prev=>prev.name === item.name)===-1){
                                                DriverMngrManagerReportObject[filters.level].push(item);
                                            }
                                            else{
                                                if(DriverMngrManagerReportObject&&DriverMngrManagerReportObject[filters.level]&&DriverMngrManagerReportObject[filters.level].findIndex(prev=>prev.name === item.name)){
                                                    let getIndex = DriverMngrManagerReportObject[filters.level].findIndex(prev=>prev.name === item.name);
                                                    DriverMngrManagerReportObject[filters.level][getIndex] = item;
                                                }
                                            }
                                }
                            });
                        }
                    }
                    if(type===5){
                        if(dashboard && dashboard.getDemographicIndexManagerData){
                            // ManagerDynaObj.concat(dashboard.getDemographicIndexManagerData)
                            dashboard.getDemographicIndexManagerData.forEach((item)=>{
                                if(ManagerDynaObj.findIndex(prev=>prev.name === item.name)===-1){
                                    ManagerDynaObj.push(item);
                                }
                                else{
                                    let getIndex = ManagerDynaObj.findIndex(prev=>prev.name === item.name);
                                    ManagerDynaObj[getIndex]=item
                                }
                            })
                        }
                    }
                },                   
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE, error } }
}

function getDemographicIndexLeader(data, type, ManagerDynaObj) {
    let obj = {
        getDemographicIndexLeaderData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexLeader(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if(type===5){
                        if(dashboard && dashboard.getDemographicIndexLeaderData){
                            // ManagerDynaObj.concat(dashboard.getDemographicIndexLeaderData)
                            dashboard.getDemographicIndexLeaderData.forEach((item)=>{
                                if(ManagerDynaObj.findIndex(prev=>prev.name === item.name)===-1){
                                    ManagerDynaObj.push(item);
                                }
                                else{
                                    let getIndex = ManagerDynaObj.findIndex(prev=>prev.name === item.name);
                                    ManagerDynaObj[getIndex]=item
                                }
                            })
                        }
                    }
                },                   
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE, error } }
}

function getDemographicIndexOrg(data) {
    let obj = {
        getDemographicIndexOrgData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        dashboardService.getDemographicIndexOrg(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_FAILURE, error } }
}

function getHappinessIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getHappinessIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_FAILURE, error } }
}

function getEngagementIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getEngagementIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_FAILURE, error } }
}

function getStressIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getStressIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_DRIVER_FAILURE, error } }
}


function getManagerIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getManagerIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_INDEX_DRIVER_FAILURE, error } }
}


function getLeaderIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getLeaderIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_INDEX_DRIVER_FAILURE, error } }
}

function getOrgIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getOrgIndexDriver(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ORG_INDEX_DRIVER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ORG_INDEX_DRIVER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ORG_INDEX_DRIVER_FAILURE, error } }
}






function getHappinessIndexV2Demographic(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getHappinessIndexV2Demographic(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE, error } }
}

function getEngagementIndexV2Demographic(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getEngagementIndexV2Demographic(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE, error } }
}

function getStressIndexV2Demographic(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getStressIndexV2Demographic(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE, error } }
}


function getManagerList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getManagerList(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    // if(dashboard && dashboard.ManagerList && dashboard.ManagerList.list && dashboard.ManagerList.list.length>0 && dashboard.ManagerList.list[0] && dashboard.ManagerList.list[0].ManagerPID){
                    //     let data1 = {
                    //         "surveyId": data.surveyId,
                    //         "filters": [
                    //             {
                    //                 "level": "ManagerPID",
                    //                 "value":dashboard.ManagerList.list[0].ManagerPID
                    //             }
                    //         ]
                    //     }
                    //     dispatch(this.getIndexByManagerWise(data1));
                    // }

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_LIST_FAILURE, error } }
}


function getManagerListDashboardManagerIndex(data,mngr,ManagerListDasboardDataObj) {
    return dispatch => {
        dispatch(request());
        dashboardService.getManagerListDashboardManagerIndex(data,mngr)
            .then(
                dashboard => {
                    dispatch(success(dashboard));  
                    if(dashboard && dashboard.getManagerListDashboardManagerIndexData && dashboard.getManagerListDashboardManagerIndexData.ManagerPID){
                        let managerId = dashboard.getManagerListDashboardManagerIndexData.ManagerPID;
                        let getIndex = ManagerListDasboardDataObj.findIndex(prev=>prev.ManagerPID === managerId)
                        if(getIndex===-1){
                            ManagerListDasboardDataObj.push(dashboard.getManagerListDashboardManagerIndexData);
                        }
                        else{
                            ManagerListDasboardDataObj[getIndex]=dashboard.getManagerListDashboardManagerIndexData;
                        }


                        console.log('asdadadad===>',dashboard.getManagerListDashboardManagerIndexData)
                    }
                    
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE, error } }
}


function getDemographicIndexForManager(data, type, DynaObj, EmpFilterData,surveyId) {
    return dispatch => {
        dispatch(request());
        dashboardService.getDemographicIndexForManager(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if(type === 5){

                    if(dashboard && dashboard.getDemographicIndexForManagerData && dashboard.getDemographicIndexForManagerData.length>0 ){

   
                    // console.log("EmpFilterData[0]",EmpFilterData)
                    let data2 =  dashboard.getDemographicIndexForManagerData[0];
                    for(let i=0;i<2;i++){
                      if(EmpFilterData && EmpFilterData.length>0){
                        // console.log("dashboard[0]",data2)

                        // let filterdata2 = {
                        //     "surveyId":surveyId,
                        //     "filters":[{
                        //          "level":"ManagerPID",
                        //          "value":data2["id"]
                        //      }]
                        // }
                        // console.log("dashboard[0]",filterdata2)

                        // dispatch(this.getManagerListDomainFilter(filterdata2,5,DynaObj));
                        EmpFilterData.forEach((filter)=>{
                            if(["ManagerPID","Department","Function","Business"].findIndex(prev=>prev===filter.name)!==-1){

                    
                                        if(data2 && data2[filter.name]){
                                        let filterdata = {
                                            "surveyId":surveyId,
                                            "filters":[{
                                                    "level":filter.name,
                                                    "value":data2[filter&&filter.name==="ManagerPID"?"id":filter.name]
                                                }]
                                        }

                                        dispatch(this.getManagerListDomainFilter(filterdata,5,DynaObj));
                            }
                 
                        }
                 
                       })
                     }
  
                    }

                    }

                }
                
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE, error } }
}


function getAllBenchMark(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getAllBenchMark(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_BENCHMARK_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_BENCHMARK_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_BENCHMARK_FAILURE, error } }
}

function getRespondantDetails(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getRespondantDetails(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RESPONDANT_DETAILS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RESPONDANT_DETAILS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RESPONDANT_DETAILS_FAILURE, error } }
}


function getManagerListDomainFilter(data,indexType,DynaObj) {
    let refresh = {
        getManagerListDomainFilterData:{}
    }
    return dispatch => {
        dispatch(success(refresh));
        dispatch(request());
        dashboardService.getManagerListDomainFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    if(indexType === 5){
                        if(dashboard && dashboard.getManagerListDomainFilterData){
                            let newData = dashboard.getManagerListDomainFilterData;
                            if(DynaObj.findIndex(prev=>prev.name[0] === newData.name[0])===-1){
                                DynaObj.push(newData);
                            }
                            else{
                                let getIndex = DynaObj.findIndex(prev=>prev.name[0] === newData.name[0]);
                                DynaObj[getIndex]=newData;
                            }
                        }
                    }
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE, error } }
}





function getQuestionManagerOfHappiness(data,type,QuesDynaObj, isReport, QuesMngrHappinessReportObject) {
    let Obj ={
        getQuestionManagerOfHappinessData:[]
    }
    return dispatch => {

        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionManagerOfHappiness(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));  
                    if(true){
                        if(dashboard && dashboard.getQuestionManagerOfHappinessData){


                            if(dashboard && dashboard.getQuestionManagerOfHappinessData){
                                dashboard.getQuestionManagerOfHappinessData.forEach((item)=>{



                                        if(QuesMngrHappinessReportObject&&QuesMngrHappinessReportObject[item.filters[0].level]&&QuesMngrHappinessReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                            QuesMngrHappinessReportObject[item.filters[0].level].push(item);
                                        }
                                        else{
                                            if(QuesMngrHappinessReportObject&&QuesMngrHappinessReportObject[item.filters[0].level]&&QuesMngrHappinessReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                                let getIndex = QuesMngrHappinessReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                                QuesMngrHappinessReportObject[item.filters[0].level][getIndex] = item;
                                            }
                                        }




                                    }
                                )
    
                            }
                        }
                            
                    } 
                    if(type===5){
                        if(dashboard && dashboard.getQuestionManagerOfHappinessData){
                            
                            dashboard.getQuestionManagerOfHappinessData.forEach((item)=>{



                                    if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                        QuesDynaObj[item.filters[0].level].push(item);
                                    }
                                    else{
                                        if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                            let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                            QuesDynaObj[item.filters[0].level][getIndex] = item;
                                        }

                                    }




                                }
                            )

                        }
                    }


                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE, error } }
}

function getQuestionManagerOfEngagement(data,type,QuesDynaObj, isReport, QuesMngrEngagementReportObject) {
    let Obj ={
        getQuestionManagerOfEngagementData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionManagerOfEngagement(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));  
                    if(true){
                        if(dashboard && dashboard.getQuestionManagerOfEngagementData){
                            
                            if(dashboard && dashboard.getQuestionManagerOfEngagementData){
                            
                                dashboard.getQuestionManagerOfEngagementData.forEach((item)=>{
                                        if(QuesMngrEngagementReportObject&&QuesMngrEngagementReportObject[item.filters[0].level]&&QuesMngrEngagementReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                            QuesMngrEngagementReportObject[item.filters[0].level].push(item);
                                        }
                                        else{
                                            if(QuesMngrEngagementReportObject&&QuesMngrEngagementReportObject[item.filters[0].level]&&QuesMngrEngagementReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                                let getIndex = QuesMngrEngagementReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                                QuesMngrEngagementReportObject[item.filters[0].level][getIndex] = item;
                                            }
                                        }
                                    }
                                )
    
                            }
                        }
                            
                    } 
                    if(type===5){
                        if(dashboard && dashboard.getQuestionManagerOfEngagementData){
                            
                            dashboard.getQuestionManagerOfEngagementData.forEach((item)=>{



                                    if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                        QuesDynaObj[item.filters[0].level].push(item);
                                    }
                                    else{
                                        if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                            let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                            QuesDynaObj[item.filters[0].level][getIndex] = item;
                                        }

                                    }
                                    
                                }
                            )

                        }
                    }


                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE, error } }
}

function getQuestionManagerOfStress(data,type,QuesDynaObj, isReport, QuesMngrStressReportObject) {
    let Obj ={
        getQuestionManagerOfStressData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionManagerOfStress(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));   
                    console.log('QuesDynaObj: ',QuesDynaObj)
                    if(true){
                        if(dashboard && dashboard.getQuestionManagerOfStressData){
                            
                            if(dashboard && dashboard.getQuestionManagerOfStressData){
                            
                                dashboard.getQuestionManagerOfStressData.forEach((item)=>{
                                        if(QuesMngrStressReportObject&&QuesMngrStressReportObject[item.filters[0].level]&&QuesMngrStressReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                            QuesMngrStressReportObject[item.filters[0].level].push(item);
                                        }
                                        else{
                                            if(QuesMngrStressReportObject&&QuesMngrStressReportObject[item.filters[0].level]&&QuesMngrStressReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                                let getIndex = QuesMngrStressReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                                QuesMngrStressReportObject[item.filters[0].level][getIndex] = item;
                                            }
 
                                        }
                                    }
                                )
    
                            }
                        }
                            
                    } 
                    if(type===5){
                        if(dashboard && dashboard.getQuestionManagerOfStressData){
                            
                            dashboard.getQuestionManagerOfStressData.forEach((item)=>{
                                    if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                        QuesDynaObj[item.filters[0].level].push(item);
                                    }
                                    else{
                                        if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                            let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                            QuesDynaObj[item.filters[0].level][getIndex] = item;
                                        }

                                    }
                                }
                            )

                        }
                    }              
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_FAILURE, error } }
}

function getQuestionManagerOfManager(data,type,QuesDynaObj, isReport, QuesMngrManagerReportObject) {
    let Obj ={
        getQuestionManagerOfManagerData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getQuestionManagerOfManager(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));   
                    console.log('QuesDynaObj: ',QuesDynaObj)
                    if(true){
                        if(dashboard && dashboard.getQuestionManagerOfManagerData){
                            
                            if(dashboard && dashboard.getQuestionManagerOfManagerData){
                            
                                dashboard.getQuestionManagerOfManagerData.forEach((item)=>{
                                        if(QuesMngrManagerReportObject&&QuesMngrManagerReportObject[item.filters[0].level]&&QuesMngrManagerReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                            QuesMngrManagerReportObject[item.filters[0].level].push(item);
                                        }
                                        else{
                                            if(QuesMngrManagerReportObject&&QuesMngrManagerReportObject[item.filters[0].level]&&QuesMngrManagerReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                                let getIndex = QuesMngrManagerReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                                QuesMngrManagerReportObject[item.filters[0].level][getIndex] = item;
                                            }

                                        }
                                    }
                                )
    
                            }
                        }
                            
                    } 
                    if(type===5){
                        if(dashboard && dashboard.getQuestionManagerOfManagerData){
                            
                            dashboard.getQuestionManagerOfManagerData.forEach((item)=>{
                                    if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                        QuesDynaObj[item.filters[0].level].push(item);
                                    }
                                    else{
                                        if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                            let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                            QuesDynaObj[item.filters[0].level][getIndex] = item;
                                        }

                                    }
                                }
                            )

                        }
                    }             
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_FAILURE, error } }
}




function getIndexQuantile(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getIndexQuantile(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_INDEX_QUANTILE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_INDEX_QUANTILE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_INDEX_QUANTILE_FAILURE, error } }
}


function getDemographicIndexScatter(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getDemographicIndexScatter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE, error } }
}


function getHappinessCorrelation(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getHappinessCorrelation(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_FAILURE, error } }
}


function getHappinessCorrelationOverAll(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getHappinessCorrelationOverAll(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_CORRELATION_FAILURE, error } }
}



function getEngagementCorrelation(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getEngagementCorrelation(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_FAILURE, error } }
}

function getEngagementCorrelationOverAll(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getEngagementCorrelationOverAll(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_CORRELATION_FAILURE, error } }
}



function getStressCorrelation(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getStressCorrelation(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_CORRELATION_FAILURE, error } }
}


function getStressCorrelationOverAll(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getStressCorrelationOverAll(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_CORRELATION_FAILURE, error } }
}



function getManagerCorrelation(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getManagerCorrelation(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_CORRELATION_FAILURE, error } }
}

function getManagerCorrelationOverAll(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getManagerCorrelationOverAll(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_CORRELATION_FAILURE, error } }
}




function getLeaderCorrelation(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getLeaderCorrelation(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_CORRELATION_FAILURE, error } }
}

function getLeaderCorrelationOverAll(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getLeaderCorrelationOverAll(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_CORRELATION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_CORRELATION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_CORRELATION_FAILURE, error } }
}



function getRWAnalysis(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getRWAnalysis(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RWA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RWA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RWA_FAILURE, error } }
}


function getTextQuestion(data, check,filer) {
    return dispatch => {
        dispatch(request());
        dashboardService.getTextQuestion(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

          
                        if(dashboard.getTextQuestionData && dashboard.getTextQuestionData.length>0){
                            let data2 ={
                                "surveyId": data.surveyId,
                                "questionId": dashboard.getTextQuestionData[0].id,
                                "filters": filer&&filer.length>0?filer:[]
                            }
    
                            if(check===1){
                                dispatch(this.wordCloud(data2));
                            }
                            else if(check===2){
                                dispatch(this.cooccurmatrix(data2));
                            }
                            else if(check===3){
                                dispatch(this.neighborGraph(data2));
                            }
                            else if(check===4){
                                dispatch(this.AdvanceWordCloud(data2));
                            }
                        }




                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_TEXT_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_TEXT_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_TEXT_QUESTION_FAILURE, error } }
}


function wordCloud(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.wordCloud(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                    if(dashboard && dashboard.wordCloudData && dashboard.wordCloudData.list.length>0){
                        let List = dashboard.wordCloudData.list;
                        List.sort((a,b)=> b[0] - a[0])
                        if(List && List.length>0){
                            let KeyWord = List[0][0];

                            let data3 = {
                                ...data,
                                "keyWord": KeyWord
                              }


                            dispatch(this.wordCloudText(data3))

                        }
                    }

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.WORD_CLOUD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.WORD_CLOUD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.WORD_CLOUD_FAILURE, error } }
}



function AdvanceWordCloud(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.AdvanceWordCloud(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                    if(dashboard && dashboard.AdvanceWordCloudData && dashboard.AdvanceWordCloudData.list.length>0){
                        let List = dashboard.AdvanceWordCloudData.list;
                        List.sort((a,b)=> b[0] - a[0])
                        if(List && List.length>0){
                            let KeyWord = List[0][0];

                            let data3 = {
                                ...data,
                                "keyWord": KeyWord
                              }


                            dispatch(this.wordCloudText(data3))

                        }
                    }

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.ADVANCE_WORD_CLOUD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.ADVANCE_WORD_CLOUD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.ADVANCE_WORD_CLOUD_FAILURE, error } }
}



function wordCloudText(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.wordCloudText(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.WORD_CLOUD_TEXT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.WORD_CLOUD_TEXT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.WORD_CLOUD_TEXT_FAILURE, error } }
}


function wordCloudMultiText(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.wordCloudMultiText(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.WORD_CLOUD_MULTI_TEXT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.WORD_CLOUD_MULTI_TEXT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.WORD_CLOUD_MULTI_TEXT_FAILURE, error } }
}

function cooccurmatrix(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.cooccurmatrix(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.COOCCUR_MATRIX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.COOCCUR_MATRIX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.COOCCUR_MATRIX_FAILURE, error } }
}

function neighborGraph(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.neighborGraph(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.NEIGHBOR_GRAPH_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.NEIGHBOR_GRAPH_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.NEIGHBOR_GRAPH_FAILURE, error } }
}


function getEngagementRWA(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getEngagementRWA(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_RWA_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_RWA_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_RWA_FAILURE, error } }
}


function cronbatchAlphaVariance(data) {
    let ClearObj = {
        cronbatchAlphaVarianceData:{}
    }
    return dispatch => {
        dispatch(success2(ClearObj));
        dispatch(request());
        dashboardService.cronbatchAlphaVariance(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_SUCCESS, dashboard } }
    function success2(dashboard) { return { type: dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO, dashboard } }
    function failure(error) { return { type: dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_FAILURE, error } }

}


function getQuestionFavorability(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getQuestionFavorability(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_QUESTION_FAVOURABILITY_FAILURE, error } }

}


function getHistoryIndex(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getHistoryIndex(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HISTORY_INDEX_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HISTORY_INDEX_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HISTORY_INDEX_FAILURE, error } }

}



function getResponseAnalytics(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getResponseAnalytics(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_RESPONSE_ANALYTIYCS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_RESPONSE_ANALYTIYCS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_RESPONSE_ANALYTIYCS_FAILURE, error } }

}



function getComment(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getComment(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_COMMENT_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_COMMENT_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_COMMENT_FAILURE, error } }

}

function getMultiManagerQuestionList(data,mngr,ManagerListQuestionDataObj) {
    return dispatch => {
        dispatch(request());
        dashboardService.getMultiManagerQuestionList(data,mngr)
            .then(
                dashboard =>{ 
                    dispatch(success(dashboard));
                    if(dashboard && dashboard.getMultiManagerQuestionListData && dashboard.getMultiManagerQuestionListData.ManagerPID){
                        let managerId = dashboard.getMultiManagerQuestionListData.ManagerPID;
                        let getIndex = ManagerListQuestionDataObj.findIndex(prev=>prev.ManagerPID === managerId)
                        if(getIndex===-1){
                            ManagerListQuestionDataObj.push(dashboard.getMultiManagerQuestionListData);
                        }
                        else{
                            ManagerListQuestionDataObj[getIndex]=dashboard.getMultiManagerQuestionListData;
                        }


                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_FAILURE, error } }

}



//------------- multifilter ---------------------------

function getDemographicIndexMultiFilter(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getDemographicIndexMultiFilter(data)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE, error } }

}


function AssignManagerActionPlan(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.AssignManagerActionPlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getClientProfile({}));
                    // dispatch(this.getManagerActionPlan({surveyId:data.surveyId}));
                    dispatch(this.getAllManagerActionPlan({surveyId:data.surveyId}));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_FAILURE, error } }
}


function getManagerActionPlan(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getManagerActionPlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_ACTION_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_ACTION_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_ACTION_PLAN_FAILURE, error } }
}

function emptyManagerActionPlan() {
    let obj = {
        getManagerActionPlanDat:[]
    }
    return dispatch => {
        dispatch(success(obj));
    };
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_ACTION_PLAN_SUCCESS, dashboard } }
}


function updateEmployeeTask(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.updateEmployeeTask(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getManagerActionPlan({surveyId:data.surveyId}));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.UPDATE_EMPLOYEE_TASK_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.UPDATE_EMPLOYEE_TASK_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.UPDATE_EMPLOYEE_TASK_FAILURE, error } }
}


function updateEmployeeResource(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.updateEmployeeResource(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                    dispatch(this.getManagerActionPlan({surveyId:data.surveyId}));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_FAILURE, error } }
}


function getAllManagerActionPlan(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getAllManagerActionPlan(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_FAILURE, error } }
}


function crossTab(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.crossTab(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CROSS_TAB_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CROSS_TAB_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CROSS_TAB_FAILURE, error } }
}



function crossTabDMG(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.crossTabDMG(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CROSS_TAB_DMG_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CROSS_TAB_DMG_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CROSS_TAB_DMG_FAILURE, error } }
}


function crossTabAnalysis(data) {
    let obj = {
        crossTabAnalysisData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.crossTabAnalysis(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CROSS_TAB_ANALYSIS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CROSS_TAB_ANALYSIS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CROSS_TAB_ANALYSIS_FAILURE, error } }
}


function getAllParameterList(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getAllParameterList(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ALL_PARAMETER_LIST_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ALL_PARAMETER_LIST_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ALL_PARAMETER_LIST_FAILURE, error } }
}


function getCorrelationDimenionVsDimension(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getCorrelationDimenionVsDimension(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CORRELATION_OVERALL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CORRELATION_OVERALL_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CORRELATION_OVERALL_FAILURE, error } }
}

function getCorrelationDimensionVsDimensionFilter(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getCorrelationDimensionVsDimensionFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_CORRELATION_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_CORRELATION_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_CORRELATION_FILTER_FAILURE, error } }
}



function getCorrelationQuestionType2(data) {
    let obj = {
        getCorrelationQuestionType2Data:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        dashboardService.getCorrelationQuestionType2(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.CORRELATION_TYPE2_ANALYSIS_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.CORRELATION_TYPE2_ANALYSIS_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.CORRELATION_TYPE2_ANALYSIS_FAILURE, error } }
}







function getHappinessQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getHappinessQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPINESS_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPINESS_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPINESS_QUESTION_FAILURE, error } }
}
function getHappinessManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getHappinessQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_FAILURE, error } }
}
function getHappinessDMGQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getHappinessQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPINESS_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPINESS_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPINESS_DMG_QUESTION_FAILURE, error } }
}


function getEngagementQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getEngagementQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_QUESTION_FAILURE, error } }
}
function getEngagementManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getEngagementQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE, error } }
}
function getEngagementDMGQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getEngagementQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_FAILURE, error } }
}



function getHopeQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getHopeQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HOPE_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HOPE_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HOPE_QUESTION_FAILURE, error } }
}

function getStressQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getStressQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_QUESTION_FAILURE, error } }
}
function getStressManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getStressQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_MANAGER_QUESTION_FAILURE, error } }
}
function getStressDMGQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getStressQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_DMG_QUESTION_FAILURE, error } }
}



function getManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getManagerQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_QUESTION_FAILURE, error } }
}

function getManagerManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getManagerQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_MANAGER_QUESTION_FAILURE, error } }
}

function getManagerDMGQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getManagerQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_DMG_QUESTION_FAILURE, error } }
}



function getLeaderQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getLeaderQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_QUESTION_FAILURE, error } }
}

function getLeaderManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getLeaderQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_MANAGER_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_MANAGER_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_MANAGER_QUESTION_FAILURE, error } }
}

function getLeaderDMGQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        dashboardService.getLeaderQuestion(data)
            .then(
                dashboard =>{
                    dispatch(success(dashboard));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_DMG_QUESTION_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_DMG_QUESTION_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_DMG_QUESTION_FAILURE, error } }
}



function getHappinessIndexByFilter(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_FAILURE, error } }
}

function getEngagementIndexByFilter(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE, error } }
}

function getStressIndexByFilter(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_STRESS_INDEX_BY_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_STRESS_INDEX_BY_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_STRESS_INDEX_BY_FILTER_FAILURE, error } }
}


function getManagerIndexByFilter(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_FAILURE, error } }
}


function getLeaderIndexByFilter(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.getIndexFilter(data)
            .then(
                dashboard => {
                    dispatch(success(dashboard));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_LEADER_INDEX_BY_FILTER_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.GET_LEADER_INDEX_BY_FILTER_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.GET_LEADER_INDEX_BY_FILTER_FAILURE, error } }
}



