import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment, { max } from 'moment';
import IndexKPI from './Components/IndexKPI';
import SingleStackScore from './Components/SingleStackScore';
import Filter from './Components/Filter';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown:"",
      showTaskDropdown:[],


      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }


  openDropdown=(emp)=>{
    if(this.state.showDropdown === emp.emp_id){
      this.setState({showDropdown:""});
    }
    else{
      this.setState({showDropdown:emp.emp_id});
    }
  }


  openTaskDropdown=(act)=>{
    let { showTaskDropdown } = this.state;

    let getIndex = showTaskDropdown.findIndex(prev=>prev===act.id);
    if(getIndex===-1){
      showTaskDropdown.push(act.id);
    }
    else{
      showTaskDropdown.splice(getIndex,1);
    }
    this.setState({showTaskDropdown});
  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterGetQuestion } = this.props;
    this.setState({ FilterValues: finalFilter });

    console.log('========s===================>',finalFilter)
    // handleMultiFilterGetQuestion(finalFilter);

  }





  render() {
    let { getAllManagerActionPlanData, getDemographicIndexForManagerData ,GetParameterLabel, 
    EmpFilterData, getIndexFilterData} = this.props;
    let { showDropdown, showTaskDropdown , FilterValues} = this.state;


    let DmgForMngrData = [];
    console.log('x FilterValues: ',FilterValues);

    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      getDemographicIndexForManagerData.forEach((Emp)=>{

        let isCheck = 0;
        if(FilterValues && FilterValues.length>0){
          FilterValues.forEach((filter)=>{
            if(filter.value !== "All"){
              if(!(Emp[filter.level] === filter.value)){
                isCheck++
              }
            }

            console.log('x 1: ',Emp[filter.level]);
            console.log('x 2: ',filter.value);

          });
        }
        // console.log('x Emp: ',Emp);
        // console.log('x isCheck: ',isCheck);

        if(isCheck===0){
          DmgForMngrData.push(Emp)
        }



      
      });
      
    }



    let AllMngrAPlanData = [];

    if(getAllManagerActionPlanData && getAllManagerActionPlanData.length>0){
      getAllManagerActionPlanData.forEach((action)=>{
        let getIndex = DmgForMngrData.findIndex(prev=>prev.emp_id===action.emp_id);
        if(getIndex!==-1){
          AllMngrAPlanData.push(action)
        }
        
      });
    }


    console.log('x AllMngrAPlanData: ',AllMngrAPlanData);


    console.log('DmgForMngrData: ',DmgForMngrData);

    let EmployeeList = [];
    let actionTotalCount = 0;
    let actionCompleteCount = 0;
    let totalTasks = 0;
    let completedTasks = 0;
    let StartedTasks = 0;

    if(AllMngrAPlanData && AllMngrAPlanData.length>0){
      AllMngrAPlanData.forEach((action)=>{

        if(action && action.employeeTaskList && action.employeeTaskList.length>0){
          // //console.log('action.employeeTaskList: ',action.employeeTaskList);

          totalTasks +=action.employeeTaskList.length;
          completedTasks += action.employeeTaskList.filter(x => x.isComplete == true).length;

          StartedTasks += action.employeeTaskList.filter(x => x.isStarted == true).length;
        }


        if(action.isComplete){
          actionCompleteCount++;
        }
        actionTotalCount++;
        let getIndex = EmployeeList.findIndex(prev=>prev.emp_id === action.emp_id);
        if(getIndex===-1){
          EmployeeList.push({
            "emp_id":action.emp_id
          });
        }
      });
    }

    let EmployeeActionList = [];
    let ManagerList = [];
    if(EmployeeList && EmployeeList.length>0){
      EmployeeList.forEach((emp)=>{
        let actionFilteredList = AllMngrAPlanData.filter(x => x.emp_id == emp.emp_id);
        let completedActionCount = actionFilteredList.filter(action => action.isComplete == true).length;
        let totalActionCount = actionFilteredList && actionFilteredList.length>0?actionFilteredList.length:0;
        ////console.log('actionFilteredList: ',actionFilteredList);
        let startedActionCount = actionFilteredList.filter(action => action.isStarted == true).length;


        let actionFilteredStartDate = AllMngrAPlanData.filter(x => x.isStarted == true);
        let actionFilteredEndDate = AllMngrAPlanData.filter(x => x.isComplete == true);
        //console.log('actionFilteredStartDate: ',actionFilteredStartDate);
        ////console.log('actionFilteredEndDate: ',actionFilteredEndDate);


        let startDateList = [];
        if(actionFilteredStartDate && actionFilteredStartDate.length>0){
          actionFilteredStartDate.forEach((item)=>{
            startDateList.push(item.startDate);
          });
        }
        let endDateList = [];
        if(actionFilteredEndDate && actionFilteredEndDate.length>0){
          actionFilteredEndDate.forEach((item)=>{
            endDateList.push(item.startDate);
          });
        }
        //console.log('startDateList: emp',emp,startDateList);

        // let min = 0
        // let max = 0
        // if(startDateList && startDateList.length>0){
        //   min = Math.min(...startDateList);
        // }
        // if(endDateList && endDateList.length>0){
        //   max = Math.max(...endDateList);
        // }

        ////console.log('minimum: ',min);
        ////console.log('maximum: ',max);


        if(DmgForMngrData && DmgForMngrData.length>0){
          ManagerList = DmgForMngrData;
        }
        let getIndex = ManagerList.findIndex(prev=>prev.emp_id===emp.emp_id);
        let AllStartDates = [];
        let AllEndDates = [];
        if(actionFilteredList && actionFilteredList.length>0){
          actionFilteredList.forEach((item)=>{
            if(true){
                //console.log('action---item: ',item);

                if(item.isStarted){
                  AllStartDates.push(item.startDate)
                }
                if(item.isComplete){
                  AllEndDates.push(item.endDate)
                }

            }
          });
        }
        //console.log('AllStartDates: ',AllStartDates);
        //console.log('AllEndDates: ',AllEndDates);

        

        let tempList = {
          "emp_id":emp.emp_id,
          "emp_details":(getIndex!==-1)?ManagerList[getIndex]:"",
          "action_list":actionFilteredList,
          "startDate":AllStartDates && AllStartDates.length>0 ? Math.min(...AllStartDates):0,
          "endDate":AllEndDates && AllEndDates.length>0 ? Math.max(...AllEndDates):0,
          "isStarted":startedActionCount?true:false,
          "isComplete":(completedActionCount === totalActionCount)?true:false,
        }
        EmployeeActionList.push(tempList);

      });
    }
    //console.log('EmployeeActionList: ',EmployeeActionList);



    let ResponseAction = {
      "assigned":totalTasks,
      "pending":totalTasks-completedTasks,
      "completed":completedTasks
    }

















    let ActionType2List = [];
    if(AllMngrAPlanData && AllMngrAPlanData.length>0){
      AllMngrAPlanData.forEach((item)=>{
        let getIndex = ActionType2List.findIndex(prev=>prev.actionId.id===item.actionId.id);
        let empIndex = ManagerList.findIndex(prev=>prev.emp_id===item.emp_id);
        // console.log('ManagerList: ',ManagerList)

        if(getIndex===-1){
          let temp= {
            actionId:item.actionId,
            empList:[]
          }
          if(item.emp_id){
            // console.log('item: ',item)
            temp["empList"].push({
              "createdAt":item.createdAt,
              "emp_id":item.emp_id,
              "emp_name":(empIndex!==-1)?ManagerList[empIndex].name:""
            });
            ActionType2List.push(temp);
          }
        }
        else{
          if(item.emp_id){
            ActionType2List[getIndex]["empList"].push({
              "createdAt":item.createdAt,
              "emp_id":item.emp_id,
              "emp_name":(empIndex!==-1)?ManagerList[empIndex].name:""
            });
          }
        }
      });
    }


    console.log('EmployeeActionList: ',EmployeeActionList);
    // console.log('ActionType2List: ',ActionType2List)



//-----------------------------------------------------------------------------------------------------------------------------------------------

    let CompletedTaskList = [];
    let NotCompletedTaskList = [];
    let TaskList = []

    if(AllMngrAPlanData && AllMngrAPlanData.length>0){
      AllMngrAPlanData.forEach((action)=>{

        if(action && action.employeeTaskList && action.employeeTaskList.length>0){
          action.employeeTaskList.forEach((task)=>{
            let getIndex = TaskList.findIndex(prev=>prev.taskId.id===task.taskId.id);
            if(getIndex===-1){
              let tempData1 = {
                "taskId":task.taskId,
                "dimension":action.actionId.parameterName,
                "completeList":[],
                "completeCount":0,
                "totalCount":0
              }
              tempData1["completeList"].push(task.isComplete);
              if(task.isComplete){
                tempData1["completeCount"]+=1;
              }
              tempData1["totalCount"]+=1;

              TaskList.push(tempData1);
            }
            else{
              TaskList[getIndex]["completeList"].push(task.isComplete);
              if(task.isComplete){
                TaskList[getIndex]["completeCount"]+=1;
              }
              TaskList[getIndex]["totalCount"]+=1;
            }


          });
        }
      });
    }


    let SortTaskList = TaskList.sort((b,a)=>(a.completeCount/a.totalCount)-(b.completeCount/a.totalCount) );

    let Top5Tasks = [];
    let Bottom5Tasks = [];

    if(SortTaskList && SortTaskList.length>0){
      SortTaskList.forEach((item,index)=>{
        let len = SortTaskList.length;
        if(index>=0 && index<=4){
          Top5Tasks.push(item);
        }
        else if(index>=(len-5)  && index<=(len-1)){
          Bottom5Tasks.push(item);
        }
      });
    }

    // console.log('Top5Tasks: ',Top5Tasks)
    // console.log('Bottom5Tasks: ',Bottom5Tasks)

    // console.log('TaskList: ',TaskList)
    // console.log('SortTaskList: ',SortTaskList)
    // console.log('CompletedTaskList: ',CompletedTaskList)
    // console.log('NotCompletedTaskList: ',NotCompletedTaskList)


    let ManagerTList = []
    if(EmployeeActionList && EmployeeActionList.length>0){
      EmployeeActionList.forEach((mngr)=>{
        let completeCount = 0;
        let totalCount = 0;

        if(mngr && mngr.action_list && mngr.action_list.length>0){
          mngr.action_list.forEach((action)=>{
            if(action && action.employeeTaskList && action.employeeTaskList.length>0){
              action.employeeTaskList.forEach((task)=>{
                if(task.isComplete){
                  completeCount++
                }
                totalCount++
              });
            }
            

          });
        }


        let temp = {
          "name":mngr.emp_details.name,
          "emp_id":mngr.emp_id,
          "completeCount":completeCount,
          "totalCount":totalCount
        }
        ManagerTList.push(temp);


      });
    }

  console.log('ManagerTList: ',ManagerTList)




  let SortManagerTList = ManagerTList.sort((b,a)=>(a.completeCount/a.totalCount)-(b.completeCount/a.totalCount) );

  let Top5ManagerTList = [];
  let Bottom5ManagerTList = [];

  if(SortManagerTList && SortManagerTList.length>0){
    SortManagerTList.forEach((item,index)=>{
      let len = SortManagerTList.length;
      if(index>=0 && index<=4){
        Top5ManagerTList.push(item);
      }
      else if(index>=(len-5)  && index<=(len-1)){
        Bottom5ManagerTList.push(item);
      }
    });
  }


  console.log('Top5ManagerTList: ',Top5ManagerTList)
  console.log('Bottom5ManagerTList: ',Bottom5ManagerTList)





  let EmpFilterData2 = []
  if (EmpFilterData && EmpFilterData.length > 0) {
    EmpFilterData.forEach((emp) => {
      if (emp.name !== 'ManagerPID') {
        EmpFilterData2.push(emp);
      }
    })
  }


    //console.log('showTaskDropdown :',showTaskDropdown)
    return (
      <> 



      <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">

      <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />

      </div>
      {FilterValues && FilterValues.length > 0 ?
      <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
        {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
          <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
        ) : null}</div>
      : null}







      <div className="px-2">
        <IndexKPI ResponseAction={ResponseAction} />
      </div>



      {Top5Tasks && Top5Tasks.length>0?
      <div className="p-4">

      <div className="bg-white rounded w-full shadow-lg p-4">
      <div className="text-lg font-semibold py-4">Top 5 Tasks :-</div>
      <div class="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4">
      <table class="w-full border ">
                  <thead>
                    <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                      <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Dimension</div></th>
                      <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Task Name</div></th>
                      <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-left">Completion(%)</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Top5Tasks && Top5Tasks.length>0?
                      Top5Tasks.map((task,index)=>
                      <tr key={index} className="bg-white  mb-10 lg:mb-0 text-sm text-gray-900">
                      <td className="px-2 border border-b w-2/12 text-center">    
                            <div  style={{width:'20%'}}>{(task && task.dimension)?GetParameterLabel(task.dimension):'NaN'}</div>
                          </td>
                          <td className="tooltip-response-question1 px-2 border border-b w-2/12">                             
                          <div className="cursor-pointer whitespace-pre-line w-96">{(task && task.taskId && task.taskId.name)?task.taskId.name:'NaN'}</div>
   
                          </td>
                          <td className=" border border-b w-10/12  px-2"> 
                            <SingleStackScore task={task} />
                          </td>
                        </tr>
                      )
                    :null}

                  </tbody>
                </table>
              </div>
        </div>
      </div>
      :null}





      {Bottom5Tasks && Bottom5Tasks.length>0?
      <div className="p-4">
      <div className="bg-white rounded w-full shadow-lg p-4">
      <div className="text-lg font-semibold py-4">Bottom 5 Tasks :-</div>
      <div class="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4">
      <table class="w-full border ">
                  <thead>
                    <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                      <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Dimension</div></th>
                      <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Task Name</div></th>
                      <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-left">Completion(%)</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Bottom5Tasks && Bottom5Tasks.length>0?
                      Bottom5Tasks.map((task,index)=>
                      <tr key={index} className="bg-white  mb-10 lg:mb-0 text-sm text-gray-900">
                      <td className="px-2 border border-b w-2/12 text-center">    
                            <div  style={{width:'20%'}}>{(task && task.dimension)?GetParameterLabel(task.dimension):'NaN'}</div>
                          </td>
                          <td className="tooltip-response-question1 px-2 border border-b w-2/12">                             
                          <div className="cursor-pointer whitespace-pre-line w-96">{(task && task.taskId && task.taskId.name)?task.taskId.name:'NaN'}</div>
   
                          </td>
                          <td className=" border border-b w-10/12  px-2"> 
                            <SingleStackScore task={task} />
                          </td>
                        </tr>
                      )
                    :null}

                  </tbody>
                </table>
              </div>
        </div>
      </div>
        :null}





      {/* ------------------------------Top/Bottom Manager---------------------------- */}

      {Top5ManagerTList && Top5ManagerTList.length>0?
      <div className="p-4">
      <div className="bg-white rounded w-full shadow-lg p-4">
      <div className="text-lg font-semibold py-4">Top 5 Managers :-</div>
      <div class="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4">
      <table class="w-full border ">
                  <thead>
                    <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                      <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Manager Name</div></th>
                      <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Emp ID</div></th>
                      <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-left">Completion(%)</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Top5ManagerTList && Top5ManagerTList.length>0?
                      Top5ManagerTList.map((mngr,index)=>
                      <tr key={index} className="bg-white  mb-10 lg:mb-0 text-sm text-gray-900">

                          <td className="px-2 border border-b w-2/12 text-left">    
                          <div className="cursor-pointer whitespace-pre-line w-96">{(mngr && mngr.name)?mngr.name:'NaN'}</div>
                          </td>

                          <td className="tooltip-response-question1 px-2 border border-b w-2/12">                             
                          <div className="cursor-pointer whitespace-pre-line w-96">{(mngr && mngr.emp_id)?mngr.emp_id:'NaN'}</div>
                          </td>
                          <td className=" border border-b w-10/12  px-2"> 
                            <SingleStackScore task={mngr} />
                          </td>
                        </tr>
                      )
                    :null}

                  </tbody>
                </table>
              </div>
        </div>
      </div>
        :null}






    {Bottom5ManagerTList && Bottom5ManagerTList.length>0?
      <div className="p-4">
      <div className="bg-white rounded w-full shadow-lg p-4">
      <div className="text-lg font-semibold py-4">Top 5 Managers :-</div>
      <div class="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4">
      <table class="w-full border ">
                  <thead>
                    <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                      <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Manager Name</div></th>
                      <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Emp ID</div></th>
                      <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-left">Completion(%)</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Bottom5ManagerTList && Bottom5ManagerTList.length>0?
                      Bottom5ManagerTList.map((mngr,index)=>
                      <tr key={index} className="bg-white  mb-10 lg:mb-0 text-sm text-gray-900">

                          <td className="px-2 border border-b w-2/12 text-left">    
                          <div className="cursor-pointer whitespace-pre-line w-96">{(mngr && mngr.name)?mngr.name:'NaN'}</div>
                          </td>

                          <td className="tooltip-response-question1 px-2 border border-b w-2/12">                             
                          <div className="cursor-pointer whitespace-pre-line w-96">{(mngr && mngr.emp_id)?mngr.emp_id:'NaN'}</div>
                          </td>
                          <td className=" border border-b w-10/12  px-2"> 
                            <SingleStackScore task={mngr} />
                          </td>
                        </tr>
                      )
                    :null}

                  </tbody>
                </table>
              </div>
        </div>
      </div>
        :null}




      
{/* 
        <div className="flex flex-col mx-4 mt-2 bg-white px-2 shadow-lg pb-4 shadow-xl">
        <div className="font-semibold text-lg py-3">Manager Action Plan (Summary View)</div>
        <div className="py-2 overflow-x-auto whitespace-nowrap w-full ">
          <div className="align-middle inline-block min-w-full overflow-hidden  sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-900 tracking-wider pl-6 font-semibold">
                  {"Name"}</th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-900 tracking-wider font-semibold">
                  {"Emp ID"}</th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-900 tracking-wider font-semibold">
                  {"Start Date"}</th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-900 tracking-wider font-semibold">
                  {"End Date"}</th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-sm leading-4 font-medium text-gray-900 tracking-wider font-semibold">
                  {"Status"}</th>
                  <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-sm leading-4 font-medium text-gray-900 tracking-wider font-semibold">
                  {"More"}</th>
                </tr>
              </thead>
              <tbody className="bg-white">
              {EmployeeActionList && EmployeeActionList.length>0?
                  EmployeeActionList.map((empAct, index)=>
                  <>
                        <tr  className={showDropdown === empAct.id?"cursor-pointer border border-purple-500 font-bold":""}>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="leading-5 text-gray-900 text-sm">{empAct && empAct.emp_details && empAct.emp_details.name?empAct.emp_details.name:"----"}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="leading-5 text-gray-900 text-sm">{empAct && empAct.emp_id?empAct.emp_id:"----"}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="leading-5 text-gray-900 text-sm">{empAct && empAct.startDate?
                            moment.unix(empAct.startDate).format("DD MMM yyyy"):"----"}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="leading-5 text-gray-900 text-sm">{
                            (actionTotalCount === actionCompleteCount)?
                            empAct && empAct.endDate?moment.unix(empAct.endDate).format("DD MMM yyyy"):"----":"----"}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center">
                          {empAct.isComplete?
                            <span className="px-2 inline-flex text-sm leading-5 font-medium rounded-full bg-green-100 text-green-800">
                              {"Completed"}</span>
                              :
                            <span className="px-2 inline-flex text-sm leading-5 font-medium rounded-full bg-red-100 text-red-800">
                            {"Pending"}</span>
                            }
                          </td>

                          <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center">
                          <span onClick={()=>this.openDropdown(empAct)} className="cursor-pointer material-icons hover:bg-blue-100 p-2 rounded-md hover:text-blue-500 text-gray-900" style={{fontSize: '16px'}}>
                            {showDropdown === empAct.emp_id?"remove_circle_outline":"add_circle_outline"}</span>
                            
                            
                          </td>
                        </tr>
                        
                        {showDropdown === empAct.emp_id?
                              <tr><td className="px-6 py-2 whitespace-no-wrap " colSpan={7}>
                                <ul className="" >
                                  {empAct && empAct.action_list && empAct.action_list.length>0?
                                    empAct.action_list.map((action)=>
                                    <>
                                    <div className="pb-4">
                                          <div onClick={()=>this.openTaskDropdown(action)} className="cursor-pointer px-2 text-lg text-left text-blue-500  py-2 flex">
                                          <span className="material-icons pr-2">hotel_class</span>
                                          <span className="select-none	">{(action && action.actionId && action.actionId.name)?action.actionId.name:""}</span>
                                          {action.isComplete?
                                          <>
                                            <span className="text-sm pl-2 pt-1.5 text-gray-900">{action && action.startDate?" (Start Date: "+moment.unix(action.startDate).format("DD MMM yyyy"):"----"}</span>
                                            <span className="text-sm pl-2 pt-1.5 text-gray-900">{action && action.endDate?", End Date: "+moment.unix(action.endDate).format("DD MMM yyyy")+")":"----"}</span>
                                          </>
                                          :
                                          <span className="text-sm pl-2 pt-1.5 text-red-500">Pending</span>
                                          }
                                          </div>

                                    {showTaskDropdown.findIndex(prev=>prev===action.id) !==-1?
                                      <>

                                          <div className="px-4 text-gray-900 cursor-default">Tasks:</div>
                                          {(action && action.employeeTaskList && action.employeeTaskList.length>0)?
                                              action.employeeTaskList.map((task)=>
                                                <div className="cursor-default px-6 py-1 text-md text-left text-gray-900 flex"><span className="material-icons pr-2">task</span>
                                                <span className="text-gray-900">{(task && task.taskId && task.taskId.name)?task.taskId.name:""}</span>
                                                  {task.isStarted?
                                                    <span className="px-2 pt-1 text-sm ">{task && task.initdate?" (Start Date: "+moment.unix(task.initdate).format("DD MMM yyyy")+")":"----"}</span>
                                                  :
                                                    null
                                                  }
                                                  {task.isComplete?
                                                    <span className="px-2 pt-1 text-sm ">{task && task.date?" (Completion Date: "+moment.unix(task.date).format("DD MMM yyyy")+")":"----"}</span>
                                                  :
                                                   null
                                                  }

                                                  {!task.isStarted && !task.isComplete?
                                                    <span className="px-2 pt-1 text-sm text-red-500">Pending</span>
                                                  :null}

                                                </div>
                                              )
                                          :null}


                                          <div className="px-4 text-gray-900 cursor-default">Resources:</div>
                                          {(action && action.employeeResourceList && action.employeeResourceList.length>0)?
                                              action.employeeResourceList.map((resource)=>
                                                <div className="cursor-default px-6 py-1 text-md text-left text-gray-900 flex"><span className="material-icons pr-2">library_books</span>
                                                <span className="text-gray-900">{(resource && resource.resourceId && resource.resourceId.name)?resource.resourceId.name:""}</span>
                                                  {resource.isComplete?
                                                    <span className="px-2 pt-1 text-sm ">{resource && resource.date?" (Completion Date: "+moment.unix(resource.date).format("DD MMM yyyy")+")":"----"}</span>
                                                  :
                                                    <span className="px-2 pt-1 text-sm text-red-500">Pending</span>
                                                  }
                                                </div>
                                              )
                                          :null}

                                      </>
                                    :null} 


                                    </div>
                                    
                                      </>
                                    ):null}
                                </ul>
                              </td></tr>
                              :null}
                        
                    
                    </>
                  ):null
                }

               </tbody>
            </table>
          </div>
        </div>
      </div> */}


      </>
    );
  }
}
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
