import React, { Component } from 'react';
import { connect } from 'react-redux';
import StackChart from './Charts/StackChart';
import StackChartDistribution from './Charts/StackChartDistribution';

import Commentary from '../../../Components/Commentary/Commentary';

// import OverallScore from './Components/OverallScore';
// import Evaluation from './Components/Evaluation';
// import OverallDistribution from './Components/OverallDistribution';

// import GaugeScore from '../../../../Overview/OverIndex/HappinessOverview/Charts/GaugeScore';
// import BarDistribution from '../../../../Overview/OverIndex/HappinessOverview/Charts/BarDistribution';

// import Filter from './Filter';

import SummaryScore from './Cards/SummaryScore/SummaryScore';
import SummaryDistribution from './Cards/SummaryDistribution/SummaryDistribution';

import IndexKPI from './Components/IndexKPI';
// import ScoreBenchmark from './Components/ScoreBenchmark/ScoreBenchmark';
// import Help from '../Help/Help';
import Filter from './Filter/Filter';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      showLeaderBoard: false,
      isMultifilter: false,
      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleMultifilterIndexScore(finalFilter, true, name);

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  render() {
    let {

      indexType, optionType, getCommentData, loading, name, CountRestrict,

      EmpFilterData, getIndexFilterData,
      getAllBenchMarkData, getRespondantDetailsData,
      getIndexData, getIndexScore, getIndexDistributionData, cronbatchAlphaVarianceData, getDemographicIndexMultiFilterData
    } = this.props;
    let { currFilterName, currentSelectValue, FilterValues } = this.state;



    let cronBachAlpha = {
      score: 0
    }
    if (cronbatchAlphaVarianceData && cronbatchAlphaVarianceData.score && cronbatchAlphaVarianceData.score.length > 0) {
      cronBachAlpha["score"] = (typeof cronbatchAlphaVarianceData.score[0] !== 'string' || cronbatchAlphaVarianceData.score[0] instanceof Number) && cronbatchAlphaVarianceData && cronbatchAlphaVarianceData.score && cronbatchAlphaVarianceData.score[0] ? cronbatchAlphaVarianceData.score[0].toFixed(2) : ''
    }

    let Item = {
      score: 0
    };
    if (getIndexFilterData) {
      Item = {
        score: (typeof getIndexFilterData[indexType] !== 'string' || getIndexFilterData[indexType] instanceof Number) && getIndexFilterData && getIndexFilterData[indexType] ? getIndexFilterData[indexType].toFixed(2) : ''
      }
    }

    let Benchmark = {
      score: 0
    };
    if (getAllBenchMarkData) {
      Benchmark = {
        score: (typeof getAllBenchMarkData[indexType] !== 'string' || getAllBenchMarkData[indexType] instanceof Number) && getAllBenchMarkData && getAllBenchMarkData[indexType] ? getAllBenchMarkData[indexType].toFixed(2) : ''
      }
    }


    console.log('FilterValues: ', FilterValues)

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    let DemographicDataScore = [];
    let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      DemographicDataScore = getDemographicIndexMultiFilterData;
      DemographicDataDistribution = getIndexDistributionData;

    }

    return (
      <>
        <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        </div>


        <div className="xl:flex lg:flex md:flex justify-start items-center">
          {/* 
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}

        </div>

        {FilterValues && FilterValues.length > 0 ?
          <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null}


        <div onClick={() => this.closeFilter(false)} className=" " >
          <div className="flex w-full" >



            <div className="bg-white rounded w-full shadow-lg px-4 m-4 py-4 justify-center">
              <div className="font-medium text-lg">{name} Scores</div>


              {FilterValues && FilterValues.length > 0 ?
                <div className="py-4">
                  <div className="flex">{FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                    <p className="mx-4 my-4 font-semibold flex"><p className="text-blue-500">{element.level}</p>{"  >>  "}<p className="text-gray-500">{element.value}</p></p>
                  ) : null}</div>
                  <StackChart
                    getIndexScore={getIndexScore}
                    getDemographicIndexData={DemographicDataScore}
                    currFilterName={currFilterName}
                    indexType={indexType}
                    CountRestrict={CountRestrict}
                  />
                  <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>
                </div>
                :
                <div className="py-4">
                  <SummaryScore cronBachAlpha={cronBachAlpha} item={Item} DistributionData={getIndexData} Benchmark={Benchmark} />
                </div>
              }

            </div>
            {/* <div className="bg-white rounded w-5/12 shadow-lg px-4 justify-center">
                <div className="text-center">Happiness Distribution</div>
                {FilterValues && FilterValues.length>0?
                       <div className="py-4">
                         <div className="flex">{FilterValues && FilterValues.length>0?FilterValues.map((element)=>
                          <p className="mx-4 my-4 font-semibold flex"><p className="text-blue-500">{element.level}</p>{"  >>  "}<p className="text-gray-500">{element.value}</p></p>
                         ):null}</div>

                                    <StackChartDistribution 
                                      getIndexData={getIndexData}
                                      getIndexDistributionData={DemographicDataDistribution}
                                      currFilterName={currFilterName}
                                      indexType={indexType}
                                    />
                      </div>
                      :
                        <div className="py-4">
                          <SummaryDistribution cronBachAlpha={cronBachAlpha}  item={Item} DistributionData={getIndexData} Benchmark={Benchmark}/>
                        </div>
                      }
                </div> */}

          </div>
        </div>

        <div className="px-2">
          {FilterValues && FilterValues.length > 0 ?
            null :
            <IndexKPI
              indexType={indexType}
              getIndexData={getIndexData}
              getIndexScore={getIndexScore}
              getAllBenchMarkData={getAllBenchMarkData}
              getRespondantDetailsData={getRespondantDetailsData}
            />
          }
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
