import React from 'react';
import ReactEcharts from "echarts-for-react";


export default function BarDistribution(props) {
  let { DistributionData } = props;

  let List = ['Unhappy','ModeratelyHappy','Happy','Happiest'];

  let labelObj = {
    'Unhappy':`Unhappy`,
    
    'ModeratelyHappy':`Moderate`,
    
    'Happy':`Happy`,
    
    'Happiest':`Happiest`
  }

  let colorObj = {
    'Unhappy':'#e53935',
    'ModeratelyHappy':'#ffc107',
    'Happy':'#00cd7e',
    'Happiest':'#ff4081'
  }

  let DistObj = {}

  let ScoreList = []

  if(DistributionData){
    if(List && List.length>0){
      List.forEach((item)=>{
        ScoreList.push({
          "name":item,
          "score":DistributionData && DistributionData[item] && parseFloat(DistributionData[item])? parseFloat(DistributionData[item]) :0
        })
        // DistObj[item] = DistributionData[item]? DistributionData[item].toFixed(2) :0;
      });
    }
  }

  ScoreList.sort((a,b)=>a.score - b.score);

  let data = [];
  let names = []
  if(ScoreList && ScoreList.length>0){
    ScoreList.forEach((item)=>{
      data.push({
        value:item.score && item.score.toFixed(2)?item.score.toFixed(2):0,
        itemStyle:{
          color:colorObj && colorObj[item.name]?colorObj[item.name]:'#757575'
        }
      })
      names.push(labelObj[item.name])
    });
  }


  console.log('x---names: ',names)


// console.log('DistObj: ',DistObj)


  return (
    <>	
      <div className="pt-1 -mt-6" style={{width:'500px'}}>
        <ReactEcharts
          option={{
            tooltip:{
              trigger: 'item',
              formatter:function (a) {
                console.log(a)
                return a.name+': <b>'+a.value+'%</b>'
              }
            },
            xAxis: {
                type: 'category',
                data: names
            },
            yAxis: {
                type: 'value',
                max:100
            },
            series: [{
                data: data,
                type: 'bar',
                showBackground: true,
                barWidth:'30px'
            }]
        }}
                          
        />
    </div>
                 
    </>
  );
}
