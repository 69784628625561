import React, { Component } from 'react';
import { connect } from 'react-redux';

class IndexKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { ResponseAction } = this.props;




    return (
      <>
      
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 px-2 py-4 px-1 gap-2"  >
            



                        <div className=" bg-white flex shadow-lg mr-2">
                          <div className="bg-blue-500 flex items-center"><span className="material-icons text-white px-6" 
                          style={{fontSize: '40px'}}>send</span></div>
                          <div className="text-center w-full py-6"><h1 className="text-3xl text-gray-800">
                          {ResponseAction['assigned']}</h1><p className="lg:text-sm text-xs text-gray-500">Assigned Tasks</p></div>
                        </div>

                        <div className=" bg-white flex shadow-lg mr-2">
                          <div className="bg-yellow-500 flex items-center"><span className="material-icons text-white px-6" 
                          style={{fontSize: '40px'}}>pending_actions</span></div>
                          <div className="text-center w-full py-6"><h1 className="text-3xl text-gray-800">
                          {ResponseAction['pending']}</h1><p className="lg:text-sm text-xs text-gray-500">Pending Tasks</p></div>
                        </div>

                        <div className=" bg-white flex shadow-lg">
                          <div className="bg-green-500 flex items-center"><span className="material-icons text-white px-6" 
                          style={{fontSize: '40px'}}>beenhere</span></div>
                          <div className="text-center w-full py-6"><h1 className="text-3xl text-gray-800">
                          {ResponseAction['completed']}</h1><p className="lg:text-sm text-xs text-gray-500">Completed Tasks</p></div>
                        </div>

                       {/* <div className="bg-white shadow-lg flex h-24 mx-2 my-2 cursor-default rounded">
                            <div className="bg-gray-500 h-full w-3/12 rounded-l" style={{background:'#12e67f'}}>
                              <img className="mx-auto p-2 pt-4" src="/img/Dashboard/SurveyDetails/ic_invited.svg" style={{width:'70px'}}/>
                            </div>
                            <div className="w-8/12 pt-6">
                                <div className="text-center text-xl" style={{fontSize:'30px'}}>{ResponseAction['assigned']}</div>
                                <div className="text-center text-sm">Assigned Tasks</div>
                            </div>
                        </div> */}

                        {/* <div className="bg-white shadow-lg flex h-24 mx-2 my-2 cursor-default rounded">
                            <div className="bg-gray-500 h-full w-3/12 rounded-l" style={{background:'#f7de39'}}>
                            <img className="mx-auto p-2 pt-4" src="/img/Dashboard/SurveyDetails/ic_responded.svg" style={{width:'60px'}}/>

                            </div>
                            <div className="w-8/12 pt-6">
                                <div className="text-center text-xl" style={{fontSize:'30px'}}>{ResponseAction['pending']}</div>
                                <div className="text-center text-sm">Pending Tasks</div>
                            </div>
                        </div> */}

                        {/* <div className="bg-white shadow-lg flex h-24 mx-2 my-2 cursor-default rounded">
                            <div className="bg-gray-500 h-full w-3/12 rounded-l" style={{background:'#169af2'}}>
                            <img className="mx-auto p-2 pt-4" src="/img/Dashboard/SurveyDetails/ic_response_ratio.svg" style={{width:'60px'}}/>

                            </div>
                            <div className="w-8/12 pt-6">
                                <div className="text-center text-xl" style={{fontSize:'30px'}}>{ResponseAction['completed']}</div>
                                <div className="text-center text-sm">Completed Tasks</div>
                            </div>
                        </div> */}


        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexKPI);
