import React from "react";
import moment from 'moment';

export default function Sidebar(props) {
  let { sidebarToggle, showSidebar, routeLink, CrrRoute,LanguageObj, CurrentLanguage, accessAllow } = props;

  console.log('LanguageObj: ',LanguageObj);

  
  return (
   <>
<div className={showSidebar?
"fixed lg:block hidden left-0 h-full z-30  w-56  transition duration-300 transform bg-white overflow-y-auto  overflow-hidden overflow-x-hidden border-r "
:
"fixed lg:block hidden left-0 h-full z-30  w-16  transition duration-300 transform bg-white overflow-y-auto  overflow-hidden overflow-x-hidden border-r "
}
id="customscroll">
  
  <div className="flex items-center border-b">
    <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-16 justify-center">
      <span onClick={()=>sidebarToggle()} className="cursor-pointer material-icons text-gray-500 rounded hover:bg-gray-100 p-2">menu</span>
    </div>
    <a href="#/app/survey"><span className="cursor-pointer font-bold ml-6 text-2xl tracking-widest text-blue-500">Plus</span></a>
  </div>
  <div className="py-4">
  
    {/* <div className="flex items-center cursor-pointer">
      <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center">
        <span className="material-icons text-blue-500 rounded hover:bg-blue-50 p-2">home</span>
      </div>
      <span className="font-semibold text-xs text-blue-500">Home</span>
    </div> */}

    <div title="My Surveys" className="flex items-center cursor-pointer" onClick={()=>routeLink("survey")}>
      <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center">
        <span className="material-icons text-gray-500 rounded hover:bg-blue-50 p-2">equalizer</span>
      </div>
      <span className="font-medium text-md text-gray-500">{LanguageObj && LanguageObj["my_surveys_label"+"_"+CurrentLanguage]? LanguageObj["my_surveys_label"+"_"+CurrentLanguage]:"My Surveys"}</span>
    </div>
 
    {accessAllow("uploadEmp")?
      <div title="My Employees" className="flex items-center cursor-pointer" onClick={()=>routeLink("employees")}>
        <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center">
          <span className="material-icons text-gray-500 rounded hover:bg-blue-50 p-2">people</span>
        </div>
        <span className="font-medium text-md text-gray-500">{LanguageObj && LanguageObj["my_employees_label"+"_"+CurrentLanguage]? LanguageObj["my_employees_label"+"_"+CurrentLanguage]:"My Employees"}</span>
      </div>
    :null}

  {accessAllow("uploadUser")?
      <div title="My Users" className="flex items-center cursor-pointer" onClick={()=>routeLink("users")}>
        <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center">
          <span className="material-icons text-gray-500 rounded hover:bg-blue-50 p-2">admin_panel_settings</span>
        </div>
        <span className="font-medium text-md text-gray-500">{"My Users"}</span>
      </div>
    :null}

    {/* <div className="flex items-center cursor-pointer" onClick={()=>routeLink("survey")}>
      <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center">
        <span className="material-icons text-gray-500 rounded hover:bg-blue-50 p-2">event_available</span>
      </div>
      <span className="font-medium text-xs text-gray-500 w-full">{LanguageObj && LanguageObj["action_plan_label"+"_"+CurrentLanguage]? LanguageObj["action_plan_label"+"_"+CurrentLanguage]:"Action Plan"}</span>
      <span className="material-icons text-gray-500 mr-4" style={{fontsize: '18px'}}>expand_more</span>
    </div> */}
  
    {/* <div className="flex items-center cursor-pointer">
      <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center">
        <span className="material-icons text-gray-500 rounded hover:bg-blue-50 p-2">email</span>
      </div>
      <span className="font-medium text-xs text-gray-500 w-full">Campaigns</span>
      <span className="material-icons text-gray-500 mr-4" style={{fontsize: '18px'}}>expand_more</span>
    </div>

    <div className="flex items-center cursor-pointer">
      <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center">
        <span className="material-icons text-gray-500 rounded hover:bg-blue-50 p-2">stacked_line_chart</span>
      </div>
      <span className="font-medium text-xs text-gray-500 w-full">Analyze Report</span>
      <span className="material-icons text-gray-500 mr-4" style={{fontsize: '18px'}}>expand_more</span>
    </div> */}



  </div>
</div>

   </>
  );
}
