import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";

class WordDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
        currentEmpPage:1,
        EmpIndexA:0,
        EmpIndexB:4,
        pageCount:10
    }
  }



  componentDidMount() {
  }

  SelectEmpPage=(val)=>{
    this.setState({currentEmpPage:val})
  }


  prevEmpPage=()=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(EmpIndexA>0){
        this.setState({EmpIndexA:EmpIndexA-1, EmpIndexB:EmpIndexB-1});
    }
  }
  nextEmpPage=(IndexListIndex)=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(IndexListIndex && IndexListIndex.length && (EmpIndexB<IndexListIndex.length)){
      this.setState({EmpIndexA:EmpIndexA+1, EmpIndexB:EmpIndexB+1});
    }
  }

    render(){

        let { wordCloudTextData, wordTextKeyWord, loading, EmpFilterData2 } = this.props;
        let { currentEmpPage, EmpIndexA, EmpIndexB, pageCount } = this.state;


        let textData = []

        if(wordCloudTextData && wordCloudTextData.length>0){
            wordCloudTextData.forEach((item)=>{
              if(item.answerText.includes("answer1:") || item.answerText.includes("answer2:")){
                textData.push({
                  ...item,
                  ans1:item.answerText.substring(
                      item.answerText.lastIndexOf("answer1:") + 8, 
                      item.answerText.lastIndexOf("answer2:")-1
                  ),
                  ans2:item.answerText.substring(
                      item.answerText.lastIndexOf("answer2:") + 8, 
                      
                  )
                });
              }
              else{
                textData.push({
                  ...item,
                  ans3:item.answerText
                })
              }

            })
        }

        console.log('wordTextKeyWord',wordTextKeyWord)

        let KEYWORD = '';
        if(wordTextKeyWord){
            KEYWORD = wordTextKeyWord.toUpperCase();
        }




                     //--------------------------------------------------------------------------------

    let IndexList = []
    let IndexListIndex = []
    let indexA=0
    let indexB=0

    if(textData && textData.length>0){
    

     if(textData && textData.length>0){
      textData.forEach((item,index)=>{
        if(index % pageCount === 0){
          IndexList.push(index)
        }
        if(index===(textData.length-1)){
          IndexList.push(index)
        }
      })
     }
     
     console.log('IndexList: ',IndexList)

     if(IndexList && IndexList.length>0){
      IndexList.forEach((item,index)=>{
        if(index!==(IndexList.length-1)){
          IndexListIndex.push(index+1)
        }
      })
     }
     console.log('IndexListIndex: ',IndexListIndex)





     if(IndexListIndex && IndexListIndex.length>0){
      IndexListIndex.forEach((item,index)=>{
        if(item === currentEmpPage){

          if(index<(IndexList.length-2)){
            indexA=IndexList[index];
            indexB=IndexList[index]+(pageCount-1)
          }
          else{
            indexA=IndexList[index];
            indexB=IndexList[IndexList.length-1]
          }
        }
      })
     }

  }
     //--------------------------------------------------------------------------------
     console.log('----------------textData:--------------- ',textData)

     let List = []
     let ExporttextData = [];
     if(textData && textData.length>=5){
      textData.forEach((item, index)=>{
        if(index===0){
          List = Object.keys(item)
        }
        let temp = {}
        if(List && List.length>0){
          List.forEach((ele)=>{

            let getIndex = EmpFilterData2 && EmpFilterData2.length>0?EmpFilterData2.findIndex(prev=>prev.name === ele):-1;
            let validDMG = (ele==="Age_Range" || ele==="Business_Unit" || ele==="Department" || ele==="Gender");
            if(ele!=="ans3" && ele!=="answerText" && ele!="ManagerPID" && getIndex!==-1 && validDMG){
              temp[ele] = item[ele];
            }
          });
        }
        temp["answerText"] = item["answerText"]
        ExporttextData.push(temp)
      });
     }
     console.log('----------------ExporttextData:--------------- ',ExporttextData)

    //  Age_Range: "31 Yrs to 40 Yrs"
    //  Business_Unit: "Gennova VFC"
    //  Department: "mRNA vaccination"
    //  Education_Qualification: "B.Sc. "
    //  Gender: "Male"
    //  Grade: "Level 1-Upto Executive( Staff+Officer+Executive)"
    //  Highest_Education_Qualification: "B.Sc. "
    //  ManagerPID: 1426
    //  Region: "Pune"
    //  Tenure: "More than 10 Yrs"
    //  ans3: "1. Traditional day activities.\n2. Employee interaction and work environment."
    //  answerText: "1. Traditional day activities.\n2. Employee interaction and work environment."



        return(
        <>
        
        <div className="bg-white w-full shadow-lg p-4 mr-2 justify-center" >
                        
                        
                        
                        
                        <div className=" md:flex justify-between items-center py-4 border-b">
                        
                        <h1 className="text-sm text-gray-900 font-semibold px-4">{KEYWORD}</h1>

                          {ExporttextData && ExporttextData.length>=5?
                          <CSVLink data={ExporttextData} filename={"TextData.csv"}>
                            <div className="cursor-pointer bg-blue-500 text-sm rounded-lg py-2 text-white font-semibold px-4">{"Download Excel"}</div>
                          </CSVLink>
                          : '...'}


                        {/* <div id="expand" className="md:mt-0 mt-4 bg-white mx-4  text-black flex justify-between px-4 items-center relative border py-1.5 rounded-md">
                            <span className="text-black text-xs font-semibold pr-4">Where does it come from? </span> 
                            <span id="expandicon" className="material-icons text-gray-500">expand_more</span>
                            <div id="dropdown" className="absolute top-10 right-0  bg-white shadow-md rounded-md w-full text-gray-500 overflow-hidden hidden z-20 w-60  ">
                            <span className="inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100 whitespace-nowrap"> 
                                Multiple Choice</span>
                            <span className="inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100 ">
                                Check Box</span>
                            <span className="inline-flex items-center py-3 px-4 text-xs w-full hover:bg-gray-100  ">
                                Text Box</span>
                            </div>
                        </div> */}

                        
                        </div>

                        <div className="p-4 ">


                        {textData && textData.length>0?
                            textData.map((item, index)=>
                            (index >= indexA && index <=indexB)?
                                <div key={index} className="flex justify-between items-center border-b py-2">
                                    <div className="leading-loose">
                                                {item.ans1?
                                                    <h2 className="text-xs font-bold py-1">{'A) '+item.ans1}</h2>
                                                :null}
                                                {item.ans2?
                                                    <h2 className="text-xs font-bold py-1">{'B) '+item.ans2}</h2>
                                                :null}
                                                {item.ans3?
                                                    <h2 className="text-xs font-bold py-1">{item.ans3}</h2>
                                                :null}
                                                <div className="py-1 px-2 flex">
                                                  {item && item.Department?<p className="text-xs text-gray-500 px-1 pl-4">{item.Department+', '}</p>:null}
                                                  {item && item.Gender?<p className="text-xs text-gray-500 px-1">{item.Gender+', '}</p>:null}
                                                  {item && item.Country?<p className="text-xs text-gray-500 px-1">{item.Country}</p>:null}
                                                </div>
                                                {/* <p className="text-xs text-gray-500">January 22 2021, 11:00 AM</p>
                                                <span className="text-xs font-bold">Agree</span>
                                                </div>
                                                <div className="text-center"><span className="text-xs bg-green-400 text-white px-4 py-2 rounded-full">Suggestion</span>
                                                <p className="text-xs font-bold py-3">Confidence: 40</p> */}
                                    </div>
                                </div>
                            :null
                            )
                        :
                        <>
                        {!loading?
                        <div className="text-center text-2xl text-gray-400 pt-40">Data not awailable!</div>
                        :null}
                        </>
                        }



                        {/*end */}
                        {/* <div className="flex justify-center items-center text-center py-4  ">
                            <span className="material-icons w-8 h-8 rounded-full bg-gray-400 inline-flex border border-transparent items-center justify-center mr-4 text-sm text-white" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_left</span>
                            <span className="  inline-flex  items-center justify-center mx-1 text-sm">1-10</span>
                            <span className="  inline-flex  items-center justify-center mx-1 text-sm">of</span>
                            <span className=" inline-flex  items-center justify-center mx-1 text-sm">238</span>
                            <span className="material-icons w-8 h-8 rounded-full bg-blue-500 text-white border inline-flex  items-center justify-center ml-4 text-sm " style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_right</span>
                        </div> */}

                        <div className="flex justify-center items-center text-center py-8">
                            <span onClick={()=>this.prevEmpPage()} className="select-none material-icons w-8 h-8 rounded-full bg-gray-400 inline-flex border border-transparent items-center justify-center mx-1 text-sm text-white" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_left</span>
                            {IndexListIndex && IndexListIndex.length>0?
                                IndexListIndex.map((item,index)=>
                                    (index >= EmpIndexA && index <= EmpIndexB)?
                                    (item===currentEmpPage)?
                                    <span onClick={()=>this.SelectEmpPage(item)} className="w-8 h-8 rounded-full bg-blue-500 text-white inline-flex border border-transparent items-center justify-center mx-1 text-sm">{item}</span>
                                    :
                                    <span onClick={()=>this.SelectEmpPage(item)} className="w-8 h-8 rounded-full bg-white border  inline-flex  items-center justify-center mx-1 text-sm">{item}</span>
                                    :null
                                )

                            :null}
                            {/* <span className="w-8 h-8 rounded-full bg-blue-500 text-white inline-flex border border-transparent items-center justify-center mx-1 text-sm">1</span>
                            <span className="w-8 h-8 rounded-full bg-white border  inline-flex  items-center justify-center mx-1 text-sm">2</span>
                            <span className="w-8 h-8 rounded-full bg-white border inline-flex  items-center justify-center mx-1 text-sm">3</span> */}
                            <span onClick={()=>this.nextEmpPage(IndexListIndex)} className="select-none material-icons w-8 h-8 rounded-full bg-white text-blue-500 border inline-flex  items-center justify-center mx-1 text-sm" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_right</span>
                        </div>



                        </div>
                        <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>

                    </div>
                    
            </>
        );
    }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordDetails);
