import React from 'react';
import ReactEcharts from "echarts-for-react";


export default function BarDistribution(props) {
    
  let { DistributionData } = props;
  
  // console.log('DistributionData:====> ',DistributionData)

  let List = ['NeedImmediateattention','OftenStressed','ModeratelyWell','WellManagedStess'];

  let labelObj = {
    'NeedImmediateattention':`Need
    Immediate
    attention`,
    
    'OftenStressed':`Often
    Stressed`,
    
    'ModeratelyWell':`Moderately
    Well`,
    
    'WellManagedStess':`Well
    Managed
    Stess`
  }

  let colorObj = {
    'NeedImmediateattention':'#e53935',
    'OftenStressed':'#ffc107',
    'ModeratelyWell':'#00cd7e',
    'WellManagedStess':'#ff4081'
  }

  let DistObj = {}

  let ScoreList = []

  if(DistributionData){

    if(List && List.length>0){
      List.forEach((item)=>{
        ScoreList.push({
          "name":item,
          "score":DistributionData && DistributionData[item] && parseFloat(DistributionData[item])? parseFloat(DistributionData[item]) :0
        })
        // DistObj[item] = DistributionData[item]? DistributionData[item].toFixed(2) :0;
      });
    }

  }



  ScoreList.sort((a,b)=>a.score - b.score);

  let data = [];
  let names = []
  if(ScoreList && ScoreList.length>0){
    ScoreList.forEach((item)=>{
      data.push({
        value:item.score && item.score.toFixed(2)?item.score.toFixed(2):0,
        itemStyle:{
          color:colorObj && colorObj[item.name]?colorObj[item.name]:'#757575'
        }
      })
      names.push(labelObj[item.name])
    });
  }




  // data = [
  //   {
  //     value:DistObj['ActivelyDisengaged'],
  //     itemStyle:{
  //       color:'#d12e2e'
  //     }
  //   },
  //   {
  //     value:DistObj['Passive'],
  //     itemStyle:{
  //       color:'#f4ae34'
  //     }
  //   },
  //   {
  //     value:DistObj['ModeratelyEngaged'],
  //     itemStyle:{
  //       color:'#a2e37d'
  //     }
  //   },
  //   {
  //     value:DistObj['HighlyEngaged'],
  //     itemStyle:{
  //       color:'#00cc66'
  //     }
  //   },
  // ]



// console.log('DistObj: ',DistObj)


  return (
    <>	
      <div className="pt-1 -mt-6" style={{width:'500px'}}>
        <ReactEcharts
          option={{
            tooltip:{
              trigger: 'item',
              formatter:function (a) {
                console.log(a)
                return names[a.name]+': <b>'+a.value+'%</b>'
              }
            },
            xAxis: {
                type: 'category',
                data: names,
                axisLabel: {
                  show: false
                }
            },
            yAxis: {
                type: 'value',
                max:100
            },
            series: [{
                data: data,
                type: 'bar',
                showBackground: true,
                barWidth:'30px',
                label:{
                    show: true,
                    position: 'bottom',
                    formatter:function (a) {
                    console.log(a)
                    return names[a.name]
                    }
                  },
            }]
        }}
                          
        />
    </div>
                 
    </>
  );
}
