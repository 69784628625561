import React, { Component } from 'react';
import { connect } from 'react-redux';


import CrossTab from './Components/CrossTab/CrossTab';
import QueCorrelation from './Components/QueCorrelation/QueCorrelation';


class AdvanceComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType:1
    }
  }
  componentDidMount() {

  }

  onOptionType=(val)=>{
    this.setState({optionType:val})
    let { onCrossTab } = this.props;

    if(val===1){
      onCrossTab()
    }

  }

  onSwitchOptionScreen=()=>{
    let { 
      EmpFilterData, getIndexFilterData, loading, questionsList,
      crossTabAnalysisData,
      handleCrossTabApi,
      crossTabData,
      getCorrelationQuestionType2Data,
      handleQueCorrelation
    
    } = this.props;

    let {optionType} = this.state;


    questionsList.filter(x=>x.templateId!==13)

    switch(optionType){
      
      case 1: return <CrossTab 
      questionsList={questionsList && questionsList.length>0? questionsList.filter(x=>x.templateId!==13).filter(x=>x.templateId!==14).filter(x=>x.templateId!==3).filter(x=>x.templateId!==4):[]}
      handleCrossTabApi={handleCrossTabApi}
      crossTabData={crossTabData}
      EmpFilterData={EmpFilterData} 
      getIndexFilterData={getIndexFilterData} 
      crossTabAnalysisData={crossTabAnalysisData}
      />

      case 2: return <QueCorrelation 
      questionsList={questionsList && questionsList.length>0? questionsList.filter(x=>x.templateId!==13).filter(x=>x.templateId!==14).filter(x=>x.templateId!==3).filter(x=>x.templateId!==4):[]}
      handleCrossTabApi={handleCrossTabApi}
      crossTabData={crossTabData}
      EmpFilterData={EmpFilterData} 
      getIndexFilterData={getIndexFilterData} 
      crossTabAnalysisData={crossTabAnalysisData}
      handleQueCorrelation={handleQueCorrelation}
      getCorrelationQuestionType2Data={getCorrelationQuestionType2Data}
      />
      
      default: return <div>Default Option</div> 
    }
  }

  render() {
    let { optionType} = this.state;

    

    return (
      <> 
      <main className="flex-1 w-full bg-gray-100  overflow-hidden overflow-y-auto ">
        
        <nav className="scroll flex justify-left bg-white  overflow-x-auto  px-6 overscroll-x-contain">
          <div className="cursor-pointer flex justify-left">
            
            
            <a onClick={()=>this.onOptionType(1)} className={(optionType===1)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            CrossTab
            </a>

            <a onClick={()=>this.onOptionType(2)} className={(optionType===2)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Correlation
            </a>



          </div>
        </nav>

          {this.onSwitchOptionScreen()}
      </main> 


      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AdvanceComparision);
