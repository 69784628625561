import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { user } from './user.reducer';
import { dashboard } from './dashboard.reducer';
import { survey } from './survey.reducer';
import { questions } from './questions.reducer';
import { campaign } from './campaign.reducer';
import { general } from './general.reducer';

const rootReducer = combineReducers({
  authentication,
  user,
  dashboard,
  survey,
  questions,
  campaign,
  general
});

export default rootReducer;
