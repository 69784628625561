import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList=()=>{
    if(this.state.showChartList){
      this.setState({showChartList:false})
    }
    else{
      this.setState({showChartList:true})
    }
  }


  render() {
    let { DriverList, getMeanScoreData,ItemSaveData, OverviewList } = this.props;

    let nameList=[]
    let scoreList=[]




    let overallmean = 0;
    if(OverviewList && OverviewList.length>0){
      let sum =0;
      OverviewList.forEach((data, index)=>{
        sum+=parseFloat(data.mean);
        if(index+1===OverviewList.length){overallmean=sum/OverviewList.length}
      });


      nameList.push({
        value:"Overall",
        textStyle:{fontWeight:'bold'}
      });
      scoreList.push({
        value:(overallmean)?overallmean.toFixed(2):"0",
        itemStyle: {color: '#1dbf4d'}
      });

    }


    OverviewList.sort((b,a)=>a.name - b.name);


    if(DriverList && DriverList.length>0){
      DriverList.forEach((data, index)=>{

          nameList.push({
            value:data.driverName,
            textStyle:{fontWeight:'bold'}
          });
          scoreList.push({
            value:data.driverMean?data.driverMean.toFixed(2):"0",
            itemStyle: {color: '#8f34eb'}
          })

      })
    }





    return (
      <>
         {/* <div className="stack-title">
            {currFilterName} 
          </div> */}
          <div className="stack-body pb-4">
              {true?
              <>
                            <ReactEcharts
                            style={{height:(nameList.length<4)?'300px':(nameList.length<7)?'350px':(nameList.length>20)?(nameList.length*60).toString()+'px':(nameList.length*50).toString()+'px',
                            marginTop:'-60px'
                          }}
                            option={{
                              tooltip: {
                                  trigger: 'axis',
                                  axisPointer: {     
                                      type: 'shadow'        
                                  },
                                  formatter: function(a,b,c,d){
                                    return (a && a[0] && a[0].name)?a[0].name+': '+a[0].value+'':''
                                  } 


                              },
                              legend: {
                                data:[]
                              },
                              grid: {
                                  left: '3%',
                                  right: '4%',
                                  bottom: '3%',
                                  containLabel: true
                              },
                              xAxis: {
                                  type: 'value',
                                  splitLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                },
                                axisLine: {
                                  lineStyle: {
                                    color:'#e6e6e6'
                                  }
                                }                              
                              },
                              yAxis: {
                                  type: 'category',
                                  data: nameList.reverse(),
                                  axisLine: {
                                    lineStyle: {
                                      color:'#e6e6e6'
                                    }
                                  }
                              },
                              axisLabel: {
                                color: "#000",
                                interval: 0,
                                formatter: function(value) {
                                  if (value.length > 50) {
                                    return value.substring(0, 50) + "..";
                                  } else {
                                    return value;
                                  }
                                }
                              },
                              series: [
                                  {
                                      name: 'Score',
                                      type: 'bar',
                                      barWidth: '30px',
                                      stack: '总量',
                                      label: {
                                          show: true,
                                          position: 'insideRight',
                                          color:'#fff'
                                      },
                                      data: scoreList.reverse()
                                      
                                  },
                                 
                              ]
                          }}
                          />
                </>
              :null}
          </div>
     

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StackChart);
